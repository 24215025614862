import * as React from "react";
import Svg, { Path } from "react-native-svg";

function OptionsChat(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M13.5 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm-7.875-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm12.75 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="#667085"
      />
    </Svg>
  );
}

export default OptionsChat;
