import { View, StyleSheet } from "react-native";
import CustomMarkdown from "../../common/general/CustomMarkdown/CustomMarkdown";
import { Check, Copy } from "../../svgs/common";
import useCopyContent from "../../../hooks/useCopyContent";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { useState } from "react";

function AIMessage({ text }) {
  if (!text) return;
  const [isHovered, setIsHovered] = useState(false);
  const [showCheckMark, setShowCheckMark] = useCopyContent();

  const handleCopyCodePress = () => {
    navigator.clipboard.writeText(text);
    setShowCheckMark(true);
  };

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <View style={styles.container}>
      <CustomMarkdown content={text} />
      <View style={styles.copyContainer} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        {!showCheckMark && (
          <CustomButton
            styleType="transparent"
            size="s"
            onPress={handleCopyCodePress}
            aria-label="Copy message"
            style={styles.codeCopyButton}
            leftIcon={
              <Copy
                style={
                  !isHovered
                    ? {
                        stroke: "#9b9b9b",
                        height: 24,
                        width: 24,
                      }
                    : {
                        height: 24,
                        width: 24,
                      }
                }
              />
            }
          />
        )}
        {showCheckMark && (
          <Check
            style={[
              styles.checkIcon,
              !isHovered && {
                stroke: "#9b9b9b",
              },
            ]}
          />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexShrink: 1,
  },
  codeCopyButton: {
    padding: 5,
    width: 28,
    height: 28,
  },
  checkIcon: {
    padding: 5,
  },
  copyContainer: {
    width: 28,
  },
});

export default AIMessage;
