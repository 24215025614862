import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import Divider from "../../common/layout/Divider/Divider";

function MealCard({ name, calories, protein, fat, carbs }) {
  return (
    <View accessible={true} aria-label="Meal card">
      <View style={styles.subContainer}>
        <CustomText useTranslationText={false} text={capitalizeFirstLetterOfEachWord(name)} weight="bold" />
        <View style={styles.caloriesContainer}>
          <CustomText useTranslationText={false} text={`${calories} Calories`} size="s" style={styles.valueText} />
        </View>
      </View>

      <View style={styles.subContainer}>
        <CustomText useTranslationText={false} text={`${protein}g Protein`} size="s" style={styles.valueText} />
        <Divider type="horizontal" size="m" />
        <CustomText useTranslationText={false} text={`${fat}g Fat`} size="s" style={styles.valueText} />
        <Divider type="horizontal" size="m" />
        <CustomText useTranslationText={false} text={`${carbs}g Carbs`} size="s" style={styles.valueText} />
      </View>
      <Divider type="vertical" size="xs" />
    </View>
  );
}

function capitalizeFirstLetterOfEachWord(str) {
  return str.replace(/\b\w/g, (match) => match.toUpperCase());
}

const styles = StyleSheet.create({
  subContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 20,
  },
  caloriesContainer: {
    backgroundColor: "#E3E9FF",
    padding: 10,
    borderRadius: 50,
  },
  valueText: {
    fontFamily: "DmMono",
  },
});

export default MealCard;
