import { Platform, Linking } from "react-native";
import Sentry from "./sentry";

const handleOpenLink = async (url, newWindow) => {
  if (!url) {
    Sentry.captureException(error);
    return;
  }

  try {
    if (Platform.OS === "web") {
      if (newWindow) {
        newWindow.location.href = url;
      } else {
        window.open(url, "_blank");
      }
    } else {
      await Linking.openURL(url);
    }
  } catch (error) {
    Sentry.captureException(error);
    if (Platform.OS === "web" && newWindow) {
      newWindow.close();
    }
  }
};

export default handleOpenLink;
