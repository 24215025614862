import AspectForm from "../../../components/chat/AspectForm/AspectForm";
import {
  useDeleteAspectMutation,
  useDeleteMessageMutation,
  TodayChatDocument,
} from "../../../graphql/generated/graphql";

function AspectFormContainer({ message, onClose }) {
  const [deleteAspect] = useDeleteAspectMutation();
  const [deleteMessage] = useDeleteMessageMutation({
    refetchQueries: [
      {
        query: TodayChatDocument,
        variables: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        },
      },
    ],
  });

  const handleDeleteAspect = async (id, nodeId) => {
    if (nodeId) {
      await deleteAspect({
        variables: {
          nodeId,
        },
      });
    }

    await deleteMessage({
      variables: {
        id,
      },
    });

    onClose();
  };

  return <AspectForm message={message} onDelete={(id, nodeId) => handleDeleteAspect(id, nodeId)} onClose={onClose} />;
}

export default AspectFormContainer;
