import Svg, { Path } from "react-native-svg";

function JournalIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M19.5 1.875H6.75A3.375 3.375 0 003.375 5.25V21A1.125 1.125 0 004.5 22.125H18a1.125 1.125 0 100-2.25H5.625V19.5a1.125 1.125 0 011.125-1.125H19.5a1.125 1.125 0 001.125-1.125V3A1.125 1.125 0 0019.5 1.875zm-8.25 2.25H15V9.75l-1.2-.9a1.125 1.125 0 00-1.35 0l-1.2.9V4.125zm7.125 12H6.75c-.383 0-.764.065-1.125.193V5.25A1.125 1.125 0 016.75 4.125H9V12a1.125 1.125 0 001.8.9l2.325-1.744 2.326 1.744a1.125 1.125 0 001.799-.9V4.125h1.125v12z"
        fill={fill}
      />
    </Svg>
  );
}

export default JournalIcon;
