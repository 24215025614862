import React from "react";
import { View, Text, TouchableOpacity, StyleSheet } from "react-native";

export interface Button {
  icon: React.ReactNode;
  onPress: () => void;
}

export interface RowItem {
  text: string;
}

export interface ListItemProps {
  title: string;
  rowItems: RowItem[];
  icon?: React.ReactNode;
  buttons: Button[];
}

const ListItem: React.FC<ListItemProps> = ({ title, rowItems, icon, buttons }) => {
  return (
    <View style={styles.container}>
      {icon && <View style={styles.iconContainer}>{icon}</View>}
      <View style={styles.titleContainer}>
        <Text style={styles.title}>{title}</Text>
      </View>
      <View style={styles.rowContainer}>
        {rowItems.map((item, index) => (
          <Text key={index} style={styles.rowItem}>
            {item.text}
          </Text>
        ))}
      </View>
      <View style={styles.buttonContainer}>
        {buttons.map((button, index) => (
          <TouchableOpacity key={index} onPress={button.onPress} style={styles.button}>
            {button.icon}
          </TouchableOpacity>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    padding: 10,
    borderBottomWidth: 1,
    borderColor: "#ddd",
    alignItems: "center",
  },
  iconContainer: {
    marginRight: 10,
  },
  titleContainer: {
    flex: 1,
  },
  title: {
    fontWeight: "bold",
    fontSize: 16,
  },
  rowContainer: {
    flex: 3,
    flexDirection: "row",
    justifyContent: "space-between",
  },
  rowItem: {
    fontSize: 14,
    color: "#555",
    marginHorizontal: 5,
  },
  buttonContainer: {
    flexDirection: "row",
  },
  button: {
    marginLeft: 10,
  },
});

export default ListItem;
