import { StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function InviteForm({ email, errorMessage, onChangeText, onFocus, onBlur, onSubmit }) {
  return (
    <>
      <CustomText text="Email" />
      <CustomSpacing type="vertical" size="xs" />
      <CustomTextInput
        onFocus={onFocus}
        onBlur={onBlur}
        value={email}
        onChangeText={(text) => onChangeText(text)}
        placeholder="Enter email to send an invite to"
      />

      {errorMessage ? (
        <>
          <CustomSpacing type="vertical" size="s" />
          <CustomText text={errorMessage} size="s" style={styles.errorMessage} />
          <CustomSpacing type="vertical" size="s" />
        </>
      ) : (
        <CustomSpacing type="vertical" size="xl" />
      )}

      <CustomButton text="Send Invite" styleType="primary" onPress={onSubmit} />
    </>
  );
}

const styles = StyleSheet.create({
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});

export default InviteForm;
