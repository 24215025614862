import React from "react";
import { View, Text, StyleSheet, Pressable } from "react-native";
import { MotiView } from "moti";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import Duration from "../Duration/Duration";
import CustomText from "../../common/general/CustomText/CustomText";
import handleOpenLink from "../../../utils/handleOpenLink";
import { shadowStyles } from "../../../styles/shadow";

function Event({ title, description, duration, time }) {
  const { isMedium } = useResponsiveScreen();

  const isUrl = (string) => {
    const pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // or ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return pattern.test(string);
  };

  const formattedTitle = capitalizeFirstLetterOfEachWord(title);
  const formattedDescription = isUrl(description) ? description : capitalizeFirstLetterOfEachWord(description);

  return (
    <MotiView
      from={{
        opacity: 0,
        translateX: -400,
      }}
      animate={{
        opacity: 1,
        translateX: 0,
      }}
      delay={200}
      transition={{ type: "spring" }}>
      <View style={styles.mainContainer}>
        <View style={styles.timeContainer}>
          <Text style={styles.timeText}>{time}</Text>
        </View>

        <View style={[styles.card, shadowStyles.primary]}>
          <View style={styles.row}>
            <View style={styles.leftContainer}>
              <View style={styles.textContainer}>
                <CustomText
                  text={formattedTitle}
                  size="m"
                  weight="bold"
                  numberOfLines={2}
                  ellipsizeMode="tail"
                  useTranslationText={false}
                />
                {isMedium &&
                  (isUrl(description) ? (
                    <Pressable role="button" onPress={() => handleOpenLink(description, false)}>
                      <Text style={styles.link}>{formattedDescription}</Text>
                    </Pressable>
                  ) : (
                    <Text style={styles.description} numberOfLines={1} ellipsizeMode="tail">
                      {formattedDescription}
                    </Text>
                  ))}
              </View>
            </View>
          </View>
          <View style={styles.row}>
            <View style={styles.leftContainer}>
              <View style={styles.durationContainer}>
                <Duration duration={duration} />
              </View>
            </View>
          </View>
        </View>
      </View>
    </MotiView>
  );
}

function capitalizeFirstLetterOfEachWord(str) {
  return str.replace(/\b\w/g, (l) => l.toUpperCase());
}

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  card: {
    flexGrow: 1,
    padding: 20,
    borderRadius: 10,
    marginVertical: 10,
  },
  description: {
    color: "#676B78",
    fontFamily: "Inter",
    fontSize: 12,
    fontWeight: "400",
  },
  link: {
    color: "#676B78",
    textDecorationLine: "underline",
    fontSize: 12,
    fontWeight: "400",
    fontFamily: "Inter",
  },
  row: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  leftContainer: {
    flexDirection: "row",
    alignItems: "center",
    flexShrink: 1,
  },
  textContainer: {
    marginLeft: 10,
    flexShrink: 1,
  },
  durationContainer: {
    marginTop: 10,
    marginLeft: 10,
    flexShrink: 1,
  },
  timeText: {
    fontSize: 12,
    color: "#2D2F36",
    fontFamily: "DmMono",
    fontWeight: "400",
  },
  timeContainer: {
    marginRight: 10,
    width: 60,
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default Event;
