import React from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import DeleteJourney from "../../../components/journey/DeleteJourney/DeleteJourney";

const DeleteJourneyModalContainer = ({ visible, onClose, onDelete }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Delete Journey"
      style={{ width: isMedium ? "50%" : "90%" }}>
      <DeleteJourney deleteButtonClick={onDelete} backButtonClick={onClose} />
    </CustomModal>
  );
};

export default DeleteJourneyModalContainer;
