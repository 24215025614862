import React from "react";
import AssignmentCardList from "../../../components/dashboard/AssignmentCardList/AssignmentCardList";
import NoAssignments from "../../../components/common/NoAssignments/NoAssignments";
import { View } from "react-native";
import { MotiView } from "moti";

const AssignmentCardListContainer = ({ Assignments }) => {
  let Length = Assignments.length;
  if (Length > 0) {
    return (
      <View>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 0.95,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          style={{ flex: 1 }}
          delay={0}
          transition={{ type: "timing", duration: 800 }}>
          <AssignmentCardList assignments={Assignments} />
        </MotiView>
      </View>
    );
  } else {
    return (
      <View>
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 0.95,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          style={{ flex: 1 }}
          delay={0}
          transition={{ type: "timing", duration: 800 }}>
          <NoAssignments />
        </MotiView>
      </View>
    );
  }
};

export default AssignmentCardListContainer;
