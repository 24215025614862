import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function CoreTitle(props) {
  return (
    <Svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect width={26} height={26} rx={13} fill="#054CD4" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.423 8.605c-1.632-.235-2.936.077-3.452.971s-.134 2.18.884 3.475c-1.018 1.295-1.4 2.58-.884 3.474.516.894 1.82 1.206 3.452.972.612 1.53 1.535 2.503 2.566 2.503 1.032 0 1.955-.974 2.567-2.503 1.631.234 2.935-.078 3.451-.972.516-.893.134-2.179-.884-3.474 1.018-1.296 1.4-2.581.884-3.475-.516-.893-1.82-1.206-3.45-.971-.613-1.53-1.536-2.503-2.568-2.503-1.031 0-1.954.973-2.566 2.503zm-.398 1.243c-.106.424-.192.874-.254 1.344-.376.29-.723.589-1.038.893a6.056 6.056 0 01-.12-.17c-.714-1.045-.604-1.57-.53-1.697.073-.128.472-.485 1.734-.39.068.005.138.012.208.02zm1.673-.966c.42.12.853.271 1.291.452.439-.181.871-.332 1.292-.452a6.062 6.062 0 00-.087-.19c-.548-1.14-1.057-1.307-1.205-1.307-.147 0-.656.167-1.204 1.308a6.06 6.06 0 00-.087.189zm1.291 1.853a13.088 13.088 0 00-2.005 1.158 13.091 13.091 0 000 2.316 13.12 13.12 0 002.005 1.158 13.075 13.075 0 002.006-1.159 13.086 13.086 0 000-2.315 13.091 13.091 0 00-2.006-1.158zm4.256 1.35a12.17 12.17 0 00-1.037-.892c-.062-.47-.148-.92-.254-1.345.07-.008.14-.015.207-.02 1.262-.095 1.661.262 1.735.39.074.127.184.652-.53 1.697a5.759 5.759 0 01-.12.17zm0 1.932c-.314.304-.66.603-1.037.892-.062.47-.148.92-.254 1.345.07.007.139.014.207.019 1.262.096 1.661-.262 1.735-.39.073-.127.183-.652-.53-1.697a6.296 6.296 0 00-.12-.17zm-8.512 0c.315.304.662.603 1.038.892.062.47.148.92.254 1.345-.07.007-.14.014-.207.019-1.263.096-1.662-.262-1.735-.39-.074-.127-.184-.651.53-1.697.038-.056.078-.113.12-.17zm4.256 2.75c.439.181.871.332 1.292.453a6.175 6.175 0 01-.087.189c-.548 1.14-1.057 1.308-1.205 1.308-.147 0-.656-.168-1.204-1.308a6.073 6.073 0 01-.087-.19c.42-.12.853-.27 1.291-.451z"
        fill="#fff"
      />
    </Svg>
  );
}

export default CoreTitle;
