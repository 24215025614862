import React from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CompleteJourney from "../../../components/journey/CompleteJourney/CompleteJourney";

const CompleteJourneyModalContainer = ({ visible, onClose, onComplete }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Complete Journey"
      style={{ width: isMedium ? "50%" : "90%" }}>
      <CompleteJourney completeButtonClick={onComplete} backButtonClick={onClose} />
    </CustomModal>
  );
};

export default CompleteJourneyModalContainer;
