import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { CheckCircle } from "../../../svgs/common";

const TrackOverviewHeroSection = ({ title, description, onCardPress, objectives }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <View style={isMedium ? styles.topRow : styles.topRowMobile}>
        <CustomText
          useTranslationText={false}
          role="header"
          aria-level="1"
          text={title}
          weight="ultraBold"
          textType="display"
          size={isMedium ? "m" : "xs"}
        />
        <CustomButton
          text="Start Track"
          styleType="primary"
          bold
          style={styles.trackButton}
          textStyle={{ fontSize: 12 }}
          onPress={onCardPress}
        />
      </View>
      <CustomSpacing type="vertical" size={isMedium ? "s" : "l"} />
      <CustomText
        useTranslationText={false}
        text={description}
        size={isMedium ? "m" : "s"}
        style={{ color: "#344054" }}
        numberOfLines={6}
        ellipsizeMode="tail"
      />
      <CustomSpacing type="vertical" size="xl" />
      <CustomText
        text="What you'll learn"
        weight="ultraBold"
        textType="display"
        size={isMedium ? "xs" : "xs"}
        style={{ color: "#182230" }}
        role="header"
        aria-level="2"
      />
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.list} role="list">
        {objectives.map((objective, index) => (
          <View role="listitem" key={index} style={styles.objective}>
            <CheckCircle />
            <CustomText useTranslationText={false} text={objective} size="m" numberOfLines={3} ellipsizeMode="tail" />
          </View>
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "left",
    textAlign: "left",
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  topRowMobile: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 12,
  },
  trackButton: {
    padding: 12,
  },
  objective: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
  },
  list: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 14,
  },
});

export default TrackOverviewHeroSection;
