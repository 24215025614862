import { View, StyleSheet } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { ScrollView } from "react-native-gesture-handler";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function QuickOptionList({ options, onPress }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <ScrollView
      role="list"
      aria-label="Chat quick options"
      horizontal={true}
      showsHorizontalScrollIndicator={false}
      style={styles.scrollView}
      contentContainerStyle={styles.scrollViewContent}>
      {options.map((option, index) => (
        <View role="listitem" style={styles.buttonContainer} key={index}>
          <CustomButton
            key={index}
            text={option.title}
            size="m"
            style={[styles.button, isMedium ? { height: 28 } : { height: 26 }]}
            textStyle={styles.buttonText}
            onPress={() => onPress(option)}
            textProps={{ numberOfLines: 1 }}
          />
          {index < options.length - 1 && <CustomSpacing type="horizontal" size="m" />}
        </View>
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: "row",
  },
  button: {
    boxShadow: "none",
    backgroundColor: "#E6EBFF",
    borderRadius: 360,
    paddingHorizontal: 8,
    paddingVertical: 4,
  },
  buttonText: {
    fontFamily: "Inter",
    textAlign: "center",
    fontSize: 14,
    fontWeight: 400,
  },
  scrollView: {
    width: "100%",
  },
  scrollViewContent: {
    flexGrow: 1,
    flexDirection: "row",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default QuickOptionList;
