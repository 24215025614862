import React from "react";
import { StyleSheet, View } from "react-native";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { LookingForJob } from "../../../svgs/careerGoals/index";
import CustomText from "../../../common/general/CustomText/CustomText";

const LookingForAJob = () => {
  const { isLarge } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <LookingForJob style={{ paddingRight: 4 }} aria-hidden={true} />
      <CustomText size={isLarge ? "s" : "xs"} style={styles.text} text="Looking for a Job" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
});

export default LookingForAJob;
