import { Pressable, View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

function LearningMessage({ topic, label, onPress }) {
  const labelMapping = {
    Field: "Field",
    Subfield: "Category",
    Topic: "Track",
    Chapter: "Lesson",
    Subchapter: "Unit",
  };

  const displayLabel = labelMapping[label] || label;

  return (
    <Pressable onPress={onPress} style={styles.container} role="button" aria-label="Learning message button">
      <View style={styles.labelContainer}>
        <CustomText text={displayLabel} styleType="light" weight="bold" size="s" useTranslationText={false} />
      </View>

      <CustomText useTranslationText={false} text={topic} textType="display" size="xs" />
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
    padding: 20,
    backgroundColor: "#F4F6FD",
    alignSelf: "flex-start",
  },
  labelContainer: {
    backgroundColor: "#913DAF",
    borderRadius: 8,
    padding: 10,
    marginRight: 12,
  },
});

export default LearningMessage;
