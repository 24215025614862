import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

function AxioDemoLogo(props) {
  return (
    <Svg width={153} height={28} viewBox="0 0 153 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M28.385 26.062c-.879 0-1.746-.4-2.315-1.157L20.4 17.346c-2.382-3.177-7.147-3.176-9.528 0l-5.668 7.56a2.892 2.892 0 11-4.627-3.471L13.324 4.439a2.892 2.892 0 014.626 0l12.746 16.996a2.892 2.892 0 01-2.31 4.627z"
        fill="#3E68FE"
      />
      <Path
        d="M8.753 25.274l4.37-5.288c1.388-1.68 3.64-1.68 5.028 0l4.37 5.288c.595.72.174 1.951-.668 1.951H9.421c-.841 0-1.262-1.231-.668-1.951zM52.38 7.64v15.77c0 .287-.231.52-.516.52h-1.786a.519.519 0 01-.516-.52v-2.678c-1.37 2.211-3.696 3.592-6.478 3.592-4.776 0-8.34-3.79-8.34-8.8 0-5.009 3.565-8.799 8.34-8.799 2.782 0 5.102 1.387 6.478 3.593V7.64c0-.287.232-.52.516-.52h1.786c.285 0 .516.233.516.52zm-2.812 7.885c0-3.462-2.55-6.068-5.95-6.068-3.4 0-5.986 2.606-5.986 6.068 0 3.461 2.551 6.061 5.986 6.061 3.435 0 5.95-2.606 5.95-6.061zM68.82 23.727l-4.788-6.355-4.752 6.355a.507.507 0 01-.41.209h-2.13a.52.52 0 01-.409-.837l5.808-7.604-5.713-7.538a.52.52 0 01.41-.837h2.135c.16 0 .315.077.41.209l4.686 6.253 4.687-6.253a.507.507 0 01.41-.21h2.135c.427 0 .67.491.41.838l-5.714 7.538 5.808 7.604a.52.52 0 01-.41.837h-2.165a.519.519 0 01-.409-.203v-.006zM75.268 1.877C75.268.79 76.086 0 77.166 0s1.863.79 1.863 1.877c0 1.088-.819 1.877-1.863 1.877a1.88 1.88 0 01-1.898-1.877zm1.008 5.243h1.78c.285 0 .516.233.516.52v15.77c0 .287-.231.52-.516.52h-1.78a.519.519 0 01-.516-.52V7.64c0-.287.231-.52.516-.52zM82.238 15.525c0-5.01 3.827-8.8 8.899-8.8 5.072 0 8.863 3.79 8.863 8.8 0 5.01-3.826 8.8-8.863 8.8-5.037 0-8.899-3.79-8.899-8.8zm14.855 0c0-3.462-2.55-6.068-5.95-6.068-3.4 0-5.986 2.606-5.986 6.068 0 3.461 2.551 6.061 5.986 6.061 3.435 0 5.95-2.606 5.95-6.061z"
        fill="#011665"
      />
      <Rect x={104} y={7} width={49} height={17} rx={8.5} fill="#E6EBFF" />
      <Path
        d="M115.83 19.51h-3.101V11h3.101c2.65 0 4.413 1.739 4.413 4.243 0 2.492-1.763 4.268-4.413 4.268zm-1.277-6.99v5.47h1.277c1.629 0 2.541-1.13 2.541-2.747 0-1.63-.912-2.723-2.541-2.723h-1.277zM124.304 19.584c-2.079 0-3.332-1.253-3.332-3.186 0-1.945 1.277-3.21 3.21-3.21 1.872 0 3.137 1.168 3.161 3.003 0 .183-.012.39-.049.584h-4.534v.085c.036.888.607 1.423 1.471 1.423.693 0 1.179-.304 1.325-.888h1.69c-.195 1.216-1.277 2.189-2.942 2.189zm-1.508-3.928h2.821c-.122-.766-.645-1.191-1.423-1.191-.741 0-1.301.45-1.398 1.191zM135.757 13.188c1.289 0 2.31.73 2.31 2.736v3.587h-1.714v-3.49c0-.936-.341-1.422-1.082-1.422-.742 0-1.24.547-1.24 1.495v3.416h-1.715v-3.489c0-.936-.34-1.422-1.094-1.422-.742 0-1.228.571-1.228 1.507v3.404h-1.714v-6.249h1.495l.158.779c.377-.487.924-.84 1.836-.852.766-.012 1.483.268 1.873 1.058.437-.668 1.167-1.058 2.115-1.058zM142.172 19.584c-1.994 0-3.295-1.277-3.295-3.198s1.301-3.198 3.295-3.198c1.993 0 3.294 1.277 3.294 3.198 0 1.933-1.301 3.198-3.294 3.198zm0-1.399c.96 0 1.556-.741 1.556-1.8 0-1.057-.596-1.798-1.556-1.798-.961 0-1.545.741-1.545 1.799 0 1.058.584 1.8 1.545 1.8z"
        fill="#011665"
      />
    </Svg>
  );
}

export default AxioDemoLogo;
