import { Text } from "react-native";
import { useFoodByDateQuery } from "../../../graphql/generated/graphql";
import MealCardList from "../../../components/health/MealCardList/MealCardList";

function MealCardListContainer({ date }) {
  const { data, loading, error } = useFoodByDateQuery({
    variables: {
      date: date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error...</Text>;

  const meals = data.getFoodsByDate.map((meal, index) => {
    return {
      id: index,
      name: meal.name,
      calories: meal.calories,
      protein: meal.protein_grams,
      fat: meal.fat_grams,
      carbs: meal.carbohydrates_grams,
    };
  });

  return <MealCardList items={meals} />;
}

export default MealCardListContainer;
