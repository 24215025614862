import React from "react";
import { View, StyleSheet, ActivityIndicator } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import Logo from "../../general/logos/Logo/Logo";
import { useAppConfig } from "../../../AppConfigProvider";

function LoginForm({
  email,
  password,
  isValidEmail,
  errorMessage,
  companionName,
  onEmailChangeText,
  onPasswordChangeText,
  onSubmit,
  onResetPasswordPress,
  onSignupPress,
  isLoading,
}) {
  const { primaryColor } = useAppConfig();
  return (
    <View style={styles.container}>
      <View accessible={true} role="header">
        <Logo name={companionName} height={100} width={280} />
        <CustomSpacing type="vertical" size="m" />
        <CustomText
          text="sign_in_to_companion"
          textType="display"
          size="xs"
          weight="bold"
          interpolation={{ companionName: companionName.charAt(0).toUpperCase() + companionName.slice(1) }} // Capitalizing companionName
        />
      </View>
      <CustomSpacing type="vertical" size="m" />

      <CustomText text="Email Address" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        style={[!isValidEmail && styles.textInputError, { backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }]}
        value={email}
        placeholder="Email..."
        onChangeText={(text) => onEmailChangeText(text)}
        autoComplete="email"
        aria-label="Email text input"
        accessibilityHint="Enter your email address"
      />
      <View aria-live={isValidEmail ? "assertive" : "none"}>
        {!isValidEmail ? (
          <>
            <CustomSpacing type="vertical" size="xs" />
            <CustomText text="Please enter a valid email." role="alert" size="xs" style={styles.errorText} />
          </>
        ) : (
          <CustomSpacing type="vertical" size="m" />
        )}
      </View>
      <CustomSpacing type="vertical" size="s" />

      <CustomText text="Password" size="s" />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={password}
        style={{ backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }}
        placeholder="Password..."
        onChangeText={(text) => onPasswordChangeText(text)}
        secureTextEntry
        aria-label="Password text input"
        accessibilityHint="Enter your password"
      />

      <View aria-live={errorMessage ? "assertive" : "none"}>
        {errorMessage ? (
          <>
            <CustomSpacing type="vertical" size="s" />
            <CustomText role="alert" text={errorMessage} size="s" style={styles.errorMessage} />
            <CustomSpacing type="vertical" size="s" />
          </>
        ) : (
          <CustomSpacing type="vertical" size="xl" />
        )}
      </View>

      {isLoading && !errorMessage ? (
        <View style={styles.loader}>
          <ActivityIndicator color={primaryColor} />
        </View>
      ) : (
        <CustomButton
          text="Sign in"
          accessibilityHint="Press to sign in"
          size="m"
          bold={true}
          styleType="primary"
          style={styles.loginButton}
          onPress={onSubmit}
          disabled={isLoading}
        />
      )}
      <CustomSpacing type="vertical" size="l" />

      <View style={styles.footer}>
        <CustomText text="Forgot your password?" size="s" style={styles.footerText} />
        <CustomButton
          text="Reset Password"
          styleType="transparent"
          size="s"
          onPress={onResetPasswordPress}
          accessibilityHint="Press to reset your password"
        />
      </View>
      <CustomSpacing type="vertical" size="s" />

      <View style={styles.footer}>
        <CustomText text="Not a member?" size="s" style={styles.footerText} />
        <CustomButton
          accessibilityHint="Press to sign up"
          text="Sign Up"
          styleType="transparent"
          size="s"
          onPress={onSignupPress}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
    padding: 9,
  },
  errorText: {
    color: "red",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
  loginButton: {
    justifyContent: "center",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  footerText: {
    color: "#6B7280",
  },
  loader: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default LoginForm;
