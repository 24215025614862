import { View, StyleSheet } from "react-native";
import { CaretDown, CalendarIcon } from "../../svgIcons";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function DatePickerButton({ date, onPress, buttonType }) {
  const { isMedium } = useResponsiveScreen();
  return (
    <View style={styles.buttonWrapper}>
      <CustomButton
        useTranslationText={false}
        text={date}
        styleType="primaryLight"
        leftIcon={<CalendarIcon />}
        rightIcon={<CaretDown />}
        onPress={onPress}
        textStyle={isMedium ? {} : { fontSize: 14 }}
        aria-label={`Date picker button ${buttonType}`}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  buttonWrapper: {
    minWidth: 150,
  },
});

export default DatePickerButton;
