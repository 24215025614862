import React from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import DropdownSelect from "react-native-input-select";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import SelectableList from "../../common/form/SelectableList/SelectableList";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { shadowStyles } from "../../../styles/shadow";
const RepeatTaskForm = ({
  timePeriod,
  onSelectedTimePeriodChange,
  repeatEvery,
  onDropdownChange,
  selectedDay,
  onSelectedDayChange,
  dayOfMonth,
  onDayOfMonthChange,
  timeValue,
  setTimeValue,
  showTimeInput,
  dayOfMonthError,
}) => {
  const { isMedium } = useResponsiveScreen();
  const inputLabelTextType = "text";
  const inputLabelTextSize = "s";
  const inputLabelTextColor = isMedium ? {} : { color: "#182230" };
  const timePeriodOptions = [
    {
      value: "AM",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="AM" />
        </View>
      ),
    },
    {
      value: "PM",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="PM" />
        </View>
      ),
    },
  ];

  const daysOfWeekOptions = [
    {
      value: "Monday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="M" />
        </View>
      ),
    },
    {
      value: "Tuesday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="T" />
        </View>
      ),
    },
    {
      value: "Wednesday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="W" />
        </View>
      ),
    },
    {
      value: "Thursday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="T" />
        </View>
      ),
    },
    {
      value: "Friday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="F" />
        </View>
      ),
    },
    {
      value: "Saturday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="S" />
        </View>
      ),
    },
    {
      value: "Sunday",
      component: (
        <View style={[styles.selectable, shadowStyles.bottomShadowButton]}>
          <CustomText text="S" />
        </View>
      ),
    },
  ];

  return (
    <View style={styles.repeatContainer}>
      <View style={styles.repeatColumn}>
        <View style={styles.columnTitle}>
          <CustomText
            text="Repeat Every"
            size={inputLabelTextSize}
            textType={inputLabelTextType}
            style={inputLabelTextColor}
            weight="ultraBold"
          />
        </View>
        <DropdownSelect
          placeholder="Select..."
          selectedValue={repeatEvery}
          optionLabel={"label"}
          optionValue={"value"}
          primaryColor={"blue"}
          listHeaderComponent={
            <View style={styles.dropdownLabel}>
              <CustomText text="Repeat Every..." weight="bold" />
            </View>
          }
          options={[
            { label: "Day", value: "Day" },
            { label: "Week", value: "Week" },
            { label: "Month", value: "Month" },
          ]}
          dropdownStyle={styles.repeatEveryDropdown}
          labelStyle={styles.repeatEveryLabel}
          onValueChange={(value) => onDropdownChange(value)}
          accessible={true}
          aria-label="Dropdown to select repeating interval"
          role="combobox"
        />
      </View>

      {repeatEvery === "Week" && (
        <View style={styles.repeatColumn}>
          <View style={styles.columnTitle}>
            <CustomText text="On Day" style={styles.title} />
          </View>
          <View style={styles.selectableListContainer}>
            <SelectableList items={daysOfWeekOptions} selectedItem={selectedDay} onSelect={onSelectedDayChange} />
          </View>
        </View>
      )}

      {repeatEvery === "Month" && (
        <View style={styles.repeatColumn}>
          <View style={styles.columnTitle}>
            <CustomText text="On Day" style={styles.title} />
          </View>

          <CustomTextInput
            value={dayOfMonth}
            onChangeText={onDayOfMonthChange}
            style={{ height: 55 }}
            placeholder="Enter day of the month..."
            keyboardType="numeric"
            aria-label="Input to enter day of the month"
          />
          {dayOfMonthError ? <CustomText text={dayOfMonthError} style={styles.errorText} /> : null}
        </View>
      )}
      {showTimeInput && (
        <View style={styles.repeatColumn}>
          <View style={styles.columnTitle}>
            <CustomText text="At" style={styles.title} />
          </View>
          <View style={{ flexDirection: "row", alignItems: "flex-start" }}>
            <CustomTextInput aria-label="Input to enter time of the day" style={{ height: 55 }} placeholder="00:00" />
            <View style={styles.selectableListContainer}>
              <SelectableList
                items={timePeriodOptions}
                selectedItem={timePeriod}
                onSelect={onSelectedTimePeriodChange}
                style={{ flex: 1 }}
                value={timeValue}
                onChangeText={setTimeValue}
              />
            </View>
          </View>
        </View>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  repeatContainer: {
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    flexWrap: "wrap",
  },
  repeatColumn: {
    flexDirection: "column",
    marginRight: 20,
    justifyContent: "flex-start",
    minWidth: 150,
  },
  dropdownLabel: {
    marginLeft: 10,
  },
  repeatEveryDropdown: {
    backgroundColor: "#F8F8F8",
    paddingHorizontal: 10,
    minHeight: 45,
    borderColor: "#D1D5DB",
  },
  repeatEveryLabel: {
    color: "#000000",
    fontSize: 15,
    fontWeight: "500",
  },
  selectable: {
    alignItems: "center",
    padding: 10,
    borderRadius: 8,
    marginBottom: 10,
  },
  title: {
    paddingBottom: 10,
    flex: 0,
    alignSelf: "flex-start",
  },
  columnTitle: {
    paddingBottom: 5,
  },
  selectableListContainer: {
    marginVertical: -10,
  },
  errorText: {
    color: "red",
    marginTop: 5,
  },
});

export default RepeatTaskForm;
