import * as React from "react";
import Svg, { Path } from "react-native-svg";

function GeneralLabel(props) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M7 5.038l-1.691.977v1.97L7 8.962l1.692-.977v-1.97L7 5.038z" fill="#fff" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.08 1.61a1.167 1.167 0 10-2.16 0l-3.2 1.825a1.166 1.166 0 00-2.137.648c0 .645.523 1.167 1.167 1.167v3.5a1.167 1.167 0 10.97 1.815l3.2 1.826a1.167 1.167 0 102.16 0l3.2-1.826a1.166 1.166 0 002.137-.648c0-.645-.523-1.167-1.167-1.167v-3.5a1.167 1.167 0 10-.97-1.815L8.08 1.61zM6.427 3.886a1.146 1.146 0 011.147 0L9.4 4.943c.356.206.574.585.574.995v2.124c0 .41-.218.79-.574.995l-1.827 1.056a1.146 1.146 0 01-1.147 0L4.599 9.057a1.15 1.15 0 01-.574-.995V5.938c0-.41.218-.79.574-.995l1.828-1.056z"
        fill="#fff"
      />
    </Svg>
  );
}

export default GeneralLabel;
