import * as React from "react";
import Svg, { Path } from "react-native-svg";

function TimeLeftSmall(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 15A6 6 0 109 3a6 6 0 000 12zm0 1.5a7.5 7.5 0 100-15 7.5 7.5 0 000 15z"
        fill="#667085"
      />
      <Path
        d="M9 5.25a.75.75 0 00-.75.75v3.75c0 .414.336.75.75.75h3A.75.75 0 0012 9H9.75V6A.75.75 0 009 5.25z"
        fill="#667085"
      />
    </Svg>
  );
}

export default TimeLeftSmall;
