import { useEffect, useState } from "react";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { View } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import Sentry from "../../../utils/sentry";
import { useGetCourseQuery } from "../../../graphql/generated/graphql";
import Skeleton from "react-loading-skeleton";
import AssignmentCardProgressList from "../../../components/lms/AssignmentCardProgressList/AssignmentCardProgressList";

const AssignmentCardProgressListContainer = ({ integrationId, courseId, onProgressUpdate }) => {
  const [modulesWithAssignments, setModulesWithAssignments] = useState([]);
  const navigation = useNavigation();

  const { data: course, loading } = useGetCourseQuery({
    variables: {
      id: courseId,
    },
  });

  useEffect(() => {
    if (course && !loading) {
      const formattedModulesWithAssignments = course?.getCourse.data[0].modules.map((module) => {
        const assignments = module.resources[0].assignments.map((assignment) => {
          // Extract total objectives from assignment
          const assignmentTotalObjectives =
            assignment.hasObjectiveObjective && assignment.hasObjectiveObjective.length > 0
              ? assignment.hasObjectiveObjective.length
              : 0;

          // Calculate completed objectives from assignment
          const assignmentCompletedObjectives =
            assignment.hasObjectiveObjective && assignment.hasObjectiveObjective.length > 0
              ? assignment.hasObjectiveObjective.filter(
                  (objective) => objective.userHasProgressConnection?.edges?.[0]?.properties?.status === "completed"
                ).length
              : 0;

          const isComplete =
            assignmentCompletedObjectives > 0 && assignmentCompletedObjectives === assignmentTotalObjectives;
          // Calculate resource duration
          const resourceDurationMinutes = (assignmentTotalObjectives - assignmentCompletedObjectives) * 10;

          return {
            ...assignment,
            details: {
              isComplete,
              totalObjectives: assignmentTotalObjectives,
              completedObjectives: assignmentCompletedObjectives,
              resourceDurationMinutes,
            },
          };
        });

        return {
          ...module,
          resources: [{ assignments }],
        };
      });

      const totalObjectivesCount = formattedModulesWithAssignments.reduce((acc, module) => {
        return (
          acc + module.resources[0].assignments.reduce((acc, assignment) => acc + assignment.details.totalObjectives, 0)
        );
      }, 0);

      const completedObjectivesCount = formattedModulesWithAssignments.reduce((acc, module) => {
        return (
          acc +
          module.resources[0].assignments.reduce((acc, assignment) => acc + assignment.details.completedObjectives, 0)
        );
      }, 0);

      // Calculate total progress and total duration
      const totalProgress = totalObjectivesCount > 0 ? (completedObjectivesCount / totalObjectivesCount) * 100 : 0;
      const totalDurationMinutes = (totalObjectivesCount - completedObjectivesCount) * 10;

      setModulesWithAssignments(formattedModulesWithAssignments);
      onProgressUpdate(totalProgress, totalDurationMinutes);
    }
  }, [course]);

  const handleResourcePress = (id) => {
    const assignment = modulesWithAssignments
      .flatMap((module) => module.resources[0].assignments)
      .find((assignment) => assignment.id === id);

    if (assignment && assignment.details) {
      const params = {
        resource_type: "assignment",
        resource_id: assignment.id,
        course_id: courseId,
        integration_id: integrationId,
      };

      navigation.navigate("Custom Resource Student", params);
    } else {
      Sentry.captureException(`Resource with id ${id} not found or missing details.`);
    }
  };

  const handleOnHoverIn = (id) => {
    setModulesWithAssignments((prev) =>
      prev.map((module) => ({
        ...module,
        resources: [
          {
            assignments: module.resources[0].assignments.map((assignment) =>
              assignment.id === id ? { ...assignment, isHovered: true } : assignment
            ),
          },
        ],
      }))
    );
  };

  const handleOnHoverOut = (id) => {
    setModulesWithAssignments((prev) =>
      prev.map((module) => ({
        ...module,
        resources: [
          {
            assignments: module.resources[0].assignments.map((assignment) =>
              assignment.id === id ? { ...assignment, isHovered: false } : assignment
            ),
          },
        ],
      }))
    );
  };

  const renderContent = () => {
    if (loading) {
      return (
        <ScrollView style={{ flex: 1 }}>
          {[1, 2, 3].map((_, idx) => (
            <View key={idx}>
              <CustomSpacing type="vertical" size="l" />
              <Skeleton height={18} width={60} />
              <CustomSpacing type="vertical" size="s" />
              <Skeleton height={32} width={200} />
              <CustomSpacing type="vertical" size="l" />
              {[1, 2, 3].map((__, idx2) => (
                <Skeleton height={80} style={{ marginBottom: 10 }} key={idx2} />
              ))}
            </View>
          ))}
        </ScrollView>
      );
    }

    return (
      <ScrollView style={{ flex: 1 }}>
        {modulesWithAssignments.map((module) => (
          <View key={module.id}>
            <CustomSpacing type="vertical" size="l" />
            <CustomText
              text={"MODULE"}
              size="m"
              style={{ fontFamily: "Inter", fontSize: 12, lineHeight: 18, letterSpacing: "0.06", color: "#667085" }}
              useTranslationText={false}
            />
            <CustomText
              text={module.title}
              size="l"
              style={{ fontFamily: "PangaiaBold", fontSize: 24, lineHeight: 32 }}
              useTranslationText={false}
            />
            <CustomSpacing type="vertical" size="l" />
            <AssignmentCardProgressList
              items={module.resources[0].assignments}
              onPress={handleResourcePress}
              onHoverIn={handleOnHoverIn}
              onHoverOut={handleOnHoverOut}
            />
          </View>
        ))}
      </ScrollView>
    );
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={1500}
      transition={{ type: "timing", duration: 600 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {renderContent()}
    </MotiView>
  );
};

export default AssignmentCardProgressListContainer;
