import { parseDate } from "./date";

export function uniqBy(a, key) {
  let seen = new Set();
  return a.filter((item) => {
    let k = key(item);
    return seen.has(k) ? false : seen.add(k);
  });
}

const taskPriorityMap = {
  high: 1,
  medium: 2,
  low: 3,
};

export function sortTasks(tasks) {
  const arrayCopy = [...tasks];

  return arrayCopy.sort((a, b) => {
    const hasValidDateA = a.date !== null && a.date !== undefined;
    const hasValidDateB = b.date !== null && b.date !== undefined;

    if (hasValidDateA && hasValidDateB) {
      const parsedDateA = parseDate(a.date);
      const parsedDateB = parseDate(b.date);
      const dateDiff = parsedDateA - parsedDateB;
      if (dateDiff !== 0) {
        return dateDiff;
      }
    } else if (hasValidDateA) {
      return -1;
    } else if (hasValidDateB) {
      return 1;
    }

    const priorityDiff = taskPriorityMap[a.priority] - taskPriorityMap[b.priority];
    if (priorityDiff !== 0) {
      return priorityDiff;
    }

    return a.name.localeCompare(b.name);
  });
}
