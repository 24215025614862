import React from "react";
import { useState, useEffect } from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import ChatList from "../ChatList/ChatList";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { ScrollView } from "react-native-gesture-handler";
import ProgressTracker from "../ProgressTracker/ProgressTracker";

import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const LightAssignmentMenu = ({ activeObjectId, objectivesData, assignmentChats, onViewChatPress, onChatPress }) => {
  const { isMedium } = useResponsiveScreen();
  const [viewedChat, setViewedChat] = useState(null);

  useEffect(() => {
    setViewedChat(null);
  }, [activeObjectId]);

  const handleActiveViewPress = (id) => {
    setViewedChat((prev) => (prev === id ? null : id));
  };

  return (
    <View role="menu" style={[styles.containerBase, isMedium ? styles.containerDesktop : styles.containerMobile]}>
      {objectivesData && objectivesData.length === 0 && (
        <>
          <View style={styles.view}>
            <CustomText styleType="dark" text="Assignment Chat" size="m" textType="text" weight="ultraBold" />
          </View>

          <ScrollView
            style={[styles.scrollContainer, { width: viewedChat ? 430 : 270 }]}
            showsVerticalScrollIndicator={false}
            onScroll={() => setViewedChat(null)}>
            {assignmentChats && (
              <ChatList
                chatData={assignmentChats}
                onViewChatPress={onViewChatPress}
                activeObjectId={activeObjectId}
                viewedChat={viewedChat}
                type="assignment"
                onChatPress={onChatPress}
                onActivateViewPress={handleActiveViewPress}
                light={true}
              />
            )}
          </ScrollView>
        </>
      )}

      {objectivesData && objectivesData.length !== 0 && <ProgressTracker data={objectivesData} />}
      <CustomSpacing size={16} />
    </View>
  );
};

const styles = StyleSheet.create({
  containerBase: {
    flex: 1,
    alignSelf: "flex-start",
    overflow: "visible",
    width: 270,
    backgroundColor: "#F9FAFB",
    paddingVertical: 32,
    paddingHorizontal: 8,
  },
  containerDesktop: {
    borderRadius: 20,
  },
  containerMobile: {
    top: 0,
    left: 0,
    borderRadius: 0,
  },
  view: {
    paddingHorizontal: 8,
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
  scrollContainer: {
    flexGrow: 1,
  },
});
export default LightAssignmentMenu;
