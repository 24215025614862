import CustomText from "../../common/general/CustomText/CustomText";
import { ListItem } from "@rneui/themed";
import { StyleSheet, Pressable } from "react-native";

const CustomCourseworkMenuItem = ({ onItemPress, name, isSelected, isLast }) => {
  return (
    <ListItem.Content
      style={[
        styles.item,
        {
          backgroundColor: isSelected ? "#F2F5FF" : "#fff",
          marginBottom: isLast ? 10 : 0,
        },
      ]}>
      <Pressable onPress={onItemPress}>
        <CustomText
          useTranslationText={false}
          text={name}
          weight="normal"
          numberOfLines={2}
          ellipsizeMode="tail"
          style={styles.text}
        />
      </Pressable>
    </ListItem.Content>
  );
};

const styles = StyleSheet.create({
  item: {
    padding: 12,
    paddingLeft: 30,
    backgroundColor: "#fff",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    flexShrink: 1,
    borderRadius: 4,
  },
  text: {
    fontSize: 16,
    color: "#344054",
    textDecorationLine: "none",
    maxWidth: 300,
    flexShrink: 1,
  },
});

export default CustomCourseworkMenuItem;
