import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import { useAppConfig } from "../../../../AppConfigProvider";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";

const TrackOverviewInfoCard = ({ title, list, onLinkPress }) => {
  const { isLarge } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  return (
    <View style={[styles.container, isLarge ? {} : { width: "100%" }]}>
      <CustomText useTranslationText={false} text={title} style={{ color: "#182230" }} size="l" weight="bold" />
      <CustomSpacing type="vertical" size="m" />
      {list?.map((item, index) => (
        <View key={index}>
          {item.subfield ? (
            <View style={styles.row}>
              <CustomButton
                useTranslationText={false}
                text={item.name}
                styleType="primaryTransparent"
                style={styles.linkButton}
                textStyle={styles.linkButtonText}
                onPress={() => onLinkPress(item)}
                leftIcon={<CustomText useTranslationText={false} text="•" size="m" style={{ color: primaryColor }} />}
                aria-label="Link to course"
                accessibilityHint="Press to navigate to course"
                role="link"
              />
              {item.enrolled && !item.complete && <CustomText style={{ color: "#344054" }} text="(in progress)" />}
              {item.enrolled && item.complete && <CustomText style={{ color: "#344054" }} text="(complete)" />}
            </View>
          ) : (
            <View style={styles.row}>
              <CustomText useTranslationText={false} text="•" size="m" style={{ color: "#344054" }} />
              <CustomText
                useTranslationText={false}
                text={item.name}
                size="m"
                numberOfLines={4}
                ellipsizeMode="tail"
                style={{ color: "#344054" }}
              />
            </View>
          )}
          <CustomSpacing type="vertical" size="s" />
        </View>
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    backgroundColor: "#F9FAFB",
    padding: 20,
    height: "100%",
    flex: 1,
  },
  linkButtonText: {
    textDecorationLine: "underline",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: 24,
    fontSize: 16,
  },
  linkButton: {
    padding: 0,
    alignItems: "flex-start",
    gap: 6,
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 6,
  },
});

export default TrackOverviewInfoCard;
