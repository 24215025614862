import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import ConvoIcon from "../../svgs/common/ConvoIcon";
import TimeEstimate from "../../svgs/common/TimeEstimate";
import AssessmentCheck from "../../svgs/common/AssessmentCheck";
import VerifyInfo from "../../svgs/common/VerifyInfo";

const ProgressTrackerInfo = () => {
  return (
    <View style={styles.container}>
      {/* <CustomText text="Instructions" size="m" weight="bold" textType="text" useTranslationText={false} /> */}

      {/* Engage in Discussion */}
      <View style={styles.section}>
        <ConvoIcon style={styles.icon} />
        <CustomText
          style={{ marginVertical: 6 }}
          text="Engage in Discussion"
          size="s"
          weight="bold"
          textType="text"
          useTranslationText={false}
        />
      </View>
      <CustomText
        text="Participate actively in each topic's interactive chat to progress. Audio input is suggested."
        size="xs"
        style={styles.text}
        textType="text"
        useTranslationText={false}
      />

      {/* Automatic Assessment */}
      <View style={styles.section}>
        <AssessmentCheck style={styles.icon} />
        <CustomText
          style={{ marginVertical: 6 }}
          text="Automatic Assessment"
          size="s"
          weight="bold"
          textType="text"
          useTranslationText={false}
        />
      </View>
      <CustomText
        text="Your AI tutor will evaluate your understanding and mark topics completed as you demonstrate understanding."
        size="xs"
        style={styles.text}
        textType="text"
        useTranslationText={false}
      />

      {/* Time Estimate */}
      <View style={styles.section}>
        <TimeEstimate style={styles.icon} />
        <CustomText
          style={{ marginVertical: 6 }}
          text="Time Estimate"
          size="s"
          weight="bold"
          textType="text"
          useTranslationText={false}
        />
      </View>
      <CustomText
        text="Each activity typically takes 20–60 minutes to complete all topics."
        size="xs"
        style={styles.text}
        textType="text"
        useTranslationText={false}
      />

      {/* Verify Information */}
      <View style={styles.section}>
        <VerifyInfo style={styles.icon} />
        <CustomText
          style={{ marginVertical: 6 }}
          text="Verify Information"
          size="s"
          weight="bold"
          textType="text"
          useTranslationText={false}
        />
      </View>
      <CustomText
        text="Cross-check important details with trusted sources."
        size="xs"
        style={styles.text}
        textType="text"
        useTranslationText={false}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    padding: 24,
    paddingTop: 12,
  },
  section: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 16,
  },
  text: {
    color: "#667085",
  },
});

export default ProgressTrackerInfo;
