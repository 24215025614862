import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M5.587 11.163l6.25 6.25a.939.939 0 101.328-1.328L7.578 10.5l5.585-5.587a.94.94 0 00-1.328-1.328l-6.25 6.25a.938.938 0 00.002 1.328z"
        fill="#000"
      />
    </Svg>
  );
}

export default SvgComponent;
