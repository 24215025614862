import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M5.625 11.25a3.125 3.125 0 100 6.25 3.125 3.125 0 000-6.25zm0 4.375a1.25 1.25 0 110-2.5 1.25 1.25 0 010 2.5zM3.125 10a.938.938 0 01-.663-1.6l.897-.9-.897-.9A.94.94 0 013.79 5.274l.897.899.9-.898a.94.94 0 011.328 1.328l-.9.898.9.9a.94.94 0 11-1.328 1.327l-.9-.899-.899.898a.938.938 0 01-.663.274zm14.413 5.9a.94.94 0 01-1.328 1.327l-.898-.899-.899.9a.94.94 0 01-1.328-1.328l.9-.9-.9-.9a.939.939 0 111.327-1.327l.9.899.9-.9A.94.94 0 1117.54 14.1l-.9.9.898.9zm-3.39-7.237c-.407 1.797-1.823 3.401-3.523 4.002A.937.937 0 1110 10.897c1.1-.39 2.052-1.477 2.318-2.649.185-.817.031-1.628-.444-2.385v.703a.938.938 0 01-1.875 0V3.438a.938.938 0 01.938-.938h3.125a.937.937 0 110 1.875h-.937c.998 1.298 1.36 2.794 1.023 4.288z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default SvgComponent;
