import DefaultLayout from "../layouts/DefaultLayout";
import CustomText from "../components/common/general/CustomText/CustomText.js";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import List from "../components/admin/List/List.tsx";
import { EditTask } from "../components/svgs/common";
import { useGetAllTenantsQuery } from "../graphql/generated/graphql";
import { useNavigation } from "@react-navigation/native";

function TenantDashboardView() {
  const navigation = useNavigation();

  const { data, loading, error } = useGetAllTenantsQuery();

  const tenants = data?.getAllTenants.data.map((tenant) => {
    return {
      title: tenant.tenantDomain,
      rowItems: [{ text: `isLTI: ${tenant.isLTI}` }, { text: `Name: ${tenant.companionName}` }],
      icon: null,
      buttons: [{ icon: <EditTask />, onPress: () => handleEditTenant(tenant.id) }],
    };
  });

  const handleEditTenant = (id) => {
    navigation.navigate("Tenant Settings", { id });
  };

  return (
    <DefaultLayout>
      <CustomText
        role="header"
        text="Tenant Dashboard"
        size="l"
        textType="display"
        weight="ultraBold"
        useTranslationText={false}
      />
      <CustomSpacing size="large" />
      {!loading && !error && <List items={tenants} />}
    </DefaultLayout>
  );
}

export default TenantDashboardView;
