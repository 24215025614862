import * as React from "react";
import Svg, { Path } from "react-native-svg";

function RightArrowSlim(props) {
  return (
    <Svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M11.3535 8.35378L6.35354 13.3538C6.30708 13.4002 6.25193 13.4371 6.19124 13.4622C6.13054 13.4874 6.06549 13.5003 5.99979 13.5003C5.93409 13.5003 5.86904 13.4874 5.80834 13.4622C5.74764 13.4371 5.69249 13.4002 5.64604 13.3538C5.59958 13.3073 5.56273 13.2522 5.53759 13.1915C5.51245 13.1308 5.49951 13.0657 5.49951 13C5.49951 12.9343 5.51245 12.8693 5.53759 12.8086C5.56273 12.7479 5.59958 12.6927 5.64604 12.6463L10.2929 8.00003L5.64604 3.35378C5.55222 3.25996 5.49951 3.13272 5.49951 3.00003C5.49951 2.86735 5.55222 2.7401 5.64604 2.64628C5.73986 2.55246 5.86711 2.49976 5.99979 2.49976C6.13247 2.49976 6.25972 2.55246 6.35354 2.64628L11.3535 7.64628C11.4 7.69272 11.4369 7.74786 11.4621 7.80856C11.4872 7.86926 11.5002 7.93433 11.5002 8.00003C11.5002 8.06574 11.4872 8.13081 11.4621 8.1915C11.4369 8.2522 11.4 8.30735 11.3535 8.35378Z"
        fill="#667085"
      />
    </Svg>
  );
}

export default RightArrowSlim;
