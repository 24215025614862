import { View, StyleSheet } from "react-native";
import { useState, useEffect } from "react";
import DefaultLayout from "../layouts/DefaultLayout";
import CustomText from "../components/common/general/CustomText/CustomText.js";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import { UnChecked, Checked } from "../components/svgs/common";
import { useGetTenantSchemaQuery, useGetTenantQuery } from "../graphql/generated/graphql";
import { ScrollView } from "react-native-gesture-handler";
import { useNavigation, useRoute } from "@react-navigation/native";
import CustomTextInput from "../components/common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import Loader from "../components/common/status/Loader/Loader";
import Divider from "../components/common/layout/Divider/Divider";
import CustomModal from "../components/common/general/CustomModal/CustomModal.js";
import { useUpdateTenantMutation } from "../graphql/generated/graphql";

const DynamicForm = ({ schema, originalValues, values, onValueChange, onSavePressed }) => {
  const renderField = (key, field, values, originalValues, handleChange) => {
    const isDiff = originalValues && originalValues[key] !== values[key];

    switch (field.type) {
      case "integer":
        return (
          <View key={key}>
            <CustomText
              role="header"
              text={isDiff ? `${key} !!!` : key}
              style={isDiff ? { color: "#FF0000" } : {}}
              weight={isDiff ? "ultraBold" : "medium"}
              size="m"
              useTranslationText={false}
            />
            <CustomSpacing size="l" />
            <CustomTextInput
              key={key}
              value={values[key] ? values[key].toString() : ""}
              onChangeText={(e) => {
                const intValue = parseInt(e, 10);
                if (!isNaN(intValue)) {
                  handleChange(key, intValue);
                } else {
                  handleChange(key, "");
                }
              }}
              keyboardType="numeric"
            />
            <Divider size="xl" />
          </View>
        );
      case "string":
        return (
          <View key={key}>
            <CustomText
              role="header"
              text={isDiff ? `${key} !!!` : key}
              style={isDiff ? { color: "#FF0000" } : {}}
              weight={isDiff ? "ultraBold" : "medium"}
              size="m"
              useTranslationText={false}
            />
            <CustomSpacing size="l" />
            <CustomTextInput
              key={key}
              value={values[key] || ""}
              onChangeText={(e) => handleChange(key, e)}
              multiline={true}
            />
            <Divider size="xl" />
          </View>
        );
      case "boolean":
        return (
          <View key={key}>
            <View style={{ flexDirection: "row", alignContent: "center", justifyContent: "space-between" }}>
              <CustomText
                role="header"
                text={isDiff ? `${key} !!!` : key}
                style={isDiff ? { color: "#FF0000" } : {}}
                weight={isDiff ? "ultraBold" : "medium"}
                size="m"
                useTranslationText={false}
              />
              <View>
                <CustomButton
                  styleType="transparent"
                  leftIcon={values[key] ? <Checked /> : <UnChecked />}
                  onPress={() => handleChange(key, !values[key])}
                />
              </View>
            </View>
            <Divider size="xl" />
          </View>
        );
      case "array":
        return (
          <View key={key}>
            <CustomText
              role="header"
              text={isDiff ? `${key} !!!` : key}
              style={isDiff ? { color: "#FF0000" } : {}}
              weight={isDiff ? "ultraBold" : "medium"}
              size="m"
              useTranslationText={false}
            />
            <CustomSpacing size="l" />
            {values[key]?.map((item, index) => (
              <View key={index}>
                <View key={index} style={{ flexDirection: "row", alignItems: "center" }}>
                  <CustomTextInput
                    value={item}
                    onChangeText={(e) => {
                      const newValues = [...values[key]];
                      newValues[index] = e;
                      handleChange(key, newValues);
                    }}
                  />
                  <CustomSpacing size="m" type="horizontal" />
                  <CustomButton
                    text="Remove"
                    styleType="danger"
                    size="s"
                    onPress={() => {
                      const newValues = [...values[key]];
                      newValues.splice(index, 1);
                      handleChange(key, newValues);
                    }}
                  />
                </View>
                <CustomSpacing size="m" />
              </View>
            ))}
            <CustomButton
              text="Add"
              size="s"
              styleType="primary"
              onPress={() => handleChange(key, [...(values[key] || []), ""])}
            />
            <Divider size="xl" />
          </View>
        );
      default:
        return null;
    }
  };
  return (
    <>
      {Object.keys(schema.properties).map((key) =>
        renderField(key, schema.properties[key], values, originalValues, onValueChange)
      )}
      <CustomSpacing size="l" />
      <CustomButton text="Save" styleType="lightGreen" onPress={onSavePressed} />
    </>
  );
};

function TenantDashboardView() {
  const navigation = useNavigation();
  const route = useRoute();
  const params = route.params;

  const [tenantSchema, setTenantSchema] = useState(null);
  const [visible, setVisible] = useState(false);
  const [originalValues, setOriginalValues] = useState(null);
  const [values, setValues] = useState({});

  const { data: schemaData } = useGetTenantSchemaQuery();
  const { data: tenantData } = useGetTenantQuery({ variables: { id: params.id } });

  const [updateTenant, { error: updateTenantError }] = useUpdateTenantMutation();

  useEffect(() => {
    const parsedData = schemaData ? JSON.parse(schemaData?.getTenantSchema.data) : null;
    setTenantSchema(parsedData);
  }, [schemaData]);

  useEffect(() => {
    if (!tenantData) return;

    const tenantValues = tenantData.getTenant.data[0];
    setOriginalValues(tenantValues);
    setValues(tenantValues);
  }, [tenantData]);

  const handleChange = (key, value) => {
    setValues((prevValues) => ({ ...prevValues, [key]: value }));
  };

  const handleModalClose = () => {
    setVisible(false);
  };

  const handleSaveModalOpen = () => {
    setVisible(true);
  };

  const handleSubmit = async () => {
    const updatedValues = Object.keys(values).reduce((diff, key) => {
      if (originalValues[key] !== values[key]) {
        diff[key] = values[key];
      }
      return diff;
    }, {});

    try {
      const { data } = await updateTenant({
        variables: {
          id: params.id,
          tenant: updatedValues,
        },
      });

      if (data?.updateTenant?.success) {
        setVisible(false);
        navigation.navigate("Tenant Dashboard");
      }
    } catch (error) {
      console.error("Error updating tenant", error);
    }
  };

  return (
    <DefaultLayout>
      {!tenantData || !tenantSchema ? (
        <Loader />
      ) : (
        <>
          <CustomText
            role="header"
            text="Tenant Settings"
            size="l"
            textType="display"
            weight="ultraBold"
            useTranslationText={false}
          />
          <CustomSpacing size="l" />

          <CustomText
            role="header"
            text={tenantData.getTenant.data[0].tenantDomain}
            size="xxl"
            weight="ultraBold"
            useTranslationText={false}
          />
          <CustomSpacing size="l" />

          <DynamicForm
            schema={tenantSchema.definitions.Tenant}
            originalValues={originalValues}
            values={values}
            onValueChange={handleChange}
            onSavePressed={handleSaveModalOpen}
          />
          <CustomModal
            visible={visible}
            style={{ width: "90%", height: "70%" }}
            onRequestClose={handleModalClose}
            showCloseButton={true}
            title="Confirm">
            <CustomSpacing size="m" />
            <CustomText
              role="header"
              text="Changes have been made to the following"
              size="l"
              weight="medium"
              useTranslationText={false}
            />
            <CustomSpacing size="m" />
            <ScrollView style={styles.scrollView}>
              {Object.keys(values)
                .filter((key) => originalValues && originalValues[key] !== values[key])
                .map((key) => (
                  <View key={key}>
                    <CustomText role="header" text={key} size="m" weight="medium" useTranslationText={false} />
                    <CustomText
                      role="header"
                      text={values[key]}
                      size="m"
                      weight="medium"
                      useTranslationText={false}
                      style={{ color: "red" }} // Highlight the updated values
                    />
                    <Divider size="xl" />
                  </View>
                ))}
            </ScrollView>
            {updateTenantError && (
              <>
                <CustomSpacing size="m" />
                <CustomText
                  role="header"
                  text="Error updating tenant. Please check the fields and try again."
                  style={{ color: "#FF0000" }}
                  size="l"
                  weight="medium"
                  useTranslationText={false}
                />
              </>
            )}
            <CustomSpacing size="m" />
            <CustomButton text="Yes" styleType="lightGreen" onPress={handleSubmit} />
            <CustomSpacing size="m" />
            <CustomButton text="No" styleType="danger" onPress={handleModalClose} />
          </CustomModal>
        </>
      )}
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 20,
  },
});

export default TenantDashboardView;
