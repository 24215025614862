import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { usePostHog } from "posthog-js/react";
import { useNavigation } from "@react-navigation/native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import LessonHeader from "../components/learning/lesson/LessonHeader/LessonHeader";
import LessonCard from "../components/learning/lesson/LessonCard/LessonCard";
import getLearningRoute from "../utils/getLearningRoute";
import { MotiView } from "moti";
import { useGetLearningObjectiveByIdQuery } from "../graphql/generated/graphql";
import DefaultLayout from "../layouts/DefaultLayout.web";
import { useChatBar } from "../contexts/ChatBarContext";
import useWebSocket from "../hooks/useWebSocket";
import AsyncStorage from "@react-native-async-storage/async-storage";

function LessonLearningView({ route }) {
  const posthog = usePostHog();
  const { sendMessage } = useWebSocket();
  const { setConversationType, setLearningMessage, mapRouteToContext, setMetaIsLoading } = useChatBar();
  const navigation = useNavigation();
  const { isMedium, isLarge } = useResponsiveScreen();
  const params = route.params;
  const { ancestors, name } = getLearningRoute(params);
  const loTitle = params.loTitle;
  const id = params.loId;

  const { data } = useGetLearningObjectiveByIdQuery({
    variables: { loId: id },
  });

  useEffect(() => {
    posthog?.capture("Learning Lesson Page Visited");
  }, []);

  const handleLessonCardPress = async (type, title) => {
    const lOId = data?.getLearningObjectiveById?.id;

    if (!lOId) {
      return;
    }

    posthog?.capture("Lesson Type: " + type);
    const token = await AsyncStorage.getItem("token");

    const updatedParams = {
      ...params,
      title,
      page: "Learning Activities",
    };

    if (type === "VIDEO") {
      navigation.navigate("Lesson Video", updatedParams);
      return;
    }

    const learningObjectiveDescription = data?.getLearningObjectiveById?.description || "No description available";

    const messageContent = `Begin ${title} on ${name}. Objective title: ${loTitle} and Objective description: ${learningObjectiveDescription}`;

    setConversationType("LEARNING");
    setLearningMessage({
      state: type,
      topic: name,
      learningObjectiveId: lOId,
      topicAncestors: ancestors,
    });

    setMetaIsLoading(true);
    sendMessage({
      meta: {
        token,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        abort: false,
        displayMessage: false,
        isLoading: true,
      },
      state: {
        general: "LEARNING",
        learning: {
          state: type,
          topic: name,
          learningObjectiveId: lOId,
          topicAncestors: ancestors,
        },
        context: mapRouteToContext(route.name),
        params: { ...route.params, title },
      },
      content: {
        message: messageContent,
        author: "HUMAN",
        type: null,
      },
    });

    navigation.navigate("Lesson Chat", updatedParams);
  };

  const learningData = [
    {
      title: "Conversational",
      subtitle: "Engage in an interactive conversation for an experiential learning experience.",
      source: require("../assets/cardImages/story.jpg"),
      type: "STORY",
    },
    {
      title: "Video",
      subtitle: "Access curated videos that demonstrate and explain the topic with visual clarity.",
      source: require("../assets/cardImages/video.jpg"),
      type: "VIDEO",
    },
  ];

  return (
    <DefaultLayout menuType="learn">
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <LessonHeader
          tag="Learning Objective"
          title={loTitle}
          secondTitle={name}
          description={data?.getLearningObjectiveById?.description}
          instructions="Choose your learning experience"
        />
      </MotiView>

      <CustomSpacing type="vertical" size="m" />
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={700}
        transition={{ type: "timing", duration: 600 }}>
        <View style={isMedium ? styles.lessonCardContainerDesktop : styles.lessonCardContainerMobile}>
          {learningData.map((data) => (
            <View key={data.title} style={isLarge ? {} : isMedium ? { width: "100%" } : styles.lessCardWrapperMobile}>
              <LessonCard
                key={data.title}
                title={data.title}
                subtitle={data.subtitle}
                source={data.source}
                onPress={() => handleLessonCardPress(data.type, data.title)}
              />
            </View>
          ))}
        </View>
      </MotiView>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  lessonCardContainerDesktop: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 20,
  },
  lessonCardContainerMobile: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 12,
  },
  lessCardWrapperMobile: {
    width: "48%",
  },
});

export default LessonLearningView;
