import { useNavigation } from "@react-navigation/native";
import LearnMainCard from "../../../components/learning/general/LearnMainCard/LearnMainCard";
import { FieldsDocument, useSetKnowledgeRelevanceMutation } from "../../../graphql/generated/graphql";
import { useAccessibilityContext } from "../../../contexts/AccessibilityContext";

const LearnSubjectCardListContainer = ({ data }) => {
  const navigation = useNavigation();
  const { screenReaderIsEnabled } = useAccessibilityContext();

  const [isNotRelevant] = useSetKnowledgeRelevanceMutation({
    refetchQueries: [
      {
        query: FieldsDocument,
      },
    ],
  });

  const handleShowHideClick = async (nodeName, type, value) => {
    await isNotRelevant({
      variables: {
        nodeName,
        nodeLabel: type,
        isRelevant: value === "hide" ? false : true,
      },
    });
  };

  const handleCardPress = async (nodeName, nodeType) => {
    navigation.navigate("Categories", {
      [nodeType.toLowerCase()]: nodeName,
    });
  };

  if (!data) return null;

  return (
    <>
      {data?.children.map((subject) => (
        <LearnMainCard
          key={subject.id}
          name={subject.name}
          onCardPress={() => handleCardPress(subject.name, subject.__typename)}
          onShowHidePress={(value) => handleShowHideClick(subject.name, subject.__typename, value)}
          isHidden={!subject.isRelevant}
          image={subject.imageUrl}
          screenReaderIsEnabled={screenReaderIsEnabled}
        />
      ))}
    </>
  );
};

export default LearnSubjectCardListContainer;
