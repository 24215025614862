import { Svg, Path } from "react-native-svg";

const Box = () => (
  <Svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M14.1 3.91495L8.6 0.904328C8.4162 0.803168 8.2098 0.750122 8 0.750122C7.7902 0.750122 7.5838 0.803168 7.4 0.904328L1.9 3.91495C1.7032 4.02263 1.539 4.18129 1.42464 4.37429C1.31027 4.56728 1.24995 4.78749 1.25 5.01183V10.9881C1.24995 11.2124 1.31027 11.4326 1.42464 11.6256C1.539 11.8186 1.7032 11.9773 1.9 12.085L7.4 15.0956C7.58377 15.1968 7.79018 15.25 8 15.25C8.20982 15.25 8.41623 15.1968 8.6 15.0956L14.1 12.085C14.2968 11.9773 14.461 11.8186 14.5754 11.6256C14.6897 11.4326 14.7501 11.2124 14.75 10.9881V5.01183C14.7501 4.78749 14.6897 4.56728 14.5754 4.37429C14.461 4.18129 14.2968 4.02263 14.1 3.91495ZM8 2.28558L12.5 4.74995L8 7.21245L3.5 4.74995L8 2.28558ZM2.75 6.04933L7.25 8.51183V13.3037L2.75 10.84V6.04933ZM8.75 13.3037V8.51183L13.25 6.04933V10.84L8.75 13.3037Z"
      fill="#3E68FE"
    />
  </Svg>
);

export default Box;
