import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { BlackInfo } from "../../svgs/common";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";

const DeleteCourse = ({ deleteButtonClick, backButtonClick }) => {
  const [confirmationText, setConfirmationText] = useState("");

  const handleTextChange = (text) => {
    setConfirmationText(text);
  };

  const isDeleteConfirmed = confirmationText === "DELETE";

  return (
    <View style={styles.container}>
      <View style={styles.warningBox}>
        <View style={{ marginRight: 10 }}>
          <BlackInfo />
        </View>
        <CustomText
          style={styles.warningBoxText}
          size="xs"
          text="Are you sure you want to delete this course? All of its modules and assignments will also be deleted. This cannot be undone. Type 'DELETE' in the box below to confirm."
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomTextInput
          style={{ textAlign: "center", backgroundColor: "#F8F8F8", borderColor: "#DEDEDE", marginBottom: 20 }}
          placeholder="Type DELETE here"
          value={confirmationText}
          onChangeText={handleTextChange}
          // accessibilityHint="Enter DELETE to confirm the cousrse deletion"
        />
        <CustomButton
          text="Delete Course"
          onPress={deleteButtonClick}
          disabled={!isDeleteConfirmed}
          styleType="danger"
          size="m"
          style={{ marginBottom: 10 }}
          bold="true"
          aria-label="Delete Course"
        />
        <CustomButton
          text="Cancel"
          onPress={backButtonClick}
          styleType="primaryLight"
          size="m"
          style={styles.backButton}
          bold="true"
          aria-label="Cancel"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderRadius: 10,
  },
  warningBox: {
    padding: 10,
    backgroundColor: "rgba(247, 92, 138, 0.2)",
    borderRadius: 10,
    marginBottom: 20,
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    flexDirection: "row",
    alignItems: "center",
  },
  warningBoxText: {
    color: "#F75C8A",
  },
});

export default DeleteCourse;
