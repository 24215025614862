import * as Font from "expo-font";

export const loadFonts = async () => {
  await Font.loadAsync({
    DmMono: require("./assets/fonts/DmMono.ttf"),
    Manrope: require("./assets/fonts/Manrope.ttf"),
    Inter: require("./assets/fonts/Inter.ttf"),
    Montserrat: require("./assets/fonts/Montserrat-Regular.ttf"),
    PangaiaMedium: require("./assets/fonts/PangaiaMedium.ttf"),
    PangaiaBold: require("./assets/fonts/PangaiaBold.ttf"),
    PangaiaThin: require("./assets/fonts/PangaiaThin.ttf"),
    MontrealBold: require("./assets/fonts/MontrealBold.ttf"),
    MontrealBook: require("./assets/fonts/MontrealBook.ttf"),
    MontrealMedium: require("./assets/fonts/MontrealMedium.ttf"),
    MaterialCommunityIcons: require("./assets/fonts/MaterialCommunityIcons.ttf"),
  });
};
