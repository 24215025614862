import { useEffect, useState } from "react";

const useCopyContent = () => {
  const [showCheckMark, setShowCheckMark] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setShowCheckMark(false);
    }, 1000);
  }, [showCheckMark]);

  return [showCheckMark, setShowCheckMark];
};

export default useCopyContent;
