import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { shadowStyles } from "../../../styles/shadow";

const Placeholder = ({ title, subtitle, accessible = true }) => {
  return (
    <View accessible={accessible} style={[styles.container, shadowStyles.primary]}>
      <View style={styles.content}>
        <View style={styles.textContainer}>
          <CustomText text={title} size="xs" textType="display" style={styles.title} />
          <CustomText text={subtitle} size="xs" style={styles.subtitle} />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    marginTop: 40,
    marginBottom: 20,
    marginHorizontal: 10,
    padding: 36,
    alignItems: "center",
    justifyContent: "center",
  },
  content: {
    alignItems: "center",
    justifyContent: "center",
  },
  textContainer: {
    alignItems: "center",
    justifyContent: "flex-start",
  },
  title: {
    textAlign: "center",
  },
  subtitle: {
    textAlign: "center",
    maxWidth: 280,
  },
});

export default Placeholder;
