import * as React from "react";
import Svg, { Path } from "react-native-svg";

function EditTaskBlue(props) {
  return (
    <Svg width={17} height={17} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.663 3.337l-2.5-2.5a.937.937 0 00-1.328 0l-7.5 7.5A.937.937 0 005.063 9v2.5a.937.937 0 00.937.938h2.5a.938.938 0 00.663-.275l7.5-7.5a.939.939 0 000-1.326zM13.5 2.828L14.672 4l-.86.86-1.171-1.173.859-.859zm-5.39 7.735H6.937V9.39l4.375-4.375 1.171 1.171-4.375 4.375zm8.203-1.206v5.893a1.563 1.563 0 01-1.563 1.563H2.25A1.563 1.563 0 01.687 15.25V2.75A1.562 1.562 0 012.25 1.187h5.893a.937.937 0 110 1.875h-5.58v11.876h11.874V9.357a.938.938 0 011.876 0z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default EditTaskBlue;
