import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { BlackInfo } from "../../svgs/common";
import CustomText from "../../common/general/CustomText/CustomText";
import { shadowStyles } from "../../../styles/shadow";

const CompleteJourney = ({ completeButtonClick, backButtonClick }) => {
  return (
    <View style={styles.container}>
      <View style={[shadowStyles.smallShadow, styles.warningBox]}>
        <View style={{ marginRight: 10 }}>
          <BlackInfo />
        </View>
        <CustomText
          style={styles.warningBoxText}
          size="xs"
          text="Are you sure you want to complete this journey? This action cannot be undone."
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          text="Complete"
          onPress={completeButtonClick}
          size="m"
          style={{
            marginBottom: 10,
            backgroundColor: "rgb(91, 224, 104)",
            color: "#fff",
          }}
          bold="true"
          aria-label="Complete Journey"
          accessibilityHint="Press to complete the journey"
        />
        <CustomButton
          text="Back"
          onPress={backButtonClick}
          styleType="primaryLight"
          size="m"
          style={styles.backButton}
          bold="true"
          aria-label="Go Back"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderRadius: 10,
  },
  warningBox: {
    padding: 10,
    backgroundColor: "rgba(91, 224, 104, 0.6)",
    borderRadius: 10,
    marginBottom: 20,
    //justifyContent: "space-between",
    flexDirection: "row",
    alignItems: "center",
  },
  warningBoxText: {
    color: "#000000",
  },
});

export default CompleteJourney;
