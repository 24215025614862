import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function AspectForm({ message, onClose, onDelete }) {
  function renderKeyLabel(keyword) {
    switch (keyword.toLowerCase()) {
      case "name":
        return "Name";
      case "category":
        return "Category";
      case "id":
        return "ID";
      case "friendid":
        return "Friend ID";
      case "relationshiptype":
        return "Relationship Type";
      case "connectionstrength":
        return "Connection Strength";
      case "frequencyofinteraction":
        return "Frequency of Interaction";
      case "lastinteractiondate":
        return "Last Interaction Date";
      case "sharedinterests":
        return "Shared Interests";
      case "sharedgoals":
        return "Shared Goals";
      case "sharedeventsattended":
        return "Shared Events Attended";
      case "friendpersonalitytraits":
        return "Friend Personality Traits";
      case "communicationchannel":
        return "Communication Channel";
      case "level":
        return "Level";
      case "startdate":
        return "Start Date";
      case "lastupdated":
        return "Last Updated";
      case "learningstyle":
        return "Learning Style";
      case "language":
        return "Language";
      case "timeofday":
        return "Time of Day";
      case "learningduration":
        return "Learning Duration";
      case "contenttype":
        return "Content Type";
      case "notificationpreferences":
        return "Notification Preferences";
      case "type":
        return "Type";
      case "completionstatus":
        return "Completion Status";
      case "deadline":
        return "Deadline";
      case "priority":
        return "Priority";
      case "value":
        return "Value";
      case "description":
        return "Description";
      case "date":
        return "Date";
      case "importance":
        return "Importance";
      case "status":
        return "Status";
      case "enddate":
        return "End Date";
      case "text":
        return "Text";
      case "sentiment":
        return "Sentiment";
      case "context":
        return "Context";
      case "intensity":
        return "Intensity";
      case "timestamp":
        return "Timestamp";
      case "trigger":
        return "Trigger";
      case "calories":
        return "Calories";
      case "carbohydrates_grams":
        return "Carbohydrates (g)";
      case "fat_grams":
        return "Fat (g)";
      case "fiber_grams":
        return "Fiber (g)";
      case "protein_grams":
        return "Protein (g)";
      case "serving_size":
        return "Serving Size";
      case "sodium_mg":
        return "Sodium (mg)";
      case "sugars_grams":
        return "Sugars (g)";
      case "totalcalories":
        return "Total Calories";
      case "totalcarbs":
        return "Total Carbohydrates";
      case "totalfats":
        return "Total Fats";
      case "totalproteins":
        return "Total Proteins";
      case "totalcaloriesburned":
        return "Total Calories Burned";
      case "totaldurationminutes":
        return "Total Duration (minutes)";
      case "totalworkouts":
        return "Total Workouts";
      case "water_intake_oz":
        return "Water Intake (oz)";
      default:
        return "Other";
    }
  }

  return (
    <View>
      <CustomText text="Ability to Edit Coming Soon. In the meantime, to revise a node, please delete and then recreate the node." />
      <CustomSpacing type="vertical" size="m" />

      {Object.entries(message.aspectDetails || {}).map(([key, value]) => {
        if (key === "__typename" || value === null) {
          return null;
        }
        return (
          <View
            key={key}
            style={{
              flexDirection: "row",
              alignItems: "center",
              marginBottom: 8,
            }}>
            <CustomText useTranslationText={false} text={renderKeyLabel(key)} weight="bold" style={styles.text} />
            <CustomSpacing type="horizontal" size="m" />
            <CustomTextInput
              value={capitalizeFirstLetter(value.toString())}
              style={{ pointerEvents: "none" }}
              editable={false}
              aria-label={key}
            />
          </View>
        );
      })}
      <CustomSpacing type="vertical" size="m" />

      <View style={styles.buttonContainer}>
        <CustomButton text="Delete" styleType="danger" onPress={() => onDelete(message.id, message.nodeId)} />
        <CustomButton text="Ok" styleType="primary" onPress={onClose} />
      </View>
    </View>
  );
}

function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = StyleSheet.create({
  text: {
    width: "15%",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default AspectForm;
