import React, { useState, useEffect } from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import EditAssignment from "../EditAssignment/EditAssignment";

const EditAddAssignmentModalContainer = ({
  visible,
  onClose,
  initialContent,
  currentName = "",
  onSaveItem,
  moduleName,
}) => {
  const { isMedium } = useResponsiveScreen();
  const [name, setName] = useState("");

  useEffect(() => {
    if (currentName !== "") {
      setName(currentName);
    }

    return () => {
      setName("");
    };
  }, [visible]);

  const isNewItem = currentName === "";

  const onChangeName = (text) => {
    setName(text);
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={isNewItem ? `Add Assignment` : `Edit Assignment`}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <EditAssignment
        onClose={onClose}
        onSave={(htmlContent) => onSaveItem(type, htmlContent, name, moduleName)}
        initialContent={initialContent}
        newName={name}
        onChangeName={onChangeName}
        buttonText={isNewItem ? "Add" : "Save"}
        type={type}
      />
    </CustomModal>
  );
};

export default EditAddAssignmentModalContainer;
