import { StyleSheet } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import { AnimatedCircularProgress } from "react-native-circular-progress";
import { View } from "moti";

function LearnProgressCircle({ value, size = 45, width = 5, ariaLabel = "Progress circle" }) {
  return (
    <View
      role="progressbar"
      aria-label={ariaLabel}
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={value}
      aria-valuetext={`${value}% complete`}>
      <AnimatedCircularProgress
        size={size}
        width={width}
        fill={value}
        tintColor="#5BE068"
        backgroundColor="#E6EBFF"
        lineCap="round">
        {() => <CustomText useTranslationText={false} text={`${value}%`} weight="bold" style={styles.text} />}
      </AnimatedCircularProgress>
    </View>
  );
}

const styles = StyleSheet.create({
  text: {
    fontSize: 10,
  },
});

export default LearnProgressCircle;
