import DefaultLayout from "../layouts/DefaultLayout";
import { useNavigation } from "@react-navigation/native";
import CustomText from "../components/common/general/CustomText/CustomText.js";
import CustomButton from "../components/common/general/CustomButton/CustomButton.js";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";

function AdminView() {
  const navigation = useNavigation();

  const handleAdminPress = () => {
    navigation.navigate("Tenant Dashboard");
  };

  return (
    <DefaultLayout>
      <CustomText
        role="header"
        text="Dashboard"
        size="l"
        textType="display"
        weight="ultraBold"
        useTranslationText={false}
      />
      <CustomSpacing size="large" />
      <CustomButton text="Tenant Dashboard" onPress={handleAdminPress} useTranslationText={false} />
    </DefaultLayout>
  );
}

export default AdminView;
