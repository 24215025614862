import Svg, { Path } from "react-native-svg";

function LearnIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M20.553 14.925A18.431 18.431 0 0018.865 12c.651-.921 1.216-1.9 1.688-2.925 1.11-2.467 1.096-4.451-.04-5.587-1.137-1.137-3.121-1.15-5.588-.04A18.435 18.435 0 0012 5.134c-.921-.651-1.9-1.216-2.925-1.688-2.467-1.11-4.451-1.096-5.587.04-1.137 1.137-1.15 3.121-.04 5.588A18.435 18.435 0 005.134 12c-.651.921-1.216 1.9-1.688 2.925-1.11 2.467-1.096 4.451.04 5.587.575.575 1.367.863 2.325.863.938 0 2.041-.274 3.26-.822a18.438 18.438 0 002.924-1.688c.922.651 1.9 1.216 2.925 1.688 1.219.548 2.32.822 3.259.822.962 0 1.754-.288 2.329-.863 1.14-1.136 1.154-3.12.044-5.587zM18.92 5.081c.492.493.167 2.438-1.5 5.042a29.407 29.407 0 00-3.546-3.54c2.608-1.671 4.552-1.996 5.046-1.504v.002zM16.054 12a27.065 27.065 0 01-1.923 2.13A27.065 27.065 0 0112 16.055a27.073 27.073 0 01-2.13-1.923A27.064 27.064 0 017.945 12 27.993 27.993 0 0112 7.946a26.885 26.885 0 012.13 1.923c.68.676 1.321 1.387 1.924 2.131zM5.079 5.08c.137-.137.385-.21.727-.21.893 0 2.432.505 4.312 1.713a29.393 29.393 0 00-1.844 1.694 28.372 28.372 0 00-1.69 1.848C4.911 7.517 4.586 5.572 5.078 5.079zm0 13.84c-.492-.493-.167-2.437 1.5-5.041a29.386 29.386 0 003.538 3.538c-2.6 1.671-4.545 1.996-5.038 1.504zm13.842 0c-.494.493-2.438.168-5.042-1.5a29.4 29.4 0 003.538-3.538c1.671 2.601 1.996 4.545 1.504 5.039zM13.5 12a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
        fill={fill}
      />
    </Svg>
  );
}

export default LearnIcon;
