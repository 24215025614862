import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={37} height={38} viewBox="0 0 37 38" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx={18.4231} cy={18.9231} r={18.4231} fill="#000B33" />
      <Path
        d="M17.126 15.049a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zm10 3.437a8.437 8.437 0 11-8.437-8.437 8.447 8.447 0 018.437 8.437zm-1.875 0a6.562 6.562 0 10-6.562 6.563 6.57 6.57 0 006.562-6.563zm-5.625 2.866v-2.553a1.563 1.563 0 00-1.562-1.563.937.937 0 00-.313 1.822v2.553a1.562 1.562 0 001.563 1.563.938.938 0 00.312-1.822z"
        fill="#fff"
      />
    </Svg>
  );
}

export default SvgComponent;
