import React from "react";
import { View, StyleSheet } from "react-native";
import ListItem, { ListItemProps } from "../ListItem/ListItem.tsx";

interface ListProps {
  items: ListItemProps[];
}

const List: React.FC<ListProps> = ({ items }) => {
  return (
    <View style={styles.container}>
      {items.map((item, index) => (
        <ListItem key={index} title={item.title} rowItems={item.rowItems} icon={item.icon} buttons={item.buttons} />
      ))}
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 10,
  },
});

export default List;
