import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Lock(props) {
  return (
    <Svg width={30} height={30} viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.25V7.5a5 5 0 0110 0v3.75h1.25a2.5 2.5 0 012.5 2.5v10a2.5 2.5 0 01-2.5 2.5H8.75a2.5 2.5 0 01-2.5-2.5v-10a2.5 2.5 0 012.5-2.5H10zm7.5-3.75v3.75h-5V7.5a2.5 2.5 0 015 0z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default Lock;
