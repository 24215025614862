import { StyleSheet } from "react-native";
import { MotiView } from "moti";
import LottieView from "lottie-react-native";
import CustomText from "../../general/CustomText/CustomText";
import CustomSpacing from "../../layout/CustomSpacing/CustomSpacing";

function TrackLoader({ message, isGenerating }) {
  return (
    <MotiView
      from={{ opacity: 0, scale: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      transition={{ type: "timing", duration: 2000 }}
      exit={{ opacity: 0, scale: 0 }}
      style={styles.content}>
      <LottieView
        autoPlay
        loop={true}
        style={styles.lottie}
        source={require("../../../../assets/track_builder.json")}
      />
      {isGenerating && (
        <>
          <CustomSpacing type="vertical" size="s" />
          <CustomText
            text={message || "Your track is being built. This can take up to 30 seconds."}
            size="l"
            style={styles.message}
          />
        </>
      )}
    </MotiView>
  );
}

const styles = StyleSheet.create({
  content: {
    alignItems: "center",
    width: 280,
  },
  message: {
    textAlign: "center",
  },
  lottie: {
    width: 100,
    height: 100,
  },
});

export default TrackLoader;
