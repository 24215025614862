import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CoreLabel(props) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.423 2.605C2.79 2.37 1.487 2.682.97 3.576s-.134 2.18.884 3.475C.837 8.346.455 9.63.971 10.525c.516.894 1.82 1.206 3.452.971C5.035 13.026 5.958 14 6.989 14c1.032 0 1.955-.974 2.567-2.503 1.631.234 2.935-.078 3.451-.972.516-.893.134-2.179-.884-3.474 1.018-1.296 1.4-2.581.884-3.475-.515-.893-1.82-1.206-3.45-.971C8.943 1.075 8.02.102 6.988.102c-1.031 0-1.954.973-2.566 2.503zm-.398 1.243a12.17 12.17 0 00-.254 1.344c-.376.29-.723.589-1.038.893a6.027 6.027 0 01-.12-.17c-.714-1.045-.604-1.57-.53-1.697.073-.128.472-.485 1.734-.39.068.005.138.012.208.02zm1.673-.966c.42.12.853.271 1.291.452.439-.181.871-.332 1.292-.452a6.038 6.038 0 00-.087-.19c-.548-1.14-1.057-1.307-1.205-1.307-.147 0-.656.167-1.204 1.308a6.06 6.06 0 00-.087.189zm1.291 1.853a13.099 13.099 0 00-2.005 1.158 13.096 13.096 0 000 2.316 13.095 13.095 0 002.005 1.158 13.091 13.091 0 002.006-1.159 13.096 13.096 0 000-2.315A13.093 13.093 0 006.99 4.735zm4.256 1.35a12.172 12.172 0 00-1.037-.892 12.19 12.19 0 00-.254-1.345c.07-.008.14-.015.207-.02 1.262-.095 1.661.262 1.735.39.074.127.184.652-.53 1.697a5.88 5.88 0 01-.12.17zm0 1.932c-.314.304-.66.603-1.037.892-.062.47-.148.92-.254 1.345.07.007.139.014.207.019 1.262.096 1.661-.262 1.735-.39.073-.127.183-.652-.53-1.697a6.278 6.278 0 00-.12-.17zm-8.512 0c.315.304.662.603 1.038.892.062.47.148.92.254 1.345a6.08 6.08 0 01-.207.019c-1.263.096-1.662-.262-1.735-.39-.074-.127-.184-.652.53-1.697.038-.056.078-.113.12-.17zm4.256 2.75c.439.181.871.332 1.292.453a6.15 6.15 0 01-.087.189c-.548 1.14-1.057 1.307-1.205 1.307-.147 0-.656-.167-1.204-1.307a6.026 6.026 0 01-.087-.19c.42-.12.853-.27 1.291-.451z"
        fill="#fff"
      />
    </Svg>
  );
}

export default CoreLabel;
