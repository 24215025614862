import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ fill = "#000000", ...props }) {
  return (
    <Svg width={20} height={21} viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M14.413 11.163l-6.25 6.25a.939.939 0 11-1.328-1.328l5.587-5.585-5.585-5.587a.94.94 0 011.328-1.328l6.25 6.25a.938.938 0 01-.002 1.328z"
        fill={fill}
      />
    </Svg>
  );
}

export default SvgComponent;
