import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M19.658 3.098a.938.938 0 00-.88-.88c-4.163-.246-7.516 1.044-8.972 3.448-.949 1.562-.982 3.45-.115 5.257-.37.473-.667 1-.878 1.563l-.757-.758c.561-1.31.502-2.665-.188-3.804-1.092-1.802-3.58-2.768-6.653-2.59a.938.938 0 00-.88.88c-.179 3.074.787 5.562 2.59 6.654.613.373 1.316.57 2.032.568a4.495 4.495 0 001.774-.38l1.707 1.71v2.109a.937.937 0 101.875 0V14.57a4.033 4.033 0 01.741-2.34 6 6 0 002.507.581 5.072 5.072 0 002.648-.739c2.404-1.46 3.694-4.814 3.449-8.975zM3.897 11.264c-1.03-.625-1.655-2.145-1.706-4.073 1.928.051 3.449.676 4.073 1.706.244.397.344.866.284 1.328l-.885-.888a.94.94 0 10-1.329 1.328l.886.886a2.053 2.053 0 01-1.323-.287zm11.341-.795c-.832.504-1.796.599-2.797.293l2.91-2.909a.939.939 0 10-1.328-1.328l-2.91 2.91c-.303-.998-.207-1.966.293-2.794.998-1.648 3.36-2.584 6.407-2.579.01 3.047-.927 5.405-2.575 6.407z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default SvgComponent;
