import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

const ComingSoonOverlay = ({ children }) => {
  return (
    <View style={styles.container}>
      {/* Original content */}
      <View style={styles.contentContainer}>{children}</View>

      {/* Overlay */}
      <View style={styles.overlay}>
        <View style={styles.messageContainer}>
          <CustomText
            useTranslationText={false}
            style={styles.centeredText}
            text="Coming Soon"
            size="l"
            textType="display"
            weight="ultraBold"
          />
          <CustomText
            useTranslationText={false}
            text="This feature is currently in development and is expected to release Jan 2025."
            size="m"
            style={styles.subText}
          />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    position: "relative",
  },
  contentContainer: {
    flex: 1,
    pointerEvents: "none",
  },
  overlay: {
    ...StyleSheet.absoluteFillObject,
    backgroundColor: "rgba(0, 0, 0, 0.3)",
    justifyContent: "center",
    alignItems: "center",
  },
  messageContainer: {
    margin: 20,
    backgroundColor: "rgba(255, 255, 255, 0.95)",
    maxWidth: 380,
    minWidth: 380,
    flexWrap: "wrap",
    justifyContent: "center",
    padding: 30,
    borderRadius: 12,
    alignItems: "center",
  },
  subText: {
    color: "#667085",
    marginTop: 8,
    textAlign: "center",
  },
  centeredText: {
    textAlign: "center",
  },
});

export default ComingSoonOverlay;
