import { View, StyleSheet } from "react-native";
import { useAppState } from "../contexts/AppStateContext";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import LearnMenuContainer from "../containers/navigation/LearnMenuContainer/LearnMenuContainer";
import ChatBarContainer from "../containers/navigation/ChatBarContainer/ChatBarContainer";
import MainMenuContainer from "../containers/navigation/MainMenuContainer/MainMenuContainer";
import MobileHeaderContainer from "../containers/navigation/MobileHeaderContainer/MobileHeaderContainer";

function LearnChatLayout({ children }) {
  const { state } = useAppState();
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      {isMedium ? (
        <View style={styles.largeContainer}>
          <LearnMenuContainer />
          <View style={styles.chatContainer}>
            {children}
            <View style={styles.charBarContainer}>
              <ChatBarContainer />
            </View>
          </View>
        </View>
      ) : (
        <View style={styles.smallContainer}>
          <MobileHeaderContainer isShowLearn={true} />

          {children}

          <View style={styles.mobileChatBarContainer}>
            <ChatBarContainer />
          </View>

          <MotiView
            style={styles.mobileMenuContainer}
            transition={{ delay: 400, damping: 15, mass: 1 }}
            from={{
              translateX: -600,
            }}
            animate={{
              translateX: state.meta.isMainMenuExpanded ? 0 : -600,
            }}>
            <MainMenuContainer />
          </MotiView>

          <MotiView
            style={styles.mobileMenuContainer}
            transition={{ delay: 400, damping: 15, mass: 1 }}
            from={{
              translateX: -600,
            }}
            animate={{
              translateX: state.meta.isLearnMenuOpen ? 0 : -600,
            }}>
            <LearnMenuContainer />
          </MotiView>
        </View>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
  largeContainer: {
    flex: 1,
    flexDirection: "row",
    backgroundColor: "#fff",
  },
  smallContainer: {
    flex: 1,
    backgroundColor: "#fff",
  },
  chatContainer: {
    flex: 1,
    maxWidth: 1200,
    width: "100%",
    marginHorizontal: "auto",
  },
  charBarContainer: {
    marginHorizontal: 40,
  },
  mobileChatBarContainer: {
    marginHorizontal: 0,
  },
  mobileMenuContainer: {
    position: "absolute",
    bottom: 0,
    top: 85,
    right: 0,
    left: 0,
  },
});

export default LearnChatLayout;
