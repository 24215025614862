import { Pressable, Image, View, StyleSheet } from "react-native";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import { shadowStyles } from "../../../../styles/shadow";

function LessonCard({ title, subtitle, source, onPress }) {
  const { isMedium, isLarge } = useResponsiveScreen();

  return (
    <Pressable
      accessible={true}
      role="button"
      aria-label={`Lesson card ${title}`}
      accessibilityHint={`Press to navigate to ${title}`}
      onPress={onPress}
      style={[styles.container, { alignSelf: isMedium ? "flex-start" : "auto" }, shadowStyles.largeCard]}>
      <Image
        source={source}
        style={[styles.image, isLarge ? styles.imageDesktop : isMedium ? styles.imageTablet : styles.imageMobile]}
        resizeMode="cover"
        alt="lesson card background image"
        aria-hidden={true}
      />
      {isMedium ? (
        <View style={styles.textContainer}>
          <CustomText
            useTranslationText={false}
            text={title}
            size="xs"
            textType="display"
            style={{ color: "#101828" }}
            numberOfLines={1}
            ellipsizeMode="tail"
            role="header"
            aria-level="3"
          />
          <CustomSpacing type="vertical" size="s" />
          <CustomText
            useTranslationText={false}
            text={subtitle}
            style={{ color: "#667085", fontWeight: 400 }}
            size="s"
            numberOfLines={2}
            ellipsizeMode="tail"
          />
        </View>
      ) : (
        <View style={styles.textContainerMobile}>
          <CustomText useTranslationText={false} text={title} size="s" weight="bold" style={{ color: "#101828" }} />
        </View>
      )}
    </Pressable>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
  },
  image: {
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
    overflow: "hidden",
  },
  imageDesktop: {
    width: 466,
    height: 250,
  },
  imageTablet: {
    width: "100%",
    height: 220,
  },
  imageMobile: {
    height: 120,
    width: "100%",
  },
  textContainer: {
    paddingHorizontal: 20,
    paddingBottom: 20,
    paddingTop: 16,
    maxWidth: 466,
  },
  textContainerMobile: {
    paddingHorizontal: 12,
    paddingVertical: 8,
    width: "100%",
  },
});

export default LessonCard;
