import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function Link(props) {
  return (
    <Svg width={12} height={12} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_635_3720)">
        <Path
          d="M4.102 7.103l3-3a.563.563 0 11.797.796l-3 3a.563.563 0 01-.797-.796zm6.14-5.344a2.816 2.816 0 00-3.978 0l-1.412 1.41a.563.563 0 10.797.797l1.41-1.409a1.688 1.688 0 012.387 2.387L8.035 6.353a.563.563 0 10.796.796l1.41-1.409a2.817 2.817 0 000-3.98l.001-.001zm-3.89 6.275l-1.409 1.41a1.688 1.688 0 01-2.387-2.387l1.41-1.41a.563.563 0 10-.797-.796L1.76 6.26a2.813 2.813 0 003.98 3.979l1.408-1.41a.563.563 0 00-.797-.797l.002.001z"
          fill="#667085"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_635_3720">
          <Path fill="#fff" d="M0 0H12V12H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default Link;
