import React from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const TwoFactorSuccess = ({ onClose }) => {
  return (
    <View style={styles.container}>
      <CustomText text="Success!" size="s" textType="display" weight="bold" style={{ textAlign: "center" }} />
      <CustomSpacing type="vertical" size={16} />
      <CustomText
        text={"Two-factor Authentication Enabled Successfully"}
        size="xl"
        textType="text"
        style={{ textAlign: "center" }}
      />
      <CustomSpacing type="vertical" size={24} />
      <CustomButton text="Close" styleType="primary" onPress={onClose} disabled={false} style={styles.closeButton} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  closeButton: {
    width: "100%",
  },
});

export default TwoFactorSuccess;
