import * as React from "react";
import Svg, { Path } from "react-native-svg";

function VerifyInfo(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M2.625 6A1.125 1.125 0 013.75 4.875h16.5a1.125 1.125 0 010 2.25H3.75A1.125 1.125 0 012.625 6zm1.125 7.125h6a1.125 1.125 0 100-2.25h-6a1.125 1.125 0 100 2.25zm7.5 3.75h-7.5a1.125 1.125 0 100 2.25h7.5a1.125 1.125 0 100-2.25zm11.296 1.92a1.125 1.125 0 01-1.594 0l-1.695-1.694a4.125 4.125 0 111.594-1.594l1.695 1.694a1.123 1.123 0 010 1.595zm-5.296-3.42a1.875 1.875 0 100-3.75 1.875 1.875 0 000 3.75z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default VerifyInfo;
