import { useState } from "react";
import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function TopMenuItem({ item, isSelected, onPress }) {
  const { isXLarge, isMedium, hp } = useResponsiveScreen();

  const [isHovered, setIsHovered] = useState(false);

  const isExpanded = isXLarge || !isMedium;

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <>
      <View
        role="menuitem"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={styles.menuItemContainer}>
        <CustomButton
          onPress={() => onPress(item)}
          text={isMedium ? item.display : item.display.toUpperCase() || ""}
          styleType={isHovered ? "primaryLightened" : "transparent"}
          size={isMedium ? "m" : "s"}
          style={[
            isSelected || isHovered ? styles.selectedMenuButton : {},
            isMedium ? [styles.defaultPadding, styles.menuButtons] : {},
            isExpanded ? styles.menuButtonFlex : styles.menuButtonFlexCollapsed,
          ]}
          textStyle={[
            isSelected ? styles.selectedMenuButtonText : {},
            isMedium ? [styles.desktopButtonText] : [styles.menuButtonText, { fontSize: hp(1.7) }],
          ]}
          aria-label={item.name}
          bold={isSelected}
        />
      </View>
      {isMedium ? <View /> : <CustomSpacing type="vertical" size="m" />}
    </>
  );
}

const styles = StyleSheet.create({
  menuItemContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  defaultPadding: {
    marginHorizontal: 5,
  },
  menuButtons: {
    height: 40,
    paddingHorizontal: 12,
    paddingVertical: 8,
    borderRadius: 8,
  },
  menuButtonFlex: {
    justifyContent: "center",
  },
  menuButtonText: {
    letterSpacing: 2,
    fontFeatureSettings: "'cling' off, 'liga' off",
  },
  selectedMenuButton: {
    backgroundColor: "#EAECF0",
    shadowColor: "transparent",
  },
  selectedMenuButtonText: {
    color: "#344054",
  },
  desktopButtonText: {
    color: "#667085",
    fontFamily: "Inter",
    fontSize: 14,
    lineHeight: 20,
  },
  menuButtonFlexCollapsed: {
    justifyContent: "center",
    alignItems: "center",
  },
});

export default TopMenuItem;
