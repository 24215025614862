import { Svg, Circle, Path } from "react-native-svg";

const CheckCircle = (props) => (
  <Svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Circle cx="15" cy="15" r="15" fill="#5BE068" fillOpacity="0.1" />
    <Path
      d="M22.6615 10.9424L12.7931 20.8108C12.7071 20.897 12.605 20.9655 12.4925 21.0122C12.3801 21.0589 12.2595 21.0829 12.1378 21.0829C12.016 21.0829 11.8954 21.0589 11.783 21.0122C11.6705 20.9655 11.5684 20.897 11.4824 20.8108L7.165 16.4934C7.07894 16.4073 7.01068 16.3051 6.9641 16.1927C6.91753 16.0802 6.89355 15.9597 6.89355 15.838C6.89355 15.7163 6.91753 15.5958 6.9641 15.4834C7.01068 15.3709 7.07894 15.2688 7.165 15.1827C7.25106 15.0966 7.35322 15.0284 7.46566 14.9818C7.57811 14.9352 7.69862 14.9113 7.82032 14.9113C7.94203 14.9113 8.06254 14.9352 8.17498 14.9818C8.28742 15.0284 8.38959 15.0966 8.47565 15.1827L12.1385 18.8456L21.3524 9.63326C21.5262 9.45946 21.7619 9.36182 22.0077 9.36182C22.2535 9.36182 22.4892 9.45946 22.663 9.63326C22.8368 9.80706 22.9345 10.0428 22.9345 10.2886C22.9345 10.5344 22.8368 10.7701 22.663 10.9439L22.6615 10.9424Z"
      fill="#209B51"
    />
  </Svg>
);

export default CheckCircle;
