import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import { capitalizeFirstLetter } from "../../../utils/string";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { hexToRgba } from "../../../utils/color";

const PriorityIndicator = ({ priority, size = "default" }) => {
  const { isMedium } = useResponsiveScreen();
  const circleColor = getCircleColor(priority);

  // Determine circle size based on the size prop
  const circleSize = size === "small" ? 12 : 30;
  const outerCircleSize = size === "small" ? 12 : 20;
  const innerCircleSize = size === "small" ? 8 : 16;

  return (
    <View
      style={styles.container}
      role="img"
      aria-label={`${capitalizeFirstLetter(priority)} Priority`}
      accessible={true}>
      {isMedium && (
        <View
          style={[
            styles.circle,
            { backgroundColor: circleColor, width: circleSize, height: circleSize, borderRadius: circleSize / 2 },
          ]}
        />
      )}
      {!isMedium && (
        <View
          style={[
            styles.outerCircle,
            { backgroundColor: hexToRgba(circleColor, 0.2), width: outerCircleSize, height: outerCircleSize },
          ]}>
          <View
            style={[
              styles.innerCircle,
              {
                backgroundColor: circleColor,
                width: innerCircleSize,
                height: innerCircleSize,
                borderRadius: innerCircleSize / 2,
              },
            ]}
          />
        </View>
      )}
      {!isMedium && size !== "small" && (
        <CustomText size="xs" weight="ultraBold" text={capitalizeFirstLetter(priority)} role="text" accessible={true} />
      )}
    </View>
  );
};

const getCircleColor = (priority) => {
  switch (priority) {
    case "low":
      return "#5BE068"; // Green
    case "medium":
      return "#FFCB46"; // Yellow
    case "high":
      return "#F75C8A"; // Red
    default:
      return "#CCC"; // Default color
  }
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
  },
  circle: {
    width: 30,
    height: 30,
    borderRadius: 15,
  },
  outerCircle: {
    width: 20,
    height: 20,
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  innerCircle: {
    width: 16,
    height: 16,
    borderRadius: 8,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default PriorityIndicator;
