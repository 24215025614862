import * as React from "react";
import Svg, { Path, G, Defs, ClipPath } from "react-native-svg";

function AxioLogo(props) {
  return (
    <Svg width={115} height={32} viewBox="0 0 115 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M32.675 30.444c-1.013 0-2.011-.459-2.666-1.331l-6.525-8.701c-2.743-3.657-8.227-3.656-10.968 0l-6.525 8.7a3.328 3.328 0 11-5.325-3.994L15.337 5.554a3.329 3.329 0 015.326 0l14.671 19.564a3.33 3.33 0 01-2.66 5.326z"
        fill="#3E68FE"
      />
      <Path
        d="M10.076 29.538l5.03-6.087c1.598-1.933 4.19-1.933 5.788 0l5.03 6.087c.685.829.2 2.246-.769 2.246h-14.31c-.969 0-1.454-1.417-.769-2.246z"
        fill="#011665"
      />
      <G clipPath="url(#clip0_86_652)" fill="#011665">
        <Path d="M60.073 8.795v18.152c0 .33-.266.6-.594.6h-2.055a.597.597 0 01-.594-.6v-3.082C55.252 26.41 52.575 28 49.372 28c-5.497 0-9.601-4.363-9.601-10.129 0-5.767 4.104-10.13 9.601-10.13 3.203 0 5.873 1.597 7.458 4.136V8.795c0-.33.266-.6.594-.6h2.055c.328 0 .594.27.594.6zm-3.237 9.076c0-3.984-2.936-6.984-6.849-6.984-3.913 0-6.89 3-6.89 6.984s2.936 6.978 6.89 6.978c3.954 0 6.85-3 6.85-6.978zM78.996 27.312l-5.51-7.315-5.47 7.315a.584.584 0 01-.472.24h-2.451a.6.6 0 01-.472-.962l6.686-8.753-6.576-8.678a.6.6 0 01.47-.963h2.46c.184 0 .361.09.47.24l5.395 7.198 5.395-7.197a.584.584 0 01.471-.241h2.459c.491 0 .771.564.47.963l-6.575 8.678 6.685 8.753a.6.6 0 01-.471.963h-2.493a.597.597 0 01-.47-.234v-.007zM86.42 2.16c0-1.252.942-2.16 2.184-2.16 1.243 0 2.145.908 2.145 2.16 0 1.253-.943 2.161-2.145 2.161-1.201 0-2.185-.95-2.185-2.16zm1.16 6.036h2.049c.328 0 .594.268.594.598v18.153c0 .33-.266.599-.594.599H87.58a.597.597 0 01-.594-.599V8.794c0-.33.266-.598.594-.598zM94.443 17.871c0-5.767 4.405-10.13 10.243-10.13 5.839 0 10.203 4.363 10.203 10.13s-4.405 10.13-10.203 10.13c-5.797 0-10.243-4.364-10.243-10.13zm17.1 0c0-3.984-2.937-6.984-6.85-6.984s-6.89 3-6.89 6.984 2.936 6.978 6.89 6.978c3.954 0 6.85-3 6.85-6.978z" />
      </G>
      <Defs>
        <ClipPath id="clip0_86_652">
          <Path fill="#fff" transform="translate(39.778)" d="M0 0H75.1111V28H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default AxioLogo;
