import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function GeneralTitle(props) {
  return (
    <Svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect y={0.0527344} width={26} height={26} rx={13} fill="#008383" />
      <Path d="M13 11.09l-1.691.978v1.97l1.691.977 1.692-.978v-1.969L13 11.091z" fill="#fff" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.08 7.662a1.167 1.167 0 10-2.16 0l-3.2 1.826a1.166 1.166 0 00-2.137.648c0 .644.523 1.167 1.167 1.167v3.5a1.167 1.167 0 10.97 1.814l3.2 1.826a1.167 1.167 0 102.16 0l3.2-1.826a1.165 1.165 0 002.137-.648c0-.644-.523-1.166-1.167-1.166v-3.5a1.167 1.167 0 10-.97-1.815l-3.2-1.826zM12.427 9.94a1.146 1.146 0 011.147 0l1.827 1.056c.356.205.574.585.574.995v2.123c0 .41-.218.79-.574.995l-1.827 1.057a1.146 1.146 0 01-1.147 0l-1.828-1.056a1.149 1.149 0 01-.574-.995V11.99c0-.41.218-.79.574-.995l1.828-1.056z"
        fill="#fff"
      />
    </Svg>
  );
}

export default GeneralTitle;
