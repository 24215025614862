import * as React from "react";
import Svg, { Path } from "react-native-svg";

function NoJourneyIcon(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M26.125 17.063a5.073 5.073 0 00-4.773 3.375H8.125a3.937 3.937 0 110-7.875h13.5a6.187 6.187 0 000-12.376h-13.5a1.687 1.687 0 100 3.376h13.5a2.812 2.812 0 110 5.624h-13.5a7.312 7.312 0 100 14.626h13.227a5.063 5.063 0 104.773-6.75zm0 6.75a1.687 1.687 0 110-3.375 1.687 1.687 0 010 3.375z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default NoJourneyIcon;
