import { Svg, G, Path } from "react-native-svg";

const ThreeDot = ({ fill = "#CECDD0", ...props }) => (
  <Svg height={25} width={25} viewBox="0 0 25 25" fill="none" {...props}>
    <G id="more-vertical">
      <Path
        d="M12.8506 13.7734C13.4029 13.7734 13.8506 13.3257 13.8506 12.7734C13.8506 12.2212 13.4029 11.7734 12.8506 11.7734C12.2983 11.7734 11.8506 12.2212 11.8506 12.7734C11.8506 13.3257 12.2983 13.7734 12.8506 13.7734Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.8506 6.77344C13.4029 6.77344 13.8506 6.32572 13.8506 5.77344C13.8506 5.22115 13.4029 4.77344 12.8506 4.77344C12.2983 4.77344 11.8506 5.22115 11.8506 5.77344C11.8506 6.32572 12.2983 6.77344 12.8506 6.77344Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M12.8506 20.7734C13.4029 20.7734 13.8506 20.3257 13.8506 19.7734C13.8506 19.2212 13.4029 18.7734 12.8506 18.7734C12.2983 18.7734 11.8506 19.2212 11.8506 19.7734C11.8506 20.3257 12.2983 20.7734 12.8506 20.7734Z"
        stroke={fill}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </G>
  </Svg>
);

export default ThreeDot;
