import React from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import JourneyFormContainer from "../JourneyFormContainer/JourneyFormContainer";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const JourneyFormModalContainer = ({ visible, onClose, onSubmit }) => {
  const { isMedium } = useResponsiveScreen();

  const modalStyle = isMedium
    ? { height: "auto", maxHeight: "90%" }
    : { width: "90%", height: "auto", maxHeight: "90%" };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Create A Journey"
      style={modalStyle}>
      <JourneyFormContainer onSubmit={onSubmit} onComplete={onClose} />
    </CustomModal>
  );
};

export default JourneyFormModalContainer;
