import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ElectiveLabel(props) {
  return (
    <Svg width={14} height={14} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M7.777 2.432a.777.777 0 00-1.554-.006c-.005 1.428-.378 2.327-.952 2.882-.58.56-1.497.896-2.853.915a.777.777 0 00.011 1.553c1.506 0 2.4.398 2.935.976.546.59.847 1.51.86 2.825a.777.777 0 001.552.032c.07-1.39.439-2.323 1.013-2.908.564-.575 1.44-.93 2.778-.925a.777.777 0 00.02-1.553c-1.469-.031-2.384-.42-2.94-.996-.556-.578-.876-1.477-.87-2.795z"
        fill="#fff"
      />
    </Svg>
  );
}

export default ElectiveLabel;
