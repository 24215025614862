import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_891_3084)">
        <Path
          d="M15.547 9.79l-3.898-1.436-1.439-3.9a1.556 1.556 0 00-2.92 0l-1.436 3.9L1.953 9.79a1.557 1.557 0 000 2.92l3.898 1.436 1.439 3.9a1.556 1.556 0 002.92 0l1.436-3.897 3.9-1.439a1.557 1.557 0 000-2.92zm-4.688 2.648a1.553 1.553 0 00-.922.922L8.75 16.584 7.562 13.36a1.553 1.553 0 00-.922-.92L3.415 11.25l3.225-1.188a1.554 1.554 0 00.922-.921L8.75 5.916 9.938 9.14a1.554 1.554 0 00.922.921l3.224 1.188-3.225 1.188zm.078-9.313a.938.938 0 01.938-.938h.937V1.25a.938.938 0 011.875 0v.938h.938a.937.937 0 010 1.874h-.938V5a.938.938 0 01-1.875 0v-.938h-.937a.937.937 0 01-.938-.937zm8.75 3.75a.938.938 0 01-.937.938h-.313v.312a.938.938 0 01-1.875 0v-.313h-.312a.937.937 0 010-1.875h.312v-.312a.938.938 0 011.875 0v.313h.313a.937.937 0 01.937.937z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_891_3084">
          <Path fill="#fff" d="M0 0H20V20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgComponent;
