import React, { useState, useEffect } from "react";
import { View, FlatList, StyleSheet, Dimensions, Platform } from "react-native";
import Placeholder from "../general/Placeholder/Placeholder";
import { WebView } from "react-native-webview";
import { useGenerateKeywordsMutation } from "../../graphql/generated/graphql";
import Loader from "../common/status/Loader/Loader";
import { isLoading } from "expo-font";
import { announceForAccessibility } from "../../utils/accessibility";
import Sentry from "../../utils/sentry";

const YOUTUBE_API_KEY = "AIzaSyA8Luh5WbUdXzxY6JvmsNfFXdpLUQaZO_c";
const YOUTUBE_SEARCH_URL = `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=5&type=video&videoCategoryId=27&videoDuration=medium&regionCode=US&key=${YOUTUBE_API_KEY}`;

const calculateHeight = (width) => {
  return (width * 9) / 16;
};

const SIDEBAR_WIDTH = 400;

const extractTitleAndDescription = (keyword) => {
  const match = /Title: (.+?) \| Description: (.*)/.exec(keyword);
  return {
    title: match ? match[1] : "",
    description: match ? match[2] : "",
  };
};

const YoutubeSearch = ({ isMobile, keyword }) => {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [height, setHeight] = useState(calculateHeight(Dimensions.get("window").width));
  const [initialFetchCompleted, setInitialFetchCompleted] = useState(false);
  const [generateKeywordsMutation] = useGenerateKeywordsMutation();

  useEffect(() => {
    if (Platform.OS === "ios" && !isLoading) {
      announceForAccessibility({ message: "Page is loaded", queue: false });
    }
  }, [isLoading]);

  const cleanKeyword = keyword
    .replace(/<[^>]+>/g, "")
    .split(".")
    .map((str) => str.trim())
    .join(" ");

  const { title, description } = extractTitleAndDescription(cleanKeyword);

  useEffect(() => {
    const onChange = ({ window }) => {
      setHeight(calculateHeight(window.width - (isMobile ? 0 : SIDEBAR_WIDTH)));
    };

    const subscription = Dimensions.addEventListener("change", onChange);

    return () => {
      subscription?.remove();
    };
  }, []);

  useEffect(() => {
    const fetchVideos = async (title, description) => {
      setLoading(true);
      try {
        const { data } = await generateKeywordsMutation({
          variables: {
            title: title,
            description: description,
          },
        });

        const generatedKeywords = data.generateYoutubeKeywords;
        fetch(`${YOUTUBE_SEARCH_URL}&q=${encodeURIComponent(generatedKeywords)}`)
          .then((response) => response.json())
          .then((data) => {
            const videoResults = data.items.map((item) => ({
              id: item.id.videoId,
              title: item.snippet.title,
              description: item.snippet.description,
            }));

            setVideos(videoResults);
          })
          .catch((error) => {
            Sentry.captureException(error);
          })
          .finally(() => {
            setLoading(false);
            setInitialFetchCompleted(true);
          });
      } catch (error) {
        Sentry.captureException(error);
        setLoading(false);
        setInitialFetchCompleted(true);
      }
    };

    if (cleanKeyword) {
      fetchVideos(title, description);
    }
  }, [title, description]);

  return (
    <View style={{ flex: 1 }}>
      {loading ? (
        <View accessible={true} role="alert" aria-live="assertive" style={styles.loaderContainer}>
          <Loader />
        </View>
      ) : !loading && initialFetchCompleted && videos.length === 0 ? (
        <Placeholder
          accessible={!loading}
          title="No Videos to See Here"
          subtitle="We are past our current capacity for videos, please check back here soon"
        />
      ) : (
        <FlatList
          contentContainerStyle={styles.list}
          data={videos}
          role="grid"
          numColumns={isMobile ? 1 : 2}
          keyExtractor={(item) => item.id}
          renderItem={({ item }) => (
            <View style={isMobile ? styles.mobileVideoContainer : styles.videoContainer}>
              <WebView
                style={{ height: height, width: "100%", paddingHorizontal: 10 }}
                source={{
                  uri: "https://www.youtube.com/embed/" + item.id,
                }}
              />
            </View>
          )}
        />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  list: {
    gap: 20,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  videoContainer: {
    width: "48%",
    aspectRatio: 16 / 9,
    paddingHorizontal: 10,
  },
  mobileVideoContainer: {
    width: "100%",
    aspectRatio: 16 / 9,
  },
});

export default YoutubeSearch;
