import React from "react";
import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../../../../AppConfigProvider";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import { MotiView } from "moti";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import { MagnifyingGlass } from "../../../svgs/common";
import CustomTextInput from "../../../common/general/CustomTextInput/CustomTextInput";

function LearnSelectableMenu({
  items,
  onSelect,
  selectedItem,
  onSearchClick,
  isSearchInputActive = false,
  onChangeText,
  searchText,
}) {
  const { primaryColor } = useAppConfig();

  return (
    <View style={styles.container} role="tablist">
      <View style={styles.innerContainer}>
        <View style={styles.itemsBox}>
          {items.map((item, index) => {
            const isActive = selectedItem === item;
            return (
              <View key={index} style={styles.buttonWrapper}>
                <CustomButton
                  useTranslationText={false}
                  text={item}
                  size="xxl"
                  styleType="primaryTransparent"
                  style={styles.itemButton}
                  textStyle={
                    isActive
                      ? {
                          color: primaryColor,
                          fontSize: 16,
                          fontFamily: "Inter",
                        }
                      : styles.itemText
                  }
                  onPress={() => onSelect(item)}
                  bold
                  aria-label={`Select ${item}`}
                  role="tab"
                />
                {isActive && (
                  <MotiView
                    from={{
                      opacity: 0,
                      translateY: 5,
                    }}
                    animate={{
                      opacity: 1,
                      translateY: 0,
                    }}
                    delay={100}
                    transition={{ type: "timing", duration: 200 }}>
                    <View style={[styles.buttonUnderline, { backgroundColor: primaryColor }]} />
                  </MotiView>
                )}
              </View>
            );
          })}
        </View>
        <View style={styles.controls}>
          {isSearchInputActive ? (
            <CustomTextInput
              style={styles.textInput}
              placeholder="Search subfields"
              onChangeText={onChangeText}
              value={searchText}
              onBlur={() => onSearchClick(false)}
              autoFocus
              role="search"
            />
          ) : (
            <CustomButton
              text=""
              size="xxl"
              style={{ padding: 10 }}
              styleType="primaryTransparent"
              onPress={() => onSearchClick(true)}
              leftIcon={<MagnifyingGlass style={{ height: 24, with: 24 }} />}
              aria-label="Submit search"
            />
          )}
        </View>
      </View>
      <View style={styles.divider} />
      <CustomSpacing type="vertical" size="m" />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  itemsBox: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 10,
    gap: 32,
  },
  itemButton: {
    padding: 0,
  },
  itemText: {
    color: "#646877",
    fontSize: 16,
    fontFamily: "Inter",
  },
  divider: {
    width: "100%",
    height: 1,
    backgroundColor: "#C0C0C0",
    marginVertical: 2,
  },
  buttonWrapper: {
    width: "auto",
  },
  buttonUnderline: {
    position: "absolute",
    width: "100%",
    height: 4,
    top: 12,
    left: 0,
  },
  innerContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  controls: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
    gap: 5,
  },
  textInput: {
    width: 200,
  },
});

export default LearnSelectableMenu;
