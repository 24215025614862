import { useNavigation } from "@react-navigation/native";
import { useSchoolsQuery } from "../../../graphql/generated/graphql";

import Loader from "../../../components/common/status/Loader/Loader";
import { View } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import SchoolCardList from "../../../components/lms/SchoolCardList/SchoolCardList";

function SchoolsCardListContainer() {
  const navigation = useNavigation();

  const { data, loading, error } = useSchoolsQuery();

  // if (loading) return <CustomText text="Loading..." />;
  if (error) return <CustomText text="Error..." />;

  const schoolsData = data?.getSchools.map((school) => {
    return {
      ...school,
      logoUri:
        school?.icon && school.icon.startsWith("https://app.axio.ai") ? school.icon : `https://ed.link${school.icon}`,
    };
  });

  const handlePress = (id) => {
    const school = data?.getSchools.filter((school) => school.id === id)[0];

    navigation.navigate("School", {
      integration_id: school.id,
    });
  };

  return (
    <View aria-live="polite">
      {loading ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Loader />
          <CustomSpacing type="vertical" size="s" />
          <CustomText text="Loading..." size="m" />
        </View>
      ) : (
        <SchoolCardList courses={schoolsData} onPress={handlePress} />
      )}
    </View>
  );
}

export default SchoolsCardListContainer;
