import { useState, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { listAllAssignments } from "../../../services";
import ItemCardList from "../../../components/lms/ItemCardList/ItemCardList";
import { useNavigation } from "@react-navigation/native";
import { View } from "react-native";
import Loader from "../../../components/common/status/Loader/Loader";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";

function AssignmentsCardListContainer({ integrationIds }) {
  const [assignments, setAssignments] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigation = useNavigation();

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const allAssignments = await Promise.all(
        integrationIds.map(async (integrationId) => {
          const data = await listAllAssignments(token, integrationId);
          return data.map((assignment) => ({
            ...assignment,
            type: "assignment",
            integrationId,
          }));
        })
      );

      const aggregatedAssignments = allAssignments.flat();

      // Filter assignments due within the next 14 days, ignoring assignments without a due_date
      const currentDate = new Date();
      const next14Days = new Date(currentDate.getTime() + 14 * 24 * 60 * 60 * 1000);
      const filteredAssignments = aggregatedAssignments.filter((assignment) => {
        if (assignment.due_date) {
          const dueDate = new Date(assignment.due_date);
          return dueDate >= currentDate && dueDate <= next14Days;
        }
        return false;
      });

      setAssignments(filteredAssignments);
      setIsLoading(false);
    };

    fetchData();
  }, [integrationIds]);

  const handlePress = (id) => {
    const assignment = assignments.find((assignment) => assignment.id === id);

    if (assignment) {
      navigation.navigate("Resource", {
        resource_type: "assignment",
        resource_id: assignment.id,
        course_id: assignment.classId, // Assuming you have classId in your assignment data
        integration_id: assignment.integrationId,
      });
    }
  };

  return (
    <View aria-live="polite">
      {isLoading ? (
        <View style={{ flex: 1, justifyContent: "center", alignItems: "center" }}>
          <Loader />
          <CustomSpacing type="vertical" size="s" />
          <CustomText text="Loading..." size="m" />
        </View>
      ) : (
        <ItemCardList items={assignments} onPress={handlePress} />
      )}
    </View>
  );
}

export default AssignmentsCardListContainer;
