import React, { useEffect, useState } from "react";
import { StyleSheet, View, ActivityIndicator } from "react-native";
import ContentForm from "../../components/content/ContentForm";
import { ScrollView } from "react-native-gesture-handler";
import { useNavigation, useRoute } from "@react-navigation/native";
import { useAppState, actions } from "../../contexts/AppStateContext";
import {
  GetAllCoursesBySchoolDocument,
  useCreateCourseMutation,
  useGetCourseQuery,
  useSchoolsQuery,
  useUpdateCourseMutation,
} from "../../graphql/generated/graphql";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../components/common/general/CustomText/CustomText";
import Sentry from "../../utils/sentry";

const CourseFormContainer = () => {
  const navigation = useNavigation();
  const { dispatch } = useAppState();

  const route = useRoute();
  const courseId = route.params?.courseId;

  const { data, loading } = useGetCourseQuery({
    variables: {
      id: courseId,
    },
    skip: !courseId,
  });

  const courseExists = data?.getCourse?.data[0] && courseId;

  useEffect(() => {
    if (courseExists) {
      setName(data?.getCourse?.data[0]?.name);
      setCourseObjectives(data?.getCourse?.data[0]?.objectives || []);
      setWeeks(data?.getCourse?.data[0]?.lengthInWeeks);
      setStandard(data?.getCourse?.data[0]?.learningStandards);
    }
  }, [data]);

  useEffect(() => {
    return () => {
      setName("");
      setCourseObjectives([]);
      setWeeks(5);
      setStandard("");
    };
  }, []);

  const [name, setName] = useState("");
  const [courseObjectives, setCourseObjectives] = useState([]);
  const [weeks, setWeeks] = useState(5);
  const [standard, setStandard] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const { data: schoolsData } = useSchoolsQuery();

  const schoolId = schoolsData?.getSchools[0]?.id;

  const [createCourse] = useCreateCourseMutation({
    refetchQueries: [
      {
        query: GetAllCoursesBySchoolDocument,
        variables: { schoolId },
      },
    ],
  });

  const [updateCourse] = useUpdateCourseMutation({
    refetchQueries: [{ query: GetAllCoursesBySchoolDocument, variables: { schoolId } }],
  });

  useEffect(() => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });

    return () => {
      dispatch({
        type: actions.SET_META,
        payload: { keypressIsListening: true },
      });
    };
  }, []);

  const handleObjectivesChange = (text) => {
    const objectivesArray = text.split(",");
    setCourseObjectives(objectivesArray);
  };

  const handleSubmit = async () => {
    setErrorMessage("");
    if (!name) {
      setErrorMessage("Please enter a name");
      return;
    }

    if (!schoolId) {
      setErrorMessage("Not able to find school id, please try again later");
      return;
    }

    if (weeks && isNaN(weeks)) {
      setErrorMessage("Weeks must be a number");
      return;
    }

    try {
      if (courseExists) {
        await updateCourse({
          variables: {
            id: courseId,
            name,
            ...(courseObjectives && { objectives: courseObjectives }),
            ...(weeks && { lengthInWeeks: weeks }),
            ...(standard && { learningStandards: standard }),
          },
        });
      } else {
        await createCourse({
          variables: {
            name,
            ...(courseObjectives && { objectives: courseObjectives }),
            ...(weeks && { lengthInWeeks: weeks }),
            ...(standard && { learningStandards: standard }),
            parentSchoolId: schoolId,
          },
        });
      }

      navigation.navigate("Custom Coursework");
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(error.message);
    }
  };

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  return (
    <ScrollView
      showsVerticalScrollIndicator={false}
      style={styles.scrollView}
      contentContainerStyle={styles.contentContainer}>
      <ContentForm
        onSubmit={handleSubmit}
        onObjectivesChange={(text) => handleObjectivesChange(text)}
        courseObjectives={courseObjectives.join(",")}
        isLoading={false}
        isUploading={false}
        setWeeks={setWeeks}
        weeks={weeks}
        setName={setName}
        name={name}
        setStandard={setStandard}
        standard={standard}
        hasFilesOption={false}
        onBackPress={() => navigation.navigate("Custom Coursework")}
      />
      <View aria-live={errorMessage ? "assertive" : "none"}>
        {!!errorMessage && (
          <>
            <CustomSpacing type="vertical" size="s" />
            <CustomText role="alert" text={errorMessage} size="s" style={styles.errorMessage} />
            <CustomSpacing type="vertical" size="s" />
          </>
        )}
      </View>
    </ScrollView>
  );
};

export default CourseFormContainer;

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  scrollView: {
    paddingHorizontal: 0,
    width: "100%",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});
