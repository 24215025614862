import * as React from "react";
import { v4 as uuidv4 } from "uuid";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function BookCircle(props) {
  const id1 = uuidv4();

  return (
    <Svg width={34} height={35} viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={`url(#${id1})`}>
        <Path
          d="M17 34.83c9.389 0 17-7.61 17-17 0-9.389-7.611-17-17-17s-17 7.611-17 17c0 9.39 7.611 17 17 17z"
          fill="#AF3D7B"
        />
        <Path
          d="M25.5 11.137h-5.666c-.531 0-1.057.097-1.544.287-.487.19-.926.468-1.29.816a3.91 3.91 0 00-1.29-.816 4.257 4.257 0 00-1.543-.287H8.5c-.47 0-.92.168-1.252.466a1.517 1.517 0 00-.519 1.127v10.2c0 .423.187.828.52 1.127.331.3.782.467 1.251.467h5.667c.47 0 .92.168 1.252.467.332.299.519.704.519 1.127a.91.91 0 00.311.676c.2.18.47.28.751.28.282 0 .552-.1.752-.28a.91.91 0 00.31-.676c0-.423.187-.828.52-1.127a1.875 1.875 0 011.252-.467H25.5c.47 0 .92-.168 1.253-.467.332-.299.518-.704.518-1.127v-10.2c0-.422-.186-.828-.518-1.127a1.875 1.875 0 00-1.253-.466zM14.167 22.612H8.854v-9.563h5.313c.47 0 .92.168 1.252.467.332.299.519.704.519 1.127v8.352a4.23 4.23 0 00-1.771-.383zm10.98 0h-5.313c-.616 0-1.223.131-1.771.385v-8.354c0-.423.186-.828.518-1.127a1.875 1.875 0 011.253-.467h5.312v9.563zm-4.96-7.332h2.834c.282 0 .552.101.752.28.199.18.31.423.31.677a.91.91 0 01-.31.676c-.2.18-.47.28-.752.28h-2.833c-.282 0-.552-.1-.752-.28a.91.91 0 01-.31-.676.91.91 0 01.31-.677c.2-.179.47-.28.752-.28zm3.897 4.144a.91.91 0 01-.311.676c-.2.18-.47.28-.752.28h-2.833c-.282 0-.552-.1-.752-.28a.91.91 0 01-.31-.676.91.91 0 01.31-.676c.2-.18.47-.28.752-.28h2.833c.282 0 .552.1.752.28.199.18.31.422.31.676z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id={id1}>
          <Path fill="#fff" transform="translate(0 .83)" d="M0 0H34V34H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default BookCircle;
