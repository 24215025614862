import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SparkleThin(props) {
  return (
    <Svg width={28} height={28} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M21.61 14.116l-5.644-2.078-2.079-5.65a1.74 1.74 0 00-3.268 0l-2.08 5.643-5.65 2.078a1.74 1.74 0 000 3.269l5.642 2.09 2.078 5.647a1.742 1.742 0 003.269 0l2.078-5.645 5.649-2.078a1.741 1.741 0 000-3.268l.005-.008zm-6.255 3.712a1.736 1.736 0 00-1.031 1.032l-2.078 5.628-2.074-5.633a1.733 1.733 0 00-1.027-1.027L3.516 15.75l5.629-2.078a1.737 1.737 0 001.027-1.027l2.078-5.629 2.078 5.629a1.736 1.736 0 001.032 1.031l5.628 2.078-5.633 2.074zm.395-13.453a.875.875 0 01.875-.875h1.75V1.75a.875.875 0 011.75 0V3.5h1.75a.875.875 0 010 1.75h-1.75V7a.875.875 0 01-1.75 0V5.25h-1.75a.875.875 0 01-.875-.875zm11.375 5.25a.875.875 0 01-.875.875h-.875v.875a.875.875 0 11-1.75 0V10.5h-.875a.875.875 0 110-1.75h.875v-.875a.875.875 0 011.75 0v.875h.875a.875.875 0 01.875.875z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default SparkleThin;
