import CustomText from "../../common/general/CustomText/CustomText";
import { ListItem } from "@rneui/themed";
import { StyleSheet } from "react-native";

const CustomCourseworkMenuHeader = ({ type }) => {
  return (
    <ListItem.Content style={styles.addItemContainer}>
      <CustomText
        role="header"
        aria-level="3"
        text={`${type}s`}
        size="m"
        weight="bold"
        textType="text"
        style={{ color: "#344054" }}
      />
    </ListItem.Content>
  );
};

const styles = StyleSheet.create({
  addItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 5,
    marginBottom: 10,
    paddingLeft: 30,
  },
});

export default CustomCourseworkMenuHeader;
