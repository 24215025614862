import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ChatIcon(props) {
  return (
    <Svg width={36} height={36} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M22.188 13.188a1.687 1.687 0 01-1.688 1.687h-9a1.688 1.688 0 110-3.375h9a1.687 1.687 0 011.688 1.688zM20.5 17.125h-9a1.688 1.688 0 100 3.375h9a1.687 1.687 0 000-3.375zM31.188 16A15.188 15.188 0 019.076 29.521l-4.56 1.526a2.813 2.813 0 01-3.564-3.564l1.52-4.56A15.188 15.188 0 1131.188 16zm-3.375 0a11.813 11.813 0 10-22.04 5.915 1.687 1.687 0 01.14 1.38l-1.396 4.188 4.189-1.396a1.688 1.688 0 011.378.14A11.812 11.812 0 0027.813 16z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default ChatIcon;
