import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx={16.3333} cy={16.5} r={16} fill="#DBF0E0" />
      <Path
        d="M26.73 9.835v6a1.125 1.125 0 11-2.25 0v-3.281l-7.078 7.08a1.124 1.124 0 01-1.594 0l-2.952-2.955-5.954 5.952a1.127 1.127 0 11-1.594-1.594l6.75-6.75a1.124 1.124 0 011.594 0l2.954 2.954 6.28-6.28h-3.28a1.125 1.125 0 010-2.25h6a1.125 1.125 0 011.125 1.124z"
        fill="#41B85C"
      />
    </Svg>
  );
}

export default SvgComponent;
