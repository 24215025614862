import * as React from "react";
import Svg, { Path } from "react-native-svg";

function MeetingIcon() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M19.5 2.625H17.625V2.25C17.625 1.95163 17.5065 1.66548 17.2955 1.4545C17.0845 1.24353 16.7984 1.125 16.5 1.125C16.2016 1.125 15.9155 1.24353 15.7045 1.4545C15.4935 1.66548 15.375 1.95163 15.375 2.25V2.625H8.625V2.25C8.625 1.95163 8.50647 1.66548 8.2955 1.4545C8.08452 1.24353 7.79837 1.125 7.5 1.125C7.20163 1.125 6.91548 1.24353 6.7045 1.4545C6.49353 1.66548 6.375 1.95163 6.375 2.25V2.625H4.5C4.00272 2.625 3.52581 2.82254 3.17417 3.17417C2.82254 3.52581 2.625 4.00272 2.625 4.5V19.5C2.625 19.9973 2.82254 20.4742 3.17417 20.8258C3.52581 21.1775 4.00272 21.375 4.5 21.375H19.5C19.9973 21.375 20.4742 21.1775 20.8258 20.8258C21.1775 20.4742 21.375 19.9973 21.375 19.5V4.5C21.375 4.00272 21.1775 3.52581 20.8258 3.17417C20.4742 2.82254 19.9973 2.625 19.5 2.625ZM6.375 4.875C6.375 5.17337 6.49353 5.45952 6.7045 5.6705C6.91548 5.88147 7.20163 6 7.5 6C7.79837 6 8.08452 5.88147 8.2955 5.6705C8.50647 5.45952 8.625 5.17337 8.625 4.875H15.375C15.375 5.17337 15.4935 5.45952 15.7045 5.6705C15.9155 5.88147 16.2016 6 16.5 6C16.7984 6 17.0845 5.88147 17.2955 5.6705C17.5065 5.45952 17.625 5.17337 17.625 4.875H19.125V7.125H4.875V4.875H6.375ZM4.875 19.125V9.375H19.125V19.125H4.875Z"
        fill={"#000000"}
      />
    </Svg>
  );
}

export default MeetingIcon;
