import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Delete({ fill = "#F63D68", ...props }) {
  return (
    <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M14.875 3.75h-2.813v-.938A2.188 2.188 0 009.876.626h-3.75a2.188 2.188 0 00-2.188 2.188v.937H1.126a.937.937 0 100 1.875h.313V16.25A1.563 1.563 0 003 17.813h10a1.563 1.563 0 001.563-1.563V5.625h.312a.937.937 0 000-1.875zm-9.063-.938a.312.312 0 01.313-.312h3.75a.313.313 0 01.313.313v.937H5.811v-.938zm6.875 13.126H3.313V5.624h9.374v10.313zM7.064 8.125v5a.937.937 0 01-1.875 0v-5a.937.937 0 111.875 0zm3.75 0v5a.938.938 0 01-1.876 0v-5a.937.937 0 111.876 0z"
        fill={fill}
      />
    </Svg>
  );
}

export default Delete;
