import Svg, { Path } from "react-native-svg";

function DashboardIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M20.771 9.455l-7.495-7.076-.015-.014a1.868 1.868 0 00-2.523 0l-.016.014L3.23 9.455a1.881 1.881 0 00-.604 1.378V19.5A1.875 1.875 0 004.5 21.375h15a1.875 1.875 0 001.875-1.875v-8.667a1.88 1.88 0 00-.604-1.378zm-1.646 9.67H4.875v-8.13L12 4.268l7.125 6.727v8.13z"
        fill={fill}
      />
    </Svg>
  );
}

export default DashboardIcon;
