import { View, StyleSheet } from "react-native";
import { classCardColors, DEFAULT_CLASS_COLOR } from "../../../constants/classCardColors";
import ClassCardProgress from "../ClassCardProgress/ClassCardProgress";
import CustomText from "../../common/general/CustomText/CustomText";

function CoursePickerList({ section, onPress, onHoverIn, onHoverOut, isNull }) {
  const getTypeConfig = (section) => {
    switch (section) {
      case "core":
        return {
          maxSlots: 3,
          courses: [
            {
              id: "core-1",
              name: "Introduction to Programming",
              type: "core",
              category: "Core Category 1",
              duration: 90,
              description: "Software Development Life Cycle, Version Control, Debugging, Testing",
              progress: 0,
            },
            {
              id: "core-2",
              name: "Software Engineering Fundamentals",
              type: "core",
              category: "Core Category 2",
              duration: 90,
              description: "Software Development Life Cycle, Version Control, Debugging, Testing",
              progress: 0,
            },
          ],
        };
      case "elective":
        return {
          maxSlots: 3,
          courses: [
            {
              id: "elective-1",
              name: "Entrepreneurship and Innovation",
              type: "elective",
              category: "Elective Category 1",
              description:
                "Discover how to launch your own business, build a plan, and turn creative ideas into real-world ventures.",
              progress: 0,
            },
          ],
        };
      case "general":
        return {
          maxSlots: 3,
          courses: [
            {
              id: "general-1",
              name: "World History: Foundations of Civilization",
              type: "general",
              category: "General Category 1",
              description:
                "Explore the key events, cultures, and figures that shaped ancient civilizations and their impact on the modern world.",
              progress: 0,
            },
          ],
        };
      default:
        return {
          maxSlots: 3,
          courses: [],
        };
    }
  };

  const config = getTypeConfig(section);
  const { courses, maxSlots } = config;

  if (isNull) {
    return (
      <View style={styles.placeholderContainer}>
        <CustomText useTranslationText={false} style={styles.placeholderText} text={"No courses match this criteria"} />
      </View>
    );
  }

  // Calculate number of placeholder slots needed
  const placeholdersNeeded = Math.max(0, maxSlots - courses.length);
  const placeholders = Array(placeholdersNeeded).fill(null);

  return (
    <View
      role="grid"
      aria-label="Classes cards"
      style={[
        styles.container,
        {
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "flex-start",
        },
      ]}>
      {courses.map((course, index) => {
        const color = classCardColors.find((color) => color.id === course.color)?.color ?? DEFAULT_CLASS_COLOR;
        return (
          <ClassCardProgress
            key={`course-${index}`}
            title={course?.name}
            type={course?.type}
            isHovered={course?.isHovered}
            description={course?.description}
            onPress={() => onPress(course?.id)}
            onHoverIn={() => onHoverIn(course?.id)}
            onHoverOut={() => onHoverOut(course?.id)}
            color={color}
            progress={course?.progress}
          />
        );
      })}

      {placeholders.map((_, index) => (
        <ClassCardProgress
          key={`placeholder-${index}`}
          isPlaceholder={true}
          type={section}
          onPress={() => onPress("add-new")}
          onHoverIn={() => onHoverIn("add-new")}
          onHoverOut={() => onHoverOut("add-new")}
        />
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 16, // Add consistent gap
    marginBottom: 32, // Add bottom margin to prevent section overlap
  },
  placeholderContainer: {
    flex: 1,
    margin: 40,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  placeholderText: {
    fontSize: 16,
    color: "#667085",
    textAlign: "center",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: 24,
  },
});

export default CoursePickerList;
