import React from "react";
import { StyleSheet, View, ImageBackground, Linking } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import Images from "../../svgs/common/Images";
import Sentry from "../../../utils/sentry";

const ImageCard = ({ imageUrl, url }) => {
  const handlePress = () => {
    Linking.openURL(url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.imageContainer}>
          <ImageBackground style={styles.image} source={{ uri: imageUrl }}>
            <CustomButton
              onPress={handlePress}
              leftIcon={<Images />}
              size="s"
              text="View more"
              styleType="dark"
              style={styles.button}
              useTranslationText={false}
              textStyle={styles.buttonText}
            />
          </ImageBackground>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 15,
    overflow: "hidden",
    backgroundColor: "white",
    minWidth: 260,
    maxWidth: 260,
  },
  imageContainer: {
    width: "100%",
    height: 173,
  },
  image: {
    height: "100%",
    width: "100%",
    resizeMode: "cover",
  },
  button: {
    backgroundColor: "#000000",
    borderRadius: 100,
    height: 36,
    position: "absolute",
    right: 9,
    bottom: 9,
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 13,
    paddingVertical: 8,
    gap: 4,
  },
  buttonText: {
    color: "white",
    fontSize: 12,
    fontWeight: 600,
    fontFamily: "Inter",
    lineHeight: 18,
  },
});

export default ImageCard;
