import { Svg, Path } from "react-native-svg";

const JourneyPathBold = ({ fill }) => (
  <Svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M17.8076 14.0938C17.1681 14.0946 16.5445 14.2933 16.0223 14.6626C15.5001 15.0318 15.1049 15.5536 14.8909 16.1562H6.80762C6.16944 16.1562 5.5574 15.9027 5.10614 15.4515C4.65488 15.0002 4.40137 14.3882 4.40137 13.75C4.40137 13.1118 4.65488 12.4998 5.10614 12.0485C5.5574 11.5973 6.16944 11.3438 6.80762 11.3438H15.0576C16.0605 11.3438 17.0222 10.9454 17.7314 10.2362C18.4405 9.52713 18.8389 8.56535 18.8389 7.5625C18.8389 6.55965 18.4405 5.59787 17.7314 4.88875C17.0222 4.17963 16.0605 3.78125 15.0576 3.78125H6.80762C6.53411 3.78125 6.27181 3.8899 6.07841 4.0833C5.88502 4.27669 5.77637 4.539 5.77637 4.8125C5.77637 5.086 5.88502 5.34831 6.07841 5.5417C6.27181 5.7351 6.53411 5.84375 6.80762 5.84375H15.0576C15.5135 5.84375 15.9506 6.02483 16.273 6.34716C16.5953 6.66949 16.7764 7.10666 16.7764 7.5625C16.7764 8.01834 16.5953 8.45551 16.273 8.77784C15.9506 9.10017 15.5135 9.28125 15.0576 9.28125H6.80762C5.62243 9.28125 4.48579 9.75206 3.64773 10.5901C2.80968 11.4282 2.33887 12.5648 2.33887 13.75C2.33887 14.9352 2.80968 16.0718 3.64773 16.9099C4.48579 17.7479 5.62243 18.2188 6.80762 18.2188H14.8909C15.08 18.7535 15.4124 19.226 15.8519 19.5846C16.2913 19.9431 16.8209 20.174 17.3827 20.2519C17.9445 20.3298 18.5169 20.2518 19.0374 20.0264C19.5578 19.8009 20.0063 19.4367 20.3338 18.9736C20.6612 18.5105 20.8551 17.9663 20.8941 17.4005C20.9332 16.8346 20.8159 16.2689 20.5552 15.7652C20.2944 15.2615 19.9002 14.8392 19.4156 14.5444C18.9311 14.2496 18.3748 14.0937 17.8076 14.0938ZM17.8076 18.2188C17.6037 18.2188 17.4043 18.1583 17.2347 18.045C17.0651 17.9316 16.9329 17.7706 16.8549 17.5821C16.7768 17.3937 16.7564 17.1864 16.7962 16.9863C16.836 16.7863 16.9342 16.6025 17.0784 16.4583C17.2226 16.3141 17.4064 16.2159 17.6064 16.1761C17.8065 16.1363 18.0138 16.1567 18.2023 16.2347C18.3907 16.3128 18.5518 16.445 18.6651 16.6146C18.7784 16.7842 18.8389 16.9835 18.8389 17.1875C18.8389 17.461 18.7302 17.7233 18.5368 17.9167C18.3434 18.1101 18.0811 18.2188 17.8076 18.2188Z"
      fill={fill}
    />
  </Svg>
);

export default JourneyPathBold;
