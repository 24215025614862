import React from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";

const AddTaskOutline = () => {
  return (
    <View style={styles.container}>
      <View style={styles.dashed}>
        <CustomText text="Add a task" size="m" weight="bold" />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: 1,
    borderColor: "#667085",
    borderStyle: "dashed",
    padding: 20,
    borderRadius: 8,
  },
  dashed: {},
});

export default AddTaskOutline;
