import React from "react";
import { StyleSheet, View, ImageBackground, Pressable } from "react-native";
import { shadowStyles } from "../../../styles/shadow";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";
import CustomText from "../../common/general/CustomText/CustomText";

const ProjectCard = ({ card, onJourneyPress }) => {
  function formatDateString(dateString) {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = { year: "numeric", month: "long", day: "numeric" };
    return date.toLocaleDateString("en-US", options);
  }

  return (
    <Pressable
      onPress={() => onJourneyPress(card.id)}
      style={[styles.container, shadowStyles.primary]}
      role="link"
      aria-label="Journey Card"
      accessibilityHint="Press to see journey card details">
      <ImageBackground source={{ uri: card.url }} style={styles.image}>
        <View style={styles.progressBarOverlay}>
          <View style={{ flex: 1, padding: 10, flexDirection: "column" }}>
            <View style={{ paddingHorizontal: 20 }}>
              <CustomText
                useTranslationText={false}
                weight="bold"
                text={`${card.completedTasks} of ${card.totalTasks} Tasks Complete`}
                styleType="light"
                size="m"
                style={null}
                role="header"
                aria-level="2"
              />
            </View>
            <View style={styles.progressBarContainer}>
              <CustomProgressBar
                ariaLabel="Journey Progress"
                progress={Math.round((card.completedTasks / card.totalTasks) * 100)}
                color={"#5BE068"}
                unfilledColor="#EAECF0"
              />
            </View>
          </View>
        </View>
      </ImageBackground>
      <View style={{ margin: 20 }}>
        <CustomText
          useTranslationText={false}
          role="header"
          aria-level="2"
          weight="bold"
          text={card.name}
          size="l"
          style={null}
        />
        <View style={styles.textRow}>
          <CustomText
            useTranslationText={false}
            role="header"
            aria-level="3"
            text={"Started: "}
            weight="bold"
            size="s"
            style={null}
          />
          <CustomText
            useTranslationText={false}
            text={formatDateString(card.created)}
            weight="light"
            size="s"
            style={null}
            role="header"
            aria-level="4"
          />
        </View>
        <View style={styles.textRow}>
          <CustomText
            useTranslationText={false}
            role="header"
            aria-level="3"
            text={"Expected Completion: "}
            weight="bold"
            size="s"
            style={null}
          />
          <CustomText
            useTranslationText={false}
            text={formatDateString(card.dueDate)}
            weight="light"
            size="s"
            style={null}
            role="header"
            aria-level="4"
          />
        </View>
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: "hidden",
    maxWidth: 570,
    width: "100%",
    margin: 10,
  },
  progressBarContainer: {
    width: "100%",
    paddingHorizontal: 20,
    paddingVertical: 4,
  },
  textRow: {
    flexDirection: "row",
    marginTop: 12,
  },
  image: {
    width: "100%",
    height: 244,
  },
  progressBarOverlay: {
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    borderRadius: 10,
    position: "absolute",
    bottom: 10,
    left: 10,
    right: 10,
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
});

export default ProjectCard;
