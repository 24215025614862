import React from "react";
import { StyleSheet, View, Pressable } from "react-native";
import CustomText from "../CustomText/CustomText";

const TabNavigator = ({ tabData, activeTab, onTabPress, rightButtonComponent }) => {
  return (
    <View style={styles.navBar}>
      {tabData.map((tab) => (
        <Pressable
          role="tab"
          key={tab.key}
          style={activeTab === tab.key ? styles.activeTab : styles.tab}
          onPress={() => onTabPress(tab.key)}>
          <CustomText bold={activeTab === tab.key} size="s" style={{ color: "#646877" }} text={tab.title} />
        </Pressable>
      ))}
      {rightButtonComponent ? rightButtonComponent : null}
    </View>
  );
};

const styles = StyleSheet.create({
  navBar: {
    flexDirection: "row",
    justifyContent: "flex-start",
    borderBottomWidth: 1,
    borderBottomColor: "#D3D3D3",
    alignItems: "center",
  },
  tab: {
    padding: 15,
  },
  activeTab: {
    padding: 15,
    borderBottomWidth: 2,
    borderBottomColor: "blue",
  },
});

export default TabNavigator;
