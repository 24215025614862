import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent({ fill = "#000000" }) {
  return (
    <Svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M12.5501 8.31868L8.49608 12.3727C8.41156 12.4572 8.29692 12.5047 8.17739 12.5047C8.05785 12.5047 7.94321 12.4572 7.85869 12.3727C7.77417 12.2882 7.72669 12.1736 7.72669 12.054C7.72669 11.9345 7.77417 11.8199 7.85869 11.7353L11.1442 8.45043H2.32154C2.20208 8.45043 2.0875 8.40298 2.00303 8.3185C1.91855 8.23402 1.87109 8.11945 1.87109 7.99998C1.87109 7.88052 1.91855 7.76594 2.00303 7.68147C2.0875 7.59699 2.20208 7.54953 2.32154 7.54953H11.1442L7.85869 4.26463C7.77417 4.18011 7.72669 4.06547 7.72669 3.94594C7.72669 3.82641 7.77417 3.71177 7.85869 3.62725C7.94321 3.54272 8.05785 3.49524 8.17739 3.49524C8.29692 3.49524 8.41156 3.54272 8.49608 3.62725L12.5501 7.68129C12.592 7.72313 12.6252 7.7728 12.6479 7.82749C12.6706 7.88217 12.6822 7.94079 12.6822 7.99998C12.6822 8.05918 12.6706 8.1178 12.6479 8.17248C12.6252 8.22716 12.592 8.27684 12.5501 8.31868Z"
        fill={fill}
      />
    </Svg>
  );
}

export default SvgComponent;
