import React from "react";
import { StyleSheet } from "react-native";
import { MotiView } from "moti";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { shadowStyles } from "../../../styles/shadow";

const OnboardMenu = ({ onLogout, onSkip }) => {
  return (
    <MotiView
      from={{ opacity: 0, scale: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      delay={10000}
      transition={{ type: "timing", duration: 1000 }}
      exit={{ opacity: 0, scale: 0 }}
      style={[styles.container, shadowStyles.primary]}>
      <CustomText text="Account Onboarding" size="s" weight="bold" style={styles.header} />
      <CustomSpacing type="horizontal" size="s" />
      <CustomButton text="Logout" size="xs" styleType="transparent" textStyle={styles.buttonText} onPress={onLogout} />
      <CustomButton text="Skip" size="xs" styleType="transparent" textStyle={styles.buttonText} onPress={onSkip} />
    </MotiView>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 10,
    // position: "absolute",
    alignSelf: "flex-end",
    flexDirection: "row",
    alignItems: "center",
    // top: 30,
    // right: 30,
    marginRight: 30,
    marginTop: 30,
    zIndex: 10,
    padding: 20,
  },
  header: {
    fontFamily: "DmMono",
  },
  buttonText: {
    color: "#888888",
  },
});

export default OnboardMenu;
