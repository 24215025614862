import React, { useState, useEffect, useRef } from "react";
import { SafeAreaView, StyleSheet, View } from "react-native";
import { RichEditor, RichToolbar, actions } from "react-native-pell-rich-editor";
import { DatePickerInput } from "react-native-paper-dates";
import useResponsiveScreen from "../../hooks/useResponsiveScreen";
import CustomModal from "../../components/common/general/CustomModal/CustomModal";
import CustomText from "../../components/common/general/CustomText/CustomText";
import CustomTextInput from "../../components/common/general/CustomTextInput/CustomTextInput";
import CustomSpacing from "../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../components/common/general/CustomButton/CustomButton";

const tomorrow = new Date(new Date().getTime() + 86400000);

const EditAddCustomAssignmentOrPageModal = ({
  visible,
  onClose,
  initialContent,
  currentName = "",
  currentDueDate,
  type,
  onSaveItem,
  moduleName,
  resourceId,
}) => {
  const { isMedium } = useResponsiveScreen();
  const [name, setName] = useState("");
  const [dueDate, setDueDate] = useState(null);
  const [editorContent, setEditorContent] = useState("");
  const editorRef = useRef(null);

  useEffect(() => {
    if (currentName !== "") {
      setName(currentName);
    }

    if (currentDueDate) {
      setDueDate(new Date(currentDueDate));
    } else {
      setDueDate(tomorrow);
    }

    if (initialContent) {
      setEditorContent(initialContent);
    } else {
      setEditorContent("");
    }

    return () => {
      setName("");
      setDueDate(tomorrow);
    };
  }, [visible]);

  const isNewItem = currentName === "";

  const onChangeName = (text) => {
    setName(text);
  };

  const handleSave = () => {
    editorRef.current.getContentHtml().then((htmlContent) => {
      onSaveItem(type, htmlContent, name, dueDate, moduleName, resourceId);
    });
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={isNewItem ? `Add ${type}` : `Edit ${type}`}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <SafeAreaView style={styles.container}>
        <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text={`${type} Name`} size="xs" />
        <CustomTextInput value={name} onChangeText={onChangeName} placeholder={`Enter ${type} Name`} />
        <CustomSpacing type="vertical" size="l" />

        <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text={`${type} Body`} size="xs" />
        <View style={styles.editorContainer}>
          <RichEditor
            ref={editorRef}
            style={styles.editor}
            initialContentHTML={editorContent}
            onChange={(html) => setEditorContent(html)}
          />
        </View>

        <RichToolbar
          editor={editorRef}
          actions={[actions.setBold, actions.setItalic, actions.setUnderline, actions.heading1]}
          style={styles.toolbar}
        />

        {type === "Assignment" && (
          <>
            <CustomSpacing type="vertical" size="l" />
            <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text="Due Date" size="xs" />
            <DatePickerInput
              locale="en"
              label="Due Date"
              value={dueDate}
              onChange={(d) => onDatePickerChange(d)}
              inputMode="start"
              mode="outlined"
              outlineColor={"#D1D5DB"}
              selectionColor={false}
              activeOutlineColor={"blue"}
              inputProps={{
                color: "#000000",
                mode: "outline",
              }}
              theme={{
                colors: "#000000",
                roundness: 8,
                opacity: 1,
              }}
              style={styles.datePicker}
              aria-label="Due date date picker"
            />
            <CustomSpacing type="vertical" size="l" />
          </>
        )}

        <View style={styles.buttonContainer}>
          <CustomButton text={isNewItem ? "Add" : "Save"} onPress={handleSave} styleType="primary" size="m" />
          <CustomButton text="Cancel" onPress={onClose} styleType="primaryLight" size="m" />
        </View>
      </SafeAreaView>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    width: "100%",
    flex: 1,
  },
  editorContainer: {
    flex: 1,
    marginBottom: 10,
    minHeight: 300,
  },
  editor: {
    flex: 1,
    borderColor: "#ccc",
    borderWidth: 1,
    borderRadius: 4,
  },
  toolbar: {
    backgroundColor: "#f1f1f1",
    borderColor: "#ccc",
    borderTopWidth: 1,
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    paddingVertical: 10,
  },
  datePicker: {
    borderWidth: 0,
    paddingVertical: 5,
    backgroundColor: "#F8F8F8",
    borderColor: "#000000",
    borderRadius: 10,
    color: "#000000",
    fontSize: 14,
  },
});

export default EditAddCustomAssignmentOrPageModal;
