import { View, StyleSheet } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import { useAppConfig } from "../../../../AppConfigProvider";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import { Redo, ThumbDown, ThumbUp } from "../../../svgs/common";

function LessonHeader({
  tag,
  title,
  secondTitle,
  description,
  instructions,
  showButtons,
  onRedoPress,
  onThumbUpPress,
  onThumbDownPress,
}) {
  const { isMedium } = useResponsiveScreen();
  const appConfig = useAppConfig();
  const primaryColor = appConfig.primaryColor;

  return (
    <View style={styles.container}>
      {!!tag && (
        <>
          <CustomText
            useTranslationText={false}
            text={tag}
            size="xs"
            weight="bold"
            numberOfLines={1}
            ellipsizeMode="tail"
            style={{
              color: primaryColor,
              fontWeight: 700,
              backgroundColor: "#E6EBFF",
              paddingVertical: 4,
              paddingHorizontal: 8,
              borderRadius: 100,
            }}
            aria-label={tag}
            role="tag"
          />
          <CustomSpacing type="vertical" size="m" />
        </>
      )}
      <View style={styles.rowContainer}>
        <CustomText
          useTranslationText={false}
          text={title}
          size={isMedium ? "s" : "xs"}
          textType="display"
          weight="ultraBold"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{ color: "#101828" }}
          role="header"
          aria-level="1"
        />

        {showButtons && (
          <View style={styles.feedbackButtons}>
            <CustomButton
              text=""
              size="s"
              styleType="transparent"
              leftIcon={<Redo style={styles.svgStyle} />}
              style={{ padding: 0 }}
              onPress={onRedoPress}
              aria-label="Redo lesson"
            />
            <CustomButton
              text=""
              size="s"
              styleType="transparent"
              leftIcon={<ThumbUp style={styles.svgStyle} />}
              style={{ padding: 0 }}
              onPress={onThumbUpPress}
              aria-label="Thumb up"
            />
            <CustomButton
              text=""
              size="s"
              styleType="transparent"
              leftIcon={<ThumbDown style={styles.svgStyle} />}
              style={{ padding: 0 }}
              onPress={onThumbDownPress}
              aria-label="Thumb down"
            />
          </View>
        )}
      </View>
      {secondTitle && !isMedium && (
        <CustomText
          useTranslationText={false}
          text={secondTitle}
          size={isMedium ? "s" : "xs"}
          textType="display"
          weight="ultraBold"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={{ color: "#101828" }}
          role="header"
          aria-level="2"
        />
      )}
      {description && (
        <>
          <CustomSpacing type="vertical" size="l" />
          <CustomText text="Instructions" weight="bold" size={isMedium ? "l" : "s"} style={{ color: "#182230" }} />
          <CustomSpacing type="vertical" size="xs" />
          <CustomText
            useTranslationText={false}
            text={description}
            style={{ fontWeight: 400, color: "#344054" }}
            numberOfLines={3}
            ellipsizeMode="tail"
            size={isMedium ? "m" : "s"}
          />
        </>
      )}
      {instructions && (
        <>
          <CustomSpacing type="vertical" size="m" />
          <CustomText
            useTranslationText={false}
            text={instructions}
            style={{ color: "#182230" }}
            size={isMedium ? "l" : "m"}
            numberOfLines={1}
            weight="bold"
            ellipsizeMode="tail"
            aria-label={instructions}
            role="header"
            aria-level="4"
          />
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    backgroundColor: "#FFFFFF",
    width: "100%",
  },
  rowContainer: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    gap: 10,
  },
  feedbackButtons: {
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 8,
    width: 88,
  },
  svgStyle: {
    width: 24,
    height: 24,
  },
});

export default LessonHeader;
