import * as React from "react";
import Svg, { G, Path, Defs, ClipPath } from "react-native-svg";

function TrashCan(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_634_417)">
        <Path
          d="M16.875 3.75h-2.813v-.938A2.188 2.188 0 0011.876.626h-3.75a2.188 2.188 0 00-2.188 2.188v.937H3.126a.937.937 0 100 1.875h.313V16.25A1.563 1.563 0 005 17.813h10a1.563 1.563 0 001.563-1.563V5.625h.312a.937.937 0 000-1.875zm-9.063-.938a.312.312 0 01.313-.312h3.75a.313.313 0 01.313.313v.937H7.811v-.938zm6.875 13.126H5.313V5.624h9.375v10.313zM9.064 8.125v5a.937.937 0 01-1.876 0v-5a.937.937 0 111.875 0zm3.75 0v5a.938.938 0 01-1.876 0v-5a.938.938 0 011.876 0z"
          fill="#fff"
        />
      </G>
      <Defs>
        <ClipPath id="clip0_634_417">
          <Path fill="#fff" d="M0 0H20V20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default TrashCan;
