import { StyleSheet, Pressable, View } from "react-native";
import React from "react";
import CustomText from "../../common/general/CustomText/CustomText";
import { SmallGreenCircle } from "../../svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { shadowStyles } from "../../../styles/shadow";
const ChatTitle = ({ title, active, onChatPress }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.floatingContainer}>
      <Pressable
        role="button"
        aria-label={`Chat ${title}`}
        onPress={onChatPress}
        style={[
          styles.floatingContentBase,
          isMedium ? styles.floatingContentDesktop : styles.floatingContentMobile,
          active ? shadowStyles.primary : {},
        ]}
        disabled={active}>
        {active && (
          <View style={isMedium ? { marginRight: 8 } : { marginRight: 4 }}>
            <SmallGreenCircle />
          </View>
        )}
        <CustomText
          useTranslationText={false}
          text={title}
          size={isMedium ? "m" : "s"}
          textType="text"
          weight="medium"
          style={{ color: "#667085" }}
          numberOfLines={1}
          ellipsizeMode="tail"
        />
      </Pressable>
    </View>
  );
};

export default ChatTitle;

const styles = StyleSheet.create({
  floatingContainer: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
  },
  floatingContentBase: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 313,
    flexShrink: 1,
    borderRadius: 360,
  },
  floatingContentDesktop: {
    marginRight: 22,
    paddingVertical: 12,
    paddingHorizontal: 20,
  },
  floatingContentMobile: {
    paddingVertical: 6,
    paddingHorizontal: 10,
  },
});
