import React from "react";
import { MotiView } from "moti";
import { View, Pressable, StyleSheet, Linking } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import Link from "../../svgs/common/Link";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import Sentry from "../../../utils/sentry";

function SourceCard({ title, url }) {
  const handlePress = () => {
    Linking.openURL(url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      delay={1000}
      transition={{ type: "timing", duration: 500 }}>
      <Pressable onPress={handlePress}>
        <View style={styles.card}>
          <CustomText size="xs" weight="medium" text={title} useTranslationText={false} />
          <View style={styles.row}>
            <Link />
            <CustomSpacing type="horizontal" size="xs" />
            <CustomText
              size="xs"
              weight="normal"
              style={styles.subText}
              text="wikipedia.org"
              useTranslationText={false}
            />
          </View>
        </View>
      </Pressable>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  card: {
    flex: 1,
    minWidth: 260,
    maxWidth: 260,
    height: 44,
    paddingVertical: 4,
    paddingHorizontal: 12,
    backgroundColor: "#F9FAFB",
    borderRadius: 10,
    marginBottom: 6,
  },
  subText: {
    color: "#667085",
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
});

export default SourceCard;
