import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";

const TwoFactorEmail = ({ handleCodePress, userEmail }) => {
  const [email, setEmail] = useState(userEmail || "");
  const [errorMessage, setErrorMessage] = useState("");

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (text) => {
    setEmail(text);
    setErrorMessage("");
  };

  const handleSendCodePress = async () => {
    if (validateEmail(email)) {
      try {
        await handleCodePress(email);
        setErrorMessage("");
      } catch (error) {
        setErrorMessage("Failed to send OTP. Please try again.");
      }
    } else {
      setErrorMessage("Please enter a valid email address.");
    }
  };

  return (
    <View style={styles.container}>
      <CustomText text="Enter your email address" size="s" textType="text" />
      <CustomSpacing type="vertical" size={16} />
      <CustomTextInput
        placeholder="Ex: jim@example.com"
        value={email}
        onChangeText={handleEmailChange}
        editable={!userEmail}
        keyboardType="email-address"
        autoCapitalize="none"
        autoCorrect={false}
      />
      <CustomSpacing type="vertical" size={8} />
      {errorMessage ? <CustomText text={errorMessage} size="xs" style={styles.errorText} /> : null}
      <CustomSpacing type="vertical" size={24} />
      <CustomButton
        text="Send Code"
        styleType="primary"
        onPress={handleSendCodePress}
        style={styles.sendCodeButton}
        leftIcon={null}
        rightIcon={null}
        textStyle={{}}
        textProps={{}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  sendCodeButton: {
    width: "100%",
  },
  errorText: {
    color: "red",
  },
});

export default TwoFactorEmail;
