import * as React from "react";
import Svg, { Path } from "react-native-svg";

function UpRightArrow(props) {
  return (
    <Svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M4.75.062H21a.938.938 0 01.938.938v16.25a.938.938 0 01-1.875 0V3.262l-18.4 18.4a.937.937 0 01-1.325-1.325l18.4-18.4H4.75a.937.937 0 110-1.875z"
        fill="#000"
      />
    </Svg>
  );
}

export default UpRightArrow;
