import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CareerIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M9.375 9.375A1.125 1.125 0 0110.5 8.25h3a1.125 1.125 0 010 2.25h-3a1.125 1.125 0 01-1.125-1.125zm12.75-3v12a1.875 1.875 0 01-1.875 1.875H3.75a1.875 1.875 0 01-1.875-1.875v-12A1.875 1.875 0 013.75 4.5h3.375v-.75A2.625 2.625 0 019.75 1.125h4.5a2.625 2.625 0 012.625 2.625v.75h3.375a1.875 1.875 0 011.875 1.875zM9.375 4.5h5.25v-.75a.375.375 0 00-.375-.375h-4.5a.375.375 0 00-.375.375v.75zm-5.25 2.25v3.303A16.88 16.88 0 0012 12c2.744 0 5.448-.668 7.875-1.948V6.75H4.125zM19.875 18v-5.443a19.15 19.15 0 01-15.75 0V18h15.75z"
        fill={fill}
      />
    </Svg>
  );
}

export default CareerIcon;
