import { StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import Delete from "../../../components/svgs/common/Delete";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import { useAppConfig } from "../../../AppConfigProvider";

function TaskDeleteConfirmationModalContainer({ visible, onClose, onDelete }) {
  const { isMedium } = useResponsiveScreen();
  const { primaryColor } = useAppConfig();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Delete Task"
      style={{
        width: isMedium ? "50%" : "90%",
        maxHeight: "90%", // Ensures modal doesn't take the whole screen height
        height: "auto",
      }}>
      <CustomText text="Are you sure you want to delete this task? This cannot be undone." />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton
        text="Delete Task"
        onPress={onDelete}
        styleType="danger"
        style={styles.deleteButton}
        leftIcon={<Delete style={{ marginRight: 7 }} fill="#FFF" />}
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton
        text="Back"
        onPress={onClose}
        styleType="primaryLight"
        style={[{ borderColor: primaryColor }, styles.noShadow]}
        aria-label="Close modal and go back"
      />
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  deleteButton: { justifyContent: "center", alignItems: "center" },
  noShadow: {
    color: "red",
    width: "auto",
    justifyContent: "center",
    alignItems: "center",
    shadowColor: "transparent",
    elevation: 0,

    borderWidth: 2,
  },
});

export default TaskDeleteConfirmationModalContainer;
