import React, { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import EditModule from "../EditModule/EditModule";

const EditModuleNameModalContainer = ({
  visible,
  onClose,
  moduleName,
  onUpdateModuleName,
  hasOrder = false,
  currentOrder,
  moduleId,
}) => {
  const { isMedium } = useResponsiveScreen();
  const [newModuleName, setNewModuleName] = useState(moduleName);
  const [order, setOrder] = useState(currentOrder);

  useEffect(() => {
    if (moduleName) {
      setNewModuleName(moduleName);
    } else {
      setNewModuleName("");
    }

    if (currentOrder) {
      setOrder(currentOrder);
    } else {
      setOrder(undefined);
    }

    return () => {
      setNewModuleName("");
      setOrder(undefined);
    };
  }, [visible]);

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={hasOrder ? "Edit Module" : "Edit Module Name"}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <EditModule
        onChangeName={setNewModuleName}
        moduleName={newModuleName ?? moduleName}
        saveButtonClick={() => onUpdateModuleName(moduleName, newModuleName, order, moduleId)}
        hasOrder={hasOrder}
        order={order ?? currentOrder}
        setOrder={setOrder}
      />
    </CustomModal>
  );
};

export default EditModuleNameModalContainer;
