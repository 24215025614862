import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={33} height={33} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx={16.6666} cy={16.5} r={16} fill="#DBF0E0" />
      <Path
        d="M27.781 13.005a1.875 1.875 0 00-1.618-1.287l-5.344-.462-2.09-4.982a1.876 1.876 0 00-3.452 0l-2.094 4.982-5.343.463a1.876 1.876 0 00-1.07 3.287l4.06 3.544-1.218 5.273a1.875 1.875 0 002.794 2.03L17 23.063l4.594 2.79a1.875 1.875 0 002.793-2.03L23.17 18.55l4.061-3.544a1.874 1.874 0 00.551-2.001zM21.523 17a1.875 1.875 0 00-.595 1.84l1.068 4.624-4.026-2.445a1.866 1.866 0 00-1.94 0l-4.026 2.445 1.068-4.623a1.875 1.875 0 00-.595-1.84l-3.571-3.116 4.7-.407a1.868 1.868 0 001.565-1.143L17 7.977l1.83 4.358a1.867 1.867 0 001.564 1.143l4.7.407L21.524 17z"
        fill="#41B85C"
      />
    </Svg>
  );
}

export default SvgComponent;
