import { Svg, Path } from "react-native-svg";

function Sidebar(props) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M20.25 3.375H3.75C3.25272 3.375 2.77581 3.57254 2.42417 3.92417C2.07254 4.27581 1.875 4.75272 1.875 5.25V18.75C1.875 19.2473 2.07254 19.7242 2.42417 20.0758C2.77581 20.4275 3.25272 20.625 3.75 20.625H20.25C20.7473 20.625 21.2242 20.4275 21.5758 20.0758C21.9275 19.7242 22.125 19.2473 22.125 18.75V5.25C22.125 4.75272 21.9275 4.27581 21.5758 3.92417C21.2242 3.57254 20.7473 3.375 20.25 3.375ZM4.125 5.625H7.125V18.375H4.125V5.625ZM19.875 18.375H9.375V5.625H19.875V18.375Z"
        fill="#667085"
      />
    </Svg>
  );
}

export default Sidebar;
