import { Pressable, View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import {
  NodeGraph,
  NodeAchievement,
  NodeEducation,
  NodeEvent,
  NodeGoal,
  NodeHealth,
  NodeInterest,
  NodeSocial,
  NodeWork,
} from "../../svgIcons";

function AspectMessage({ message, onPress }) {
  function renderIcon(label) {
    switch (label.toLowerCase()) {
      case "achievements":
        return <NodeAchievement />;
      case "educationhistory":
        return <NodeEducation />;
      case "events":
        return <NodeEvent />;
      case "goals":
        return <NodeGoal />;
      case "waterintake":
        return <NodeHealth />;
      case "fooditem":
        return <NodeHealth />;
      case "exerciseitem":
        return <NodeHealth />;
      case "interests":
        return <NodeInterest />;
      case "socialconnections":
        return <NodeSocial />;
      case "employmenthistory":
        return <NodeWork />;
      default:
        return <NodeGraph />;
    }
  }

  function renderLabel(keyword) {
    switch (keyword.toLowerCase()) {
      case "interests":
        return "Interest Added";
      case "learninginterests":
        return "Learning Interest Added";
      case "socialconnections":
        return "Social Connection Added";
      case "goals":
        return "Goal Added";
      case "hobbies":
        return "Hobby Added";
      case "achievements":
        return "Achievement Added";
      case "fooditem":
        return "Food Item Added";
      case "exerciseitem":
        return "Exercise Item Added";
      case "waterintake":
        return "Water Intake Added";
      case "corevalues":
        return "Core Value Added";
      case "lifeaspirations":
        return "Life Aspiration Added";
      case "challenges":
        return "Challenge Added";
      case "reflections":
        return "Reflection Added";
      case "emotionalstates":
        return "Emotional State Added";
      case "physicalattributes":
        return "Physical Attribute Added";
      case "personaldetails":
        return "Personal Detail Added";
      case "employmenthistory":
        return "Employment History Added";
      case "educationhistory":
        return "Education History Added";
      case "assessment":
        return "Assessment Added";
      default:
        return "Node Added";
    }
  }

  function renderKeyLabel(keyword) {
    switch (keyword.toLowerCase()) {
      case "name":
        return "Name";
      case "category":
        return "Category";
      case "id":
        return "ID";
      case "friendid":
        return "Friend ID";
      case "relationshiptype":
        return "Relationship Type";
      case "connectionstrength":
        return "Connection Strength";
      case "frequencyofinteraction":
        return "Frequency of Interaction";
      case "lastinteractiondate":
        return "Last Interaction Date";
      case "sharedinterests":
        return "Shared Interests";
      case "sharedgoals":
        return "Shared Goals";
      case "sharedeventsattended":
        return "Shared Events Attended";
      case "friendpersonalitytraits":
        return "Friend Personality Traits";
      case "communicationchannel":
        return "Communication Channel";
      case "level":
        return "Level";
      case "startdate":
        return "Start Date";
      case "lastupdated":
        return "Last Updated";
      case "learningstyle":
        return "Learning Style";
      case "language":
        return "Language";
      case "timeofday":
        return "Time of Day";
      case "learningduration":
        return "Learning Duration";
      case "contenttype":
        return "Content Type";
      case "notificationpreferences":
        return "Notification Preferences";
      case "type":
        return "Type";
      case "completionstatus":
        return "Completion Status";
      case "deadline":
        return "Deadline";
      case "priority":
        return "Priority";
      case "value":
        return "Value";
      case "description":
        return "Description";
      case "date":
        return "Date";
      case "importance":
        return "Importance";
      case "status":
        return "Status";
      case "enddate":
        return "End Date";
      case "text":
        return "Text";
      case "sentiment":
        return "Sentiment";
      case "context":
        return "Context";
      case "intensity":
        return "Intensity";
      case "timestamp":
        return "Timestamp";
      case "trigger":
        return "Trigger";
      case "calories":
        return "Calories";
      case "carbohydrates_grams":
        return "Carbohydrates (g)";
      case "fat_grams":
        return "Fat (g)";
      case "fiber_grams":
        return "Fiber (g)";
      case "protein_grams":
        return "Protein (g)";
      case "serving_size":
        return "Serving Size";
      case "sodium_mg":
        return "Sodium (mg)";
      case "sugars_grams":
        return "Sugars (g)";
      case "totalcalories":
        return "Total Calories";
      case "totalcarbs":
        return "Total Carbohydrates";
      case "totalfats":
        return "Total Fats";
      case "totalproteins":
        return "Total Proteins";
      case "totalcaloriesburned":
        return "Total Calories Burned";
      case "totaldurationminutes":
        return "Total Duration (minutes)";
      case "totalworkouts":
        return "Total Workouts";
      case "water_intake_oz":
        return "Water Intake (oz)";
      default:
        return "Other";
    }
  }

  return (
    <Pressable onPress={onPress} style={styles.container} role="button" aria-label="Aspect Message Button">
      {renderIcon(message.snackType)}
      <View style={styles.textContainer}>
        <CustomText text={renderLabel(message.snackType)} weight="bold" size="m" useTranslationText={false} />

        {Object.entries(message.aspectDetails || {}).map(([key, value], index) => {
          // Ignore _typename key or if value is null
          if (key === "__typename" || value === null) {
            return null;
          }
          return (
            <CustomText
              text={`${renderKeyLabel(key)}: ${capitalizeFirstLetter(value.toString())}`}
              size="s"
              key={index}
              style={styles.detailsText}
              useTranslationText={false}
            />
          );
        })}
      </View>
    </Pressable>
  );
}

function capitalizeFirstLetter(string) {
  if (typeof string !== "string") return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 20,
    padding: 20,
    backgroundColor: "#F4F6FD",
    alignSelf: "flex-start",
  },
  textContainer: {
    marginLeft: 12,
  },
  detailsText: {
    fontWeight: "500",
  },
});

export default AspectMessage;
