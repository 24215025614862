import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ChatMenu(props) {
  return (
    <Svg width={21} height={20} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12.125 5.36v3.864l3.203 1.922a1.124 1.124 0 11-1.156 1.93l-3.75-2.25a1.125 1.125 0 01-.547-.966v-4.5a1.125 1.125 0 012.25 0zM11 .485a9.317 9.317 0 00-6.634 2.751c-.44.444-.843.879-1.241 1.313V3.86a1.125 1.125 0 00-2.25 0v3.75A1.125 1.125 0 002 8.735h3.75a1.125 1.125 0 100-2.25H4.416a35.9 35.9 0 011.546-1.663 7.125 7.125 0 11.148 10.22 1.125 1.125 0 00-1.545 1.636A9.375 9.375 0 1011 .485z"
        fill="#344054"
      />
    </Svg>
  );
}

export default ChatMenu;
