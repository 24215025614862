import React from "react";
import { StyleSheet, View, ImageBackground } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import Divider from "../../common/layout/Divider/Divider";
import { Salary, Outlook, KeySkills } from "../../svgs/careerDetails";
import { LinearGradient } from "expo-linear-gradient";
import { shadowStyles } from "../../../styles/shadow";

const CareerPathCardWide = ({ job, salary, growth, skills, flexDirection, url }) => {
  const salaryText = salary + " annually";
  const growthText = growth + "% growth";
  return (
    <View accessible={true} style={[styles.container, shadowStyles.largeCard]}>
      <View style={styles.imageContainer}>
        <ImageBackground
          style={styles.imageBackground}
          source={{ uri: url }}
          role={"image"}
          alt="Career path card picture"
          accessibilityLabel="Career path card picture">
          <View style={styles.overlayContainer}>
            <LinearGradient
              colors={["transparent", "rgba(0,0,0,0.5)", "black"]}
              locations={[0.5, 0.65, 1]}
              style={styles.linearGradient}>
              <View style={styles.imageTextContainer}>
                <CustomText bold="true" text={job} size="xxl" style={styles.jobText} useTranslationText={false} />
              </View>
            </LinearGradient>
          </View>
        </ImageBackground>
      </View>
      <View style={[styles.textContainer, { flexDirection: flexDirection }]}>
        <View style={styles.cell}>
          <View>
            <Salary />
          </View>
          <View style={styles.textCell}>
            <CustomText text="Average Salary" bold="true" size="xs" />
            <CustomText text={salaryText} size="xs" numberOfLines={1} ellipsizeMode="tail" useTranslationText={false} />
          </View>
        </View>
        {flexDirection === "row" && <Divider size="m" type="horizontal" />}
        <View style={styles.cell}>
          <View>
            <Outlook />
          </View>
          <View style={styles.textCell}>
            <CustomText text="Career Outlook" bold="true" size="xs" />
            <View style={{ flexDirection: "row" }}>
              <CustomText text="Projected " size="xs" />
              <CustomText
                text={growthText}
                style={{ color: "#21A60B" }}
                size="xs"
                numberOfLines={1}
                ellipsizeMode="tail"
                useTranslationText={false}
              />
              <CustomText text=" over the next decade" size="xs" />
            </View>
          </View>
        </View>
        {flexDirection === "row" && <Divider size="m" type="horizontal" />}
        <View style={styles.cell}>
          <View>
            <KeySkills />
          </View>
          <View style={styles.textCell}>
            <CustomText text="Key Skills" bold="true" size="xs" />
            <CustomText text={skills} size="xs" numberOfLines={1} ellipsizeMode="tail" useTranslationText={false} />
          </View>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: "hidden",
    maxWidth: 1520,
    marginBottom: 10,
  },
  textContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: 10,
  },
  cell: {
    flexDirection: "row",
    alignItems: "center",
  },
  textCell: {
    flexDirection: "column",
    padding: 8,
    flexShrink: 1,
  },
  imageContainer: {
    width: "100%",
    height: 300,
    resizeMode: "contain",
  },
  imageBackground: {
    width: "100%",
    height: "100%",
  },
  overlayContainer: {
    flex: 1,
    justifyContent: "flex-end",
  },
  linearGradient: {
    flex: 1,
    justifyContent: "flex-end",
  },
  imageTextContainer: {
    alignSelf: "flex-start",
    marginBottom: 10,
    marginLeft: 10,
  },
  jobText: {
    color: "white",
    textShadow: "0px 2px 10px rgba(255, 255, 255, 0.75)",
  },
});

export default CareerPathCardWide;
