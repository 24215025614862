import React from "react";
import { StyleSheet, View } from "react-native";
import CareerPathCard from "../CareerPathCard/CareerPathCard";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { SparkleBlue } from "../../svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const CareerPathCardList = ({
  isCareerLoading,
  hasCareer,
  careerPathCardData,
  onPressSetActive,
  navigation,
  onButtonPress,
  loading,
}) => {
  const { isMedium } = useResponsiveScreen();
  return (
    <View>
      <View
        aria-label="Career Path Card List"
        style={[
          styles.container,
          {
            justifyContent: isMedium ? "flex-start" : "center",
            flexWrap: isMedium ? "wrap" : "nowrap",
            flexDirection: isMedium ? "row" : "column",
          },
        ]}>
        {careerPathCardData.map((card) => (
          <CareerPathCard
            onPressSetActive={() => onPressSetActive(card.id)}
            key={card.id}
            jobTitle={card.name}
            description={card.brief_description}
            onPress={() => navigation(card.id)}
            url={card.url}
          />
        ))}
      </View>
      <View aria-live="polite">
        {isCareerLoading ? null : (
          <CustomButton
            onPress={onButtonPress}
            style={{
              marginTop: 18,
              alignSelf: hasCareer ? "auto" : "center",
              maxWidth: 243,
              marginBottom: 10,
            }}
            size="l"
            text={loading ? "Generating..." : hasCareer ? "Generate More Paths" : "Generate Paths"}
            aria-label={loading ? "Generating..." : hasCareer ? "Generate More Paths" : "Generate Paths"}
            disabled={loading}
            styleType="primaryLight"
            leftIcon={<SparkleBlue style={{ marginRight: 10 }} />}
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
  },
});

export default CareerPathCardList;
