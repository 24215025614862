import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import DatePickerButton from "../../general/DatePickerButton/DatePickerButton";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { SparkleWhite } from "../../svgs/common";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { BlackInfo } from "../../svgs/common";
import { shadowStyles } from "../../../styles/shadow";

const CreateJourneyForm = ({ description, startDate, onChangeDescription, onDatePick, onSubmit }) => {
  const { isMedium } = useResponsiveScreen();
  const inputLabelTextType = isMedium ? "display" : "text";
  const inputLabelTextSize = isMedium ? "xs" : "s";
  const inputLabelTextColor = isMedium ? {} : { color: "#182230" };
  const [infoVisible, setInfoVisible] = useState(true);

  const handleDismissInfo = () => {
    setInfoVisible(false);
  };

  return (
    <View style={isMedium ? styles.container : {}}>
      {infoVisible && isMedium && (
        <View style={[styles.infoBox, shadowStyles.smallShadow]}>
          <View style={styles.topInfoBox}>
            <View style={{ marginRight: 10 }}>
              <BlackInfo />
            </View>
            <CustomText
              style={styles.infoBoxText}
              size="xs"
              text="Kickstart your new journey by describing your goal. You'll receive actionable steps and assistance for each task, helping you progress. Customize these steps as you move forward, adding or completing tasks on your path to achievement."
            />
          </View>
          <View style={{ width: 80 }}>
            <CustomButton
              styleType="primaryTransparent"
              text="Dismiss"
              onPress={handleDismissInfo}
              aria-label="Dismiss info"
            />
          </View>
        </View>
      )}
      <CustomText
        text="Describe what you'd like to accomplish"
        textType={inputLabelTextType}
        style={inputLabelTextColor}
        size={inputLabelTextSize}
        role="header"
        aria-level="2"
      />
      <CustomSpacing type="vertical" size="s" />
      <CustomTextInput
        value={description}
        placeholder="Describe your goal in a few sentences. For example, “I have a 5k marathon coming up, I’d like to prepare for it both physically and mentally.”"
        onChangeText={(text) => onChangeDescription(text)}
        multiline={true}
        autoFocus={true}
        required
        numberOfLines={4}
      />
      <CustomSpacing type="vertical" size="m" />
      <CustomText
        text="Select a due date"
        textType={inputLabelTextType}
        style={inputLabelTextColor}
        size={inputLabelTextSize}
        role="header"
        aria-level="2"
      />
      <CustomSpacing type="vertical" size="s" />
      <View style={styles.buttonWrapper}>
        <DatePickerButton date={startDate.toLocaleDateString()} onPress={onDatePick} />
      </View>
      <CustomSpacing type="vertical" size="m" />
      <View style={styles.buttonWrapper}>
        <CustomButton
          text="Generate Tasks"
          styleType="primary"
          textStyle={isMedium ? {} : { fontSize: 14 }}
          onPress={onSubmit}
          leftIcon={<SparkleWhite />}
          aria-label="Generate tasks"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
  },
  buttonWrapper: {
    width: 160,
  },
  infoBox: {
    paddingHorizontal: 15,
    marginBottom: 15,
    paddingTop: 15,
    paddingBottom: 5,
    borderRadius: 10,
    justifyContent: "space-between",
    flexDirection: "column",
  },
  infoBoxText: {
    color: "grey",
  },
  topInfoBox: {
    paddingLeft: 5,
    flexDirection: "row",
    alignItems: "center",
  },
});

export default CreateJourneyForm;
