import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M21.796 5.204l-3-3a1.125 1.125 0 00-1.594 0l-9 9c-.21.211-.328.498-.327.796v3A1.125 1.125 0 009 16.125h3a1.125 1.125 0 00.796-.33l9-9a1.125 1.125 0 000-1.59zM18 4.594L19.406 6l-1.031 1.031-1.406-1.406L18 4.594zm-6.469 9.281h-1.406v-1.406l5.25-5.25 1.406 1.406-5.25 5.25zm9.844-1.447V19.5a1.875 1.875 0 01-1.875 1.875h-15A1.875 1.875 0 012.625 19.5v-15A1.875 1.875 0 014.5 2.625h7.072a1.125 1.125 0 010 2.25H4.875v14.25h14.25v-6.697a1.125 1.125 0 012.25 0z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default SvgComponent;
