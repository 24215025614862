import React, { useState } from "react";
import { StyleSheet, View, Image, Pressable } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { shadowStyles } from "../../../styles/shadow";

const CareerPathCard = ({ jobTitle, description, onPressSetActive, onPress, url }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const maxDescriptionLength = 300;
  const toggleDescription = () => {
    setIsExpanded(!isExpanded);
  };
  const truncateText = (text, maxLength) => {
    return text.length > maxLength && !isExpanded ? text.substring(0, maxLength - 3) + "..." : text;
  };

  return (
    <View style={[styles.container, shadowStyles.largeCard]} aria-label="Career path card">
      <Image
        source={{ uri: url }}
        style={styles.image}
        accessibilityLabel={description}
        alt={description}
        role={"image"}
      />
      <View style={{ margin: 20 }}>
        <CustomText bold="true" text={jobTitle} size="l" useTranslationText={false} />
        <Pressable role="button" aria-label="Toggle description" onPress={toggleDescription}>
          <CustomText
            text={truncateText(description, maxDescriptionLength)}
            size="s"
            style={{ marginTop: 12 }}
            useTranslationText={false}
          />
        </Pressable>
        <View style={styles.buttonContainer}>
          <CustomButton onPress={() => onPress()} styleType="primary" text="Explore Career" />
          <CustomButton
            onPress={onPressSetActive}
            text="Set as Active"
            aria-label="Set as active career"
            style={styles.activeButton}
          />
          <CustomSpacing size="xxl" type="horizontal" />
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 20,
    overflow: "hidden",
    maxWidth: 570,
    width: "100%",
    margin: 10,
  },
  image: {
    width: "100%",
    height: 300,
  },
  buttonContainer: {
    flexDirection: "row",
    paddingTop: 12,
  },
  activeButton: {
    backgroundColor: "#5BE0681A",
    color: "#0AA519",
    marginLeft: 15,
  },
});

export default CareerPathCard;
