export const classCardColors = [
  {
    id: 1,
    color: "#7839EE",
  },
  {
    id: 2,
    color: "#007CC1",
  },
  {
    id: 3,
    color: "#D80F7C",
  },
  {
    id: 4,
    color: "#054CD4",
  },
  {
    id: 5,
    color: "#008383",
  },
  {
    id: 6,
    color: "#C5109D",
  },
  {
    id: 7,
    color: "#4A1FB8",
  },
  {
    id: 8,
    color: "#E31B54",
  },
  {
    id: 9,
    color: "#4E5BA6",
  },
  {
    id: 10,
    color: "#125D56",
  },
];

export const DEFAULT_CLASS_COLOR = "#7839EE";
