import * as Logos from "../../../svgs/logos";

function Logo({ name, ...props }) {
  switch (name) {
    case "channelview":
      return <Logos.ChannelViewLogo {...props} />;
    case "OrchardView":
      return <Logos.OrchardViewLogo {...props} />;
    case "houstoncc":
      return <Logos.HoustonCCLogo {...props} />;
    case "mike":
      return <Logos.DailyDoseLogo {...props} />;
    case "primer":
      return <Logos.PrimerLogo {...props} />;
    case "capella":
      return <Logos.CapellaLogo {...props} />;
    case "asu":
      return <Logos.ASULogo {...props} />;
    case "chemonics":
      return <Logos.ChemonicsLogo {...props} />;
    case "academica":
      return <Logos.AcademicaLogo {...props} />;
    case "imentor":
      return <Logos.IMentorLogo {...props} />;
    case "axio":
      return <Logos.AxioLogo {...props} />;
    case "axiodemo":
      return <Logos.AxioDemoLogo {...props} />;
    case "cuesta":
      return <Logos.CuestaLogo {...props} />;
    case "jessup":
      return <Logos.JessupLogo {...props} />;
    case "umich":
      return <Logos.UMichLogo {...props} />;
    case "belhaven":
      return <Logos.BelhavenLogo {...props} />;
    case "quitumbe":
      return <Logos.QuitumbeLogo {...props} />;
    case "gmi":
      return <Logos.GMILogo {...props} />;
    case "azcyber":
      return <Logos.AzCyberLogo {...props} />;
    default:
      return null;
  }
}

export default Logo;
