import React, { createContext, useContext } from "react";

export type DomainConfig = {
  metaTagsTitle: string;
  metaTagsDescription: string;
  metaTagsThemeColor: string;
  metaTagsAppleTouchIcon: string;
  metaTagsTwitterCard: string;
  metaTagsTwitterSite: string;
  metaTagsTwitterTitle: string;
  metaTagsTwitterDescription: string;
  metaTagsTwitterImage: string;
  externalStripeStripeSubscriptionId: string;
  externalStripeHasTrial: boolean;
  externalStripeTrialDays: number;
  externalSendGridAppInviteTemplateId: string;
  externalSendGridDailyEmailTemplateId: string;
  externalSendGridFromEmail: string;
  externalSendGridFromName: string;
  externalSendGridTwilioServiceSID: string;
  moderationSexual: boolean;
  moderationHate: boolean;
  moderationHarassment: boolean;
  moderationSelfHarm: boolean;
  moderationSexualMinors: boolean;
  moderationHateThreatening: boolean;
  moderationViolenceGraphic: boolean;
  moderationSelfHarmIntent: boolean;
  moderationSelfHarmInstructions: boolean;
  moderationHarassmentThreatening: boolean;
  moderationViolence: boolean;
  companionName: string;
  key: string;
  isLMS: boolean;
  isLTI: boolean;
  isAxioOffering: boolean;
  isSuggestedInsights: boolean;
  axioCourses: number[];
  disableWebsearchWhenRAG: boolean;
  primaryColor: string;
  learningFieldColor: string;
  learningSubFieldColor: string;
  gameMenuBg: string;
  gameMenuTrack: string;
  gameMenuRefresh: string;
  gameMenuRefreshText: string;
  gameMenuProgress: string;
  gameMenuCardBg: string;
  gameMenuCardButton: string;
  gameMenuCardButtonText: string;
  gameMenuCardRefresh: string;
  emulationStatement: string;
  learningEmulationStatement: string;
  logo: string;
  showMiniLogo: boolean;
  scope: string[];
  additionalScope?: string[]; // { name: string; externalURL: string; index: number }
  connectURL: string;
  termsOfServiceLink: string;
  showObjectivesTab: boolean;
  showJobsTab: boolean;
  generalPrompt: string;
  onboardingPrompt: string;
  newsSectionTitle: string;
  customNews: boolean;
  showNews: boolean;
  newsSearchTerms: string;
  showInviteFriends: boolean;
  showFeedback: boolean;
  isPrivateLabelIcon: boolean;
  bugReportEmails: string;
  upgradeModalBody: string;
  isFreemium: boolean;
  hasEmailVerification: boolean;
};

type ExtendedDomainConfig = DomainConfig & {
  currentDomain: string;
};

// Create a Context
const AppConfigContext = createContext<ExtendedDomainConfig | null>(null);

export const useAppConfig = () => useContext(AppConfigContext);

export const AppConfigProvider = ({
  children,
  currentDomain,
  appConfig,
}: {
  children: React.ReactNode;
  currentDomain: string;
  appConfig: DomainConfig;
}) => {
  // No need for useState for appConfig or isLoading here

  const value = {
    ...appConfig,
    currentDomain,
  };

  return <AppConfigContext.Provider value={value}>{children}</AppConfigContext.Provider>;
};
