import React, { useState, useCallback } from "react";
import CreateJourneyForm from "../../../components/journey/CreateJourneyForm/CreateJourneyForm";
import { DatePickerModal } from "react-native-paper-dates";

const JourneyFormContainer = ({ onSubmit, onComplete }) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [date, setDate] = useState(new Date());
  const [description, setDescription] = useState("");

  const handleDatePickerDismiss = useCallback(() => {
    setIsDatePickerVisible(false);
  }, [setIsDatePickerVisible]);

  const handleDatePickerConfirm = useCallback(
    (params) => {
      setIsDatePickerVisible(false);
      setDate(params.date);
    },
    [isDatePickerVisible, setIsDatePickerVisible]
  );

  const handleDatePick = () => {
    setIsDatePickerVisible(true);
  };

  const handleDescriptionChange = (description) => {
    setDescription(description);
  };

  const handleSubmit = async () => {
    onComplete();
    onSubmit(description, date);
  };

  return (
    <>
      <CreateJourneyForm
        description={description}
        onDatePick={handleDatePick}
        startDate={date}
        onChangeDescription={handleDescriptionChange}
        onSubmit={() => handleSubmit()}
      />
      <DatePickerModal
        locale="en"
        mode="single"
        label="Select date"
        onDismiss={handleDatePickerDismiss}
        onConfirm={handleDatePickerConfirm}
        date={date}
        visible={isDatePickerVisible}
      />
    </>
  );
};

export default JourneyFormContainer;
