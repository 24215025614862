import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CheckMark({ fill = "#66C61C", ...props }) {
  return (
    <Svg width={16} height={18} viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* <Path transform="translate(0 .5)" fill="#fff" d="M0 0H16V17H0z" /> */}
      <Path
        d="M15.207 6.537l-8.025 8.527a.971.971 0 01-.707.31.971.971 0 01-.706-.31l-4.477-4.782C1.105 10.083 1 9.813 1 9.532c0-.282.105-.552.292-.751l1.25-1.328a.971.971 0 01.706-.31c.264 0 .518.111.706.31L6.5 10.077l6.048-6.346a.97.97 0 01.706-.31.97.97 0 01.706.31l1.247 1.297c.093.099.168.216.219.346a1.12 1.12 0 01-.219 1.163z"
        fill={fill}
      />
    </Svg>
  );
}

export default CheckMark;
