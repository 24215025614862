import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { shadowStyles } from "../../../styles/shadow";

function IntegrationCard({ Icon, header, body, isConnected, onConnect }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={[styles.container, shadowStyles.smallShadow]}>
      <View style={styles.iconContainer}>
        <Icon width={50} height={50} />
      </View>
      <View style={styles.textContainer}>
        <CustomText text={header} size="s" weight="medium" />
        <CustomText
          useTranslationText={false}
          text={body}
          size="xs"
          numberOfLines={isMedium ? 2 : 3}
          ellipsizeMode="tail"
          style={styles.bodyText}
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          text={isConnected ? "Connected" : "Connect Account"}
          styleType="transparent"
          onPress={onConnect}
          textStyle={[styles.buttonText, isConnected ? styles.connectedButtonText : styles.connectButtonText]}
          style={styles.button}
          aria-label={isConnected ? "Connected" : "Connect Account"}
        />
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    alignItems: "flex-start",
    padding: 15,
    borderRadius: 8,
    width: "100%",
    height: "100%",
    justifyContent: "space-between",
  },
  iconContainer: {
    marginBottom: 15,
  },
  textContainer: {
    width: "100%",
    marginBottom: 15,
    flex: 1,
  },
  bodyText: {
    color: "#6B7280",
    marginTop: 5,
  },
  buttonContainer: {
    width: "100%",
    alignItems: "flex-start",
  },
  button: {
    alignSelf: "flex-start",
  },
  buttonText: {
    fontWeight: "medium",
  },
  connectedButtonText: {
    color: "#209B51",
  },
  connectButtonText: {
    color: "#3E68FE",
  },
});

export default IntegrationCard;
