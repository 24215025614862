import { useEffect, useState } from "react";
import { View, StyleSheet } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { usePostHog } from "posthog-js/react";
import {
  useMeQuery,
  useDeleteAccountMutation,
  useResetAccountMutation,
  useExportCsvQuery,
} from "../graphql/generated/graphql";
import { useAuth } from "../hooks/useAuth";
import DefaultLayout from "../layouts/DefaultLayout.web";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../components/common/general/CustomTextInput/CustomTextInput";

import IntegrationCardListContainer from "../containers/settings/IntegrationCardListContainer/IntegrationCardListContainer";
import PlanCardContainer from "../containers/settings/PlanCardContainer/PlanCardContainer";
import { MotiView } from "moti";
import DeleteAccountModalContainer from "../containers/settings/DeleteAccountModalContainer/DeleteAccountModalContainer";
import ResetAccountModalContainer from "../containers/settings/ResetAccountModalContainer/ResetAccountModalContainer";
import ExportAccountModalContainer from "../containers/settings/ExportAccountModalContainer/ExportAccountModalContainer";
import DeleteAccountConfirmationModal from "../components/settings/DeleteAccountConfirmationModal/DeleteAccountConfirmationModal";
import ResetAccountConfirmationModal from "../components/settings/ResetAccountConfirmationModal/ResetAccountConfirmationModal";
import { useAppState, actions } from "../contexts/AppStateContext";
import ExportAccountConfirmationModal from "../components/settings/ExportAccountConfirmationModal/ExportAccountConfirmationModal";
import TwoFactorAuthModalContainer from "../containers/settings/TwoFactorAuthModalContainer/TwoFactorAuthModalContainer";
import TwoFactorAuthDisableContainer from "../containers/settings/TwoFactorAuthModalContainer/TwoFactorAuthDisableContainer";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import NotificationsContainer from "../containers/settings/NotificationsContainer/NotificationsContainer";
import { shadowStyles } from "../styles/shadow";
import { useAppConfig } from "../AppConfigProvider";
import CustomButton from "../components/common/general/CustomButton/CustomButton";

enum ModalState {
  NONE = "NONE",
  DELETE = "DELETE",
  RESET = "RESET",
  EXPORT = "EXPORT",
  DELETE_CONFIRMATION = "DELETE_CONFIRMATION",
  RESET_CONFIRMATION = "RESET_CONFIRMATION",
  EXPORT_CONFIRMATION = "EXPORT_CONFIRMATION",
  TWO_FACTOR_AUTH = "TWO_FACTOR_AUTH",
  TWO_FACTOR_DISABLE = "TWO_FACTOR_DISABLE",
}

function SettingsView() {
  const { dispatch } = useAppState();
  const posthog = usePostHog();
  const appConfig = useAppConfig();
  const isLTI = appConfig?.isLTI;
  const isAxioOffering = appConfig.isAxioOffering;
  const { data, refetch } = useMeQuery();
  const { logout } = useAuth();
  const [modalState, setModalState] = useState(ModalState.NONE);
  const [deleteAccount] = useDeleteAccountMutation();
  const [resetAccount] = useResetAccountMutation();
  const { isMedium, isXLarge } = useResponsiveScreen();
  const { refetch: exportCsvRefetch } = useExportCsvQuery({
    skip: true,
  });

  const handleDeleteAccount = async (typedText: string) => {
    if (typedText === "DELETE") {
      try {
        await AsyncStorage.setItem("deletionInProgress", "true");

        const { data: deleteAccountData } = await deleteAccount();
        if (deleteAccountData?.deleteAccount?.success) {
          setModalState(ModalState.DELETE_CONFIRMATION);
        } else {
          alert(deleteAccountData?.deleteAccount?.message || "Failed to delete account.");
        }
      } catch (error) {
        alert("An error occurred. Please try again.");
      }
    } else {
      alert("You must type DELETE to confirm.");
    }
  };

  const handleResetAccount = () => {
    resetAccount()
      .then(({ data: resetAccountData }) => {
        if (resetAccountData?.resetAccount?.success) {
          setModalState(ModalState.RESET_CONFIRMATION);
        } else {
          alert(resetAccountData?.resetAccount?.message || "Failed to reset account.");
        }
      })
      .catch(() => {
        alert("An error occurred. Please try again.");
      });
  };

  const handleExportAccount = () => {
    exportCsvRefetch()
      .then(({ data: exportCsvData }) => {
        if (exportCsvData?.exportCsv?.success) {
          const blob = new Blob([exportCsvData.exportCsv.csvData], { type: "text/csv;charset=utf-8;" });
          const url = URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.setAttribute("href", url);
          link.setAttribute("download", "user_data.csv");
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setModalState(ModalState.EXPORT_CONFIRMATION);
        } else {
          alert(exportCsvData?.exportCsv?.message || "Failed to export account data.");
        }
      })
      .catch(() => {
        alert("An error occurred. Please try again.");
      });
  };

  const handleModalOpen = (modalState: ModalState) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setModalState(modalState);
  };

  const handleLogout = () => {
    logout();
  };

  const handleModalClose = (callback?: () => void) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setModalState(ModalState.NONE);
    if (callback) {
      callback();
    }
  };

  const handleResetModalClose = (callback?: () => void) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setModalState(ModalState.NONE);
    window.location.reload(); // Refresh the site after reset
    if (callback) {
      callback();
    }
  };

  const handleDeleteAccountConfirmationCloseCb = async () => {
    await AsyncStorage.removeItem("deletionInProgress");
    logout();
  };

  useEffect(() => {
    const checkDeletionState = async () => {
      const deletionInProgress = await AsyncStorage.getItem("deletionInProgress");
      if (deletionInProgress === "true") {
        setModalState(ModalState.DELETE_CONFIRMATION);
      }
    };

    checkDeletionState();
    posthog?.capture("Settings Page Visited");
  }, []);

  return (
    <DefaultLayout>
      <MotiView
        style={{ flex: 1 }}
        from={{ opacity: 0, translateY: 20, scale: 1 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={500}
        // transition={{ type: "timing", duration: 600 }}
      >
        <CustomText
          role="header"
          text="Settings"
          size={isMedium ? "l" : "s"}
          textType="display"
          weight="ultraBold"
          style={undefined}
        />
        <CustomSpacing type="vertical" size="xxl" />
        {isMedium ?? <CustomSpacing type="vertical" size="xxl" />}

        <View style={isXLarge ? styles.twoColumnLayout : styles.oneColumnLayout}>
          <View style={isXLarge ? styles.column : styles.fullWidthColumn}>
            <CustomText text="Basic Information" size="xs" textType="display" weight="bold" style={undefined} />
            <CustomSpacing type="vertical" size="l" />
            <CustomText text="Name" size="s" color="secondary" textType="text" style={undefined} />
            <CustomTextInput
              value={data?.me?.name}
              style={{ pointerEvents: "none" }}
              editable={false}
              onChangeText={undefined}
              placeholder={undefined}
            />
          </View>
          {!isLTI && !isAxioOffering && (
            <View style={isXLarge ? styles.column : styles.fullWidthColumn}>
              <CustomText text="Current Plan" size="xs" textType="display" weight="bold" style={undefined} />
              <CustomSpacing type="vertical" size="l" />
              <PlanCardContainer userData={data} />
            </View>
          )}
        </View>

        <View style={isXLarge ? styles.twoColumnLayout : styles.oneColumnLayout}>
          <View style={isXLarge ? styles.column : styles.fullWidthColumn}>
            <CustomText text="Integrations" size="xs" textType="display" weight="ultraBold" style={undefined} />
            <CustomSpacing type="vertical" size="l" />
            <IntegrationCardListContainer userData={data} refetchUserData={refetch} />
          </View>
        </View>

        <View style={isXLarge ? styles.twoColumnLayout : { flexDirection: "column" }}>
          {!isLTI && (
            <View style={isXLarge ? styles.column : styles.fullWidthColumn}>
              <CustomText
                text="Two-factor Authentication"
                size="xs"
                textType="display"
                weight="ultraBold"
                style={undefined}
              />
              <CustomSpacing type="vertical" size="s" />
              <CustomText
                text="Two-factor authentication adds an additional layer of security to your account by requiring more than just a password to sign in."
                size="xs"
                style={undefined}
              />
              <CustomSpacing type="vertical" size="l" />
              {data?.me?.otpPreference === "NONE" ? (
                <CustomButton
                  text="Enable Two-factor Authentication"
                  styleType="primaryTransparent"
                  style={[
                    styles.managementButton,
                    shadowStyles.smallShadowButton,
                    isXLarge ? {} : styles.fullWidthButton,
                    { width: "fit-content" },
                  ]}
                  onPress={() => handleModalOpen(ModalState.TWO_FACTOR_AUTH)}
                  disabled={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  textStyle={undefined}
                  textProps={undefined}
                />
              ) : (
                <CustomButton
                  text="Turn off Two-factor Authentication"
                  styleType="primaryTransparent"
                  style={[styles.managementButton, isXLarge ? {} : styles.fullWidthButton, { width: "fit-content" }]}
                  onPress={() => handleModalOpen(ModalState.TWO_FACTOR_DISABLE)}
                  disabled={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  textStyle={undefined}
                  textProps={undefined}
                />
              )}
            </View>
          )}
          <View style={isXLarge ? styles.column : styles.fullWidthColumn}>
            <CustomText text="Account Management" size="xs" textType="display" weight="ultraBold" style={undefined} />
            <CustomSpacing type="vertical" size="l" />
            <View style={isXLarge ? styles.managementButtonsContainer : styles.managementButtonsContainerMobile}>
              <CustomButton
                text="Export Account Data"
                styleType="primaryTransparent"
                style={[
                  styles.managementButton,
                  shadowStyles.smallShadowButton,
                  isXLarge ? {} : styles.fullWidthButton,
                  { width: "fit-content" },
                ]}
                onPress={() => handleModalOpen(ModalState.EXPORT)}
                disabled={undefined}
                leftIcon={undefined}
                rightIcon={undefined}
                textStyle={undefined}
                textProps={undefined}
              />
              <CustomButton
                text="Reset Account"
                styleType="primaryTransparent"
                style={[
                  styles.managementButton,
                  shadowStyles.smallShadowButton,
                  isXLarge ? {} : styles.fullWidthButton,
                  { width: "fit-content" },
                ]}
                onPress={() => handleModalOpen(ModalState.RESET)}
                disabled={undefined}
                leftIcon={undefined}
                rightIcon={undefined}
                textStyle={undefined}
                textProps={undefined}
              />
              <CustomButton
                text="Delete Account"
                styleType="primaryTransparent"
                style={[
                  styles.managementButton,
                  shadowStyles.smallShadowButton,
                  isXLarge ? {} : styles.fullWidthButton,
                  { width: "fit-content" },
                ]}
                onPress={() => handleModalOpen(ModalState.DELETE)}
                disabled={undefined}
                leftIcon={undefined}
                rightIcon={undefined}
                textStyle={undefined}
                textProps={undefined}
              />
              {/* {!isLTI && (
                <CustomButton
                  text="Log Out"
                  styleType="primaryTransparent"
                  style={[
                    styles.managementButton,
                    shadowStyles.smallShadowButton,
                    isXLarge ? {} : styles.fullWidthButton,
                    { width: "fit-content" },
                  ]}
                  onPress={() => handleLogout()}
                  disabled={undefined}
                  leftIcon={undefined}
                  rightIcon={undefined}
                  textStyle={undefined}
                  textProps={undefined}
                />
              )} */}
            </View>
          </View>
        </View>

        <View style={isXLarge ? styles.twoColumnLayout : styles.oneColumnLayout}>
          <View style={isXLarge ? styles.column : styles.fullWidthColumn}>
            <CustomText text="Notifications" size="xs" textType="display" weight="ultraBold" style={undefined} />
            <CustomSpacing type="vertical" size="l" />
            <NotificationsContainer />
          </View>
        </View>
      </MotiView>
      <DeleteAccountModalContainer
        visible={modalState === ModalState.DELETE}
        onClose={handleModalClose}
        onDelete={handleDeleteAccount}
      />
      <ResetAccountModalContainer
        visible={modalState === ModalState.RESET}
        onClose={handleModalClose}
        onReset={handleResetAccount}
      />
      <ExportAccountModalContainer
        visible={modalState === ModalState.EXPORT}
        onClose={handleModalClose}
        onExport={handleExportAccount}
      />
      <DeleteAccountConfirmationModal
        visible={modalState === ModalState.DELETE_CONFIRMATION}
        onClose={() => handleModalClose(handleDeleteAccountConfirmationCloseCb)}
      />
      <ResetAccountConfirmationModal
        visible={modalState === ModalState.RESET_CONFIRMATION}
        onClose={handleResetModalClose}
      />
      <ExportAccountConfirmationModal
        visible={modalState === ModalState.EXPORT_CONFIRMATION}
        onClose={handleModalClose}
      />
      <TwoFactorAuthModalContainer visible={modalState === ModalState.TWO_FACTOR_AUTH} onClose={handleModalClose} />
      <TwoFactorAuthDisableContainer
        visible={modalState === ModalState.TWO_FACTOR_DISABLE}
        onClose={handleModalClose}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  twoColumnLayout: {
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 40,
    marginBottom: 48,
  },
  oneColumnLayout: {
    flexDirection: "column",
    marginBottom: 48,
  },
  column: {
    flex: 1,
    justifyContent: "flex-start",
  },
  fullWidthColumn: {
    width: "100%",
    marginBottom: 20,
  },
  managementButtonsContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 10,
  },
  managementButtonsContainerMobile: {
    flexDirection: "column",
    gap: 10,
  },
  managementButton: {
    color: "#3E68FE",
    borderWidth: 1,
    borderColor: "#E5E7EB",
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  fullWidthButton: {
    width: "100%",
  },
});

export default SettingsView;
