import React from "react";
import { View, StyleSheet } from "react-native";
import IntegrationCard from "../IntegrationCard/IntegrationCard";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function IntegrationCardList({ cards, onConnect }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={isMedium ? styles.containerWeb : styles.containerMobile}>
      {cards.map(({ id, Icon, header, body, isConnected }) => (
        <View key={id} style={isMedium ? styles.cardWrapperWeb : styles.cardWrapperMobile}>
          <IntegrationCard
            Icon={Icon}
            header={header}
            body={body}
            isConnected={isConnected}
            onConnect={() => onConnect(id)}
          />
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  containerWeb: {
    flexDirection: "row",
    // justifyContent: "space-between", //uncomment this line when adding SMS card back in
    flexWrap: "wrap",
  },
  containerMobile: {
    flexDirection: "column",
  },
  cardWrapperWeb: {
    width: "calc(33.33% - 13.33px)",
    marginBottom: 20,
    marginRight: 20,
  },
  cardWrapperMobile: {
    width: "100%",
    marginBottom: 20,
  },
});

export default IntegrationCardList;
