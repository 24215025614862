import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Create a context for loading translation files
const loadResources = async (lng) => {
  switch (lng) {
    case "es":
      return (await import("./locales/es/translation.json")).default;
    case "en":
    default:
      return (await import("./locales/en/translation.json")).default;
    case "pt-BR":
      return (await import("./locales/pt-BR/translation.json")).default;
  }
};

// Initialize i18n with dynamically loaded resources
const initI18n = async () => {
  i18n
    .use(LanguageDetector) // Optional: Detect language from browser
    .use(initReactI18next) // Bind react-i18next to i18next
    .init({
      resources: {},
      fallbackLng: "en", // Fallback language
      debug: false,
      interpolation: {
        escapeValue: false, // React already escapes values
      },
      detection: {
        order: ["navigator", "htmlTag", "path", "subdomain"],
        caches: ["localStorage", "cookie"],
      },
    });

  const detectedLanguage = i18n.language || "en"; // Detect or fallback to 'en'

  const resources = await loadResources(detectedLanguage);

  i18n.addResourceBundle(detectedLanguage, "translation", resources);

  i18n.changeLanguage(detectedLanguage); // Switch to the detected language
};

initI18n(); // Call the function to initialize i18n

export default i18n;
