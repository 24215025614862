import React from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import ResetAccount from "../../../components/settings/ResetAccount/ResetAccount";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const ResetAccountModalContainer = ({ visible, onClose, onReset }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Reset Account"
      style={{ width: isMedium ? "50%" : "90%" }}>
      <ResetAccount cancelButtonClick={onClose} resetButtonClick={onReset} />
    </CustomModal>
  );
};

export default ResetAccountModalContainer;
