import * as React from "react";
import Svg, { Path, Rect } from "react-native-svg";

function VideoPlay(props) {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect width="24" height="24" rx="12" fill="#000B33" />
      <Path
        d="M14.6998 12C14.6998 12.1091 14.6734 12.2167 14.6227 12.3134C14.5721 12.4101 14.4987 12.493 14.409 12.5552L11.484 14.5802C11.3827 14.6503 11.2642 14.6914 11.1412 14.6989C11.0182 14.7065 10.8955 14.6802 10.7864 14.623C10.6773 14.5658 10.5859 14.4799 10.5222 14.3745C10.4585 14.269 10.4248 14.1482 10.4248 14.025V9.97499C10.4248 9.8518 10.4585 9.73094 10.5222 9.62552C10.5859 9.52011 10.6773 9.43414 10.7864 9.37695C10.8955 9.31976 11.0182 9.29352 11.1412 9.30107C11.2642 9.30862 11.3827 9.34968 11.484 9.4198L14.409 11.4448C14.4987 11.5069 14.5721 11.5899 14.6227 11.6866C14.6734 11.7833 14.6998 11.8908 14.6998 12ZM18.0748 12C18.0748 13.2015 17.7185 14.376 17.051 15.3751C16.3835 16.3741 15.4347 17.1528 14.3246 17.6126C13.2145 18.0724 11.9931 18.1927 10.8146 17.9583C9.6362 17.7239 8.55374 17.1453 7.70413 16.2957C6.85453 15.4461 6.27594 14.3636 6.04154 13.1852C5.80713 12.0067 5.92744 10.7852 6.38724 9.67519C6.84704 8.56512 7.62569 7.61634 8.62472 6.94881C9.62375 6.28128 10.7983 5.92499 11.9998 5.92499C13.6104 5.92677 15.1546 6.56739 16.2935 7.70629C17.4324 8.84518 18.073 10.3893 18.0748 12ZM16.7248 12C16.7248 11.0655 16.4477 10.1519 15.9285 9.37492C15.4093 8.5979 14.6714 7.99228 13.808 7.63466C12.9446 7.27703 11.9946 7.18346 11.078 7.36578C10.1614 7.54809 9.31953 7.99811 8.65873 8.65891C7.99792 9.31971 7.54791 10.1616 7.3656 11.0782C7.18328 11.9947 7.27685 12.9448 7.63448 13.8082C7.9921 14.6715 8.59772 15.4095 9.37474 15.9287C10.1518 16.4479 11.0653 16.725 11.9998 16.725C13.2525 16.7236 14.4536 16.2254 15.3394 15.3396C16.2252 14.4538 16.7235 13.2527 16.7248 12Z"
        fill="white"
      />
    </Svg>
  );
}

export default VideoPlay;
