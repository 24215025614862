import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Sparkle({ fill = "#0D0D0D", ...props }) {
  return (
    <Svg width={23} height={23} viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M17.656 11.748l-4.677-1.723-1.727-4.681a1.867 1.867 0 00-3.504 0l-1.723 4.68-4.681 1.724a1.867 1.867 0 000 3.504l4.677 1.723 1.727 4.681a1.867 1.867 0 003.504 0l1.723-4.677 4.681-1.727a1.867 1.867 0 000-3.504zm-5.625 3.178a1.865 1.865 0 00-1.106 1.106L9.5 19.902l-1.426-3.87a1.865 1.865 0 00-1.105-1.106L3.099 13.5l3.87-1.426a1.864 1.864 0 001.105-1.105L9.5 7.099l1.426 3.87a1.865 1.865 0 001.106 1.106l3.87 1.425-3.87 1.426zm.094-11.176a1.125 1.125 0 011.125-1.125h1.125V1.5a1.125 1.125 0 112.25 0v1.125h1.125a1.125 1.125 0 010 2.25h-1.125V6a1.125 1.125 0 11-2.25 0V4.875H13.25a1.125 1.125 0 01-1.125-1.125zm10.5 4.5A1.125 1.125 0 0121.5 9.375h-.375v.375a1.125 1.125 0 11-2.25 0v-.375H18.5a1.125 1.125 0 010-2.25h.375V6.75a1.125 1.125 0 112.25 0v.375h.375a1.125 1.125 0 011.125 1.125z"
        fill={fill}
      />
    </Svg>
  );
}

export default Sparkle;
