import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";

function RightIcon(props) {
  return (
    <Svg width={97} height={97} viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G>
        {/* Shadow Circle */}
        <Circle cx={49} cy={41} r={32.5} fill="#000" opacity={0.2} />
        {/* Main Circle */}
        <Circle cx={48.5} cy={40.5} r={32.5} fill="#fff" />
      </G>
      <Path
        d="M54.565 42.565l-10 10a1.503 1.503 0 01-2.125-2.125l8.939-8.936-8.936-8.939a1.503 1.503 0 012.125-2.125l10 10a1.499 1.499 0 01-.003 2.125z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default RightIcon;
