import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";

const EditModule = ({ saveButtonClick, moduleName, onChangeName, hasOrder, setOrder, order }) => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text="Module Name" size="xs" />
        <CustomTextInput
          style={{ backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }}
          placeholder={moduleName}
          onChangeText={onChangeName}
          value={moduleName}
        />
        <CustomSpacing type="vertical" size="s" />
        {hasOrder && (
          <>
            <CustomText text="Order:" size="s" textType="text" style={{ color: "#182230" }} weight="ultraBold" />
            <CustomSpacing type="vertical" size="s" />
            <CustomTextInput placeholder="Ex: 9" required onChangeText={(text) => setOrder(text)} value={order} />
          </>
        )}

        <CustomSpacing type="vertical" size="m" />
        <CustomButton
          text="Save"
          onPress={saveButtonClick}
          styleType="primary"
          size="m"
          style={{ marginBottom: 10 }}
          bold="true"
          aria-label="Save"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
});

export default EditModule;
