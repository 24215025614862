import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { ForkIcon, WorkoutIcon } from "../../svgIcons";

function HealthSuggestions({ companionName, onMealPress, onWorkoutPress }) {
  return (
    <View style={styles.container}>
      <CustomText text="companion_suggestions" style={styles.text} interpolation={{ companionName }} />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton
        leftIcon={
          <View style={styles.row}>
            <ForkIcon />
            <CustomSpacing type="horizontal" size="s" />
          </View>
        }
        text="Suggest meal"
        styleType="primaryLight"
        size="s"
        onPress={onMealPress}
        aria-label="Suggest meal button"
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton
        leftIcon={
          <View style={styles.row}>
            <WorkoutIcon />
            <CustomSpacing type="horizontal" size="s" />
          </View>
        }
        text="Suggest workout"
        styleType="primaryLight"
        size="s"
        onPress={onWorkoutPress}
        aria-label="Suggest workout button"
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    borderRadius: 16,
    padding: 16,
    backgroundColor: "#F5F7FF",
    alignItems: "center",
    flexShrink: 1,
    minWidth: 200,
    maxWidth: 310,
  },
  row: {
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    textAlign: "center",
    flexShrink: 1,
  },
});

export default HealthSuggestions;
