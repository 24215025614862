import React from "react";
import { Pressable, View, Text, Image, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomProgressBar from "../../common/general/CustomProgressBar/CustomProgressBar";
import { shadowStyles } from "../../../styles/shadow";

const ActiveCard = ({ data, stackTitle, onPress, courseTitle = "Basics of Digital Painting", color = "#5BE068" }) => {
  const { isXLarge } = useResponsiveScreen();

  const progress =
    stackTitle === "Journeys" ? ((data.completedTasks || 0) / (data.totalTasks || 1)) * 100 : data.mastery;

  const unitsText =
    stackTitle === "Journeys"
      ? `${data.completedTasks} of ${data.totalTasks} Tasks Complete`
      : `${data.completedUnits} of ${data.totalUnits} Units Complete`;

  const imageUrl = stackTitle === "Journeys" ? data?.url : data?.imageUrl;

  return (
    <View
      style={[isXLarge ? styles.container : styles.containerMobile, shadowStyles.primary]}
      aria-label={data.name || courseTitle}>
      <View style={isXLarge ? styles.header : styles.headerMobile} aria-label="picture of the track or journey">
        <Image
          source={imageUrl || require("../../../assets/cardImages/story.jpg")}
          style={styles.image}
          alt={`Image of the Active ${stackTitle}`}
          role={"image"}
        />
      </View>

      <View style={isXLarge ? styles.progressBarContainer : styles.progressBarContainerMobile}>
        <CustomText
          useTranslationText={false}
          text={data.name || courseTitle}
          size="m"
          weight="bold"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={styles.courseTitle}
        />
        <View style={{ marginTop: 7 }}>
          <Text
            style={styles.unitsText}
            numberOfLines={1}
            ellipsizeMode="tail"
            aria-label="Your Progress"
            aria-live="polite">
            {unitsText}
          </Text>
          <View>
            <CustomProgressBar progress={progress} color={color} />
          </View>
        </View>
      </View>

      <Pressable
        onPress={() => {
          if (stackTitle === "Journeys") {
            onPress(stackTitle, { id: data.id });
          } else if (stackTitle === "Tracks") {
            onPress(stackTitle, {
              field: data.parentFields ? data.parentFields[0] : undefined,
              subfield: data.parentSubfield,
              topic: data.name,
            });
          }
        }}
        role="button"
        aria-label={stackTitle === "Journeys" ? "View Journey" : "Resume Course"}
        accessibilityHint={`Press to ${
          stackTitle === "Journeys" ? "view more details about this journey" : "resume your progress in this course"
        }`}
        style={isXLarge ? styles.button : styles.buttonMobile}>
        <Text style={styles.buttonText}>{stackTitle === "Journeys" ? "View Journey" : "Resume Course"}</Text>
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 11,
    flexDirection: "row",
    alignItems: "center",
    borderRadius: 10,
    padding: 16,
  },
  containerMobile: {
    marginBottom: 18,
    flexDirection: "column",
    alignItems: "center",
    borderRadius: 10,
    padding: 16,
    marginHorizontal: 12,
    width: 280,
  },
  header: {
    flexShrink: 1,
  },
  headerMobile: {
    width: "100%",
    marginBottom: 4,
  },
  image: {
    width: 94,
    height: 56,
    borderRadius: 5,
  },
  progressBarContainer: {
    flex: 2,
    justifyContent: "center",
    width: "100%",
    marginRight: 10,
    marginLeft: 10,
  },
  progressBarContainerMobile: {
    flex: 1,
    width: "100%",
    justifyContent: "center",
  },
  courseTitle: {
    fontSize: 16,
    fontWeight: "bold",
    color: "#333",
  },
  unitsText: {
    fontSize: 14,
    paddingBottom: 5,
    color: "#666",
  },
  button: {
    // flex: 1,
    backgroundColor: "#3E68FE",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 10,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonMobile: {
    marginTop: 10,
    backgroundColor: "#3E68FE",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 12,
    alignSelf: "flex-start",
  },
  buttonText: {
    color: "#FFF",
    fontSize: 12,
    fontWeight: "bold",
  },
});

export default ActiveCard;
