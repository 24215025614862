import Notifications from "../../../components/settings/Notifications/Notifications";
import { MeDocument, useMeQuery, useUpdateNotificationsMutation } from "../../../graphql/generated/graphql";
import Loader from "../../../components/common/status/Loader/Loader";
import { View } from "moti";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { useState } from "react";

function NotificationsContainer() {
  const [errorUpdatingNotifications, setErrorUpdatingNotifications] = useState("");
  const { data: meData, loading: meLoading, error: meError } = useMeQuery();
  const [updateNotifications] = useUpdateNotificationsMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
  });

  const handleDailyEmailOptInPress = async () => {
    setErrorUpdatingNotifications("");
    try {
      await updateNotifications({
        variables: {
          dailyEmailOptIn: !meData?.me?.dailyEmailOptIn,
        },
      });
    } catch (error) {
      console.error("failed", error);
      setErrorUpdatingNotifications(error);
    }
  };

  if (meError) {
    return (
      <View style={{ flex: 1, justifyContent: "center" }}>
        <CustomText
          style={{ color: "#E57373" }}
          text="There was an error fetching the user notifications. Please try again later."
        />
      </View>
    );
  }

  if (meLoading) {
    return <Loader />;
  }

  return (
    <Notifications
      errorUpdatingNotifications={errorUpdatingNotifications}
      dailyEmailOptIn={meData?.me?.dailyEmailOptIn}
      onDailyEmailOptInPress={handleDailyEmailOptInPress}
    />
  );
}

export default NotificationsContainer;
