import { Svg, Path } from "react-native-svg";

const Images = ({ ...props }) => (
  <Svg width="24" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Path
      d="M12.5 6.875C12.5 6.62777 12.5733 6.3861 12.7107 6.18054C12.848 5.97498 13.0432 5.81476 13.2716 5.72015C13.5001 5.62554 13.7514 5.60079 13.9939 5.64902C14.2363 5.69725 14.4591 5.8163 14.6339 5.99112C14.8087 6.16593 14.9278 6.38866 14.976 6.63114C15.0242 6.87361 14.9995 7.12495 14.9049 7.35335C14.8102 7.58176 14.65 7.77699 14.4445 7.91434C14.2389 8.05169 13.9972 8.125 13.75 8.125C13.4185 8.125 13.1005 7.9933 12.8661 7.75888C12.6317 7.52446 12.5 7.20652 12.5 6.875ZM18.4375 4.375V12.5C18.4375 12.9144 18.2729 13.3118 17.9799 13.6049C17.6868 13.8979 17.2894 14.0625 16.875 14.0625H15.9375V15.625C15.9375 16.0394 15.7729 16.4368 15.4799 16.7299C15.1868 17.0229 14.7894 17.1875 14.375 17.1875H3.125C2.7106 17.1875 2.31317 17.0229 2.02015 16.7299C1.72712 16.4368 1.5625 16.0394 1.5625 15.625V6.875C1.5625 6.4606 1.72712 6.06317 2.02015 5.77015C2.31317 5.47712 2.7106 5.3125 3.125 5.3125H4.6875V4.375C4.6875 3.9606 4.85212 3.56317 5.14515 3.27015C5.43817 2.97712 5.8356 2.8125 6.25 2.8125H16.875C17.2894 2.8125 17.6868 2.97712 17.9799 3.27015C18.2729 3.56317 18.4375 3.9606 18.4375 4.375ZM14.0625 14.0625H6.25C5.8356 14.0625 5.43817 13.8979 5.14515 13.6049C4.85212 13.3118 4.6875 12.9144 4.6875 12.5V7.1875H3.4375V15.3125H14.0625V14.0625ZM12.3703 12.1875L9.6875 9.50469L7.00469 12.1875H12.3703ZM16.5625 4.6875H6.5625V9.97813L8.58281 7.95781C8.87582 7.66489 9.27318 7.50033 9.6875 7.50033C10.1018 7.50033 10.4992 7.66489 10.7922 7.95781L15.0219 12.1875H16.5625V4.6875Z"
      fill="white"
    />
  </Svg>
);

export default Images;
