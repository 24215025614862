import React from "react";
import { View, Text, StyleSheet } from "react-native";

const AudioTrack = ({ text }) => {
  return (
    <View accessible={true} style={styles.hiddenFromView} aria-hidden={false}>
      <Text
        role="document"
        aria-label="Audio Transcription"
        accessibilityHint="Transcription of the audio track."
        aria-live="polite">
        {text}
      </Text>
    </View>
  );
};

const styles = StyleSheet.create({
  hiddenFromView: {
    position: "absolute",
    left: -10000,
    top: -10000,
    height: 0,
    width: 0,
    overflow: "hidden",
  },
});

export default AudioTrack;
