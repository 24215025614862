import { Svg, Rect, Path } from "react-native-svg";

const Checked = (props) => (
  <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <Rect width="22" height="22" rx="2" fill="#66C61C" />
    <Path
      d="M18.5306 9.03063L10.5306 17.0306C10.4609 17.1005 10.3781 17.156 10.287 17.1939C10.1958 17.2317 10.0981 17.2512 9.99935 17.2512C9.90064 17.2512 9.8029 17.2317 9.71173 17.1939C9.62057 17.156 9.53778 17.1005 9.4681 17.0306L5.9681 13.5306C5.89833 13.4609 5.84299 13.378 5.80524 13.2869C5.76748 13.1957 5.74805 13.098 5.74805 12.9994C5.74805 12.9007 5.76748 12.803 5.80524 12.7119C5.84299 12.6207 5.89833 12.5379 5.9681 12.4681C6.03786 12.3984 6.12069 12.343 6.21184 12.3053C6.30299 12.2675 6.40069 12.2481 6.49935 12.2481C6.59801 12.2481 6.69571 12.2675 6.78686 12.3053C6.87801 12.343 6.96083 12.3984 7.0306 12.4681L9.99997 15.4375L17.4693 7.96938C17.6102 7.82848 17.8013 7.74933 18.0006 7.74933C18.1999 7.74933 18.391 7.82848 18.5318 7.96938C18.6727 8.11028 18.7519 8.30137 18.7519 8.50063C18.7519 8.69989 18.6727 8.89098 18.5318 9.03188L18.5306 9.03063Z"
      fill="white"
    />
  </Svg>
);

export default Checked;
