import * as React from "react";
import Svg, { Path } from "react-native-svg";

function AxioMiniLogo(props) {
  return (
    <Svg width={78} height={61} viewBox="0 0 78 61" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M70.794 56.997a7.193 7.193 0 01-5.775-2.895L50.882 35.19c-5.942-7.95-17.825-7.947-23.764 0L12.98 54.103c-2.39 3.195-6.912 3.845-10.096 1.447C-.3 53.152-.948 48.618 1.442 45.42L33.231 2.894A7.205 7.205 0 0139 0a7.205 7.205 0 015.769 2.894L76.558 45.42c2.39 3.198 1.742 7.732-1.443 10.13a7.158 7.158 0 01-4.32 1.447z"
        fill="#3E68FE"
      />
      <Path
        d="M21.831 55.026l10.9-13.23c3.461-4.203 9.077-4.203 12.538 0l10.9 13.23c1.483 1.801.433 4.881-1.666 4.881H23.497c-2.098 0-3.15-3.08-1.666-4.881z"
        fill="#011665"
      />
    </Svg>
  );
}
export default AxioMiniLogo;
