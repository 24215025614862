import React, { useEffect, useState } from "react";
import { View, Image, StyleSheet, Pressable } from "react-native";
import { DarkGraduationCap, DarkJourneyPath } from "../../svgs/common";
import CustomText from "../../common/general/CustomText/CustomText";
import { OptionsChat } from "../../svgs/common";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function ChatList({
  chatData,
  activeObjectId,
  viewedChat,
  type,
  light,
  onActivateViewPress,
  onViewChatPress,
  onChatPress,
}) {
  const [hoveredChat, setHoveredChat] = useState(null);

  const { isMedium } = useResponsiveScreen();

  useEffect(() => {
    if (!isMedium) {
      setHoveredChat(null);
    }
  }, [isMedium]);

  return (
    <View>
      <View style={styles.header}>
        {type === "assignment" ? (
          <>
            <DarkGraduationCap style={styles.graduation} />
            <CustomText
              text={"Assignment chats"}
              size="s"
              textType="text"
              weight="ultraBold"
              styleType={light ? "dark" : "light"}
            />
          </>
        ) : (
          <>
            <DarkJourneyPath style={styles.graduation} />
            <CustomText
              text={"Journey chats"}
              size="s"
              textType="text"
              weight="ultraBold"
              styleType={light ? "dark" : "light"}
            />
          </>
        )}
      </View>
      <CustomSpacing type="vertical" size="m" />

      {chatData.map((chat, index) => (
        <View style={styles.itemRow} key={index}>
          <View
            style={[
              styles.chatItem,
              (activeObjectId === chat.objectId || hoveredChat === chat.id) && {
                borderRadius: 5,
                backgroundColor: light ? "#F0F0F0" : "#EBECF1",
              },
            ]}
            onMouseEnter={() => isMedium && setHoveredChat(chat.id)}
            onMouseLeave={() => isMedium && setHoveredChat(null)}>
            <Pressable
              role="button"
              aria-label={`Open chat with ${chat.name}`}
              onPress={() => {
                onChatPress(chat.id);
              }}
              style={styles.chatContent}>
              <View style={styles.imgContainer}>
                {type == "journey" && (
                  <Image
                    style={styles.image}
                    source={chat.url}
                    resizeMode="cover"
                    accessibilityLabel="Image of the journey"
                    alt="Image of the journey"
                    role={"image"}
                  />
                )}
                <CustomText
                  useTranslationText={false}
                  text={chat.name}
                  size="s"
                  textType="text"
                  weight="regular"
                  styleType={
                    light ? "dark" : activeObjectId === chat.objectId || hoveredChat === chat.id ? "dark" : "light"
                  }
                  numberOfLines={1}
                  ellipsizeMode="tail"
                />
              </View>
            </Pressable>

            {(activeObjectId === chat.objectId || hoveredChat === chat.id) && (
              <Pressable role="button" aria-label="Options Chat Button" onPress={() => onActivateViewPress(chat.id)}>
                <OptionsChat />
              </Pressable>
            )}
          </View>

          {viewedChat === chat.id && (
            <View style={styles.buttonContainer}>
              <CustomButton
                text={type === "assignment" ? "View Assignment" : "View Journey"}
                onPress={() => onViewChatPress(chat.id)}
                aria-label={type === "assignment" ? "View Assignment" : "View Journey"}
              />
            </View>
          )}
        </View>
      ))}
    </View>
  );
}

const styles = StyleSheet.create({
  imgContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    alignItems: "center",
    paddingHorizontal: 8,
  },
  graduation: {
    marginRight: 8,
  },
  chatItem: {
    zIndex: 0,
    width: 254,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    paddingLeft: 8,
    paddingVertical: 8,
  },
  image: {
    width: 24,
    height: 24,
    marginRight: 8,
    borderRadius: 99,
    backgroundColor: "#fff",
    justifyContent: "center",
    alignItems: "center",
  },
  itemRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignContent: "center",
    height: 32,
    marginVertical: 4,
  },
  buttonContainer: {
    marginLeft: 16,
    flex: 1,
    justifyContent: "center",
    alignItems: "flex-start",
  },
});

export default ChatList;
