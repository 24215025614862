import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import DefaultLayout from "../layouts/DefaultLayout.web";
import ResourceCardListContainer from "../containers/school/ResourceCardListContainer/ResourceCardListContainer";
import { Divider } from "react-native-paper";
import BackButton from "../components/navigation/BackButton/BackButton";
import { useNavigation, useRoute } from "@react-navigation/native";
import TabNavigator from "../components/common/general/TabNavigator/TabNavigator";
import { getClass, getCategoriesWithAssignments, getModulesWithResources } from "../services";
import AsyncStorage from "@react-native-async-storage/async-storage";
import SequenceCardListContainer from "../containers/school/SequenceCardListContainer/SequenceCardListContainer";
import { MotiView } from "moti";
import AnnouncementsCardListContainer from "../containers/school/AnnouncementsCardListContainer/AnnouncementsCardListContainer";
import { useSchoolsQuery } from "../graphql/generated/graphql";
import { useAppConfig } from "../AppConfigProvider";

const GOOGLE_PROVIDER = "Google";

function CourseView() {
  const posthog = usePostHog();
  const navigation = useNavigation();
  const route = useRoute();
  const { data: schoolsData, loading, error } = useSchoolsQuery();
  const appConfig = useAppConfig();
  const isAxioOffering = appConfig.isAxioOffering;

  const params = route.params;
  const courseId = params.course_id;
  const [courseName, setCourseName] = useState(courseId);

  const integrationId = params.integration_id;
  const [activeTab, setActiveTab] = useState("sequencing");
  const [hasSequenceContent, setHasSequenceContent] = useState(true);

  const schoolProvider = schoolsData?.getSchools.find((school) => school.id === integrationId)?.providerName;

  const isGoogleProvider = schoolProvider === GOOGLE_PROVIDER && !loading && !error;

  // Fetch course data and check if there's sequencing data
  useEffect(() => {
    const fetchData = async () => {
      const token = await AsyncStorage.getItem("token");
      const data = await getClass(token, integrationId, courseId);

      setCourseName(data.name);

      try {
        if (isGoogleProvider) {
          const categories = await getCategoriesWithAssignments(token, integrationId, courseId);
          setHasSequenceContent(categories.length > 0);
        } else {
          const modules = await getModulesWithResources(token, integrationId, courseId);
          setHasSequenceContent(modules.length > 0);
        }
      } catch (error) {
        console.error("Error fetching sequencing data:", error);
        setHasSequenceContent(false);
      }

      if (!hasSequenceContent) {
        setActiveTab("resources");
      }
    };

    fetchData();
  }, [integrationId, courseId, isGoogleProvider]);

  useEffect(() => {
    if (!hasSequenceContent) {
      setActiveTab("resources");
    }
  }, [hasSequenceContent]);

  const tabs = [
    ...(hasSequenceContent ? [{ key: "sequencing", title: "Sequencing" }] : []),
    { key: "resources", title: "Resources" },
    ...(isGoogleProvider ? [{ key: "announcements", title: "Announcements" }] : []),
  ];

  useEffect(() => {
    posthog?.capture("School Page Visited");
  }, []);

  const handleBackPress = () => {
    navigation.navigate("School", {
      integration_id: integrationId,
    });
  };

  return (
    <DefaultLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={800}
        transition={{ type: "timing", duration: 600 }}>
        <View style={styles.headerContainer} role="header">
          <BackButton onPress={handleBackPress} />
          <CustomSpacing type="horizontal" size="s" />
          <CustomText useTranslationText={false} text={courseName} size="xxxl" style={{ fontFamily: "PangaiaBold" }} />
        </View>

        <CustomSpacing type="vertical" size="s" />
        <Divider size="l" />
        <CustomSpacing type="vertical" size="xl" />
      </MotiView>

      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={1000}
        transition={{ type: "timing", duration: 600 }}>
        {isAxioOffering ? (
          <CustomText text="Activities" size="xxl" style={{ fontFamily: "PangaiaBold" }} role="header" aria-level="1" />
        ) : (
          <CustomText text="Classwork" size="xxl" style={{ fontFamily: "PangaiaBold" }} role="header" aria-level="1" />
        )}

        <CustomSpacing type="vertical" size="s" />
        <TabNavigator
          tabData={tabs}
          activeTab={activeTab}
          onTabPress={(key) => {
            setActiveTab(key);
          }}
        />
        <CustomSpacing type="vertical" size="m" />
      </MotiView>
      {activeTab === "sequencing" && hasSequenceContent && (
        <SequenceCardListContainer integrationId={integrationId} courseId={courseId} isGoogle={isGoogleProvider} />
      )}
      {activeTab === "resources" && (
        <ResourceCardListContainer integrationId={integrationId} courseId={courseId} isGoogle={isGoogleProvider} />
      )}
      {activeTab === "announcements" && (
        <AnnouncementsCardListContainer integrationId={integrationId} courseId={courseId} />
      )}
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
  },
});

export default CourseView;
