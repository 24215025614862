import SlideInMenu from "../../../components/navigation/SlideInMenu/SlideInMenu";
import { useNavigation } from "@react-navigation/native";
import { useProgressStats } from "../../../hooks/useProgressStats";
import { useAuth } from "../../../hooks/useAuth";

function SlideInMenuContainer({
  courses,
  courseIds,
  visible,
  slideAnim,
  onClose,
  onSettingsPress,
  isAnalyticsEnabled,
}) {
  const { logout } = useAuth();
  const navigation = useNavigation();
  const { progressStats, loading } = useProgressStats(courses, courseIds);

  const handleAcademicPlanNav = () => {
    navigation.navigate("Academic Plan");
  };

  const handleLogout = () => {
    logout();
  };

  return (
    <SlideInMenu
      visible={visible}
      slideAnim={slideAnim}
      onClose={onClose}
      isAnalyticsEnabled={isAnalyticsEnabled}
      onSettingsPress={onSettingsPress}
      progressStats={progressStats}
      loading={loading}
      onAcademicPlanNav={handleAcademicPlanNav}
      onLogout={handleLogout}
    />
  );
}
export default SlideInMenuContainer;
