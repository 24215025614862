import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={32} height={33} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx={16} cy={16.5} r={16} fill="#DBF0E0" />
      <Path
        d="M16 6.875A10.125 10.125 0 1026.125 17 10.137 10.137 0 0016 6.875zm0 18A7.875 7.875 0 1123.875 17 7.883 7.883 0 0116 24.875zm4.125-6a3 3 0 01-3 3v.375a1.125 1.125 0 11-2.25 0v-.375H13.75a1.125 1.125 0 110-2.25h3.375a.75.75 0 100-1.5h-2.25a3 3 0 010-6v-.375a1.125 1.125 0 112.25 0v.375h1.125a1.125 1.125 0 110 2.25h-3.375a.75.75 0 100 1.5h2.25a3 3 0 013 3z"
        fill="#41B85C"
      />
    </Svg>
  );
}

export default SvgComponent;
