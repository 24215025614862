import { StyleSheet } from "react-native";
import { MotiView } from "moti";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function OnboardWelcome() {
  return (
    <>
      <MotiView
        from={{
          opacity: 0,
          scale: 0,
          translateY: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
          translateY: 0,
        }}
        delay={500}
        transition={{
          type: "timing",
          duration: 1000,
        }}
        exit={{ opacity: 0, scale: 0, translateY: 0 }}>
        <CustomText text="Welcome" size="xxl" />
      </MotiView>
      <CustomSpacing type="vertical" size="m" />

      <MotiView
        from={{
          opacity: 0,
          scale: 0,
          translateY: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
          translateY: 0,
        }}
        delay={1000}
        transition={{
          duration: 1000,
        }}
        exit={{ opacity: 0, scale: 0, translateY: 0 }}
        style={styles.bottomContainer}>
        <CustomText text="Submit a Greeting to Begin" size="xxl" style={styles.text} />
      </MotiView>
    </>
  );
}

const styles = StyleSheet.create({
  bottomContainer: {
    margin: 20,
  },
  text: {
    fontFamily: "Inter",
    fontWeight: 100,
    textAlign: "center",
  },
});

export default OnboardWelcome;
