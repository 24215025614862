import { useState } from "react";
import { useAppState, actions } from "../../../contexts/AppStateContext";
import { useAddToWhitelistMutation, InviteCountDocument } from "../../../graphql/generated/graphql";
import InviteForm from "../../../components/invite/InviteForm/InviteForm";

function InviteFormContainer() {
  const { dispatch } = useAppState();
  const [addToWhitelistMutation] = useAddToWhitelistMutation({
    refetchQueries: [
      {
        query: InviteCountDocument,
      },
    ],
  });

  const [email, setEmail] = useState("");
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");

  const handleChangeText = (text) => {
    setEmail(text);
    validateEmail(text);
  };

  const handleFocus = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
  };

  const handleBlur = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
  };

  const handleSubmit = async () => {
    if (!isValidEmail) {
      setErrorMessage("Please enter a valid email address");
      return;
    }

    try {
      await addToWhitelistMutation({
        variables: {
          email,
        },
      });
      alert("Email invite sent successfully.");
      setEmail("");
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("This user has already been invited, please try sending to someone else");
    }
  };

  const validateEmail = (email) => {
    const pattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(pattern.test(email));
  };

  return (
    <InviteForm
      email={email}
      errorMessage={errorMessage}
      onChangeText={handleChangeText}
      onFocus={handleFocus}
      onBlur={handleBlur}
      onSubmit={handleSubmit}
    />
  );
}

export default InviteFormContainer;
