import { View, StyleSheet } from "react-native";
import { useInviteCountQuery } from "../graphql/generated/graphql";
import DefaultLayout from "../layouts/DefaultLayout.web";
import InviteFormContainer from "../containers/invite/InviteFormContainer/InviteFormContainer";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";

function InviteView() {
  const { data } = useInviteCountQuery();

  const remainingInvites = data?.me?.isPro
    ? 20 - (data?.userInviteCount?.count || 0)
    : 10 - (data?.userInviteCount?.count || 0);

  return (
    <DefaultLayout>
      <View style={styles.container}>
        <CustomText text="Add Email To Invite Friends to Early Access" size="xxl" weight="bold" />
        <CustomSpacing type="vertical" size="l" />
        <CustomText text={`You have ${remainingInvites} invites remaining.`} size="l" />
        <CustomSpacing type="vertical" size="l" />

        <View style={styles.formContainer}>{remainingInvites <= 0 ? <></> : <InviteFormContainer />}</View>
      </View>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  formContainer: {
    width: "50%",
  },
});

export default InviteView;
