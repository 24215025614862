import { RouteProp, useFocusEffect, useNavigation, useRoute } from "@react-navigation/native";
import { useAuth } from "../hooks/useAuth";
import BlankLayout from "../layouts/BlankLayout";
import Loader from "../components/common/status/Loader/Loader";
import { NativeStackNavigationProp } from "@react-navigation/native-stack";
import { RootStackParamList } from "../navigation/AppNavigator.web";
import { useCallback, useEffect } from "react";

function LoginLTIView() {
  const route = useRoute<RouteProp<RootStackParamList, "LoginLTI">>();
  const { login, isUpdating } = useAuth();
  const navigation = useNavigation<NativeStackNavigationProp<RootStackParamList>>();

  useEffect(() => {
    if (!isUpdating) {
      navigation.navigate("My Day");
    }
  }, [isUpdating]);

  useFocusEffect(
    useCallback(() => {
      const triggerLogin = async () => {
        if (route.params?.token) {
          await login(route.params.token);
        }
      };

      triggerLogin();
    }, [navigation, route, isUpdating])
  );

  return (
    <BlankLayout>
      <Loader />
    </BlankLayout>
  );
}

export default LoginLTIView;
