import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M18.828 11.77v-.004a4.019 4.019 0 00-.197-.461L15.385 3.92a.937.937 0 00-.195-.285 2.811 2.811 0 00-3.978 0 .937.937 0 00-.274.662v1.64H9.062v-1.64a.937.937 0 00-.274-.663 2.813 2.813 0 00-3.978 0 .938.938 0 00-.195.286L1.37 11.305c-.076.15-.142.303-.198.46v.005a4.063 4.063 0 107.89 1.355V7.813h1.876v5.312a4.063 4.063 0 107.89-1.355zM6.25 4.866a.937.937 0 01.938-.096v4.934a4.055 4.055 0 00-2.805-.595L6.25 4.866zM5 15.313a2.188 2.188 0 110-4.376 2.188 2.188 0 010 4.376zM12.813 4.77a.942.942 0 01.937.096l1.865 4.243a4.054 4.054 0 00-2.805.595l.002-4.934zM15 15.313a2.188 2.188 0 110-4.376 2.188 2.188 0 010 4.376z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default SvgComponent;
