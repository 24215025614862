import React, { useState } from "react";
import { View, StyleSheet } from "react-native";
import { ListItem } from "@rneui/themed";
import { MotiView } from "moti";
import CustomText from "../../common/general/CustomText/CustomText";

function Accordion({ title, children }) {
  const [expanded, setExpanded] = useState(true); //state for determining if the accordion is collapsed or not

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };
  return (
    <View style={styles.container}>
      <ListItem.Accordion
        content={<CustomText text={title} size="l" weight="bold" style={styles.header} />}
        isExpanded={expanded}
        onPress={toggleExpanded}
        role="button"
        aria-checked={expanded}
        aria-label={`Accordion, ${expanded ? "expanded" : "collapsed"}`}
        accessibilityHint="Double tap to toggle the accordion.">
        <MotiView
          from={{ opacity: 0, translateY: -20 }}
          animate={{ opacity: 1, translateY: 0 }}
          transition={{ type: "timing", duration: 100 }}>
          {children}
        </MotiView>
      </ListItem.Accordion>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginVertical: 0,
    paddingVertical: 0,
  },
  header: {
    fontSize: 18,
    fontWeight: "bold",
    paddingVertical: 0,
    paddingHorizontal: 0,
    borderRadius: 5,
    left: -15,
  },
});

export default Accordion;
