import Sentry from "../utils/sentry";
import apiClient from "./ApiService";

const getEdlinkLoginUrl = async (token) => {
  try {
    const response = await apiClient.get("/edlink/user/login", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listAllAssignments = async (token, integrationId) => {
  try {
    const classes = await listClasses(token, integrationId);
    const allAssignments = [];

    for (const cls of classes) {
      const assignments = await listAssignments(token, integrationId, cls.id);
      const assignmentsWithClassInfo = assignments.map((assignment) => ({
        ...assignment,
        className: cls.name,
        classId: cls.id,
        color: cls.color,
      }));
      allAssignments.push(...assignmentsWithClassInfo);
    }

    // Sort the assignments based on the due_date in ascending order
    allAssignments.sort((a, b) => {
      if (!a.due_date && !b.due_date) {
        return 0;
      } else if (!a.due_date) {
        return 1;
      } else if (!b.due_date) {
        return -1;
      } else {
        return new Date(a.due_date) - new Date(b.due_date);
      }
    });

    return allAssignments;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listClasses = async (token, integrationId) => {
  try {
    const classesResponse = await apiClient.get("/edlink/user/classes", {
      params: { integrationId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return classesResponse.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getClass = async (token, integrationId, classId) => {
  try {
    const response = await apiClient.get("/edlink/user/class", {
      params: { integrationId, classId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listModules = async (token, integrationId, classId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/modules", {
      params: { integrationId, classId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listCategories = async (token, integrationId, classId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/categories", {
      params: { integrationId, classId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getCategory = async (token, integrationId, classId, categoryId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/category", {
      params: { integrationId, classId, categoryId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listResources = async (token, integrationId, classId, moduleId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/modules/resources", {
      params: { integrationId, classId, moduleId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listPages = async (token, integrationId, classId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/pages", {
      params: { integrationId, classId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getPage = async (token, integrationId, classId, pageId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/page", {
      params: { integrationId, classId, pageId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const listAssignments = async (token, integrationId, classId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/assignments", {
      params: { integrationId, classId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    const filteredAssignments = response.data.filter((assignment) => assignment.assignee_mode !== "none");

    return filteredAssignments;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getAssignment = async (token, integrationId, classId, assignmentId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/assignment", {
      params: { integrationId, classId, assignmentId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getSubmissions = async (token, integrationId, classId, assignmentId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/assignment/submissions", {
      params: { integrationId, classId, assignmentId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return "ungraded";
  }
};

const listAnnouncements = async (token, integrationId, classId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/announcements", {
      params: { integrationId, classId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

const getAnnouncement = async (token, integrationId, classId, announcementId) => {
  try {
    const response = await apiClient.get("/edlink/user/classes/announcement", {
      params: { integrationId, classId, announcementId },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

async function getCategoriesWithAssignments(token, integrationId, classId) {
  try {
    // Fetch categories and assignments in parallel
    const [categories, assignments] = await Promise.all([
      listCategories(token, integrationId, classId),
      listAssignments(token, integrationId, classId),
    ]);

    // Sort assignments by due_date
    assignments.sort((a, b) => {
      if (a.due_date === null && b.due_date === null) {
        return 0;
      } else if (a.due_date === null) {
        return 1;
      } else if (b.due_date === null) {
        return -1;
      } else {
        return new Date(a.due_date) - new Date(b.due_date);
      }
    });

    // Map each category to include its assignments
    const categoriesWithAssignments = categories.map((category) => {
      // Filter assignments that belong to this category
      const categoryAssignments = assignments.filter((assignment) => assignment.category_id === category.id);
      return {
        ...category, // Spread operator to copy category properties
        assignments: categoryAssignments, // Add filtered assignments to this category
      };
    });

    // Return the combined structure
    return categoriesWithAssignments;
  } catch (error) {
    Sentry.captureException(error);
    throw error; // Rethrow to let the caller handle it
  }
}

async function getModulesWithResources(token, integrationId, classId) {
  try {
    const modules = await listModules(token, integrationId, classId);

    const modulesWithDetailedResources = await Promise.all(
      modules.map(async (module) => {
        try {
          const resources = await listResources(token, integrationId, classId, module.id);

          const detailedResourcesPromises = resources.map(async (resource) => {
            if (resource.type === "page") {
              return getPage(token, integrationId, classId, resource.target_id)
                .then((pageDetails) => ({ ...resource, details: pageDetails }))
                .catch(() => null);
            } else if (resource.type === "assignment") {
              return getAssignment(token, integrationId, classId, resource.target_id)
                .then((assignmentDetails) => {
                  if (assignmentDetails.assignee_mode !== "none") {
                    return { ...resource, details: assignmentDetails };
                  }
                  return null;
                })
                .catch(() => null);
            }
            return null;
          });

          const detailedResources = await Promise.all(detailedResourcesPromises);
          const filteredResources = detailedResources.filter((resource) => resource !== null);

          return {
            ...module,
            resources: filteredResources,
          };
        } catch (resourceError) {
          Sentry.captureException(resourceError);
          return { ...module, resources: [] };
        }
      })
    );

    return modulesWithDetailedResources;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
}

export {
  getEdlinkLoginUrl,
  listClasses,
  getClass,
  listModules,
  listCategories,
  getCategory,
  listResources,
  listPages,
  getPage,
  listAssignments,
  getAssignment,
  listAllAssignments,
  listAnnouncements,
  getAnnouncement,
  getCategoriesWithAssignments,
  getModulesWithResources,
  getSubmissions,
};
