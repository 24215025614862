import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import TaskFormContainer from "../TaskFormContainer/TaskFormContainer";
import { ScrollView } from "react-native";
function TaskFormModalContainer({ task, journeyId, visible, onClose }) {
  const { isMedium } = useResponsiveScreen();

  const modalStyle = isMedium
    ? { height: "auto", maxHeight: "90%" }
    : { width: "90%", height: "auto", maxHeight: "90%" };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={task ? "Edit A Task" : "Add A Task"}
      style={modalStyle}
      aria-label="Task Form Modal Open">
      <ScrollView showsVerticalScrollIndicator={false} style={{ maxHeight: "100%" }}>
        <TaskFormContainer task={task} journeyId={journeyId} onComplete={onClose} />
      </ScrollView>
    </CustomModal>
  );
}

export default TaskFormModalContainer;
