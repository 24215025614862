import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const HideLessonConfirmationModal = ({ hideModalState, modalText, updateRelevance }) => {
  const [showHideModal, setShowHideModal] = hideModalState;
  const { isMedium } = useResponsiveScreen();
  return (
    <CustomModal
      visible={showHideModal}
      showCloseButton={true}
      onRequestClose={() => setShowHideModal(false)}
      width={isMedium ? "40%" : "80%"}
      title={modalText.title}>
      <CustomText text={modalText.text} size="s" textType="text" />
      <CustomSpacing type="vertical" size="m" />
      <CustomButton text="Confirm" styleType="primary" onPress={() => updateRelevance(false)} />
    </CustomModal>
  );
};

export default HideLessonConfirmationModal;
