import { View, StyleSheet } from "react-native";
import Logo from "../../general/logos/Logo/Logo";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function VerifyEmailForm({
  isAfterSignup,
  errorMessage,
  otpPreference,
  companionName,
  otp,
  onOTPChange,
  onVerifyOTP,
  onResendOTP,
}) {
  return (
    <View style={styles.container}>
      <View accessible={true} role="header">
        <Logo name={companionName} height={55} width={190} />
        <CustomSpacing type="vertical" size="m" />

        {isAfterSignup && <CustomText text="Please verify your email address" size="s" weight="bold" />}
        <CustomSpacing type="vertical" size="s" />
        {otpPreference === "EMAIL" && (
          <CustomText text="A one-time verification code has been sent to your email" size="s" weight="bold" />
        )}
        {otpPreference === "SMS" && (
          <CustomText text="A one-time verification code has been sent to your phone number" size="s" weight="bold" />
        )}
        <CustomSpacing type="vertical" size="m" />

        <CustomText text="One-time verification code" size="s" />
        <CustomSpacing type="vertical" size="xs" />
        <CustomTextInput
          style={errorMessage && styles.textInputError}
          value={otp}
          placeholder="Enter 6 digit code"
          onChangeText={(text) => onOTPChange(text)}
          autoCompleteType="one-time-code"
          aria-label="6 Digit verification code text input"
        />
        <CustomSpacing type="vertical" size="m" />

        {(otpPreference === "EMAIL" || otpPreference === "NONE") && (
          <CustomButton
            text={isAfterSignup ? "Verify Email" : "Verify Code"}
            styleType="primary"
            style={styles.submitButton}
            onPress={onVerifyOTP}
          />
        )}
        {otpPreference === "SMS" && (
          <CustomButton text={"Verify Code"} styleType="primary" style={styles.submitButton} onPress={onVerifyOTP} />
        )}
        <CustomSpacing type="vertical" size="s" />

        <CustomButton text="Resend Code" onPress={onResendOTP} styleType="transparent" />

        <View aria-live={errorMessage ? "assertive" : "none"}>
          {errorMessage ? (
            <>
              <CustomSpacing type="vertical" size="s" />
              <CustomText useTranslationText={false} text={errorMessage} size="s" style={styles.errorMessage} />
              <CustomSpacing type="vertical" size="s" />
            </>
          ) : (
            <CustomSpacing type="vertical" size="xl" />
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
  },
  submitButton: {
    justifyContent: "center",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});

export default VerifyEmailForm;
