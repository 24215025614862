import DefaultLayout from "../layouts/DefaultLayout";
import { useAppState, actions } from "../contexts/AppStateContext";
import { View, StyleSheet, Alert } from "react-native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { useState } from "react";
import CustomText from "../components/common/general/CustomText/CustomText";
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import {
  GetAllCoursesBySchoolDocument,
  useDeleteCourseCascadeMutation,
  useGetAllCoursesBySchoolQuery,
  useSchoolsQuery,
} from "../graphql/generated/graphql";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import Loader from "../components/common/status/Loader/Loader";
import DeleteCourseModal from "../components/content/ContentModalContainers/DeleteCourseModal";
import Sentry from "../utils/sentry";
import CustomCourseCard from "../components/customCoursework/CustomCourseCard";

function CustomCourseworkView() {
  const navigation = useNavigation();
  const { dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();

  const [deleteCourseModalVisible, setDeleteCourseModalVisible] = useState(null);

  const { data: schoolsData, loading: schoolDataLoading, error: schoolDataError } = useSchoolsQuery();

  const schoolId = schoolsData?.getSchools[0]?.id;

  const [deleteCourse] = useDeleteCourseCascadeMutation({
    refetchQueries: [
      {
        query: GetAllCoursesBySchoolDocument,
        variables: {
          schoolId,
        },
      },
    ],
  });

  const {
    loading: coursesLoading,
    data,
    error: coursesError,
  } = useGetAllCoursesBySchoolQuery({
    variables: {
      schoolId,
    },
    skip: !schoolId,
  });

  const courses = data?.getAllCoursesBySchool?.data ?? [];

  const handleCreateCourse = () => {
    navigation.navigate("Custom Coursework Course Form");
  };

  const handleCardPress = (courseName, courseId) => {
    navigation.navigate("Custom Coursework Modules", {
      courseId: courseId,
      courseName: courseName,
    });
  };

  const handleDeleteCourseModalOpen = (courseId) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setDeleteCourseModalVisible(courseId);
  };

  const handleDeleteCourseModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setDeleteCourseModalVisible(null);
  };

  const handleDeleteCourse = async () => {
    const courseId = deleteCourseModalVisible;

    try {
      const response = await deleteCourse({
        variables: {
          id: courseId,
        },
      });
      if (response.data.deleteCourseCascade.success) {
        Alert.alert("Success", "Module deleted successfully");
        handleDeleteCourseModalClose();
      } else {
        Sentry.captureException(response.data?.deleteContentModule.message || "Failed to delete module");
        Alert.alert("Error", response.data?.deleteContentModule.message || "Failed to delete module");
      }
    } catch (error) {
      Sentry.captureException(error);
      Alert.alert("Error", "An error occurred while deleting the module.");
    }
  };

  const handleEditCourse = (courseId) => {
    navigation.navigate("Custom Coursework Course Form", {
      courseId,
    });
  };

  const error = schoolDataError || coursesError;

  const loading = schoolDataLoading || coursesLoading;

  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <View style={styles.headerContainer}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <CustomText
            role="header"
            text="My Courses"
            size={isMedium ? "l" : "xs"}
            textType="display"
            weight="ultraBold"
          />
          <CustomButton
            onPress={handleCreateCourse}
            size="l"
            text={"Create a Course"}
            disabled={loading}
            styleType="primary"
          />
        </View>
      </View>
      <CustomSpacing type="vertical" size="xxl" />
      <CustomSpacing type="vertical" size="xxl" />
      <View>
        {courses.length === 0 ? (
          <View style={styles.emptyCourseContainer}>
            <CustomText
              useTranslationText={false}
              ultraBold
              text="You haven’t created a course yet"
              size="xl"
              style={{ color: "white" }}
            />
          </View>
        ) : (
          courses.map((course, courseIndex) => (
            <CustomCourseCard
              key={course.id}
              course={course}
              courseIndex={courseIndex}
              handleCardPress={handleCardPress}
              handleDeleteCourseModalOpen={handleDeleteCourseModalOpen}
              handleEditCourse={handleEditCourse}
            />
          ))
        )}
      </View>
      <DeleteCourseModal
        visible={!!deleteCourseModalVisible}
        onClose={handleDeleteCourseModalClose}
        onDeleteCourse={handleDeleteCourse}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  emptyCourseContainer: {
    backgroundColor: "#d3d3d3",
    borderRadius: 20,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  headerContainer: {
    flexDirection: "column",
  },
});

export default CustomCourseworkView;
