import { View, StyleSheet, Pressable } from "react-native";
import React, { useEffect, useState } from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { PlusCircle } from "../../svgIcons";
import CustomText from "../../common/general/CustomText/CustomText";
import { ScrollView } from "react-native-gesture-handler";
import { ListItem } from "@rneui/themed";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { actions, useAppState } from "../../../contexts/AppStateContext";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import DeleteModule from "../../content/DeleteModule/DeleteModule";
import AddModuleModalContainer from "../../content/ContentModalContainers/AddModuleModalContainer";
import EditModuleNameModalContainer from "../../content/ContentModalContainers/EditModuleNameModalContainer";
import { useNavigation } from "@react-navigation/native";
import CustomCourseworkMenuHeader from "./CustomCourseworkMenuHeader";
import CustomCourseworkMenuItem from "./CustomCourseworkMenuItem";
import { Sidebar, ExitDoor, DownArrow, ThreeDot } from "../../svgs/common";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { shadowStyles } from "../../../styles/shadow";
import { Menu } from "react-native-paper";
import EditAddCustomAssignmentOrPageModal from "../../../containers/customCoursework/EditAddCustomAssignmentOrPageModal";

const menuOptions = [
  { id: 1, text: "Edit Module Name" },
  { id: 2, text: "Add Assignment" },
  { id: 3, text: "Add Page" },
  { id: 4, text: "Delete Module" },
];

function CustomCourseWorkContentMenu({
  data,
  loadingData,
  onItemPress,
  onRenameModule,
  onDeleteModule,
  onAddModule,
  onAddAssignmentOrPage,
  editModuleNameModalState,
  editAssignmentOrPageModalState,
  addModuleModalState,
  deleteModuleModalState,
}) {
  const { dispatch } = useAppState();
  const { isXLarge, isMedium, hp } = useResponsiveScreen();

  const [selectedItem, setSelectedItem] = useState();
  const [selectedModule, setSelectedModule] = useState(null);
  const [expandedModule, setExpandedModule] = useState(null);
  const [menuVisible, setMenuVisible] = useState(null);
  const [isMenuExpanded, setIsMenuExpanded] = useState(true);

  const [deleteModuleModalVisible, setDeleteModuleModalVisible] = deleteModuleModalState;
  const [addModuleModalVisible, setAddModuleModalVisible] = addModuleModalState;
  const [editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible] = editAssignmentOrPageModalState;
  const [editModuleNameModalVisible, setEditModuleNameModalVisible] = editModuleNameModalState;

  const selectedModuleData = data?.modules?.find((module) => module.title === selectedModule);
  const navigation = useNavigation();

  useEffect(() => {
    if (data?.modules?.length > 0) {
      const firstModule = data.modules?.[0];
      setSelectedModule(firstModule.title);

      if (data.modules[0].resources[0].assignments?.length > 0) {
        const firstAssignment = data.modules?.[0].resources[0].assignments?.[0];
        setSelectedItem(firstAssignment.title);

        navigation.navigate("Custom Coursework Modules", {
          courseId: data?.id,
          courseName: data.title,
          assignmentIndex: firstModule.resources[0].assignments.indexOf(firstAssignment),
          moduleIndex: data?.modules?.indexOf(firstModule),
        });
      } else if (data.modules[0].resources[0].pages?.length > 0) {
        const firstPage = data.modules?.[0].resources[0].pages?.[0];
        setSelectedItem(firstPage.title);

        navigation.navigate("Custom Coursework Modules", {
          courseId: data?.id,
          courseName: data.title,
          pageIndex: firstModule.resources[0].pages.indexOf(firstPage),
          moduleIndex: data?.modules?.indexOf(firstModule),
        });
      }
    }
  }, [data]);

  const handleAddModule = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setAddModuleModalVisible(true);
  };
  const handleEditModuleName = (moduleName) => {
    setSelectedModule(moduleName);
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditModuleNameModalVisible(true);
  };

  const handleDeleteModule = (moduleName) => {
    setDeleteModuleModalVisible(true);
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setSelectedModule(moduleName);
  };

  const handleEditAssignmentOrPage = (moduleName, type) => {
    setSelectedModule(moduleName);
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditAssignmentOrPageModalVisible(type);
  };

  const handleDeleteModuleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setDeleteModuleModalVisible(false);
  };

  const handleAddModuleModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setAddModuleModalVisible(false);
  };
  const handleEditModuleNameModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setEditModuleNameModalVisible(false);
  };

  const handleEditAssignmentOrPageModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setEditAssignmentOrPageModalVisible(null);
  };

  const handleItemPress = (name, moduleName, type) => {
    setSelectedItem(name);
    onItemPress(name, moduleName, data.title, type);
  };

  const handleExitPress = () => {
    navigation.navigate("Custom Coursework");
    handleNavigate();
  };

  const handleNavigate = () => {
    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: false,
        isCustomCourseworkMenuOpen: false,
      },
    });
  };

  const handleExpandMenu = () => {
    setIsMenuExpanded((prev) => !prev);
  };

  const handleOpenOptionsMenu = (id) => {
    setMenuVisible(id);
  };

  const handleOptionPress = (optionId, moduleName) => {
    switch (optionId) {
      case 1:
        handleEditModuleName(moduleName);
        setMenuVisible(null);
        break;
      case 2:
        handleEditAssignmentOrPage(moduleName, "Assignment");
        setMenuVisible(null);
        break;
      case 3:
        handleEditAssignmentOrPage(moduleName, "Page");
        setMenuVisible(null);
        break;
      case 4:
        handleDeleteModule(moduleName);
        setMenuVisible(null);
        break;
      default:
        break;
    }
  };

  return (
    <View
      style={[styles.container, { maxWidth: isMenuExpanded ? (isXLarge ? 400 : isMedium ? 240 : hp(100)) : 65 }]}
      aria-live="polite"
      aria-label="Content Menu"
      role="menu">
      <View
        style={[
          styles.topContainer,
          isMenuExpanded
            ? {
                height: 48,
                flexDirection: "row",
                borderBottomWidth: 1,
              }
            : {
                height: 60,
                flexDirection: "column",
                borderBottomWidth: 0,
                paddingTop: 12,
                gap: 20,
              },
        ]}>
        <CustomButton
          leftIcon={<ExitDoor />}
          size="s"
          style={styles.topMenuButton}
          onPress={handleExitPress}
          aria-label="Exit Modules"
          accessibilityHint="Press to exit modules"
        />
        {(isXLarge || isMedium) && (
          <CustomButton
            leftIcon={<Sidebar />}
            size="s"
            style={styles.topMenuButton}
            onPress={handleExpandMenu}
            aria-label="Expand side menu"
            accessibilityHint="Press to expand side menu"
          />
        )}
      </View>
      {isMenuExpanded && (
        <>
          <CustomSpacing type="vertical" size="xxl" />
          <View style={{ paddingHorizontal: 20 }}>
            <View style={styles.menuContainer}>
              <CustomText role="header" aria-level="3" text="Modules" weight="ultraBold" style={{ color: "#121212" }} />
              <CustomButton
                text="Add Module"
                leftIcon={<PlusCircle />}
                styleType="primaryTransparent"
                size="s"
                style={[styles.addButton, shadowStyles.smallShadowButton, { marginLeft: isMedium ? "0" : "auto" }]}
                textStyle={styles.addText}
                bold={true}
                onPress={handleAddModule}
                aria-label="Add Module"
                accessibilityHint="Press to add Module"
              />
            </View>
          </View>
          <CustomModal
            visible={deleteModuleModalVisible}
            onRequestClose={handleDeleteModuleModalClose}
            showCloseButton={true}
            title="Delete Module"
            style={{ width: isMedium ? "50%" : "90%" }}>
            <DeleteModule
              deleteButtonClick={onDeleteModule}
              backButtonClick={handleDeleteModuleModalClose}
              moduleName={selectedModule}
              moduleId={selectedModuleData?.id}
            />
          </CustomModal>

          <EditModuleNameModalContainer
            visible={editModuleNameModalVisible}
            onClose={handleEditModuleNameModalClose}
            moduleName={selectedModule}
            onUpdateModuleName={onRenameModule}
            hasOrder={true}
            currentOrder={selectedModuleData?.order}
            moduleId={selectedModuleData?.id}
          />

          <EditAddCustomAssignmentOrPageModal
            visible={!!editAssignmentOrPageModalVisible}
            onClose={handleEditAssignmentOrPageModalClose}
            initialContent={""}
            onSaveItem={onAddAssignmentOrPage}
            type={editAssignmentOrPageModalVisible}
            moduleName={selectedModule}
            resourceId={selectedModuleData?.resources?.[0]?.id}
          />

          <AddModuleModalContainer
            visible={addModuleModalVisible}
            onClose={handleAddModuleModalClose}
            onAddModule={onAddModule}
            hasOrder={true}
          />

          {loadingData ? (
            <View>
              <Skeleton height={34} width="98%" style={{ marginVertical: 10 }} />
            </View>
          ) : (
            <ScrollView role="list" showsVerticalScrollIndicator={false} style={styles.scrollView}>
              {data?.modules?.map((module, index) => {
                const moduleName = module.title;

                const assignments = module.resources?.[0].assignments ?? [];

                return (
                  <ListItem.Accordion
                    key={moduleName}
                    role="button"
                    aria-label={moduleName}
                    pad={8}
                    containerStyle={styles.chapterAccordion}
                    isExpanded={expandedModule === moduleName}
                    icon={<DownArrow fill="#344054" />}
                    content={
                      <ListItem.Content
                        style={[
                          styles.chapterNameContainer,
                          { flexDirection: "row", alignItems: "center", justifyContent: "space-between" },
                        ]}>
                        <View style={{ flex: 3, paddingRight: 10 }}>
                          <CustomText
                            useTranslationText={false}
                            text={moduleName}
                            weight="ultraBold"
                            numberOfLines={2}
                            ellipsizeMode="tail"
                            style={{ textDecorationLine: "none", color: "#344054" }}
                          />
                        </View>
                        <View
                          style={{
                            flexShrink: 0,
                            flexDirection: "row",
                            gap: 8,
                            alignItems: "center",
                          }}>
                          <Menu
                            contentStyle={styles.menu}
                            visible={menuVisible === module.id}
                            onDismiss={() => setMenuVisible(null)}
                            anchor={
                              <Pressable
                                onPress={() => handleOpenOptionsMenu(module.id)}
                                style={{ height: 24, width: 30 }}>
                                <ThreeDot fill="#000" height={24} width={24} />
                              </Pressable>
                            }>
                            {menuOptions.map((option) => (
                              <Menu.Item
                                key={option.id}
                                onPress={() => handleOptionPress(option.id, module.title)}
                                title={option.text}
                              />
                            ))}
                          </Menu>
                        </View>
                      </ListItem.Content>
                    }
                    onPress={() => setExpandedModule((prev) => (prev === moduleName ? null : moduleName))}
                    topDivider={index !== 0}>
                    <CustomCourseworkMenuHeader
                      type="Assignment"
                      onAddPress={() => handleEditAssignmentOrPage(moduleName, "Assignment")}
                    />
                    {assignments.map((assignment) => {
                      const assignmentName = assignment.title;
                      return (
                        <CustomCourseworkMenuItem
                          onItemPress={() => handleItemPress(assignmentName, module.title, "Assignment")}
                          name={assignmentName}
                          key={assignmentName}
                          isSelected={selectedItem === assignmentName}
                          isLast={assignments.indexOf(assignment) === assignments.length - 1}
                        />
                      );
                    })}
                    <CustomCourseworkMenuHeader
                      type="Page"
                      onAddPress={() => handleEditAssignmentOrPage(moduleName, "Page")}
                    />
                    {module.resources?.[0].pages && (
                      <>
                        {module.resources?.[0].pages.map((page) => (
                          <CustomCourseworkMenuItem
                            onItemPress={() => handleItemPress(page.title, module.title, "Page")}
                            name={page.title}
                            key={page.title}
                            isSelected={selectedItem === page.title}
                            isLast={module.resources[0].pages.indexOf(page) === module.resources[0].pages.length - 1}
                          />
                        ))}
                      </>
                    )}
                  </ListItem.Accordion>
                );
              })}
            </ScrollView>
          )}
        </>
      )}
    </View>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    height: 1,
    paddingHorizontal: 20,
  },
  topContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    paddingHorizontal: 20,
    backgroundColor: "#fff",
    borderBottomColor: "#EAECF0",
  },
  topMenuButton: {
    width: 24,
    height: 24,
    justifyContent: "center",
  },
  container: {
    top: 0,
    left: 0,
    bottom: 0,
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#EAECF0",
    display: "flex",
    flexDirection: "column",
    backgroundColor: "#fff",
    zIndex: 20,
    elevation: 5,
  },
  menuContainer: {
    display: "flex",
    paddingVertical: 10,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "#fff",
    borderBottomColor: "#D0D5DD",
    borderBottomWidth: 1,
  },
  chapterAccordion: {
    flexDirection: "row-reverse",
    alignItems: "flex-start",
    paddingRight: 16,
    paddingLeft: 0,
    paddingVertical: 12,
  },
  chapterNameContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: "auto",
    backgroundColor: "#fff",
  },
  addButton: {
    fontSize: 12,
    border: "1px solid rgba(5, 6, 15, 0.10)",
  },
  addText: {
    paddingLeft: 10,
  },
  menu: {
    backgroundColor: "#fff",
    borderRadius: 5,
    paddingVertical: 8,
    paddingHorizontal: 10,
  },
});

export default CustomCourseWorkContentMenu;
