import { StyleSheet } from "react-native";
import { MotiView } from "moti";
import LottieView from "lottie-react-native";

function Loader() {
  return (
    <MotiView
      from={{ opacity: 0, scale: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      transition={{ type: "timing", duration: 2000 }}
      exit={{ opacity: 0, scale: 0 }}>
      <LottieView
        autoPlay
        loop={true}
        style={styles.lottie}
        source={{ ...require("../../../../assets/brain_lottie.json") }}
      />
    </MotiView>
  );
}

const styles = StyleSheet.create({
  lottie: {
    width: 100,
    height: 100,
  },
});

export default Loader;
