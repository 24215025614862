import * as React from "react";
import Svg, { Path } from "react-native-svg";

function CoreHeader(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.638 4.562c-2.76-.396-4.968.132-5.841 1.644-.873 1.512-.227 3.688 1.497 5.88-1.724 2.192-2.37 4.368-1.497 5.88.873 1.512 3.08 2.04 5.841 1.644 1.037 2.589 2.598 4.236 4.344 4.236 1.746 0 3.307-1.647 4.344-4.236 2.76.396 4.967-.132 5.84-1.644.873-1.512.227-3.688-1.497-5.88 1.724-2.192 2.37-4.368 1.497-5.88-.873-1.512-3.08-2.04-5.84-1.644C15.289 1.974 13.728.326 11.982.326c-1.746 0-3.307 1.648-4.344 4.236zm-.673 2.104c-.18.718-.324 1.48-.43 2.275a20.59 20.59 0 00-1.756 1.51c-.071-.096-.139-.191-.204-.287-1.208-1.768-1.022-2.656-.897-2.872.124-.216.8-.82 2.936-.66.115.01.232.02.351.034zM9.796 5.03a20.6 20.6 0 012.186.765 20.59 20.59 0 012.186-.765c-.048-.11-.097-.216-.147-.32-.928-1.93-1.79-2.213-2.039-2.213-.25 0-1.11.283-2.039 2.213-.05.104-.099.21-.147.32zm2.186 3.136a22.173 22.173 0 00-3.394 1.96 22.162 22.162 0 000 3.92 22.178 22.178 0 003.394 1.959 22.19 22.19 0 003.394-1.96 22.151 22.151 0 000-3.919 22.15 22.15 0 00-3.394-1.96zm7.202 2.285a20.613 20.613 0 00-1.754-1.51 20.593 20.593 0 00-.431-2.276c.119-.013.235-.024.35-.033 2.136-.162 2.812.443 2.936.66.125.215.311 1.103-.897 2.872-.065.095-.133.19-.204.287zm0 3.269a20.587 20.587 0 01-1.754 1.51c-.106.796-.251 1.557-.43 2.276.117.013.234.024.349.032 2.136.162 2.811-.443 2.936-.659.125-.216.31-1.103-.897-2.872-.065-.095-.133-.19-.204-.287zm-14.405 0c.532.515 1.12 1.021 1.756 1.51.106.796.25 1.558.43 2.276-.119.013-.236.024-.35.032-2.137.162-2.812-.443-2.937-.659-.125-.216-.31-1.103.898-2.872.064-.095.132-.19.203-.287zm7.203 4.655c.742.306 1.474.562 2.186.765-.048.11-.097.216-.147.32-.928 1.93-1.79 2.213-2.039 2.213-.25 0-1.11-.282-2.039-2.213-.05-.104-.099-.21-.147-.32a20.598 20.598 0 002.186-.765z"
        fill="#054CD4"
      />
    </Svg>
  );
}

export default CoreHeader;
