import { View, StyleSheet } from "react-native";
import CustomSpacing from "../CustomSpacing/CustomSpacing";

const Divider = ({ size = "m", type = "vertical" }) => {
  const isVertical = type === "vertical";

  return (
    <>
      <CustomSpacing size={size} type={type} />
      <View style={isVertical ? styles.verticalDivider : styles.horizontalDivider} />
      <CustomSpacing size={size} type={type} />
    </>
  );
};

const styles = StyleSheet.create({
  verticalDivider: {
    width: "100%",
    borderColor: "#DADADA",
    borderTopWidth: 1,
  },
  horizontalDivider: {
    height: "100%",
    borderColor: "#DADADA",
    borderLeftWidth: 1,
  },
});

export default Divider;
