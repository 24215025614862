import { useEffect } from "react";
import { View, StyleSheet } from "react-native";
import { usePostHog } from "posthog-js/react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import useWebSocket from "../hooks/useWebSocket";
import { useNavigation } from "@react-navigation/native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import LessonHeader from "../components/learning/lesson/LessonHeader/LessonHeader";
import LessonCard from "../components/learning/lesson/LessonCard/LessonCard";
import getLearningRoute from "../utils/getLearningRoute";
import { MotiView } from "moti";
import { useSubchapterByIdQuery } from "../graphql/generated/graphql";
import DefaultLayout from "../layouts/DefaultLayout.web";
import { useChatBar } from "../contexts/ChatBarContext";

function LessonAssessmentView({ route }) {
  const posthog = usePostHog();
  const { sendMessage } = useWebSocket();
  const { setConversationType, setLearningMessage, mapRouteToContext, setMetaIsLoading } = useChatBar();
  const navigation = useNavigation();
  const { isMedium, isLarge } = useResponsiveScreen();

  const params = route.params;
  const { ancestors, name } = getLearningRoute(params);

  const loId = params.loId;

  const { data } = useSubchapterByIdQuery({
    variables: {
      name: params.subchapter,
    },
  });

  useEffect(() => {
    posthog?.capture("Assessment Lesson Page Visited");
  }, []);

  const handleLessonCardPress = async (type, title) => {
    posthog?.capture("Lesson Type: " + type);
    const token = await AsyncStorage.getItem("token");

    const view = "LESSON_CHAT";

    setConversationType("LEARNING");
    setLearningMessage({
      state: type,
      topic: name,
      topicAncestors: ancestors,
      isUnitAssessment: true,
      subchapterName: data?.getSubchapterById?.name,
      subchapterDescription: data?.getSubchapterById?.description,
      objectives: data?.getSubchapterById?.objectives
        ?.map((objective) => `${objective.title}: ${objective.description}`)
        ?.join("\n"),
      learningObjectiveId: loId,
    });

    setMetaIsLoading(true);
    sendMessage({
      meta: {
        token,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        abort: false,
        displayMessage: false,
        isLoading: true,
      },
      state: {
        general: "LEARNING",
        view,
        learning: {
          state: type,
          topic: name,
          topicAncestors: ancestors,
          isUnitAssessment: true,
          subchapterName: data?.getSubchapterById?.name,
          subchapterDescription: data?.getSubchapterById?.description,
          objectives: data?.getSubchapterById?.objectives
            ?.map((objective) => `${objective.title}: ${objective.description}`)
            ?.join("\n"),
          learningObjectiveId: loId,
        },
        context: mapRouteToContext(route.name),
        params: route.params,
      },
      content: {
        message: "Begin " + title + " on " + data?.getSubchapterById?.name,
        author: "HUMAN",
        type: null,
      },
    });

    const updatedParams = {
      ...params,
      title,
      page: "Unit Assessment",
    };

    navigation.navigate("Lesson Chat", updatedParams);
  };

  const assessmentData = [
    {
      title: "Game",
      subtitle:
        "Challenge your understanding by playing a text-based adventure game that integrates key concepts in interactive scenarios.",
      source: require("../assets/cardImages/game.jpg"),
      type: "ASSESS_GAME",
    },
    {
      title: "Multiple Choice",
      subtitle:
        "Assess your knowledge with a multiple-choice quiz that covers key points from the lessons, helping to reinforce learning.",
      source: require("../assets/cardImages/multiple_choice.jpg"),
      type: "ASSESS_MC",
    },
    {
      title: "Discussion",
      subtitle:
        "Engage in a simulated discussion with an expert to explore your understanding and articulate your thoughts on the topic.",
      source: require("../assets/cardImages/discussion.jpg"),
      type: "ASSESS_FR",
    },
  ];

  return (
    <DefaultLayout menuType="learn">
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <LessonHeader title="Unit Assessment" instructions="Choose an experience to demonstrate your knowledge" />
      </MotiView>

      <CustomSpacing type="vertical" size="m" />
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={700}
        transition={{ type: "timing", duration: 600 }}>
        <View style={isMedium ? styles.lessonCardContainerDesktop : styles.lessonCardContainerMobile}>
          {assessmentData.map((data) => (
            <View key={data.title} style={isLarge ? {} : isMedium ? { width: "100%" } : styles.lessCardWrapperMobile}>
              <LessonCard
                key={data.title}
                title={data.title}
                subtitle={data.subtitle}
                source={data.source}
                onPress={() => handleLessonCardPress(data.type, data.title)}
              />
            </View>
          ))}
        </View>
      </MotiView>
    </DefaultLayout>
  );
}
const styles = StyleSheet.create({
  lessonCardContainerDesktop: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    gap: 20,
  },
  lessonCardContainerMobile: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    gap: 12,
  },
  lessCardWrapperMobile: {
    width: "48%",
  },
});

export default LessonAssessmentView;
