import React from "react";
import QuickOptionList from "../../../components/chat/QuickOptionList/QuickOptionList";
import { useChatBar, Context } from "../../../contexts/ChatBarContext";

const QuickOptionListContainer = ({ onQuickOptionPress, onGetSuggestionsPress, isCustomOffering }) => {
  const { state: chatBarState } = useChatBar();

  let primaryOptions = [];

  switch (chatBarState.activeContext) {
    case Context.GENERAL:
      primaryOptions = [
        { title: "News Briefing", message: "Give me today’s news briefing" },
        { title: "Task Support", message: "Help me with my current tasks" },
        { title: "Learn Something New", message: "Help me learn something new" },
        { title: "Plan my Day", message: "Help me setup a plan for my day" },
      ];
      break;
    // case Context.TEACHER:
    //   primaryOptions = [
    //     { title: "Rubric Generator", message: "Can you create me a rubric based on the details I will provide you?" },
    //     { title: "Draft Email", message: "Can you draft me a personal email based on the details I will provide you?" },
    //     {
    //       title: "Quiz Generator",
    //       message:
    //         "Can you generate a multiple choice quiz based on the grade level and learning objectives I provide you?",
    //     },
    //     { title: "Brainstorm", message: "Can you help me brainstorm on the topic I will provide?" },
    //     { title: "Plan my Day", message: "Help me setup a plan for my day" },
    //   ];
    //   break;
    case Context.TRACK:
    case Context.ASSIGNMENT:
      if (!isCustomOffering) {
        primaryOptions = [
          { title: "Story", message: "Teach me about this topic using a story" },
          { title: "Hands-On Activity", message: "Let's do a hands-on activity." },
          { title: "Game", message: "Let's play a text-based game to learn this." },
          { title: "Career Alignment", message: "Help me align this my career goals" },
          { title: "Quiz Me", message: "Quiz me this topic" },
        ];
      } else {
        primaryOptions = [{ title: "Get Video Insights", message: "Get Suggestions" }];
      }
      break;
    case Context.JOURNEY:
      primaryOptions = [
        { title: "Next Steps", message: "What is the next step I should focus on in my journey?" },
        { title: "Task Support", message: "Help me with my outstanding tasks" },
        { title: "Assess Progress", message: "Assess me on my progress on this journey" },
      ];
      break;
    case Context.ONBOARDING:
      return null;
    default:
      primaryOptions = [];
  }

  const handleOptionPress = (option) => {
    onQuickOptionPress(option.message);
  };

  return (
    <QuickOptionList options={primaryOptions} onPress={isCustomOffering ? onGetSuggestionsPress : handleOptionPress} />
  );
};

export default QuickOptionListContainer;
