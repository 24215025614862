import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function ConfirmationModal({ message, visible, onClose, onConfirm }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal visible={visible} style={{ width: isMedium ? "30%" : "80%" }}>
      <CustomText text={message} weight="bold" />
      <CustomSpacing type="vertical" size="l" />
      <View style={styles.modalButtonContainer}>
        <CustomButton text="Cancel" styleType="primary" onPress={onClose} accessibilityHint="Press to close modal" />
        <CustomButton text="Continue" styleType="danger" onPress={onConfirm} accessibilityHint="Press to continue" />
      </View>
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  modalButtonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

export default ConfirmationModal;
