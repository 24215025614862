import * as React from "react";
import Svg, { Path } from "react-native-svg";

function DarkJourneyPath(props) {
  return (
    <Svg width={18} height={16} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M14.625 9.813a2.818 2.818 0 00-2.652 1.874H4.625a2.188 2.188 0 010-4.374h7.5a3.438 3.438 0 000-6.875h-7.5a.937.937 0 100 1.875h7.5a1.563 1.563 0 010 3.124h-7.5a4.062 4.062 0 100 8.125h7.348a2.812 2.812 0 102.652-3.75zm0 3.75a.937.937 0 110-1.875.937.937 0 010 1.874z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default DarkJourneyPath;
