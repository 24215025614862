import { StyleSheet } from "react-native";
import { MotiView } from "moti";
import CustomText from "../../general/CustomText/CustomText";
import CustomSpacing from "../../layout/CustomSpacing/CustomSpacing";

function ErrorLoader({ message }) {
  return (
    <MotiView
      from={{ opacity: 0, scale: 0 }}
      animate={{
        scale: 1,
        opacity: 1,
      }}
      transition={{ type: "timing", duration: 2000 }}
      exit={{ opacity: 0, scale: 0 }}
      style={styles.content}>
      <CustomText text="Error" size="xl" weight="bold" />
      <CustomSpacing type="vertical" size="s" />
      <CustomText
        role="alert"
        text={message || "Something has gone wrong. Our team has been notified. Please refresh the page."}
        size="xs"
        weight="bold"
        style={styles.message}
      />
    </MotiView>
  );
}

const styles = StyleSheet.create({
  content: {
    alignItems: "center",
    width: 250,
  },
  message: {
    textAlign: "center",
  },
});

export default ErrorLoader;
