import React, { createContext, useEffect } from "react";
import { useAppState, actions } from "./AppStateContext";
import { useAuth } from "../hooks/useAuth";
import { useMeQuery } from "../graphql/generated/graphql";
import { usePostHog } from "posthog-js/react";
import Sentry from "../utils/sentry";

export const UserContext = createContext({ refetch: () => {} });

export const UserProvider = ({ children }) => {
  const { dispatch } = useAppState();
  const { isAuthenticated } = useAuth();
  const posthog = usePostHog();

  const {
    data,
    loading,
    error,
    refetch: refetchMe,
  } = useMeQuery({
    skip: !isAuthenticated,
  });

  const isProUser = data?.me?.isPro || false;
  const hasFreeTrial = !!data?.me?.trialEndDate;

  useEffect(() => {
    if (data && !loading && !error) {
      posthog?.capture("Set user properties on login.", {
        $set: {
          isPayingUser: isProUser,
          hasFreeTrial,
        },
      });
      dispatch({
        type: actions.SET_META,
        payload: {
          isOnboarded: data.me.isOnboarded, // TODO: remove this, use user.isOnboarded
          isEmailVerified: data.me.isEmailVerified, // TODO: remove this, use user.isEmailVerified
          isAudioOn: data.me.audioOn, // TODO: remove this, use user.isAudioOn
          user: data.me,
        },
      });
    }
  }, [data, loading, error]);

  const refetch = async () => {
    try {
      await refetchMe();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return <UserContext.Provider value={{ refetch }}>{children}</UserContext.Provider>;
};
