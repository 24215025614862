import React from "react";
import { View, Text, StyleSheet } from "react-native";
import { DurationIcon } from "../../svgIcons";

function Duration({ duration }) {
  return (
    <View accessible aria-label="Dutation" style={styles.durationBox}>
      <DurationIcon />
      <Text style={styles.title}>{duration}</Text>
    </View>
  );
}

const styles = StyleSheet.create({
  title: {
    fontSize: 12,
    color: "#8E8E8E",
    fontWeight: "bold",
    fontFamily: "Inter",
    marginLeft: 4,
  },
  durationBox: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 10,
    borderWidth: 1,
    borderColor: "#B8B8B8",
    paddingHorizontal: 8,
    paddingVertical: 4,
    // maxWidth: 100, // Add this line to limit the width
  },
});

export default Duration;
