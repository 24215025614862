import React from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import DeleteCourse from "../../../components/content/DeleteCourse/DeleteCourse";

const DeleteCourseModal = ({ visible, onClose, onDeleteCourse }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Delete Course"
      style={{ width: isMedium ? "50%" : "90%" }}>
      <DeleteCourse deleteButtonClick={onDeleteCourse} backButtonClick={onClose} />
    </CustomModal>
  );
};

export default DeleteCourseModal;
