import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

const TwoFactorDisable = ({ onDisable }) => {
  return (
    <View style={styles.container}>
      <CustomText
        text={"Are you sure you want to disable Two-factor authentication?"}
        size="xl"
        textType="text"
        style={{ textAlign: "center" }}
      />
      <CustomSpacing type="vertical" size={24} />
      <CustomButton
        text="Disable"
        styleType="danger"
        onPress={onDisable}
        disabled={false}
        style={styles.disableButton}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  disableButton: {
    width: "100%",
  },
});

export default TwoFactorDisable;
