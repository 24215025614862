import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import { UnChecked, Checked } from "../../svgs/common";

function Notifications({ onDailyEmailOptInPress, dailyEmailOptIn, errorUpdatingNotifications }) {
  return (
    <>
      <View style={styles.notificationsContainer}>
        <CustomButton
          styleType="transparent"
          role="checkbox"
          aria-checked={dailyEmailOptIn}
          aria-label="Daily email opt in/opt out"
          style={styles.checkboxButton}
          leftIcon={dailyEmailOptIn ? <Checked /> : <UnChecked />}
          onPress={onDailyEmailOptInPress}
        />
        <CustomText text="Receive daily plan email" style={styles.checkboxText} />
      </View>
      {errorUpdatingNotifications && (
        <View style={styles.notificationsContainer}>
          <CustomText text={errorUpdatingNotifications} useTranslationText={false} style={{ color: "#E57373" }} />
        </View>
      )}
    </>
  );
}

const styles = StyleSheet.create({
  notificationsContainer: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  checkboxButton: {
    padding: 0,
    marginRight: 10,
  },
  checkboxText: {
    fontSize: 14,
  },
});

export default Notifications;
