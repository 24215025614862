import React from "react";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import DeleteAssignmentOrPage from "../DeleteAssignmentOrPage/DeleteAssignmentOrPage";

const DeleteAssignmentOrPageModalContainer = ({ visible, onClose, onDeleteAssignmentOrPage, type }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title={`Delete ${type}`}
      style={{ width: isMedium ? "50%" : "90%" }}>
      <DeleteAssignmentOrPage
        deleteButtonClick={() => onDeleteAssignmentOrPage(type)}
        backButtonClick={onClose}
        type={type}
      />
    </CustomModal>
  );
};

export default DeleteAssignmentOrPageModalContainer;
