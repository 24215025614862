import React from "react";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { updateOtpPreference } from "../../../services";
import TwoFactorDisable from "../../../components/settings/TwoFactorDisable/TwoFactorDisable";
import { useMeQuery } from "../../../graphql/generated/graphql";
import { OTPPreference } from "./TwoFactorAuthModalContainer";
import Sentry from "../../../utils/sentry";

interface TwoFactorAuthDisableContainerProps {
  visible: boolean;
  onClose: () => void;
}

const TwoFactorAuthDisableContainer: React.FC<TwoFactorAuthDisableContainerProps> = ({ visible, onClose }) => {
  const { isMedium } = useResponsiveScreen();
  const { refetch } = useMeQuery();

  const handleDisableTwoFactorAuth = async () => {
    try {
      const token = await AsyncStorage.getItem("token");
      await updateOtpPreference(OTPPreference.NONE, token);
      await refetch(); // Refetch user data to update the UI
      onClose();
    } catch (error) {
      Sentry.captureException(error);
    }
  };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Two-factor Identification Setup"
      style={{ width: isMedium ? "50%" : "90%" }}
      exitFill="#000000"
      onShow={() => {}}
      ariaLabel="Two-factor Authentication Modal">
      <TwoFactorDisable onDisable={handleDisableTwoFactorAuth} />
    </CustomModal>
  );
};

export default TwoFactorAuthDisableContainer;
