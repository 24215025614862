import { View, StyleSheet, Image } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import { Box } from "../../../svgs/common";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import CustomProgressBar from "../../../common/general/CustomProgressBar/CustomProgressBar";
import { shadowStyles } from "../../../../styles/shadow";

const LearnTrackCard = ({
  name,
  description,
  onCardPress,
  completedTracks,
  totalTracks,
  isUserEnrolled,
  mastery,
  image,
}) => {
  const { isMedium } = useResponsiveScreen();
  const hasProgressBar = (completedTracks === 0 || completedTracks) && totalTracks;
  return (
    <View
      style={[
        styles.mainContainer,
        shadowStyles.primary,
        isMedium ? styles.mediumDimensions : styles.mobileDimensions,
      ]}>
      <View style={{ width: "100%" }}>
        <Image
          style={styles.image}
          source={image ? image : require("../../../../assets/onboardingModals/Experience.png")}
          resizeMode="cover"
          alt="background"
          aria-hidden={true}
        />
        {(totalTracks === 0 || totalTracks) && isMedium && (
          <View style={styles.unitsInfoBox}>
            <Box />
            <CustomText
              useTranslationText={false}
              aria-label="Lessons count"
              role="header"
              aria-level="4"
              text={`${totalTracks} Lesson${totalTracks > 1 ? "s" : ""}`}
              size="xs"
              weight="ultraBold"
              styleType="primary"
            />
          </View>
        )}
        {!!mastery && (
          <View style={isMedium ? styles.progressBar : styles.mobileProgressBar}>
            {isMedium && hasProgressBar && (
              <>
                <CustomText
                  useTranslationText={false}
                  text={`${completedTracks} of ${totalTracks} Unit${totalTracks > 1 ? "s" : ""} Complete`}
                  size="xs"
                  weight="bold"
                  styleType="light"
                />
                <CustomSpacing type="vertical" size="xs" />
                <CustomSpacing type="vertical" size="xs" />
              </>
            )}
            <CustomProgressBar ariaLabel="Unit mastery progress bar" progress={mastery} />
          </View>
        )}
      </View>
      <View style={styles.bottomContainer}>
        <CustomText
          useTranslationText={false}
          text={name}
          weight="bold"
          size="m"
          numberOfLines={2}
          ellipsizeMode="tail"
          aria-label={name}
          role="header"
          aria-level="3"
        />
        <CustomText
          useTranslationText={false}
          text={description}
          size="s"
          style={{ color: "#667085" }}
          numberOfLines={3}
          ellipsizeMode="tail"
        />
        {isUserEnrolled || hasProgressBar ? (
          <CustomButton
            text="Resume Track"
            bold
            style={[styles.trackButton, { border: "2px solid #3E68FE" }]}
            textStyle={{ fontSize: 12 }}
            styleType="primaryLight"
            onPress={onCardPress}
            role="link"
          />
        ) : (
          <CustomButton
            text="View Track"
            styleType="primary"
            bold
            style={styles.trackButton}
            textStyle={{ fontSize: 12 }}
            onPress={onCardPress}
            role="link"
          />
        )}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 10,
  },
  mobileDimensions: {
    width: "96%",
    margin: 6,
  },
  mediumDimensions: {
    width: 360,
    margin: 8,
  },
  bottomContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    paddingVertical: 14,
    paddingHorizontal: 20,
    textAlign: "flex-start",
    gap: 12,
    width: "100%",
  },
  image: {
    height: 250,
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  trackButton: {
    padding: 8,
  },
  progressBar: {
    position: "absolute",
    left: 4,
    bottom: 7,
    elevation: 10,
    backgroundColor: "#000B33B2",
    width: "97%",
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderRadius: 10,
  },
  mobileProgressBar: {
    position: "absolute",
    left: 0,
    bottom: 0,
    elevation: 10,
    backgroundColor: "#000B33B2",
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 12,
  },
  unitsInfoBox: {
    position: "absolute",
    top: 9,
    left: 11,
    backgroundColor: "white",
    padding: 6,
    borderRadius: 100,
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
  },
});

export default LearnTrackCard;
