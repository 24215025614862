import * as React from "react";
import Svg, { Path } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={32} height={32} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M9 11.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zm7-1.5a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm8.5 3a1.5 1.5 0 100-3 1.5 1.5 0 000 3zm-17 6a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm8.5 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3zm8.5 0a1.5 1.5 0 100 3 1.5 1.5 0 000-3z"
        fill="#667085"
      />
    </Svg>
  );
}

export default SvgComponent;
