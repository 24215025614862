import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import DefaultLayout from "../layouts/DefaultLayout.web";
import SchoolsCardListContainer from "../containers/school/SchoolsCardListContainer/SchoolsCardListContainer";
import { MotiView } from "moti";

function SchoolView() {
  const { isMedium } = useResponsiveScreen();
  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("Schools Page Visited", {
      $set_once: {
        lms_connected: true,
      },
    });
  }, []);

  return (
    <DefaultLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={styles.subheaderContainer}>
          <CustomText
            role="header"
            text="Schools"
            size={isMedium ? "l" : "xs"}
            textType="display"
            weight="ultraBold"
            aria-level="1"
          />
        </View>
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={700}
        transition={{ type: "timing", duration: 600 }}>
        <CustomSpacing type="vertical" size="m" />
        <CustomText role="header" aria-level="2" text="My Schools" size="xs" textType="display" />
        <CustomSpacing type="vertical" size="m" />
        <CustomSpacing type="vertical" size="m" />
      </MotiView>
      <View>
        <SchoolsCardListContainer />
      </View>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  subheaderContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default SchoolView;
