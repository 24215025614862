import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import { RedInfo } from "../../svgs/common";
import { shadowStyles } from "../../../styles/shadow";

const ResetAccount = ({ resetButtonClick, cancelButtonClick }) => {
  return (
    <View style={styles.container}>
      <View style={[shadowStyles.smallShadow, styles.warningBox]}>
        <View style={{ marginRight: 10 }}>
          <RedInfo />
        </View>
        <CustomText
          style={styles.warningBoxText}
          size="xs"
          text="Are you sure you want to reset your account? This action will erase all your data, including your preferences, learning history, and personalized settings. It’s like starting fresh with Axio."
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          text="Clear Account"
          onPress={resetButtonClick}
          styleType="primary"
          size="m"
          style={{ marginBottom: 10, aliignItems: "center" }}
          bold="true"
        />
        <CustomButton
          text="Cancel"
          onPress={cancelButtonClick}
          styleType="primaryLight"
          size="m"
          style={styles.backButton}
          bold="true"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderRadius: 10,
  },
  warningBox: {
    padding: 10,
    backgroundColor: "rgba(247, 92, 138, 0.2)",
    borderRadius: 10,
    marginBottom: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  warningBoxText: {
    color: "#F75C8A",
  },
});

export default ResetAccount;
