import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";

const TwoFactorEnterCode = ({ handleVerifyPress, handleResendPress, contactMethod }) => {
  const [code, setCode] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);

  const validateCode = (code) => {
    return code.length === 6 && /^\d+$/.test(code);
  };

  const handleCodeChange = (text) => {
    setCode(text);
    setErrorMessage("");
  };

  const handleVerifyCodePress = async () => {
    if (!validateCode(code)) {
      setErrorMessage("Please enter a valid 6-digit code.");
      return;
    }

    setIsVerifying(true);
    try {
      await handleVerifyPress(code);
      setErrorMessage("");
    } catch (error) {
      setErrorMessage(error.message || "OTP verification failed. Please try again.");
    } finally {
      setIsVerifying(false);
    }
  };

  const handleResend = async () => {
    try {
      await handleResendPress();
      setErrorMessage("");
    } catch (error) {
      setErrorMessage("Failed to resend code. Please try again later.");
    }
  };

  return (
    <View style={styles.container}>
      <CustomText text={`We've sent a code to your ${contactMethod}. Enter it below.`} size="s" textType="text" />
      <CustomSpacing type="vertical" size={16} />
      <CustomTextInput
        value={code}
        onChangeText={handleCodeChange}
        keyboardType="number-pad"
        maxLength={6}
        placeholder="Enter 6-digit code"
      />
      <CustomSpacing type="vertical" size={8} />
      {errorMessage ? <CustomText text={errorMessage} size="xs" style={styles.errorText} /> : null}
      <View style={styles.resendContainer}>
        <CustomText text="Didn't get the code?" size="s" textType="text" />
        <CustomButton styleType="primaryTransparent" text="Resend" size="s" bold={true} onPress={handleResend} />
      </View>
      <CustomSpacing type="vertical" size={24} />
      <CustomButton
        text={isVerifying ? "Verifying..." : "Verify"}
        styleType="primary"
        onPress={handleVerifyCodePress}
        style={styles.sendCodeButton}
        disabled={isVerifying}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  sendCodeButton: {
    width: "100%",
  },
  resendContainer: {
    flexDirection: "row",
    alignItems: "center",
    marginTop: 8,
  },
  errorText: {
    color: "red",
  },
});

export default TwoFactorEnterCode;
