import * as React from "react";
import Svg, { Circle, Path } from "react-native-svg";

function TimeLeftTitle(props) {
  return (
    <Svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx={13} cy={13.0526} r={10.8333} stroke="#13193A" strokeWidth={1.5} />
      <Path
        opacity={0.45}
        d="M13.27 8.72v4.874l-4.332 2.167"
        stroke="#13193A"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
}

export default TimeLeftTitle;
