import { Platform } from "react-native";
import { useAppConfig } from "../../../AppConfigProvider";
import AsyncStorage from "@react-native-async-storage/async-storage";
import GoogleConsentModal from "../../../components/settings/GoogleConsentModal/GoogleConsentModal";
import { calendar } from "../../../services";
import handleOpenLink from "../../../utils/handleOpenLink";

function GoogleConcentModalContainer({ visible, onClose }) {
  const appConfig = useAppConfig();

  const companionName = appConfig.companionName;

  const handleConfirm = async () => {
    let newWindow;
    if (Platform.OS === "web") {
      newWindow = window.open("", "_blank");
    }

    const token = await AsyncStorage.getItem("token");
    const data = await calendar(token);

    const { authURL } = data;

    await handleOpenLink(authURL, newWindow);
    onClose();
  };

  return (
    <GoogleConsentModal visible={visible} companionName={companionName} onClose={onClose} onConfirm={handleConfirm} />
  );
}

export default GoogleConcentModalContainer;
