import React from "react";
import { StyleSheet, View, ImageBackground, Linking } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import VideoPlay from "../../svgs/common/VideoPlay";
import Sentry from "../../../utils/sentry";
import { shadowStyles } from "../../../styles/shadow";

const VideoCard = ({ imageUrl, url }) => {
  const handlePress = () => {
    Linking.openURL(url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  return (
    <>
      <View style={[shadowStyles.primary, styles.container]}>
        <View style={styles.imageContainer}>
          <ImageBackground style={styles.image} source={{ uri: imageUrl }}>
            <CustomButton
              onPress={handlePress}
              leftIcon={<VideoPlay />}
              styleType="light"
              style={styles.button}
              aria-label="Play Video"
            />
          </ImageBackground>
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  container: {
    borderRadius: 4,
    overflow: "hidden",
    maxWidth: 120,
    minWidth: 120,
    margin: 5,
  },
  imageContainer: {
    width: "100%",
    height: 67.5,
  },
  image: {
    height: "100%",
    width: "100%",
    resizeMode: "contain",
  },
  button: {
    backgroundColor: "#000000",
    borderRadius: 80,
    width: 24,
    height: 24,
    position: "absolute",
    right: 6,
    bottom: 5,
    justifyContent: "center",
  },
});

export default VideoCard;
