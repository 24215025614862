import React, { useState, useCallback, useEffect } from "react";
import { StyleSheet } from "react-native";
import TaskForm from "../../../components/tasks/TaskForm/TaskForm";
import {
  useAddTodoMutation,
  useUpdateTodoMutation,
  TodosDocument,
  RecentTodosDocument,
  GetJourneyByIdDocument,
  GetJourneysDocument,
} from "../../../graphql/generated/graphql";
import { DatePickerModal } from "react-native-paper-dates";
import { ScrollView } from "react-native-gesture-handler";

const TaskFormContainer = ({ task, journeyId, onComplete }) => {
  const [isDatePickerVisible, setIsDatePickerVisible] = useState(false);
  const [date, setDate] = useState(task ? new Date(task.date) : new Date());
  const [title, setTitle] = useState(task ? task.name : "");
  const [description, setDescription] = useState(task ? task.description : "");
  const [priority, setPriority] = useState(task ? task.priority : "low");
  const [repeat, setRepeat] = useState(task ? task.isRecurring : false);
  const [timePeriod, setTimePeriod] = useState(task ? task.timeOfDay : "AM");
  const [repeatEvery, setRepeatEvery] = useState(task ? task.recurringFrequency : "Day");
  const [selectedDay, setSelectedDay] = useState(task ? task.recurringDayOfWeek : "Monday");
  const [dayOfMonth, setDayOfMonth] = useState(task ? task.recurringDayOfMonth : 1);
  const [timeValue, setTimeValue] = useState(task ? task.recurringTime : "12:00");

  const [dayOfMonthError, setDayOfMonthError] = useState("");

  const [addTodoMutation] = useAddTodoMutation({
    refetchQueries: [
      { query: TodosDocument },
      { query: RecentTodosDocument },
      { query: GetJourneyByIdDocument, variables: { journeyId } },
      { query: GetJourneysDocument },
    ],
  });
  const [updateTodoMutation] = useUpdateTodoMutation({
    refetchQueries: [{ query: TodosDocument }, { query: RecentTodosDocument }],
  });

  useEffect(() => {
    if (date) {
      const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "long" });
      const dayOfMonth = date.getDate();
      setSelectedDay(dayOfWeek);
      setDayOfMonth(dayOfMonth);
    }
  }, [date]);

  const handleTitleChange = (title) => {
    setTitle(title);
  };

  const handleDescriptionChange = (description) => {
    setDescription(description);
  };

  const handleSelectPriority = (priority) => {
    setPriority(priority);
  };

  const handleDatePick = () => {
    setIsDatePickerVisible(true);
  };

  const handleDayOfMonthChange = (value) => {
    setDayOfMonth(value);
    const day = parseInt(value, 10);
    if (day < 1 || day > 31) {
      setDayOfMonthError("Day of the month must be between 1 and 31.");
    } else {
      setDayOfMonthError("");
    }
  };

  const handleSubmit = async () => {
    if (!title || !date) {
      // TODO handle incomplete form
      return;
    }
    let endOfDayDate = new Date(date);
    endOfDayDate.setHours(23, 59, 59, 0);
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    const variables = {
      name: title,
      description,
      priority,
      date: endOfDayDate,
      completed_date: null,
      isRecurring: repeat || null,
      recurringFrequency: repeatEvery,
      recurringDaily: repeatEvery === "Day",
      recurringDayOfWeek: repeatEvery === "Week" ? selectedDay : null,
      recurringDayOfMonth: repeatEvery === "Month" ? parseInt(dayOfMonth, 10) : null,
      recurringTime: timeValue,
      timeOfDay: timePeriod,
      timezone,
    };

    if (task) {
      await updateTodoMutation({
        variables: {
          ...variables,
          id: task.id,
          completed: task.completed,
          completed_date: new Date(),
          isPartOfJourney: task?.journeyId ? true : false,
          journeyId: task?.journeyId || null,
        },
      });
    } else {
      await addTodoMutation({
        variables: {
          ...variables,
          isPartOfJourney: journeyId ? true : false,
          journeyId: journeyId || null,
        },
      });
    }
    onComplete();
  };

  const handleDatePickerDismiss = useCallback(() => {
    setIsDatePickerVisible(false);
  }, [setIsDatePickerVisible]);

  const handleDatePickerConfirm = useCallback(
    (params) => {
      setIsDatePickerVisible(false);
      setDate(params.date);
    },
    [isDatePickerVisible, setIsDatePickerVisible]
  );

  const handleTimePeriodSelect = (value) => {
    setTimePeriod(value);
  };

  const handleDropdownChange = (value) => {
    setRepeatEvery(value);
  };

  const handleSelectedDayChange = (value) => {
    setSelectedDay(value);
  };

  return (
    <>
      <ScrollView
        showsVerticalScrollIndicator={false}
        style={styles.scrollView}
        contentContainerStyle={styles.contentContainer}>
        <TaskForm
          title={title}
          description={description}
          priority={priority}
          startDate={date}
          isEdit={!!task}
          onChangeTitle={handleTitleChange}
          onChangeDescription={handleDescriptionChange}
          onSelectPriority={handleSelectPriority}
          onDatePick={handleDatePick}
          onSubmit={handleSubmit}
          repeat={repeat}
          setRepeat={setRepeat}
          timePeriod={timePeriod}
          onSelectedTimePeriodChange={handleTimePeriodSelect}
          repeatEvery={repeatEvery}
          onDropdownChange={handleDropdownChange}
          selectedDay={selectedDay}
          onSelectedDayChange={handleSelectedDayChange}
          dayOfMonth={dayOfMonth}
          onDayOfMonthChange={handleDayOfMonthChange}
          timeValue={timeValue}
          setTimeValue={setTimeValue}
          dayOfMonthError={dayOfMonthError}
        />
      </ScrollView>
      <DatePickerModal
        locale="en"
        mode="single"
        label="Select date"
        visible={isDatePickerVisible}
        onDismiss={handleDatePickerDismiss}
        date={date}
        onConfirm={handleDatePickerConfirm}
      />
    </>
  );
};

export default TaskFormContainer;

const styles = StyleSheet.create({
  contentContainer: {
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  scrollView: {
    paddingHorizontal: 0,
  },
});
