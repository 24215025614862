import { useNavigation } from "@react-navigation/native";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import { LearnIcon } from "../../../svgs/menuIcons";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import { StyleSheet, View } from "react-native";
import { RightArrowSlim } from "../../../svgs/common";
import CustomText from "../../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";

const learnNodes = ["field", "subfield", "topic", "chapter"];
const learnUrls = ["Categories", "Tracks", "Lesson"];

const LearningBreadcrumbs = ({ links }) => {
  const { isMedium } = useResponsiveScreen();

  const navigation = useNavigation();

  const handleHomeClick = () => {
    navigation.navigate("Learn");
  };

  const handleLinkClick = (index) => {
    const newParams = links
      .slice(0, index + 1)
      .map((link, index) => {
        return { [learnNodes[index]]: link };
      })
      .reduce((acc, curr) => {
        return { ...acc, ...curr };
      }, {});

    navigation.navigate(learnUrls[index], newParams);
  };

  return (
    <View style={styles.container} aria-label="Learning breadcrumbs navigation">
      <View style={styles.row}>
        <CustomButton
          text=""
          size="xxl"
          style={{ padding: 0 }}
          styleType="primaryTransparent"
          onPress={handleHomeClick}
          leftIcon={<LearnIcon fill="#667085" height={18} width={18} />}
          aria-label="Navigate to Learn section"
        />
        {links.map((link, index) => {
          const isLast = index === links.length - 1;
          return (
            <View style={styles.row} key={link}>
              <RightArrowSlim />
              {isLast ? (
                <CustomText
                  useTranslationText={false}
                  text={link}
                  size="xs"
                  style={[styles.linkText, isMedium ? {} : { fontSize: 10 }]}
                />
              ) : (
                <CustomButton
                  useTranslationText={false}
                  text={link}
                  size="xs"
                  style={{ padding: 0 }}
                  styleType="primaryTransparent"
                  onPress={() => handleLinkClick(index)}
                  textStyle={[styles.linkButton, isMedium ? { fontSize: 12 } : { fontSize: 10 }]}
                  aria-label={`Navigate to ${link}`}
                />
              )}
            </View>
          );
        })}
      </View>
      <CustomSpacing size="l" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
  },
  linkButton: {
    color: "#667085",
    textDecorationLine: "underline",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: 24,
  },
  linkText: { color: "#667085", lineHeight: 24, marginTop: 1 },
});

export default LearningBreadcrumbs;
