import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import TaskDetailsContainer from "../TaskDetailsContainer/TaskDetailsContainer";
import { ScrollView } from "react-native";
function TaskDetailsModalContainer({ task, visible, onClose, onEdit, onDelete, onComplete }) {
  const { isMedium } = useResponsiveScreen();

  const modalStyle = isMedium
    ? { height: "auto", maxHeight: "90%", width: 600 }
    : { width: "auto", height: "auto", maxHeight: "90%" };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Task Details"
      style={modalStyle}>
      <ScrollView showsVerticalScrollIndicator={false} style={{ maxHeight: "100%" }}>
        <TaskDetailsContainer
          onDelete={onDelete}
          onEdit={onEdit}
          task={task}
          onComplete={onComplete}
          onClose={onClose}
        />
      </ScrollView>
    </CustomModal>
  );
}

export default TaskDetailsModalContainer;
