import CustomText from "../../common/general/CustomText/CustomText";
import { ListItem } from "@rneui/themed";
import { StyleSheet, Pressable } from "react-native";

const ContentMenuItem = ({ onItemPress, name, isSelected }) => {
  return (
    <ListItem.Content
      style={[
        styles.item,
        {
          backgroundColor: isSelected ? "#F2F5FF" : "#fff",
          flexDirection: "row",
          justifyContent: "flex-start",
          flexShrink: 1,
        },
      ]}>
      <Pressable onPress={onItemPress}>
        <CustomText
          useTranslationText={false}
          text={name}
          size="m"
          weight="normal"
          textType="text"
          numberOfLines={1}
          ellipsizeMode="tail"
          style={[
            styles.text,
            {
              maxWidth: 300,
              flexShrink: 1,
            },
          ]}
        />
      </Pressable>
    </ListItem.Content>
  );
};

const styles = StyleSheet.create({
  item: {
    padding: 10,
    paddingLeft: 33,
    backgroundColor: "#fff",
  },
  text: {
    fontSize: 16,
    color: "#000",
    textDecorationLine: "none",
  },
});

export default ContentMenuItem;
