import React, { createContext, useState, useEffect } from "react";
import { Dimensions } from "react-native";

const WindowDimensionsContext = createContext();

export const WindowDimensionsProvider = ({ children }) => {
  const [windowDimensions, setWindowDimensions] = useState(Dimensions.get("window"));

  // Define breakpoints
  const breakpoints = {
    isXSmall: windowDimensions.width <= 320,
    isSmall: windowDimensions.width > 320,
    isMedium: windowDimensions.width > 480,
    isLarge: windowDimensions.width > 768,
    isXLarge: windowDimensions.width > 1024,
    isXXLarge: windowDimensions.width > 1280,
  };

  useEffect(() => {
    const onChange = ({ window }) => {
      setWindowDimensions(window);
    };

    Dimensions.addEventListener("change", onChange);
    return () => Dimensions.removeEventListener("change", onChange);
  }, []);

  // Functions to calculate width and height percentages
  const wp = (percentage) => {
    const width = windowDimensions.width;
    return (width * percentage) / 100;
  };

  const hp = (percentage) => {
    let height = windowDimensions.height;
    if (height > 1100) {
      height = 1100;
    }
    return (height * percentage) / 100;
  };

  return (
    <WindowDimensionsContext.Provider value={{ ...windowDimensions, ...breakpoints, wp, hp }}>
      {children}
    </WindowDimensionsContext.Provider>
  );
};

export default WindowDimensionsContext;
