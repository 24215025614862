// useResponsiveScreen.js
import { useContext } from "react";
import WindowDimensionsContext from "../contexts/WindowDimensionsContext";

const useResponsiveScreen = () => {
  const context = useContext(WindowDimensionsContext);

  if (context === undefined) {
    throw new Error("useResponsiveScreen must be used within a WindowDimensionsProvider");
  }

  return context;
};

export default useResponsiveScreen;
