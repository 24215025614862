import * as MiniLogos from "../../../svgs/miniLogos";

function MiniLogo({ name, ...props }) {
  switch (name) {
    case "primer":
      return <MiniLogos.PrimerMiniLogo {...props} />;
    case "axio":
      return <MiniLogos.AxioMiniLogo {...props} />;
    default:
      return null;
  }
}

export default MiniLogo;
