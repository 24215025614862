import React from "react";
import { useWindowDimensions } from "react-native";
import RenderHtml from "react-native-render-html";

const CustomHtml = ({ html, ...props }) => {
  const { width } = useWindowDimensions();

  return <RenderHtml contentWidth={width} source={{ html }} tagsStyles={customStyles} {...props} />;
};

const customStyles = {
  body: {
    fontFamily: "Inter",
    fontSize: 16,
    color: "#344054",
    lineHeight: 24,
    paragraphSpacing: 16,
  },
  h1: {
    fontSize: 24,
    fontWeight: "bold",
    color: "#344054",
  },
  p: {
    fontFamily: "Inter",
    fontSize: 16,
    lineHeight: 24,
    color: "#344054",
    paragraphSpacing: 16,
  },
  a: {
    color: "#1e90ff",
    textDecorationLine: "underline",
  },
  // Add more styles for other tags as needed
};

export default CustomHtml;
