import { useState } from "react";
import { View, StyleSheet } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  useDeleteMessageMutation,
  TodayChatDocument,
  DateForChatDocument,
  useGetLearningTreeQuery,
  useEnrollUserInTrackMutation,
  MeDocument,
  useMeQuery,
} from "../../../graphql/generated/graphql";
import useWebSocket from "../../../hooks/useWebSocket";
import { useNavigation, useRoute } from "@react-navigation/native";
import AIMessage from "../../../components/chat/AIMessage/AIMessage";
import UserMessage from "../../../components/chat/UserMessage/UserMessage";
import LearningMessage from "../../../components/chat/LearningMessage/LearningMessage";
import AspectMessage from "../../../components/chat/AspectMessage/AspectMessage";
import AspectModal from "../../../components/chat/AspectModal/AspectModal";
import AspectFormContainer from "../../chat/AspectFormContainer/AspectFormContainer";
import QuestMessage from "../../../components/chat/QuestMessage/QuestMessage";
import QuestModal from "../../../components/gamification/QuestModal/QuestModal";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import ConfirmationModal from "../../../components/general/ConfirmationModal/ConfirmationModal";
import { EditIcon } from "../../../components/svgIcons";
import Sentry from "../../../utils/sentry";

// Date is only being passed in here until it is a part of the message object
function JournalMessageContainer({ message, date }) {
  const { sendMessage } = useWebSocket();
  const navigation = useNavigation();
  const router = useRoute();
  const { data: meData } = useMeQuery({});
  const [enrollUserInTrack] = useEnrollUserInTrackMutation({
    refetchQueries: [
      {
        query: MeDocument,
      },
    ],
  });

  const [deleteMessage] = useDeleteMessageMutation({
    refetchQueries: [
      {
        query: TodayChatDocument,
        variables: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          context: router.name,
          params: router.params,
        },
      },
      {
        query: DateForChatDocument,
        variables: {
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          date,
        },
      },
    ],
  });

  const level = message.parameters?.lastGeneratedLabel;
  const name = message.parameters?.lastGeneratedTopic;
  const type = message.type || message.sender || null;
  const enrolledTracks = meData?.me?.enrolledTracks || [];

  const {
    data: learningTreeData,
    loading,
    error,
  } = useGetLearningTreeQuery({
    variables: { level, name },
    skip: !level || !name,
  });

  const [confirmationModalVisible, setConfirmationModalVisible] = useState(false);
  const [aspectModalVisible, setAspectModalVisible] = useState(false);
  const [questModalVisible, setQuestModalVisible] = useState(false);

  const handleOpenConfirmationModal = () => {
    setConfirmationModalVisible(true);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationModalVisible(false);
  };

  const handleDeleteMessage = async (id) => {
    await deleteMessage({
      variables: {
        id,
      },
    });

    handleCloseConfirmationModal();
  };

  const handleOpenAspectModal = () => {
    setAspectModalVisible(true);
  };

  const handleCloseAspectModal = () => {
    setAspectModalVisible(false);
  };

  const handleOpenQuestModal = () => {
    setQuestModalVisible(true);
  };

  const handleCloseQuestModal = () => {
    setQuestModalVisible(false);
  };

  const handleLearningPress = async () => {
    const token = await AsyncStorage.getItem("token");
    sendMessage({
      meta: {
        token: token,
        abort: true,
      },
      state: {},
      content: {},
    });

    if (loading) return;
    if (error) {
      Sentry.captureException(error);
      return;
    }

    const tree = learningTreeData?.getLearningTree;

    if (!tree) {
      Sentry.captureException("No learning tree data available");
      return;
    }

    switch (level) {
      case "Field":
        navigation.navigate("Categories", { field: tree.FieldName });
        break;
      case "Subfield":
        navigation.navigate("Tracks", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
        });
        break;
      case "Topic":
        if (enrolledTracks.includes(name)) {
          navigation.navigate("Lesson", {
            field: tree.FieldName,
            subfield: tree.SubfieldName,
            topic: tree.TopicName,
          });
        } else {
          navigation.navigate("Track Overview", {
            field: tree.FieldName,
            subfield: tree.SubfieldName,
            topic: tree.TopicName,
          });
        }
        break;
      case "Chapter": {
        const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(tree.TopicName);

        if (!isUserAlreadyEnrolled) {
          enrollUserInTrack({
            variables: {
              topicName: tree.TopicName,
            },
          });
        }

        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
          chapter: tree.ChapterName,
          ...(tree.SubchapterName && { subchapter: tree.SubchapterName }),
        });
        break;
      }
      case "Subchapter": {
        const isUserAlreadyEnrolled = meData.me?.enrolledTracks?.includes(tree.TopicName);

        if (!isUserAlreadyEnrolled) {
          enrollUserInTrack({
            variables: {
              topicName: tree.TopicName,
            },
          });
        }

        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
          chapter: tree.ChapterName,
          subchapter: tree.SubchapterName,
        });
        break;
      }
      default:
        Sentry.captureException(`Unhandled learning type: ${level}`);
    }
  };

  switch (type) {
    case "ai":
      return (
        <>
          <CustomSpacing type="vertical" size="xs" />
          <View style={styles.messageWrapper}>
            <CustomButton
              leftIcon={<EditIcon />}
              styleType="transparent"
              onPress={() => handleOpenConfirmationModal(message.id)}
              aria-label="Delete message"
            />
            <AIMessage text={message.content} />
          </View>
          <CustomSpacing type="vertical" size="xs" />

          <ConfirmationModal
            message="Are you sure you want to delete this message?"
            visible={confirmationModalVisible}
            onClose={handleCloseConfirmationModal}
            onConfirm={() => handleDeleteMessage(message.id)}
          />
        </>
      );
    case "human":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <View style={styles.messageWrapper}>
            <CustomButton
              leftIcon={<EditIcon />}
              styleType="transparent"
              onPress={() => handleOpenConfirmationModal(message.id)}
              aria-label="Delete message"
            />
            <UserMessage text={message.content} />
          </View>
          <CustomSpacing type="vertical" size="m" />

          <ConfirmationModal
            message="Are you sure you want to delete this message?"
            visible={confirmationModalVisible}
            onClose={handleCloseConfirmationModal}
            onConfirm={() => handleDeleteMessage(message.id)}
          />
        </>
      );
    case "aspect":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <AspectMessage message={message} onPress={handleOpenAspectModal} />
          <CustomSpacing type="vertical" size="m" />

          <AspectModal message={message} visible={aspectModalVisible} onClose={handleCloseAspectModal}>
            <CustomSpacing type="vertical" size="m" />
            <AspectFormContainer message={message} onClose={handleCloseAspectModal} />
          </AspectModal>
        </>
      );
    case "quest":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <QuestMessage type={message.snackType} onPress={handleOpenQuestModal} />
          <CustomSpacing type="vertical" size="m" />

          <QuestModal
            type={message.snackType}
            userResponse={message.parameters.userResponse}
            visible={questModalVisible}
            onClose={handleCloseQuestModal}
          />
        </>
      );
    case "snack":
      return (
        <>
          <CustomSpacing type="vertical" size="m" />
          <LearningMessage
            topic={message.parameters.lastGeneratedTopic}
            label={message.parameters.lastGeneratedLabel}
            onPress={handleLearningPress}
          />
          <CustomSpacing type="vertical" size="m" />
        </>
      );
    default:
      return <></>;
  }
}

const styles = StyleSheet.create({
  messageWrapper: {
    flexDirection: "row",
    alignItems: "flex-start",
  },
});

export default JournalMessageContainer;
