import React from "react";
import { StyleSheet, View, ImageBackground, Linking } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import VideoPlay from "../../svgs/common/VideoPlay";
import Sentry from "../../../utils/sentry";

const VideoSuggestionCard = ({ imageUrl, url, style }) => {
  const handlePress = () => {
    Linking.openURL(url).catch((error) => {
      Sentry.captureException(error);
    });
  };

  const styles = StyleSheet.create({
    container: {
      width: "100%",
      height: "100%",
      borderRadius: 8,
      overflow: "hidden",
    },
    imageContainer: {
      flex: 1,
    },
    image: {
      flex: 1,
      resizeMode: "cover",
    },
    button: {
      backgroundColor: "rgba(0, 0, 0, 0.6)",
      borderRadius: 24,
      width: 36,
      height: 36,
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: [{ translateX: -18 }, { translateY: -18 }],
      justifyContent: "center",
      alignItems: "center",
    },
  });

  return (
    <View style={[styles.container, style]}>
      <View style={styles.imageContainer}>
        <ImageBackground style={styles.image} source={{ uri: imageUrl }}>
          <CustomButton
            onPress={handlePress}
            leftIcon={<VideoPlay />}
            styleType="light"
            style={styles.button}
            aria-label="Play Video"
          />
        </ImageBackground>
      </View>
    </View>
  );
};

export default VideoSuggestionCard;
