import { StyleSheet } from "react-native";
import LottieView from "lottie-react-native";
import { MotiView } from "moti";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function OnboardBegin({ onBeginPress }) {
  return (
    <>
      <MotiView
        from={{
          opacity: 0,
          scale: 0,
          translateY: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
          translateY: 0,
        }}
        delay={1000}
        transition={{
          duration: 1000,
        }}
        exit={{ opacity: 0, scale: 0, translateY: 0 }}
        style={styles.topContainer}>
        <LottieView
          loop={true}
          autoPlay={true}
          style={styles.beginLottie}
          source={require("../../../assets/audio-lottie.json")}
        />
        <CustomText
          text="For the best experience, please turn on your sound to hear voice guidance."
          size="xxl"
          style={styles.beginText}
        />
      </MotiView>
      <CustomSpacing type="vertical" size="xl" />

      <MotiView
        from={{
          opacity: 0,
          scale: 0,
          translateY: 0,
        }}
        animate={{
          scale: 1,
          opacity: 1,
          translateY: 0,
        }}
        delay={3000}
        transition={{
          type: "timing",
          duration: 500,
        }}
        exit={{ opacity: 0, scale: 0, translateY: 0 }}>
        <CustomButton
          text="Get Started"
          styleType="primary"
          size="l"
          style={styles.beginButton}
          onPress={onBeginPress}
        />
      </MotiView>
    </>
  );
}

const styles = StyleSheet.create({
  topContainer: {
    alignItems: "center",
    justifyContent: "center",
    margin: 20,
  },
  beginLottie: {
    width: 60,
    height: 60,
  },
  beginText: {
    fontFamily: "Inter",
    fontWeight: 100,
    textAlign: "center",
  },
  beginButton: {
    borderRadius: 30,
  },
});

export default OnboardBegin;
