import { useAppState, actions } from "../../../contexts/AppStateContext";
import MobileHeader from "../../../components/navigation/MobileHeader/MobileHeader";

function MobileHeaderContainer({ isShowLearn, isShowCustomCoursework }) {
  const { state, dispatch } = useAppState();

  const handleMenuOpenPress = () => {
    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: true,
        isExpanded: false,
        isLearnMenuOpen: false,
        isCustomCourseworkMenuOpen: false,
      },
    });
  };

  const handleMenuClosePress = () => {
    dispatch({
      type: actions.SET_META,
      payload: {
        isMainMenuExpanded: false,
        isExpanded: false,
        isLearnMenuOpen: false,
        isCustomCourseworkMenuOpen: false,
      },
    });
  };

  const handleLearnOpenPress = () => {
    dispatch({
      type: actions.SET_META,
      payload: {
        isLearnMenuOpen: true,
        isMainMenuExpanded: false,
        isExpanded: false,
      },
    });
  };

  const handleCustomCourseworkOpenPress = () => {
    dispatch({
      type: actions.SET_META,
      payload: {
        isCustomCourseworkMenuOpen: true,
        isMainMenuExpanded: false,
        isExpanded: false,
      },
    });
  };

  return (
    <MobileHeader
      isMenuOpen={state.meta.isMainMenuExpanded}
      isShowLearn={isShowLearn}
      isShowCustomCoursework={isShowCustomCoursework}
      isLearnOpen={state.meta.isLearnMenuOpen}
      onMenuOpenPress={handleMenuOpenPress}
      onMenuClosePress={handleMenuClosePress}
      onLearnOpenPress={handleLearnOpenPress}
      onCustomCourseworkPress={handleCustomCourseworkOpenPress}
      isCustomCourseworkOpen={state.meta.isCustomCourseworkMenuOpen}
    />
  );
}

export default MobileHeaderContainer;
