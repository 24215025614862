import { Svg, Path } from "react-native-svg";
const GraduationCap = (props) => (
  <Svg xmlns="http://www.w3.org/2000/svg" width={22} height={20} fill="none" {...props}>
    <Path
      fill="#fff"
      d="M21.488 6.138 11.53.828a.664.664 0 0 0-.624 0L.949 6.137a.664.664 0 0 0 0 1.172l2.304 1.229v4.017c-.001.326.119.641.337.884 1.087 1.21 3.522 3.241 7.629 3.241a10.789 10.789 0 0 0 3.982-.727v2.718a.664.664 0 1 0 1.328 0v-3.36a9.593 9.593 0 0 0 2.319-1.872 1.32 1.32 0 0 0 .336-.884V8.54l2.304-1.23a.664.664 0 0 0 0-1.171Zm-10.27 9.215c-3.59 0-5.702-1.754-6.638-2.797v-3.31l6.327 3.374a.664.664 0 0 0 .624 0l3.67-1.957v3.845c-1.045.488-2.363.845-3.982.845Zm6.639-2.8a8.122 8.122 0 0 1-1.328 1.182v-3.78l1.328-.709v3.307Zm-1.66-3.926-.018-.01-4.647-2.479a.664.664 0 0 0-.624 1.172l3.879 2.07-3.568 1.902-8.547-4.558 8.547-4.558 8.546 4.558-3.568 1.903Z"
    />
  </Svg>
);
export default GraduationCap;
