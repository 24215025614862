export const getWelcomeOnboardingModalData = (name, companionName) => {
  return {
    step1: {
      title: `Hi ${name}, Welcome to ${companionName}!`,
      description: `${companionName} is an AI assistant that evolves with you over time, supporting you in areas such as productivity, and learning. To help you get started, ${companionName} has created a few tasks for you.`,
      image: require("../assets/onboardingModals/Welcome.png"),
      aspectRatio: 1,
      imageHeight: 219,
    },
  };
};
