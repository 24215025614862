import { View, StyleSheet } from "react-native";
import BlankLayout from "../layouts/BlankLayout";
import BackgroundVideo from "../components/general/BackgroundVideo/BackgroundVideo.web";
import ResetPasswordContainer from "../containers/auth/ResetPasswordContainer/ResetPasswordContainer";

function ResetPasswordView() {
  return (
    <BlankLayout>
      <BackgroundVideo />

      <View style={styles.container}>
        <View style={styles.formContainer}>
          <ResetPasswordContainer />
        </View>
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
  formContainer: {
    width: "50%",
    justifyContent: "center",
    alignItems: "center",
  },
});

export default ResetPasswordView;
