import React from "react";
import { StyleSheet, View } from "react-native";
import JourneyCard from "../JourneyCard/JourneyCard";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import Loader from "../../common/status/Loader/Loader";

const JourneyCardList = ({ journeys, onJourneyPress, journeysLoading }) => {
  if (journeysLoading) {
    return (
      <>
        <CustomSpacing type="vertical" />
        <Loader />
        <CustomSpacing type="vertical" />
      </>
    );
  }

  return (
    <View>
      <View style={styles.container}>
        {journeys?.map((card) => (
          <JourneyCard key={card.id} card={card} onJourneyPress={onJourneyPress} />
        ))}
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    justifyContent: "flex-start",
  },
});

export default JourneyCardList;
