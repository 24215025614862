import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomModal from "../../../components/common/general/CustomModal/CustomModal";
import EventFormContainer from "../EventFormContainer/EventFormContainer";

function EventFormModalContainer({ event, visible, onClose, onEventCreated }) {
  const { isMedium } = useResponsiveScreen();

  const modalStyle = isMedium
    ? { height: "auto", maxHeight: "90%" }
    : { width: "100%", height: "auto", maxHeight: "90%" };

  return (
    <CustomModal
      visible={visible}
      onRequestClose={onClose}
      showCloseButton={true}
      title="Event Details"
      style={modalStyle}>
      <EventFormContainer event={event} onComplete={onClose} onEventCreated={onEventCreated} />
    </CustomModal>
  );
}

export default EventFormModalContainer;
