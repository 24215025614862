import { View, StyleSheet } from "react-native";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import {
  NodeGraph,
  NodeAchievement,
  NodeEducation,
  NodeEvent,
  NodeGoal,
  NodeHealth,
  NodeInterest,
  NodeSocial,
  NodeWork,
} from "../../svgIcons";

function AspectModal({ message, visible, onClose, children }) {
  function renderIcon(label) {
    switch (label.toLowerCase()) {
      case "achievements":
        return <NodeAchievement />;
      case "educationhistory":
        return <NodeEducation />;
      case "events":
        return <NodeEvent />;
      case "goals":
        return <NodeGoal />;
      case "waterintake":
        return <NodeHealth />;
      case "fooditem":
        return <NodeHealth />;
      case "exerciseitem":
        return <NodeHealth />;
      case "interests":
        return <NodeInterest />;
      case "socialconnections":
        return <NodeSocial />;
      case "employmenthistory":
        return <NodeWork />;
      default:
        return <NodeGraph />;
    }
  }

  function renderLabel(keyword) {
    switch (keyword.toLowerCase()) {
      case "interests":
        return "Interest Added";
      case "learninginterests":
        return "Learning Interest Added";
      case "socialconnections":
        return "Social Connection Added";
      case "goals":
        return "Goal Added";
      case "hobbies":
        return "Hobby Added";
      case "achievements":
        return "Achievement Added";
      case "fooditem":
        return "Food Item Added";
      case "exerciseitem":
        return "Exercise Item Added";
      case "waterintake":
        return "Water Intake Added";
      case "corevalues":
        return "Core Value Added";
      case "lifeaspirations":
        return "Life Aspiration Added";
      case "challenges":
        return "Challenge Added";
      case "reflections":
        return "Reflection Added";
      case "emotionalstates":
        return "Emotional State Added";
      case "physicalattributes":
        return "Physical Attribute Added";
      case "personaldetails":
        return "Personal Detail Added";
      case "employmenthistory":
        return "Employment History Added";
      case "educationhistory":
        return "Education History Added";
      case "assessment":
        return "Assessment Added";
      default:
        return "Node Added";
    }
  }

  return (
    <CustomModal visible={visible} onRequestClose={onClose} showCloseButton={true}>
      <View style={styles.headerContainer}>
        <View>{renderIcon(message.snackType)}</View>
        <CustomSpacing type="horizontal" size="m" />
        <View style={styles.textContainer}>
          <CustomText
            text={renderLabel(message.snackType)}
            weight="bold"
            size="xxl"
            numberOfLines={1}
            ellipsizeMode="tail"
          />
        </View>
      </View>
      {children}
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  headerContainer: {
    position: "absolute",
    top: 25,
    left: 20,
    right: 70,
    flexDirection: "row",
    alignItems: "center",
  },
  textContainer: { flexShrink: 1 },
});

export default AspectModal;
