import { Text } from "react-native";
import { useWaterIntakeByDateQuery } from "../../../graphql/generated/graphql";
import HydrationAnimation from "../../../components/health/HydrationAnimation/HydrationAnimation";

function HydrationAnimationContainer({ date }) {
  const { data, loading, error } = useWaterIntakeByDateQuery({
    variables: {
      date: date,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
  });

  if (loading) return <Text>Loading...</Text>;
  if (error) return <Text>Error...</Text>;

  return (
    <HydrationAnimation value={data.getWaterIntakeByDate.totalWaterIntake} goal={data.getWaterIntakeByDate.goalOz} />
  );
}

export default HydrationAnimationContainer;
