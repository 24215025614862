import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import { TodosIcon } from "../../svgs/menuIcons";
import { RightArrowSlim } from "../../svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const JourneyBreadcrumbs = ({ onPress, firstItem = "Journeys", secondItem }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View style={styles.container} aria-label="Journey Navigation structure">
      <View style={styles.row}>
        <TodosIcon height={18} width={18} fill="#667085" />
        <RightArrowSlim />
        <CustomButton
          useTranslationText={false}
          text={firstItem}
          size="xs"
          style={{ padding: 0 }}
          styleType="primaryTransparent"
          onPress={onPress}
          textStyle={[styles.linkButton, isMedium ? { fontSize: 12 } : { fontSize: 10 }]}
          aria-label={`Link to ${firstItem}`}
        />
        <RightArrowSlim />
        <CustomText
          useTranslationText={false}
          text={secondItem}
          size="xs"
          style={[styles.linkText, isMedium ? {} : { fontSize: 10 }]}
        />
      </View>
      <CustomSpacing size="l" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
  },
  row: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
  },
  linkButton: {
    color: "#667085",
    textDecorationLine: "underline",
    fontFamily: "Inter",
    fontWeight: "400",
    lineHeight: 24,
  },
  linkText: { color: "#667085", lineHeight: 24, marginTop: 1 },
});

export default JourneyBreadcrumbs;
