import { StyleSheet } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import CourseCard from "../CourseCard/CourseCard";

function SchoolCardList({ courses = [], onPress }) {
  return (
    <ScrollView
      role="grid"
      aria-label="School card list"
      horizontal={true}
      height={200}
      showsHorizontalScrollIndicator={false}
      style={styles.scrollView}>
      {courses.map((course, index) => (
        <CourseCard
          key={index}
          title={course?.teamName}
          category={null}
          duration={null}
          description={null}
          fillPercentage={null}
          logoUri={course?.logoUri}
          label={course?.providerName}
          onPress={() => onPress(course?.id)}
        />
      ))}
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  scrollView: {
    flex: 1,
  },
});

export default SchoolCardList;
