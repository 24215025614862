import { MotiView } from "moti";
import SuggestionCard from "../SuggestionCard/SuggestionCard";
import { StyleSheet, ScrollView } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function SuggetionsCardList({ data, onPress }) {
  const { isMedium } = useResponsiveScreen();

  return (
    <ScrollView
      horizontal={!isMedium}
      showsHorizontalScrollIndicator={false}
      style={isMedium ? styles.verticalScroll : styles.horizontalScroll}
      role="list"
      aria-label="List of suggestions">
      <MotiView
        from={{ opacity: 0, translateY: 20, scale: 0.95 }}
        animate={{ opacity: 1, translateY: 0, scale: 1 }}
        delay={100}
        transition={{ type: "timing", duration: 800 }}
        style={[styles.motiContainer, isMedium ? {} : {}]}>
        {data?.map((suggestion, id) => (
          <SuggestionCard key={id} content={suggestion.content} type={suggestion.type} onPress={onPress} />
        ))}
      </MotiView>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  motiContainer: {
    flexDirection: "row",
    // justifyContent: "space-around",
  },
  horizontalScroll: {
    flexDirection: "none",
  },
  verticalScroll: {
    flexDirection: "column",
  },
});

export default SuggetionsCardList;
