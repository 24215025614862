import { Svg, G, Path, Defs, ClipPath, Rect } from "react-native-svg";
import { v4 as uuidv4 } from "uuid";

function MenuCircle({ fill = "#000", ...props }) {
  const id1 = uuidv4();

  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath={`url(#${id1})`}>
        <Path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill={fill}
        />
        <Path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.75 12.5C13.75 11.8097 14.3096 11.25 15 11.25H25C25.6904 11.25 26.25 11.8097 26.25 12.5C26.25 13.1904 25.6904 13.75 25 13.75H15C14.3096 13.75 13.75 13.1904 13.75 12.5ZM10 20C10 19.3096 10.5597 18.75 11.25 18.75H28.75C29.4404 18.75 30 19.3096 30 20C30 20.6904 29.4404 21.25 28.75 21.25H11.25C10.5597 21.25 10 20.6904 10 20ZM15 26.25C14.3096 26.25 13.75 26.8096 13.75 27.5C13.75 28.1904 14.3096 28.75 15 28.75H25C25.6904 28.75 26.25 28.1904 26.25 27.5C26.25 26.8096 25.6904 26.25 25 26.25H15Z"
          fill="#FFFFFD"
        />
      </G>
      <Defs>
        <ClipPath id={id1}>
          <Rect width="40" height="40" fill="white" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default MenuCircle;
