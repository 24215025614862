import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import { RedInfo } from "../../svgs/common";
import { shadowStyles } from "../../../styles/shadow";

const DeleteAccount = ({ cancelButtonClick, deleteButtonClick }) => {
  const [inputText, setInputText] = useState("");

  const handleDeleteClick = () => {
    deleteButtonClick(inputText);
  };

  return (
    <View style={styles.container}>
      <View style={[shadowStyles.smallShadow, styles.warningBox]}>
        <View style={{ marginRight: 10 }}>
          <RedInfo />
        </View>
        <CustomText
          style={styles.warningBoxText}
          size="xs"
          text='Are you sure you want to delete your account? All your data, including conversations, preferences, and learning history, will be permanently deleted. Type "DELETE" in the box below to confirm.'
        />
      </View>
      <View style={styles.textInputContainer}>
        <CustomTextInput
          placeholder="Type DELETE here"
          onChangeText={(text) => {
            setInputText(text);
          }}
          value={inputText}
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          text="Delete Account"
          onPress={handleDeleteClick}
          styleType="danger"
          size="m"
          style={{ marginBottom: 10, aliignItems: "center" }}
          bold="true"
        />
        <CustomButton
          text="Cancel"
          onPress={cancelButtonClick}
          styleType="primaryLight"
          size="m"
          style={styles.backButton}
          bold="true"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderRadius: 10,
  },
  warningBox: {
    padding: 10,
    backgroundColor: "rgba(247, 92, 138, 0.2)",
    borderRadius: 10,
    marginBottom: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  warningBoxText: {
    color: "#F75C8A",
  },
  textInputContainer: {
    marginBottom: 30,
  },
});

export default DeleteAccount;
