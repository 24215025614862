import DefaultLayout from "../layouts/DefaultLayout";
import { useAppState, actions } from "../contexts/AppStateContext";
import React, { useState } from "react";
import { View, StyleSheet, ActivityIndicator, Alert } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import { MotiView } from "moti";
import { EditIcon, Trash } from "../components/svgIcons";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { handleExportCustomCoursePress } from "./handleExportCustomCoursePress";
import { WebView } from "react-native-webview";
import Sentry from "../utils/sentry";
import DeleteAssignmentOrPageModalContainer from "../components/content/ContentModalContainers/DeleteAssignmentOrPageModalContainer";
import {
  GetCourseDocument,
  useDeleteAssignmentMutation,
  useDeletePageMutation,
  useEditAssignmentMutation,
  useGetCourseQuery,
  useUpdatePageMutation,
} from "../graphql/generated/graphql";
import { formatDate } from "../utils/date";
import EditAddCustomAssignmentOrPageModal from "../containers/customCoursework/EditAddCustomAssignmentOrPageModal";

function CustomCourseworkModulesView({ route }) {
  const { dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();

  const [deleteAssignmentModalVisible, setDeleteAssignmentModalVisible] = useState(false);
  const [editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible] = useState(null);

  const courseId = route.params?.courseId;

  const { data, loading } = useGetCourseQuery({
    variables: {
      id: courseId,
    },
  });

  const [deleteAssignmentMutation] = useDeleteAssignmentMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: courseId,
        },
      },
    ],
  });

  const [deletePageMutation] = useDeletePageMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: courseId,
        },
      },
    ],
  });

  const [editAssignmentMutation] = useEditAssignmentMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: courseId,
        },
      },
    ],
  });

  const [editPageMutation] = useUpdatePageMutation({
    refetchQueries: [
      {
        query: GetCourseDocument,
        variables: {
          id: courseId,
        },
      },
    ],
  });

  const handleDeleteAssignmentModalClose = () => {
    setDeleteAssignmentModalVisible(false);
  };

  const handleDeleteAssignmentModalOpen = () => {
    setDeleteAssignmentModalVisible(true);
  };

  const handleEditAssignmentOrPageModalOpen = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setEditAssignmentOrPageModalVisible(true);
  };
  const handleEditAssignmentOrPageModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setEditAssignmentOrPageModalVisible(false);
  };

  const moduleIndex = route.params?.moduleIndex || 0;

  const isAssignment = route.params?.assignmentIndex !== undefined;
  const isPage = route.params?.pageIndex !== undefined;

  const assignmentIndex = route.params?.assignmentIndex || 0;
  const pageIndex = route.params?.pageIndex || 0;

  if (loading) {
    return (
      <View style={styles.loaderContainer}>
        <ActivityIndicator size="large" color="#0000ff" />
      </View>
    );
  }

  const courseName = data?.getCourse?.data?.[0]?.title || "No Course Name";

  const module = data?.getCourse?.data?.[0]?.modules?.[moduleIndex];

  const assignment = module?.resources[0]?.assignments[assignmentIndex];

  const page = module?.resources[0]?.pages[pageIndex];

  const isValidItem = !!assignment || !!page;
  const selectedType = isAssignment ? "Assignment" : isPage ? "Page" : "Module";
  const activeItemType = selectedType === "Module" && isValidItem ? (assignment ? "Assignment" : "Page") : selectedType;
  const isAssignmentOrPageActive = activeItemType === "Assignment" || activeItemType === "Page";
  const currentItemName =
    activeItemType === "Assignment" ? assignment?.title : activeItemType === "Page" ? page?.title : "No resources";
  const moduleName = module?.title || "No Module Name";

  const handleDeleteAssignmentOrPage = async (type) => {
    try {
      let response;
      if (type === "Assignment") {
        response = await deleteAssignmentMutation({ variables: { id: assignment.id } });
      } else {
        response = await deletePageMutation({ variables: { id: page.id } });
      }
      if (response?.data?.deleteAssignment?.success || response?.data?.deletePage?.success) {
        Alert.alert("Success", `${type} deleted successfully`);
        handleDeleteAssignmentModalClose();
      } else {
        Sentry.captureMessage(`Failed to delete ${type}`);
        Sentry.captureException(response.data.deleteAssignment.message || response.data.deletePage.message);
        Alert.alert(
          "Error",
          response.data.deleteAssignment.message || response.data.deletePage.message || `Failed to delete ${type}`
        );
      }
    } catch (error) {
      Sentry.captureException(error);
      Alert.alert("Error", `An error occurred while deleting the ${type}.`);
    }
  };

  const handleEditAssignmentOrPage = async (type, html, newName, dueDate) => {
    const cleanedHtml = html.replace(/\n\s+/g, "").trim();
    const encodedHtml = encodeURIComponent(cleanedHtml);

    try {
      if (type === "Assignment") {
        await editAssignmentMutation({
          variables: {
            id: assignment.id,
            title: newName,
            body: encodedHtml,
            dueDate: dueDate,
          },
        });
      } else {
        await editPageMutation({
          variables: {
            id: page.id,
            title: newName,
            body: encodedHtml,
          },
        });
      }

      handleEditAssignmentOrPageModalClose();
    } catch (error) {
      Sentry.captureException(error);
      console.error(isNewItem ? `Error adding ${type}` : `Error updating ${type}`, error);
    }
  };

  return (
    <DefaultLayout menuType="customCoursework" withPadding={false}>
      <View
        style={[
          styles.topContainer,
          {
            borderBottomWidth: isMedium ? 1 : 0,
          },
        ]}>
        <CustomText
          textType="display"
          size="xs"
          weight="ultraBold"
          useTranslationText={false}
          text={courseName}
          style={{ color: "#101828" }}
        />

        <CustomButton
          text="Export Course"
          textStyle={{ color: "white", fontSize: 12, fontWeight: 700 }}
          size="s"
          style={[styles.exportButton, { marginLeft: isMedium ? "0" : "auto" }]}
          bold={true}
          onPress={() => handleExportCustomCoursePress(data?.getCourse?.data?.[0])}
          aria-label="Export Course Data"
          accessibilityHint="Press to export data"
        />
      </View>

      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}
        style={styles.titleContainer}>
        <CustomSpacing type="vertical" size="xl" />
        <View
          style={{
            display: "flex",
            flexDirection: isMedium ? "row" : "column",
            gap: isMedium ? 8 : 14,
            alignItems: isMedium ? "center" : "flex-start",
            justifyContent: "space-between",
          }}>
          <CustomText
            useTranslationText={false}
            text={currentItemName}
            size="xl"
            weight="bold"
            numberOfLines={1}
            ellipsizeMode="tail"
            style={{ color: "#101828" }}
          />
          {isAssignmentOrPageActive && (
            <View
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 8,
                alignItems: "center",
                justifyContent: "flex-end",
              }}>
              <CustomButton
                text="Edit"
                leftIcon={<EditIcon style={{ marginRight: 7 }} color={"#3e68fe"} height="17" width="16" />}
                styleType="primaryLight"
                size="s"
                style={[styles.actionButton, { marginLeft: isMedium ? "0" : "auto" }]}
                bold={true}
                onPress={handleEditAssignmentOrPageModalOpen}
                aria-label={`Edit ${activeItemType}`}
                accessibilityHint={`Press to edit ${activeItemType}`}
              />

              <CustomButton
                text="Delete"
                leftIcon={<Trash style={{ marginRight: 7 }} color={"#3e68fe"} />}
                styleType="primaryLight"
                size="s"
                style={[styles.actionButton, { marginLeft: isMedium ? "0" : "auto" }]}
                bold={true}
                onPress={handleDeleteAssignmentModalOpen}
                aria-label={`Delete ${activeItemType}`}
                accessibilityHint={`Press to delete ${activeItemType}`}
              />
            </View>
          )}
        </View>
        <CustomSpacing type="vertical" size="xl" />
      </MotiView>

      <View style={{ paddingHorizontal: 20 }}>
        <CustomSpacing size="m" type="vertical" />
        <View style={styles.divider} />
        <CustomSpacing size="m" type="vertical" />
      </View>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={300}
        transition={{ type: "timing", duration: 400 }}
        style={styles.contentContainer}>
        {isAssignmentOrPageActive ? (
          <>
            {activeItemType === "Assignment" ? (
              <View style={styles.htmlContainer}>
                <WebView
                  originWhitelist={["*"]}
                  source={{ html: assignment?.body || "<p>No content available</p>" }}
                  style={{ height: 400 }}
                />
                <CustomSpacing type="vertical" size="l" />
                <CustomText text="Due Date" size="m" weight="bold" />
                <CustomSpacing type="vertical" size="s" />
                <CustomText
                  useTranslationText={false}
                  text={formatDate(assignment?.due_date) || "No due date"}
                  size="m"
                />
              </View>
            ) : (
              <View style={styles.htmlContainer}>
                <WebView
                  originWhitelist={["*"]}
                  source={{ html: page?.body || "<p>No content available</p>" }}
                  style={{ height: 400 }}
                />
              </View>
            )}
          </>
        ) : (
          <View style={styles.htmlContainer}>
            <CustomText text="No content available" />
          </View>
        )}
      </MotiView>

      <View>
        <DeleteAssignmentOrPageModalContainer
          visible={deleteAssignmentModalVisible}
          onClose={handleDeleteAssignmentModalClose}
          onDeleteAssignmentOrPage={handleDeleteAssignmentOrPage}
          type={activeItemType}
        />
        <EditAddCustomAssignmentOrPageModal
          visible={editAssignmentOrPageModalVisible}
          onClose={handleEditAssignmentOrPageModalClose}
          initialContent={
            activeItemType === "Assignment" ? assignment?.body || "" : activeItemType === "Page" ? page?.body || "" : ""
          }
          currentName={currentItemName}
          currentDueDate={activeItemType === "Assignment" ? assignment?.due_date : undefined}
          type={activeItemType}
          moduleName={moduleName}
          onSaveItem={handleEditAssignmentOrPage}
        />
      </View>
    </DefaultLayout>
  );
}
const styles = StyleSheet.create({
  htmlContainer: {
    flex: 1,
  },
  titleContainer: {
    paddingHorizontal: 20,
    paddingTop: 30,
    backgroundColor: "#fff",
    display: "flex",
    flexDirection: "column",
  },
  contentContainer: {
    flex: 1,
    backgroundColor: "#fff",
    paddingHorizontal: 20,
  },
  actionButton: {
    borderStyle: "solid",
    borderColor: "#3e68fe",
    borderWidth: 2,
  },
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  exportButton: {
    width: "fit-content",
    paddingVertical: "8px",
    paddingHorizontal: "12px",
    gap: "4px",
    backgroundColor: "#3E68FE",
  },
  topContainer: {
    backgroundColor: "#fff",
    height: 48,
    paddingHorizontal: 20,
    borderBottomColor: "#EAECF0",
    borderBottomWidth: 1,
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  divider: { width: "100%", borderColor: "#EAECF0", borderTopWidth: 1 },
});
export default CustomCourseworkModulesView;
