import CustomButton from "../../common/general/CustomButton/CustomButton";
import { PlusCircle } from "../../svgIcons";
import { ListItem } from "@rneui/themed";
import { StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const ContentMenuHeader = ({ type, onAddPress }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <ListItem.Content style={styles.addItemContainer}>
      <CustomButton
        text="Add"
        leftIcon={<PlusCircle />}
        styleType="primaryLight"
        size="s"
        style={[styles.addButton, { marginLeft: isMedium ? "0" : "auto" }]}
        textStyle={styles.addText}
        bold={true}
        onPress={onAddPress}
        aria-label={`Add ${type}`}
        accessibilityHint={`Press to add ${type}`}
      />
    </ListItem.Content>
  );
};

const styles = StyleSheet.create({
  addItemContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    padding: 5,
    marginBottom: 10,
    paddingLeft: 30,
  },
  addButton: {
    padding: 5,
    borderRadius: 8,
  },
  addText: {
    fontSize: 14,
  },
});

export default ContentMenuHeader;
