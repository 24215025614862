import * as React from "react";
import Svg, { G, Circle, Path } from "react-native-svg";
/* SVGR has dropped some elements not supported by react-native-svg: filter */

function LeftIcon(props) {
  return (
    <Svg width={97} height={97} viewBox="0 0 97 97" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G>
        <Circle cx={49} cy={41} r={32.5} fill="#000" opacity={0.2} />
        <Circle cx={32.5} cy={32.5} r={32.5} transform="matrix(-1 0 0 1 81 8)" fill="#fff" />
      </G>
      <Path
        d="M42.435 42.565l10 10a1.503 1.503 0 002.125-2.125l-8.939-8.936 8.936-8.939a1.503 1.503 0 00-2.125-2.125l-10 10a1.499 1.499 0 00.003 2.125z"
        fill="#3E68FE"
      />
    </Svg>
  );
}

export default LeftIcon;
