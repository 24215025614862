import { Svg, Path, Circle } from "react-native-svg";

function ExitArrow({ fill = "#000", ...props }) {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx="20" cy="20" r="20" fill={fill} />
      <Path
        d="M29.3749 20.4966C29.3749 20.795 29.2564 21.0811 29.0454 21.2921C28.8344 21.5031 28.5483 21.6216 28.2499 21.6216H14.4687L19.2987 26.4507C19.51 26.662 19.6287 26.9487 19.6287 27.2476C19.6287 27.5464 19.51 27.8331 19.2987 28.0444C19.0873 28.2558 18.8007 28.3745 18.5018 28.3745C18.2029 28.3745 17.9163 28.2558 17.7049 28.0444L10.9549 21.2944C10.85 21.1899 10.7668 21.0657 10.71 20.929C10.6533 20.7922 10.624 20.6456 10.624 20.4976C10.624 20.3495 10.6533 20.2029 10.71 20.0661C10.7668 19.9294 10.85 19.8052 10.9549 19.7007L17.7049 12.9507C17.8096 12.846 17.9338 12.763 18.0705 12.7064C18.2072 12.6498 18.3538 12.6206 18.5018 12.6206C18.6498 12.6206 18.7963 12.6498 18.9331 12.7064C19.0698 12.763 19.194 12.846 19.2987 12.9507C19.4033 13.0553 19.4863 13.1796 19.543 13.3163C19.5996 13.453 19.6287 13.5996 19.6287 13.7476C19.6287 13.8956 19.5996 14.0421 19.543 14.1788C19.4863 14.3156 19.4033 14.4398 19.2987 14.5444L14.4687 19.3716H28.2499C28.5483 19.3716 28.8344 19.4901 29.0454 19.7011C29.2564 19.9121 29.3749 20.1983 29.3749 20.4966Z"
        fill="white"
      />
    </Svg>
  );
}

export default ExitArrow;
