import * as React from "react";
import Svg, { Path } from "react-native-svg";

function MagnifyingGlass(props) {
  return (
    <Svg width={18} height={18} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.347 15.1531L13.0078 11.8125C14.009 10.5079 14.4764 8.87121 14.3153 7.23458C14.1541 5.59795 13.3765 4.08389 12.1401 2.99955C10.9037 1.9152 9.30107 1.34177 7.65741 1.39556C6.01375 1.44935 4.45209 2.12635 3.28922 3.28922C2.12635 4.45209 1.44935 6.01375 1.39556 7.65741C1.34177 9.30107 1.9152 10.9037 2.99955 12.1401C4.08389 13.3765 5.59795 14.1541 7.23458 14.3153C8.87121 14.4764 10.5079 14.009 11.8125 13.0078L15.1545 16.3505C15.233 16.429 15.3261 16.4912 15.4287 16.5337C15.5312 16.5762 15.6411 16.5981 15.7521 16.5981C15.8631 16.5981 15.973 16.5762 16.0756 16.5337C16.1781 16.4912 16.2713 16.429 16.3498 16.3505C16.4283 16.272 16.4905 16.1788 16.533 16.0763C16.5755 15.9737 16.5973 15.8638 16.5973 15.7528C16.5973 15.6418 16.5755 15.5319 16.533 15.4294C16.4905 15.3268 16.4283 15.2337 16.3498 15.1552L16.347 15.1531ZM3.09378 7.87503C3.09378 6.92938 3.37419 6.00498 3.89956 5.21871C4.42493 4.43243 5.17166 3.81961 6.04532 3.45773C6.91898 3.09585 7.88033 3.00116 8.8078 3.18565C9.73527 3.37013 10.5872 3.8255 11.2559 4.49417C11.9246 5.16284 12.3799 6.01478 12.5644 6.94225C12.7489 7.86972 12.6542 8.83107 12.2923 9.70473C11.9304 10.5784 11.3176 11.3251 10.5313 11.8505C9.74507 12.3759 8.82067 12.6563 7.87503 12.6563C6.60736 12.655 5.39199 12.1508 4.49561 11.2544C3.59923 10.3581 3.09508 9.14269 3.09378 7.87503Z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default MagnifyingGlass;
