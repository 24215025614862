import { useNavigation } from "@react-navigation/native";
import ContentMenu from "../../../components/navigation/ContentMenu/ContentMenu";
import {
  GetGeneratedContentDocument,
  useAddAssignmentMutation,
  useAddContentModuleMutation,
  useDeleteContentModuleMutation,
  useGetGeneratedContentQuery,
  useUpdateContentModuleNameMutation,
} from "../../../graphql/generated/graphql";
import { useEffect, useState } from "react";
import { Alert, Text, View } from "react-native";
import { actions, useAppState } from "../../../contexts/AppStateContext";
import Sentry from "../../../utils/sentry";

function ContentMenuContainer({ courseName }) {
  const [editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible] = useState(null);
  const [editModuleNameModalVisible, setEditModuleNameModalVisible] = useState(false);
  const [addModuleModalVisible, setAddModuleModalVisible] = useState(false);
  const [deleteModuleModalVisible, setDeleteModuleModalVisible] = useState(false);

  const navigation = useNavigation();
  const { dispatch } = useAppState();

  const {
    data,
    loading: loadingData,
    error,
    refetch: refetchContent,
  } = useGetGeneratedContentQuery({ fetchPolicy: "network-only" });

  const [updateModuleName] = useUpdateContentModuleNameMutation({
    refetchQueries: [
      {
        query: GetGeneratedContentDocument,
      },
    ],
  });

  const [deleteModule] = useDeleteContentModuleMutation({
    refetchQueries: [
      {
        query: GetGeneratedContentDocument,
      },
    ],
  });

  const [addModule] = useAddContentModuleMutation({
    refetchQueries: [
      {
        query: GetGeneratedContentDocument,
      },
    ],
  });

  const [addAssignment] = useAddAssignmentMutation({
    refetchQueries: [
      {
        query: GetGeneratedContentDocument,
      },
    ],
  });

  useEffect(() => {
    refetchContent();
    if (error) {
      console.error("Error fetching data: ", error);
    }
    if (data) {
      console.log("Generated Content Data: ", data.getGeneratedContent);
    } else {
      console.log("Data is undefined");
    }
  }, [data, error, refetchContent]);

  if (loadingData)
    return (
      <View>
        <Text>Loading...</Text>
      </View>
    );

  const courses = data?.getGeneratedContent || [];
  const selectedCourse = courses.filter((c) => c.name === courseName);

  const handleAssignmentPress = (assignmentName, moduleName, courseName) => {
    const selectedCourse = courses.find((c) => c.name === courseName);
    if (!selectedCourse) {
      console.error(`Course with name "${courseName}" not found.`);
      return;
    }

    const module = selectedCourse.modules.find((mod) => mod.module_name === moduleName);
    if (!module) {
      console.error(`Module with name "${moduleName}" not found.`);
      return;
    }

    const assignment = module.assignments.find((assign) => assign.assignment_name === assignmentName);
    if (!assignment) {
      console.error(`Assignment with name "${assignmentName}" not found in module "${moduleName}".`);
      return;
    }

    navigation.navigate("Modules", {
      id: selectedCourse.id,
      courseName: courseName,
      moduleIndex: selectedCourse.modules.indexOf(module),
      assignmentIndex: module.assignments.indexOf(assignment),
      assignment: {
        name: assignment.assignment_name,
        html: assignment.html,
      },
    });
  };

  const handleRenameModule = async (moduleName, newModuleName) => {
    try {
      const response = await updateModuleName({
        variables: {
          courseId: selectedCourse[0].id,
          oldModuleName: moduleName,
          newModuleName,
        },
      });
      if (response?.data?.updateContentModuleName?.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setEditModuleNameModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to update module name");
        Sentry.captureException(response?.data?.updateContentModuleName?.message);
        console.error("Failed to update module name:", response?.data?.updateContentModuleName?.message);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error("Error updating module name:", e);
    }
  };

  const handleDeleteModule = async (moduleName) => {
    try {
      const response = await deleteModule({
        variables: {
          courseId: selectedCourse[0].id,
          moduleName,
        },
      });

      if (response.data?.deleteContentModule.success) {
        Alert.alert("Success", "Module deleted successfully");
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setDeleteModuleModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to delete module");
        Sentry.captureException(response.data?.deleteContentModule.message);
        Alert.alert("Error", response.data?.deleteContentModule.message || "Failed to delete module");
      }
    } catch (error) {
      Sentry.captureException(error);
      Alert.alert("Error", "An error occurred while deleting the module.");
    }
  };

  const handleAddModule = async (moduleName) => {
    try {
      const response = await addModule({
        variables: {
          courseId: selectedCourse[0].id,
          moduleName,
        },
      });

      if (response?.data?.addContentModule?.success) {
        dispatch({
          type: actions.SET_META,
          payload: { keypressIsListening: true },
        });
        setAddModuleModalVisible(false);
      } else {
        Sentry.captureMessage("Failed to add module");
        Sentry.captureException(response?.data?.addContentModule?.message);
        console.error("Failed to add module:", response?.data?.addContentModule?.message);
      }
    } catch (e) {
      Sentry.captureException(e);
      console.error("Error adding module:", e);
    }
  };

  const handleAddAssignment = async (type, html, newName, moduleName) => {
    const cleanedHtml = html.replace(/\n\s+/g, "").trim();
    const encodedHtml = encodeURIComponent(cleanedHtml);

    const variables = {
      courseId: selectedCourse[0].id,
      moduleName,
      newname: newName,
      updatedHtml: encodedHtml,
    };

    try {
      await addAssignment({
        variables,
      });

      dispatch({
        type: actions.SET_META,
        payload: { keypressIsListening: true },
      });
      setEditAssignmentOrPageModalVisible(false);
    } catch (error) {
      Sentry.captureException(error);
      console.error(`Error adding ${type}`, error);
    }
  };

  return (
    <>
      {courses.length > 0 ? (
        <ContentMenu
          modulesRoute={"Modules"}
          data={selectedCourse[0]}
          loadingData={loadingData}
          courseName={selectedCourse[0]?.name}
          onItemPress={handleAssignmentPress}
          onRenameModule={handleRenameModule}
          onDeleteModule={handleDeleteModule}
          onAddModule={handleAddModule}
          onAddAssignment={handleAddAssignment}
          editAssignmentOrPageModalState={[editAssignmentOrPageModalVisible, setEditAssignmentOrPageModalVisible]}
          addModuleModalState={[addModuleModalVisible, setAddModuleModalVisible]}
          deleteModuleModalState={[deleteModuleModalVisible, setDeleteModuleModalVisible]}
          editModuleNameModalState={[editModuleNameModalVisible, setEditModuleNameModalVisible]}
        />
      ) : (
        <View>
          <Text>No courses available</Text>
        </View>
      )}
    </>
  );
}

export default ContentMenuContainer;
