import { Svg, Path } from "react-native-svg";

const Check = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="#000B33"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    {...props}>
    <Path stroke="none" d="M0 0h24v24H0z" fill="none" />
    <Path d="M5 12l5 5l10 -10" />
  </Svg>
);

export default Check;
