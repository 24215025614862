import { Svg, Path } from "react-native-svg";

function ExitDoor() {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <Path
        d="M21.75 19.875H19.875V3.75C19.875 3.25272 19.6775 2.77581 19.3258 2.42417C18.9742 2.07254 18.4973 1.875 18 1.875H6C5.50272 1.875 5.02581 2.07254 4.67417 2.42417C4.32254 2.77581 4.125 3.25272 4.125 3.75V19.875H2.25C1.95163 19.875 1.66548 19.9935 1.4545 20.2045C1.24353 20.4155 1.125 20.7016 1.125 21C1.125 21.2984 1.24353 21.5845 1.4545 21.7955C1.66548 22.0065 1.95163 22.125 2.25 22.125H21.75C22.0484 22.125 22.3345 22.0065 22.5455 21.7955C22.7565 21.5845 22.875 21.2984 22.875 21C22.875 20.7016 22.7565 20.4155 22.5455 20.2045C22.3345 19.9935 22.0484 19.875 21.75 19.875ZM6.375 4.125H17.625V19.875H6.375V4.125ZM16.125 12.375C16.125 12.6717 16.037 12.9617 15.8722 13.2084C15.7074 13.455 15.4731 13.6473 15.199 13.7608C14.9249 13.8744 14.6233 13.9041 14.3324 13.8462C14.0414 13.7883 13.7741 13.6454 13.5643 13.4357C13.3546 13.2259 13.2117 12.9586 13.1538 12.6676C13.0959 12.3767 13.1256 12.0751 13.2392 11.801C13.3527 11.5269 13.545 11.2926 13.7916 11.1278C14.0383 10.963 14.3283 10.875 14.625 10.875C15.0228 10.875 15.4044 11.033 15.6857 11.3143C15.967 11.5956 16.125 11.9772 16.125 12.375Z"
        fill="#667085"
      />
    </Svg>
  );
}

export default ExitDoor;
