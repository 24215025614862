import React from "react";
import { View, StyleSheet } from "react-native";
import CustomText from "../general/CustomText/CustomText";

const NoAssignments = () => {
  return (
    <View style={styles.container}>
      <CustomText style={styles.text} text="You're all caught up!" textType="text" size="xl" weight="medium" />
      <CustomText
        style={styles.lowerText}
        text="No assignments due right now. Keep up the great work!"
        size="m"
        weight="normal"
        textType="text"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 224,
    // backgroundColor: "#F5F5F5",
    backgroundColor: "#F9FAFB",
    borderRadius: 20,
    justifyContent: "center",
    alignItems: "center",
    padding: 20,
    borderColor: "black",
  },
  text: {
    color: "black",
  },
  lowerText: {
    color: "#A9A9A9",
    textAlign: "center",
  },
});

export default NoAssignments;
