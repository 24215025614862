import { View } from "react-native";
import { useDateForChatQuery } from "../../../graphql/generated/graphql";
import JournalMessageContainer from "../JournalMessageContainer/JournalMessageContainer";
import Placeholder from "../../../components/general/Placeholder/Placeholder";
import { useRoute } from "@react-navigation/native";

function JournalChatContainer({ date }) {
  const router = useRoute();

  const { data } = useDateForChatQuery({
    variables: {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      date: date,
      context: router.name,
      params: router.params,
    },
  });

  const chatData = [...(data?.getChatForDate?.conversation ?? [])].reverse();

  return (
    <>
      {chatData.length === 0 ? (
        <Placeholder title="No data for this date" subtitle="journalPlaceholderSubtitle" />
      ) : (
        <>
          {chatData.map((item, index) => (
            <View key={index}>
              <JournalMessageContainer message={item} date={date} />
            </View>
          ))}
        </>
      )}
    </>
  );
}

export default JournalChatContainer;
