import React, { useState } from "react";
import { StyleSheet, View } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import Sentry from "../../../utils/sentry";

const TwoFactorPhoneNumber = ({ handleCodePress, userPhoneNumber, userCountryCode }) => {
  const [countryCode, setCountryCode] = useState(userCountryCode || "");
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber || "");
  const [errorMessage, setErrorMessage] = useState("");

  const validatePhoneNumber = () => {
    const phoneRegex = /^\d{10}$/; // Regex to validate 10-digit phone number
    const countryCodeRegex = /^\+\d{1,3}$/; // Regex to validate country code
    return countryCodeRegex.test(countryCode) && phoneRegex.test(phoneNumber);
  };

  const handleCountryCodeChange = (text) => {
    // Ensure the country code starts with a '+'
    if (text.charAt(0) !== "+") {
      setCountryCode("+" + text);
    } else {
      setCountryCode(text);
    }
    setErrorMessage("");
  };

  const handlePhoneNumberChange = (text) => {
    setPhoneNumber(text);
    setErrorMessage("");
  };

  const handlePress = async () => {
    if (validatePhoneNumber()) {
      try {
        await handleCodePress(`${countryCode} ${phoneNumber}`);
        setErrorMessage("");
      } catch (error) {
        Sentry.captureException(error);
        setErrorMessage("An error occurred while sending the code. Please try again.");
      }
    } else {
      setErrorMessage("Please enter a valid country code (+X) and 10-digit phone number.");
    }
  };

  return (
    <View style={styles.container}>
      <CustomText text="Enter your phone number" size="s" textType="text" />
      <CustomSpacing type="vertical" size={16} />
      <View style={styles.inputContainer}>
        <CustomTextInput
          placeholder="+1"
          value={countryCode}
          onChangeText={handleCountryCodeChange}
          keyboardType="phone-pad"
          style={styles.countryCodeInput}
          disabled={!!userCountryCode}
        />
        <CustomTextInput
          placeholder="5551234567"
          value={phoneNumber}
          onChangeText={handlePhoneNumberChange}
          keyboardType="phone-pad"
          style={styles.phoneNumberInput}
          disabled={!!userPhoneNumber}
        />
      </View>
      <CustomSpacing type="vertical" size={8} />
      {errorMessage ? (
        <CustomText text={errorMessage} size="xs" style={styles.errorText} useTranslationText={false} />
      ) : null}
      <CustomSpacing type="vertical" size={24} />
      <CustomButton
        text="Send Code"
        styleType="primary"
        onPress={handlePress}
        style={styles.sendCodeButton}
        leftIcon={null}
        rightIcon={null}
        textStyle={{}}
        textProps={{}}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  inputContainer: {
    flexDirection: "row",
    alignItems: "center",
  },
  countryCodeInput: {
    flex: 1,
    marginRight: 10,
  },
  phoneNumberInput: {
    flex: 3,
  },
  sendCodeButton: {
    width: "100%",
  },
  errorText: {
    color: "red",
  },
});

export default TwoFactorPhoneNumber;
