import Svg, { Path } from "react-native-svg";

function ChatIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M22.13 17.596a7.875 7.875 0 00-5.978-11.137A7.875 7.875 0 101.87 13.096l-.678 2.377a1.688 1.688 0 002.09 2.086l2.378-.678a7.9 7.9 0 002.19.656 7.879 7.879 0 0010.5 3.844l2.377.678a1.687 1.687 0 002.086-2.086l-.681-2.377zM5.769 14.552c-.104 0-.208.014-.309.043l-1.827.522.522-1.827a1.125 1.125 0 00-.094-.848 5.625 5.625 0 112.25 2.25 1.116 1.116 0 00-.542-.14zm14.078 3.238l.522 1.827-1.827-.522a1.125 1.125 0 00-.849.094 5.63 5.63 0 01-7.286-1.692 7.869 7.869 0 006.427-8.566 5.625 5.625 0 013.109 8.011c-.143.26-.177.564-.096.849v-.001z"
        fill={fill}
      />
    </Svg>
  );
}

export default ChatIcon;
