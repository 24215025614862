import React from "react";
import { StyleSheet, Animated, View, Pressable } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import CustomText from "../../common/general/CustomText/CustomText";

function NotificationsSlideIn({ visible, slideAnim, onClose, notifications = [] }) {
  if (!visible) return null;

  return (
    <Animated.View style={[styles.mobile, { transform: [{ translateX: slideAnim }] }]}>
      <View style={styles.mobileContainer}>
        <View style={styles.topRow}>
          <CustomText
            size="m"
            weight="bold"
            useTranslationText={false}
            style={styles.headerText}
            text="Notifications"
          />
          <Pressable role="button" aria-label="Close notifications" style={styles.closeButton} onPress={onClose}>
            <Ionicons name="close" size={24} color="black" />
          </Pressable>
        </View>

        <View style={styles.contentContainer}>
          {notifications.length === 0 ? (
            <View style={styles.emptyContainer}>
              <Ionicons name="notifications-outline" size={48} color="#9CA3AF" />
              <CustomText
                size="m"
                weight="medium"
                useTranslationText={false}
                style={styles.emptyText}
                text="No notifications yet"
              />
              <CustomText
                size="s"
                weight="normal"
                useTranslationText={false}
                style={styles.emptySubtext}
                text="We'll notify you when something important happens"
              />
            </View>
          ) : (
            <View style={styles.notificationsList}>
              {notifications.map((notification) => (
                <View
                  key={notification.id}
                  style={[styles.notificationItem, !notification.read && styles.unreadNotification]}>
                  <CustomText
                    size="s"
                    weight="bold"
                    useTranslationText={false}
                    style={styles.notificationTitle}
                    text={notification.title}
                  />
                  <CustomText
                    size="s"
                    weight="normal"
                    useTranslationText={false}
                    style={styles.notificationMessage}
                    text={notification.message}
                  />
                  <CustomText
                    size="xs"
                    weight="normal"
                    useTranslationText={false}
                    style={styles.timestamp}
                    text={notification.timestamp}
                  />
                </View>
              ))}
            </View>
          )}
        </View>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  mobile: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 360,
    zIndex: 100,
  },
  mobileContainer: {
    flex: 1,
    backgroundColor: "white",
    borderLeftWidth: 1,
    borderLeftColor: "#e0e0e0",
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#e0e0e0",
  },
  headerText: {
    fontSize: 18,
  },
  closeButton: {
    padding: 5,
  },
  contentContainer: {
    flex: 1,
    padding: 16,
  },
  emptyContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingBottom: 100,
  },
  emptyText: {
    marginTop: 16,
    color: "#4B5563",
  },
  emptySubtext: {
    marginTop: 8,
    color: "#9CA3AF",
    textAlign: "center",
  },
  notificationsList: {
    flex: 1,
  },
  notificationItem: {
    padding: 16,
    borderBottomWidth: 1,
    borderBottomColor: "#E5E7EB",
    backgroundColor: "white",
  },
  unreadNotification: {
    backgroundColor: "#F3F4F6",
  },
  notificationTitle: {
    marginBottom: 4,
    color: "#111827",
  },
  notificationMessage: {
    color: "#4B5563",
    marginBottom: 8,
  },
  timestamp: {
    color: "#9CA3AF",
  },
});

export default NotificationsSlideIn;
