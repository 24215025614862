import { Svg, Path } from "react-native-svg";

const EditTask = () => (
  <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M24.8631 8.02507L19.9752 3.13819C19.8127 2.97565 19.6197 2.84671 19.4074 2.75874C19.195 2.67077 18.9674 2.62549 18.7376 2.62549C18.5077 2.62549 18.2801 2.67077 18.0678 2.75874C17.8555 2.84671 17.6625 2.97565 17.5 3.13819L4.01298 16.6252C3.84977 16.7871 3.72037 16.9799 3.63231 17.1922C3.54425 17.4046 3.49927 17.6324 3.50001 17.8623V22.7502C3.50001 23.2144 3.68438 23.6595 4.01257 23.9877C4.34076 24.3158 4.78588 24.5002 5.25001 24.5002H23.625C23.8571 24.5002 24.0796 24.408 24.2437 24.2439C24.4078 24.0798 24.5 23.8573 24.5 23.6252C24.5 23.3932 24.4078 23.1706 24.2437 23.0065C24.0796 22.8424 23.8571 22.7502 23.625 22.7502H12.6131L24.8631 10.5002C25.0257 10.3377 25.1546 10.1448 25.2426 9.93243C25.3306 9.72009 25.3758 9.49249 25.3758 9.26265C25.3758 9.0328 25.3306 8.8052 25.2426 8.59286C25.1546 8.38051 25.0257 8.18758 24.8631 8.02507ZM10.138 22.7502H5.25001V17.8623L14.875 8.23725L19.763 13.1252L10.138 22.7502ZM21 11.8882L16.1131 7.00022L18.7381 4.37522L23.625 9.26319L21 11.8882Z"
      fill="#98A2B3"
    />
  </Svg>
);

export default EditTask;
