import { View } from "react-native";
import * as Progress from "react-native-progress";

const DEFAULT_UNFILLED_COLOR = "#EAECF0";
const DEFAULT_COLOR = "#5BE068";

const CustomProgressBar = ({
  progress,
  color = DEFAULT_COLOR,
  unfilledColor = DEFAULT_UNFILLED_COLOR,
  ariaLabel = "Progress bar",
}: {
  progress: number | undefined | null;
  color: string;
  unfilledColor: string;
  ariaLabel: string;
}) => {
  const currentProgress = progress || 0;

  return (
    <View
      role="progressbar"
      aria-label={ariaLabel}
      aria-valuemax={100}
      aria-valuemin={0}
      aria-valuenow={currentProgress}
      aria-valuetext={`${currentProgress}% complete`}>
      <Progress.Bar
        progress={currentProgress / 100}
        width={null}
        animated
        unfilledColor={unfilledColor}
        color={color}
        borderWidth={0}
        height={8}
      />
    </View>
  );
};

export default CustomProgressBar;
