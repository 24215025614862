import { useState, useEffect, useMemo } from "react";
import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { usePostHog } from "posthog-js/react";
import DefaultLayout from "../layouts/DefaultLayout.web";
import LearnHeader from "../components/learning/home/LearnHeader/LearnHeader";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";

import { useFieldsQuery, useRecentTracksQuery } from "../graphql/generated/graphql";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import { MotiView } from "moti";
import LearnSelectableMenu from "../components/learning/general/LearnSelectableMenu/LearnSelectableMenu";
import LearnSubjectCardListContainer from "../containers/learning/LearnSubjectCardListContainer/LearnSubjectCardListContainer";
import SelectableMenu from "../components/navigation/SelectableMenu/SelectableMenu";
import LearnTrackCardListContainer from "../containers/learning/LearnTrackCardListContainer/LearnTrackCardListContainer";
import { actions, useAppState } from "../contexts/AppStateContext";
import Loader from "../components/common/status/Loader/Loader";

function LearnView() {
  const { isMedium } = useResponsiveScreen();
  const posthog = usePostHog();
  const { dispatch } = useAppState();

  const [selectedView, setSelectedView] = useState("Subjects");
  const [searchValue, setSearchValue] = useState("");
  const [isSearchInputActive, setIsSearchInputActive] = useState(false);

  useEffect(() => {
    posthog?.capture("Learning Home Page Visited");
  }, []);

  const { data: graphData, error: graphError, loading: graphLoading } = useFieldsQuery();

  const subjects = useMemo(() => {
    return graphData?.getFields.map((obj) => ({ ...obj, type: "Field" }));
  }, [graphData]);

  const filteredSubjects = {
    name: "root",
    type: "root",
    children: subjects?.filter((obj) => obj.name.toLowerCase().includes(searchValue.toLowerCase())),
  };

  const { data: graphRecentTracks, error: recentTracksError, recentTracksLoading } = useRecentTracksQuery();

  const filteredRecentTracks = graphRecentTracks?.getRecentTracks.filter((item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const handleSearchClick = async (state) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: !state },
    });

    if (!state) setSearchValue("");
    setIsSearchInputActive(state);
  };

  const handleTabChange = (item) => {
    setSelectedView(item);
    setIsSearchInputActive(false);
    setSearchValue("");
  };

  const handleSelect = (item) => {
    setSelectedView(item);
  };

  const error = graphError || recentTracksError;
  const loading = graphLoading || recentTracksLoading;

  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </DefaultLayout>
    );
  }

  const noRecentTracks = graphRecentTracks?.getRecentTracks?.length === 0;
  const selectableTabs = noRecentTracks ? ["Subjects"] : ["Subjects", "All Recent Tracks"];

  return (
    <DefaultLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        {isMedium ? (
          <LearnHeader title="Learn" />
        ) : (
          <View style={styles.mobileLearnHeader}>
            <LearnHeader title="Learn" />
          </View>
        )}
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={30}
        transition={{ type: "timing", duration: 500 }}>
        <CustomSpacing type="vertical" size={isMedium ? "xl" : "l"} />
        {isMedium ? (
          <LearnSelectableMenu
            items={selectableTabs}
            selectedItem={selectedView}
            onSelect={handleTabChange}
            onSearchClick={handleSearchClick}
            isSearchInputActive={isSearchInputActive}
            onChangeText={setSearchValue}
            searchText={searchValue}
          />
        ) : (
          <>
            <SelectableMenu items={selectableTabs} selectedItem={selectedView} onSelect={handleSelect} />

            <CustomSpacing type="vertical" size="l" />
          </>
        )}
      </MotiView>

      {selectedView === "Subjects" && (
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={200}
          transition={{ type: "timing", duration: 600 }}>
          <View style={styles.listContainer}>
            <LearnSubjectCardListContainer data={filteredSubjects} />
          </View>
        </MotiView>
      )}

      {selectedView === "All Recent Tracks" && (
        <MotiView
          from={{
            opacity: 0,
            translateY: 20,
            scale: 1,
          }}
          animate={{
            opacity: 1,
            translateY: 0,
            scale: 1,
          }}
          delay={200}
          transition={{ type: "timing", duration: 600 }}>
          <View style={styles.listContainer}>
            <LearnTrackCardListContainer data={filteredRecentTracks} />
          </View>
        </MotiView>
      )}
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loaderContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  listContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
  },
  mobileLearnHeader: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
});

export default LearnView;
