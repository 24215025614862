import * as React from "react";
import Svg, { Path } from "react-native-svg";

function TimeEstimate(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12 1.875A10.125 10.125 0 1022.125 12 10.137 10.137 0 0012 1.875zm0 18A7.875 7.875 0 1119.875 12 7.883 7.883 0 0112 19.875zM18.375 12a1.125 1.125 0 01-1.125 1.125H12A1.125 1.125 0 0110.875 12V6.75a1.125 1.125 0 112.25 0v4.125h4.125A1.125 1.125 0 0118.375 12z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default TimeEstimate;
