import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import ActiveCardList from "../../../components/dashboard/ActiveCardList/ActiveCardList";
import { View, StyleSheet } from "react-native";
import { useGetJourneysQuery, useRecentTracksQuery } from "../../../graphql/generated/graphql";
import { useNavigation } from "@react-navigation/native";

function ActiveCardListContainer() {
  const { isXLarge } = useResponsiveScreen();
  const navigation = useNavigation();

  const { data: graphRecentTracks } = useRecentTracksQuery();

  const { data: journeys } = useGetJourneysQuery({});
  const journeyCardData = journeys?.getJourneys || [];

  const firstThreeJourneyCardData = journeyCardData.slice(0, 3);

  const activeCardsData = [
    {
      id: 1,
      stackTitle: "Tracks",
      numberOfCards: ["a", "b", "c"],
      customText: "No active learning tracks.",
      text: "Ready to tackle something new?",
      buttonText: "Explore Tracks",
      data: graphRecentTracks?.getRecentTracks.slice(0, 3),
    },
    {
      id: 2,
      stackTitle: "Journeys",
      numberOfCards: ["a", "b", "c"],
      customText: "No journeys started yet.",
      text: "Ready to embark on a new adventure?",
      buttonText: "Create a Journey",
      data: firstThreeJourneyCardData,
    },
  ];

  const handleOnPress = (title, params) => {
    if (title === "Tracks") {
      const { field, subfield, topic } = params;
      navigation.navigate("Track Overview", {
        field,
        subfield,
        topic,
      });
    } else if (title === "Journeys") {
      const { id } = params;
      navigation.navigate("Journey", {
        id,
      });
    }
  };

  const handleViewAllPressed = (title) => {
    title === "Journeys" ? navigation.navigate("Productivity", { tab: "journeys" }) : navigation.navigate("Learn");
  };

  return (
    <View
      style={[
        styles.container,
        isXLarge ? { flexWrap: "wrap", gap: 20 } : { flexDirection: "column", flexWrap: "nowrap", gap: 8 },
      ]}>
      {activeCardsData.map((activeCardData, index) => (
        <ActiveCardList
          key={index}
          activeCardData={activeCardData}
          onPress={handleOnPress}
          onViewallPressed={handleViewAllPressed}
        />
      ))}
    </View>
  );
}

export default ActiveCardListContainer;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
});
