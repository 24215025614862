import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Assessment(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M6.566 3.44l.035-.035a.44.44 0 01.584.654l-2.5 2.5h0a.438.438 0 01-.62 0h0l-1.25-1.25a.44.44 0 01.62-.621l.587.587.353.354.354-.353L6.566 3.44zm0 5l.035-.035a.44.44 0 01.584.654l-2.5 2.5h0a.438.438 0 01-.62 0h0l-1.25-1.25a.44.44 0 01.62-.621l.587.587.353.354.354-.353L6.566 8.44zm0 5l.035-.035a.44.44 0 01.584.654l-2.5 2.5h0a.438.438 0 01-.62 0h0l-1.25-1.25a.44.44 0 01.62-.621l.587.587.353.354.354-.353 1.837-1.836zM17.313 10a.438.438 0 01-.438.438h-6.874a.438.438 0 110-.875h6.874a.437.437 0 01.438.437zm-.438-4.562h-6.874a.438.438 0 110-.875h6.874a.437.437 0 110 .875zm-6.874 9.125h6.874a.437.437 0 110 .874h-6.874a.438.438 0 110-.874z"
        fill="#344054"
        stroke="#000"
      />
    </Svg>
  );
}

export default Assessment;
