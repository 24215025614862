import { StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import videoFile from "../../../assets/bg.mp4";

function BackgroundVideo() {
  const { isMedium } = useResponsiveScreen();

  return (
    <>
      {isMedium ? (
        <video autoPlay loop muted playsInline style={styles.video}>
          <source src={videoFile} type="video/mp4" />
        </video>
      ) : null}
    </>
  );
}

const styles = StyleSheet.create({
  video: {
    position: "fixed",
    width: "100%",
    height: "100%",
    objectFit: "cover",
    zIndex: -1,
  },
});

export default BackgroundVideo;
