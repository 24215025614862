import React, { useMemo } from "react";
import { View, StyleSheet, ScrollView } from "react-native";
import CustomText from "../../common/general/CustomText/CustomText";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import ProgressTracker from "../ProgressTracker/ProgressTracker";
import ProgressTrackerInfo from "../ProgressTrackerInfo/ProgressTrackerInfo";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import Skeleton from "react-loading-skeleton";
import { Divider } from "react-native-paper";
import { useAppState } from "../../../contexts/AppStateContext";
import mediaSidebar from "../../mediaSidebar/mediaSidebar";
import { useChatBar } from "../../../contexts/ChatBarContext";

const ProgressLightMenu = ({ objectivesData }) => {
  const { state: chatBarState } = useChatBar();
  const { isMedium } = useResponsiveScreen();

  const { state } = useAppState();

  const media = state.state.media;

  const { sources, videoCardData, imageCardData } = useMemo(() => {
    if (!media) {
      return { sources: [], videoCardData: [], imageCardData: [] };
    }

    const sources =
      media.wiki?.map((item) => ({
        title: item.metadata.title,
        url: item.metadata.source,
      })) || [];

    const videoCardData =
      media.video?.map((item) => ({
        title: item.metadata.title,
        thumbnailUrl: item.metadata.videoThumbnail,
        url: item.metadata.source,
      })) || [];

    const imageCardData =
      media.wiki?.flatMap((item) =>
        item.metadata.images.map((imageUrl) => ({
          title: item.metadata.title,
          imageUrl: imageUrl,
        }))
      ) || [];

    return { sources, videoCardData, imageCardData };
  }, [media]);

  return (
    <View role="menu" style={[styles.containerBase, isMedium ? styles.containerDesktop : styles.containerMobile]}>
      {objectivesData && objectivesData.length === 0 && (
        <>
          <View style={styles.view}>
            <CustomText
              useTranslationText={false}
              styleType="dark"
              text="Initializing Experience..."
              size="."
              textType="text"
              weight="bold"
            />
          </View>
          <View style={styles.sidebarContainer}>
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={175} borderRadius={8} />
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={120} borderRadius={8} />
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={120} borderRadius={8} />
            <CustomSpacing type="vertical" size="s" />
            <Skeleton width="100%" height={120} borderRadius={8} />
          </View>
        </>
      )}
      {objectivesData && objectivesData.length !== 0 && <ProgressTracker data={objectivesData} />}
      <CustomSpacing size={16} />

      {(media && (media.wiki || media.video)) || state.meta.isSuggestionsLoading ? (
        <>
          <ScrollView showsVerticalScrollIndicator={false}>
            {isMedium ? (
              <>
                {state.meta.isSuggestionsLoading ? (
                  <View styles={styles.insightsCenterView}>
                    <View style={styles.sidebarContainer}>
                      <CustomSpacing type="vertical" size="s" />
                      <Skeleton width="100%" height={175} borderRadius={8} />
                      <CustomSpacing type="vertical" size="s" />
                      <Skeleton width="100%" height={120} borderRadius={8} />
                      <CustomSpacing type="vertical" size="s" />
                      <Skeleton width="100%" height={120} borderRadius={8} />
                      <CustomSpacing type="vertical" size="s" />
                      <Skeleton width="100%" height={120} borderRadius={8} />
                    </View>
                  </View>
                ) : (
                  mediaSidebar({ sources, imageCardData, videoCardData })
                )}
              </>
            ) : (
              <>
                <Divider size="l" />
                <CustomText
                  textType="display"
                  text="Suggested Insights"
                  size="xs"
                  bold={true}
                  weight="bold"
                  role="heading"
                  aria-level="1"
                />
                <View style={chatBarState.llmMessage.meta.isLoading ? { alignItems: "center" } : {}}>
                  {chatBarState.llmMessage.meta.isLoading ? (
                    <View style={styles.fullWidthContentContainer}>
                      <View style={styles.sidebarContainer}>
                        <CustomSpacing type="vertical" size="s" />
                        <Skeleton width="100%" height={175} borderRadius={8} />
                        <CustomSpacing type="vertical" size="s" />
                        <Skeleton width="100%" height={120} borderRadius={8} />
                        <CustomSpacing type="vertical" size="s" />
                        <Skeleton width="100%" height={120} borderRadius={8} />
                        <CustomSpacing type="vertical" size="s" />
                        <Skeleton width="100%" height={120} borderRadius={8} />
                      </View>
                    </View>
                  ) : (
                    mediaSidebar({ sources, imageCardData, videoCardData })
                  )}
                </View>
              </>
            )}
          </ScrollView>
        </>
      ) : (
        objectivesData && objectivesData.length !== 0 && <ProgressTrackerInfo />
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  sidebarContainer: {
    padding: 10,
  },
  containerBase: {
    flex: 1,
    alignSelf: "flex-start",
    overflow: "visible",
    width: 270,
    backgroundColor: "#F9FAFB",
    paddingVertical: 32,
    paddingHorizontal: 8,
  },
  containerDesktop: {
    borderRadius: 20,
  },
  containerMobile: {
    top: 0,
    left: 0,
    borderRadius: 0,
  },
  view: {
    paddingHorizontal: 8,
    justifyContent: "flex-start",
    paddingBottom: 20,
  },
});
export default ProgressLightMenu;
