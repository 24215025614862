import * as React from "react";
import Svg, { Path } from "react-native-svg";

function Calendar(props) {
  return (
    <Svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M12 1.75h-1.25V1.5a.75.75 0 10-1.5 0v.25h-4.5V1.5a.75.75 0 00-1.5 0v.25H2A1.25 1.25 0 00.75 3v10A1.25 1.25 0 002 14.25h10A1.25 1.25 0 0013.25 13V3A1.25 1.25 0 0012 1.75zm-8.75 1.5a.75.75 0 001.5 0h4.5a.75.75 0 001.5 0h1v1.5h-9.5v-1.5h1zm-1 9.5v-6.5h9.5v6.5h-9.5z"
        fill="#344054"
      />
    </Svg>
  );
}

export default Calendar;
