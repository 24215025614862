import CustomText from "../common/general/CustomText/CustomText";
import CustomSpacing from "../common/layout/CustomSpacing/CustomSpacing";
import { MotiView, View } from "moti";
import VideoSuggestionCard from "../lms/VideoSuggestionCard/VideoSuggestionCard";
const mediaSidebar = ({ sources, imageCardData, videoCardData }) => {
  const hasData = sources.length > 0 || imageCardData.length > 0 || videoCardData.length > 0;
  if (!hasData) return null;

  const styles = {
    sidebarContainer: {
      padding: 12,
    },
    headerText: {
      marginBottom: 12,
      color: "#1a1a1a",
    },
    videoCard: {
      flexDirection: "column",
      overflow: "hidden",
      padding: 12,
      marginBottom: 12,
      borderColor: "#F2F4F7",
      backgroundColor: "#FFFFFF",
      borderWidth: 1,
      borderRadius: 12,
    },
    videoThumbnailContainer: {
      width: "100%",
      aspectRatio: 16 / 9,
      backgroundColor: "#eee",
    },
    videoTitle: {
      margin: 8,
      marginBottom: 2,
      lineHeight: 12,
      fontSize: 9,
      fontWeight: "500",
      color: "#1a1a1a",
    },
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20, scale: 1 }}
      animate={{ opacity: 1, translateY: 0, scale: 1 }}
      delay={10}
      style={styles.sidebarContainer}
      transition={{ type: "timing", duration: 600 }}>
      {videoCardData.length > 0 && (
        <>
          <CustomText
            text="Suggested Insights"
            style={styles.headerText}
            size="s"
            weight="bold"
            role="heading"
            aria-level="2"
          />
          <View style={styles.videoContainer}>
            {videoCardData.map((video, index) => (
              <View key={index} style={styles.videoCard}>
                <View style={styles.videoThumbnailContainer}>
                  <VideoSuggestionCard
                    imageUrl={video.thumbnailUrl}
                    url={video.metadata?.source || video.url} // Fallback for URL
                  />
                </View>
                <View style={styles.videoContent}>
                  <CustomText
                    text={video.metadata?.title || video.title} // Fallback for title
                    style={styles.videoTitle}
                    useTranslationText={false}
                    numberOfLines={4}
                  />
                </View>
              </View>
            ))}
          </View>
          <CustomSpacing type="vertical" size="m" />
        </>
      )}
    </MotiView>
  );
};

export default mediaSidebar;
