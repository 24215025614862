import { View, StyleSheet } from "react-native";
import { useAppConfig } from "../AppConfigProvider";
import BlankLayout from "../layouts/BlankLayout";
import CustomText from "../components/common/general/CustomText/CustomText";
import Logo from "../components/general/logos/Logo/Logo";

function EdlinkSuccessView() {
  const appConfig = useAppConfig();

  const domainKey = appConfig.key;
  const companionName = appConfig.companionName;

  return (
    <BlankLayout>
      <View style={styles.container} role="header" accessible>
        <Logo name={domainKey} height={100} width={280} />
        <CustomText
          text="account_link_success"
          size="l"
          style={styles.text}
          interpolation={{ companionName }} // Pass companionName here
        />
        <CustomText text={`Please reload the previous page to continue.`} size="l" style={styles.text} />
      </View>
    </BlankLayout>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "center",
    alignItems: "center",
    flex: 1,
  },
  text: {
    textAlign: "center",
  },
});

export default EdlinkSuccessView;
