import { View, StyleSheet, ImageBackground, Image } from "react-native";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

function ActivityInstructionsModal({ visible, onClose }) {
  const { isMedium } = useResponsiveScreen();

  const features = [
    {
      title: "Engage in Discussion",
      description: "Participate actively in each topic's interactive chat to progress. Audio input is suggested.",
      icon: require("../../../assets/instructions/engage.png"),
    },
    {
      title: "Automatic Assessment",
      description:
        "Your AI tutor will evaluate your understanding and mark topics completed as you demonstrate understanding.",
      icon: require("../../../assets/instructions/assess.png"),
    },
    {
      title: "Time Estimate",
      description: "Each activity typically takes 20–60 minutes to complete all topics.",
      icon: require("../../../assets/instructions/time.png"),
    },
    {
      title: "Verify Information",
      description: "Cross-check important details with trusted sources.",
      icon: require("../../../assets/instructions/verify.png"),
    },
  ];

  const renderFeatures = () => {
    if (isMedium) {
      return (
        <View style={styles.featuresGrid}>
          <View style={styles.featureRow}>
            {features.slice(0, 2).map((feature, index) => (
              <View key={index} style={[styles.featureItem, styles.featureItemDesktop]}>
                <Image source={feature.icon} style={styles.featureIcon} />
                <View style={styles.featureText}>
                  <CustomText
                    weight="bold"
                    size="l"
                    useTranslationText={false}
                    text={feature.title}
                    style={styles.featureTitle}
                  />
                  <CustomText
                    weight="regular"
                    size="m"
                    useTranslationText={false}
                    text={feature.description}
                    style={styles.featureDescription}
                  />
                </View>
              </View>
            ))}
          </View>
          <View style={styles.featureRow}>
            {features.slice(2, 4).map((feature, index) => (
              <View key={index} style={[styles.featureItem, styles.featureItemDesktop]}>
                <Image source={feature.icon} style={styles.featureIcon} />
                <View style={styles.featureText}>
                  <CustomText
                    weight="bold"
                    size="l"
                    useTranslationText={false}
                    text={feature.title}
                    style={styles.featureTitle}
                  />
                  <CustomText
                    weight="regular"
                    size="m"
                    useTranslationText={false}
                    text={feature.description}
                    style={styles.featureDescription}
                  />
                </View>
              </View>
            ))}
          </View>
        </View>
      );
    }

    return (
      <View style={styles.featuresGrid}>
        {features.map((feature, index) => (
          <View key={index} style={styles.featureItem}>
            <Image source={feature.icon} style={styles.featureIcon} />
            <View style={styles.featureText}>
              <CustomText
                weight="bold"
                size="l"
                useTranslationText={false}
                text={feature.title}
                style={styles.featureTitle}
              />
              <CustomText
                weight="regular"
                size="m"
                useTranslationText={false}
                text={feature.description}
                style={styles.featureDescription}
              />
            </View>
          </View>
        ))}
      </View>
    );
  };

  return (
    <CustomModal visible={visible} onRequestClose={onClose} width={isMedium ? "900px" : "90%"} style={styles.modal}>
      <ImageBackground
        source={require("../../../assets/instructions/instructionsHeader.png")}
        style={[styles.backgroundImage, { height: isMedium ? 200 : 200 }]}
        resizeMode="cover">
        <View style={styles.headerContent}>
          <CustomText
            useTranslationText={false}
            styleType="light"
            text="×"
            style={styles.closeButton}
            onPress={onClose}
          />
          <View>
            <CustomText
              role="header"
              aria-level="1"
              textType="display"
              weight="bold"
              useTranslationText={false}
              styleType="light"
              text="Welcome to your Learning Activity"
              style={styles.headerTitle}
            />
          </View>
          <CustomText
            useTranslationText={false}
            styleType="light"
            weight="normal"
            size="l"
            text="Engage with Axio through interactive dialogue to master each topic and track your progress along the way."
            style={styles.headerDescription}
          />
        </View>
      </ImageBackground>

      <View style={isMedium ? styles.bodyContainer : styles.bodyContainerMobile}>
        {renderFeatures()}

        <View style={styles.audioContainer}>
          <CustomText useTranslationText={false} text="Audio Input is Suggested" style={styles.audioText} />
        </View>

        <View style={styles.buttonContainer}>
          <CustomButton
            useTranslationText={false}
            text="Got It!"
            styleType="primary"
            onPress={onClose}
            style={styles.gotItButton}
          />
          {/* <CustomButton
            useTranslationText={false}
            text="Don't Show Again"
            styleType="text"
            onPress={onClose}
            textStyle={styles.dontShowText}
          /> */}
        </View>
      </View>
    </CustomModal>
  );
}

const styles = StyleSheet.create({
  modal: {
    margin: 10,
    padding: 0,
  },
  featuresGrid: {
    marginBottom: 24,
    alignItems: "center", // Center the rows
  },
  featureRow: {
    width: 800, // Accommodate two 380px items plus gap
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 24,
  },
  featureItem: {
    flexDirection: "row",
    marginBottom: 24,
  },
  featureItemDesktop: {
    width: 380,
    marginBottom: 0,
  },
  backgroundImage: {
    width: "100%",
    borderTopLeftRadius: 20,
    borderTopRightRadius: 20,
    overflow: "hidden",
  },
  headerContent: {
    padding: 36,
    height: "100%",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: 16,
    right: 16,
    fontSize: 24,
    color: "#FFFFFF",
    zIndex: 1,
  },
  headerTitle: { lineHeight: 36 },
  headerDescription: {
    // color: "#E0E0E0",
    flexWrap: "wrap",
    lineHeight: 28,
    maxWidth: 700,
    marginTop: 16,
  },
  bodyContainer: {
    marginTop: 0,
    padding: 24,
  },
  bodyContainerMobile: {
    marginTop: 0,
    padding: 16,
  },

  featureIcon: {
    width: 71,
    height: 71,
    borderRadius: 100,
    backgroundColor: "#6B46C1",
    marginRight: 16,
  },
  featureText: {
    flex: 1,
  },
  featureTitle: {
    lineHeight: 28,
    marginBottom: 4,
  },
  featureDescription: {
    color: "#667085",
    lineHeight: 24,
  },
  audioContainer: {
    backgroundColor: "#F3F4F6",
    padding: 12,
    borderRadius: 8,
    marginBottom: 24,
  },
  audioText: {
    color: "#4B5563",
    textAlign: "center",
  },
  buttonContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  gotItButton: {
    backgroundColor: "#4F46E5",
  },
});

export default ActivityInstructionsModal;
