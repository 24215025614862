import React, { useState, useRef } from "react";
import { View, StyleSheet, Dimensions } from "react-native";
import { Portal } from "react-native-paper";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomText from "../general/CustomText/CustomText";

const Tooltip = ({ children, content, offset = -20, horizontalOffset = -45 }) => {
  const { isMedium } = useResponsiveScreen();
  const [visible, setVisible] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
  const targetRef = useRef(null);

  const showTooltip = () => {
    targetRef.current.measure((x, y, width, height, pageX, pageY) => {
      const screenWidth = Dimensions.get("window").width;

      let tooltipTop = pageY - height - offset - 10;
      let tooltipLeft = pageX + width / 2 + horizontalOffset;

      if (tooltipLeft + 110 > screenWidth) {
        tooltipLeft = screenWidth - 110 - 10;
      }

      if (tooltipTop < 0) {
        tooltipTop = pageY + height + 10;
      }

      setTooltipPosition({ top: tooltipTop, left: tooltipLeft });
      setVisible(true);
    });
  };

  const hideTooltip = () => {
    setVisible(false);
  };

  if (!isMedium) {
    return <View>{children}</View>;
  }

  return (
    <View>
      <View ref={targetRef} onMouseEnter={showTooltip} onMouseLeave={hideTooltip}>
        {children}
      </View>
      {visible && (
        <Portal>
          <View style={[styles.tooltipContainer, tooltipPosition]}>
            <View style={[styles.tooltip, styles.tooltipBottom]}>
              <CustomText style={styles.tooltipText} text={content} numberOfLines={1} ellipsizeMode="tail" />
              <View style={styles.triangle} />
            </View>
          </View>
        </Portal>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  tooltipContainer: {
    position: "absolute",
    zIndex: 1000,
    maxWidth: 130,
  },
  tooltip: {
    backgroundColor: "#000B33",
    padding: 8,
    borderRadius: 8,
  },
  tooltipText: {
    color: "#fff",
    fontSize: 12,
    fontFamily: "Inter",
    fontWeight: 600,
    lineHeight: 12,
  },
  triangle: {
    position: "absolute",
    bottom: -8,
    left: "50%",
    marginLeft: -5,
    width: 0,
    height: 0,
    borderLeftWidth: 5,
    borderRightWidth: 5,
    borderTopWidth: 8,
    borderLeftColor: "transparent",
    borderRightColor: "transparent",
    borderTopColor: "#000B33",
  },
});

export default Tooltip;
