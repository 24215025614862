import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import { shadowStyles } from "../../../styles/shadow";

const ExportAccount = ({ exportButtonClick, cancelButtonClick }) => {
  return (
    <View style={styles.container}>
      <View style={[shadowStyles.smallShadow, styles.questionBox]}>
        <CustomText
          style={styles.questionBoxText}
          size="xs"
          text="Ready to export your account data? We'll compile all your information into a CSV file for you to download. This includes your learning history, preferences, and interaction logs."
        />
      </View>
      <View style={styles.buttonContainer}>
        <CustomButton
          text="Download CSV"
          onPress={exportButtonClick}
          styleType="primary"
          size="m"
          style={{ marginBottom: 10, aliignItems: "center" }}
          bold="true"
        />
        <CustomButton
          text="Cancel"
          onPress={cancelButtonClick}
          styleType="primaryLight"
          size="m"
          style={styles.backButton}
          bold="true"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
  backButton: {
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderRadius: 10,
  },
  questionBox: {
    padding: 10,
    backgroundColor: "#F9FAFB",
    borderRadius: 10,
    marginBottom: 20,
    flexDirection: "row",
    alignItems: "center",
  },
  questionBoxText: {
    color: "#344054",
  },
});

export default ExportAccount;
