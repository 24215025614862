import { useState } from "react";
import { MotiView } from "moti";
import { useNavigation } from "@react-navigation/native";
import { View, StyleSheet } from "react-native";
import Skeleton from "react-loading-skeleton";
import CoursePickerList from "../../../components/lms/CoursePickerList/CoursePickerList";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CoreHeader from "../../../components/svgs/common/CoreHeader";
import { shadowStyles } from "../../../styles/shadow";
import ElectiveHeader from "../../../components/svgs/common/ElectiveHeader";
import GeneralHeader from "../../../components/svgs/common/GeneralHeader";

function CoursePlanListContainer({ type }) {
  const [courses, setCourses] = useState([]);
  const [isLoading] = useState(false);
  const navigation = useNavigation();

  const getTypeConfig = (type) => {
    switch (type.toLowerCase()) {
      case "core":
        return {
          title: "Core Courses: Computer Science",
          subTitle:
            "These required courses are are pre-selected and tailored to your degree and form the foundation of your learning path.",
          Icon: CoreHeader,
        };
      case "elective":
        return {
          title: "Elective Courses",
          subTitle:
            "Personalize your degree with electives that fit your interests and career goals. Browse recommended courses or search our database.",
          Icon: ElectiveHeader,
        };
      case "general":
        return {
          title: "General Courses",
          subTitle:
            "Choose from a variety of foundational courses like English, History, and Social Sciences to meet your degree requirements.",
          Icon: GeneralHeader,
        };
      default:
        console.log("default runs", type);
        return {
          title: "Core Courses",
          subTitle:
            "These required courses are are pre-selected and tailored to your degree and form the foundation of your learning path.",
          Icon: CoreHeader,
        };
    }
  };

  const typeConfig = getTypeConfig(type);

  const handlePress = (id) => {
    const course = courses.filter((course) => course.id === id)[0];
    navigation.replace("Custom Course Student", {
      course_id: course.id,
      integration_id: integrationId,
    });
  };

  const handleOnHoverIn = (id) => {
    setCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: true,
          };
        }

        return course;
      })
    );
  };

  const handleOnHoverOut = (id) => {
    setCourses((prevCourses) =>
      prevCourses.map((course) => {
        if (course.id === id) {
          return {
            ...course,
            isHovered: false,
          };
        }

        return course;
      })
    );
  };

  return (
    <MotiView
      from={{ opacity: 0, translateY: 20 }}
      animate={{ opacity: 1, translateY: 0 }}
      delay={500}
      transition={{ type: "timing", duration: 300 }}
      style={{ flex: 1 }}
      aria-live="polite">
      {isLoading ? (
        <View style={{ flexDirection: "row", flexWrap: "wrap", gap: 20, marginBottom: 20, justifyContent: "center" }}>
          {[...Array(3)].map((_, index) => (
            <Skeleton width={400} height={210} margin={10} borderRadius={30} key={index} />
          ))}
        </View>
      ) : (
        <>
          <View style={{ flexDirection: "row", alignItems: "center" }}>
            <View
              style={[
                { borderRadius: 100, padding: 12, margin: 8, justifyContent: "center", alignItems: "center" },
                shadowStyles.allAroundShadow,
              ]}>
              <typeConfig.Icon />
            </View>
            <View style={styles.subheaderContainer}>
              <CustomText
                useTranslationText={false}
                text={typeConfig.title}
                size={"xl"}
                weight="bold"
                role="heading"
                aria-level="1"
              />
              <CustomText
                useTranslationText={false}
                text={typeConfig.subTitle}
                size="s"
                weight="regular"
                style={styles.subText}
              />
            </View>
          </View>
          <CustomSpacing type="vertical" size="m" />
          <CoursePickerList
            section={type}
            onPress={handlePress}
            onHoverIn={handleOnHoverIn}
            onHoverOut={handleOnHoverOut}
            isLoading={isLoading}
            isNull={false}
          />
        </>
      )}
    </MotiView>
  );
}

const styles = StyleSheet.create({
  subheaderContainer: {
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 4,
  },

  subText: {
    color: "#667085",
    // marginTop: 8,
    lineHeight: 20,
    // marginBottom: 20,
  },
});

export default CoursePlanListContainer;
