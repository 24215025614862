import { View, StyleSheet } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { useAppConfig } from "../../../AppConfigProvider";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";

function WeightGoal({ value, goal }) {
  const { hp } = useResponsiveScreen();
  const appConfig = useAppConfig();

  const gameMenuBg = appConfig.gameMenuBg;
  const primaryColor = appConfig.primaryColor;

  return (
    <View style={styles.container}>
      <View style={[styles.topContainer, { backgroundColor: gameMenuBg }]}>
        {value ? (
          <>
            <View style={styles.row}>
              <CustomText
                useTranslationText={false}
                text={value}
                styleType="light"
                size="xxl"
                weight="bold"
                style={[styles.valueText, { fontSize: hp(3.5), lineHeight: hp(3.1) }]}
              />
              <CustomSpacing type="horizontal" size="s" />
              <CustomText text="lbs" styleType="light" />
            </View>
            <CustomSpacing type="vertical" size="s" />
            <CustomText text="Current" styleType="light" />
          </>
        ) : (
          <View style={styles.placeholderContainer}>
            <CustomText text="No current weight set" styleType="light" />
          </View>
        )}
      </View>
      <CustomSpacing type="vertical" size="s" />

      <View style={[styles.bottomContainer, { backgroundColor: primaryColor }]}>
        {goal ? (
          <>
            <View style={styles.row}>
              <CustomText
                useTranslationText={false}
                text={goal || 0}
                styleType="light"
                size="xxl"
                weight="bold"
                style={[styles.valueText, { fontSize: hp(3.5), lineHeight: hp(3.1) }]}
              />
              <CustomSpacing type="horizontal" size="s" />
              <CustomText useTranslationText={false} text="lbs" styleType="light" />
            </View>
            <CustomSpacing type="vertical" size="s" />
            <CustomText useTranslationText={false} text="Goal" styleType="light" />
          </>
        ) : (
          <View style={styles.placeholderContainer}>
            <CustomText text="No weight goal set" styleType="light" />
          </View>
        )}
      </View>
    </View>
  );
}
const styles = StyleSheet.create({
  container: {
    minWidth: 100,
  },
  topContainer: {
    borderTopRightRadius: 16,
    borderTopLeftRadius: 16,
    padding: 16,
  },
  bottomContainer: {
    borderBottomRightRadius: 16,
    borderBottomLeftRadius: 16,
    padding: 16,
  },
  row: {
    flexDirection: "row",
    alignItems: "flex-end",
  },
  valueText: {
    fontWeight: "500",
  },
  placeholderContainer: {
    paddingVertical: 20,
  },
});

export default WeightGoal;
