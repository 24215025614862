import * as React from "react";
import Svg, { Rect, Path } from "react-native-svg";

function ElectiveTitle(props) {
  return (
    <Svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Rect y={0.0527344} width={26} height={26} rx={13} fill="#9747FF" />
      <Path
        d="M13.777 8.485a.777.777 0 00-1.554-.006c-.005 1.428-.378 2.327-.952 2.882-.58.56-1.497.895-2.853.915a.777.777 0 00.011 1.553c1.506 0 2.4.398 2.935.976.546.59.847 1.51.86 2.825a.777.777 0 001.551.032c.07-1.39.44-2.323 1.014-2.908.564-.576 1.44-.93 2.778-.925a.777.777 0 00.02-1.553c-1.469-.032-2.384-.42-2.94-.996-.556-.579-.876-1.477-.87-2.795z"
        fill="#fff"
      />
    </Svg>
  );
}

export default ElectiveTitle;
