import DefaultLayout from "../layouts/DefaultLayout";
import React from "react";
import { StyleSheet, View } from "react-native";
import CourseFormContainer from "../containers/customCoursework/CourseFormContainer";

function CustomCourseworkCourseFormView() {
  return (
    <DefaultLayout>
      <View style={styles.centeredView}>
        <CourseFormContainer />
      </View>
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  centeredView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
});

export default CustomCourseworkCourseFormView;
