import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";

const AddModule = ({ addButtonClick, onChangeModuleName, setOrder, hasOrder }) => {
  return (
    <View style={styles.container}>
      <View style={styles.buttonContainer}>
        <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text="Module Name" size="xs" />
        <CustomTextInput
          style={{ backgroundColor: "#F8F8F8", borderColor: "#DEDEDE" }}
          onChangeText={onChangeModuleName}
          accessibilityHint="Enter your module name"
        />
        <CustomSpacing type="vertical" size="s" />
        {hasOrder && (
          <>
            <CustomText style={{ fontSize: "16", fontWeight: 700, marginBottom: 15 }} text="Order" size="xs" />
            <CustomTextInput placeholder="Ex: 9" required onChangeText={(text) => setOrder(parseInt(text, 10))} />
            <CustomSpacing type="vertical" size="l" />
          </>
        )}
        <CustomButton
          text="Add Module"
          onPress={addButtonClick}
          styleType="primary"
          size="m"
          style={{ marginBottom: 10 }}
          bold="true"
          aria-label="Add Module"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    margin: 20,
    padding: 20,
  },
  buttonContainer: {
    flexDirection: "column",
    justifyContent: "center",
  },
});

export default AddModule;
