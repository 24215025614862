import { Svg, G, Path, Defs, Rect } from "react-native-svg";

function ExitCircle({ fill = "#000", ...props }) {
  return (
    <Svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <G clipPath="url(#clip0_390_9310)">
        <Path
          d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z"
          fill={fill}
        />
        <Path
          d="M28.9197 27.5982C29.1758 27.8544 29.3198 28.2018 29.3198 28.5641C29.3198 28.9264 29.1758 29.2738 28.9197 29.53C28.6635 29.7862 28.316 29.9301 27.9538 29.9301C27.5915 29.9301 27.244 29.7862 26.9879 29.53L19.7731 22.313L12.556 29.5277C12.2999 29.7839 11.9524 29.9278 11.5901 29.9278C11.2278 29.9278 10.8804 29.7839 10.6242 29.5277C10.368 29.2716 10.2241 28.9241 10.2241 28.5618C10.2241 28.1995 10.368 27.8521 10.6242 27.5959L17.8413 20.3811L10.6265 13.1641C10.3703 12.9079 10.2264 12.5605 10.2264 12.1982C10.2264 11.8359 10.3703 11.4885 10.6265 11.2323C10.8827 10.9761 11.2301 10.8322 11.5924 10.8322C11.9547 10.8322 12.3021 10.9761 12.5583 11.2323L19.7731 18.4493L26.9901 11.2311C27.2463 10.975 27.5937 10.8311 27.956 10.8311C28.3183 10.8311 28.6658 10.975 28.9219 11.2311C29.1781 11.4873 29.322 11.8348 29.322 12.1971C29.322 12.5593 29.1781 12.9068 28.9219 13.163L21.7049 20.3811L28.9197 27.5982Z"
          fill="white"
        />
      </G>
      <Defs>
        <clipPath id="clip0_390_9310">
          <Rect width="40" height="40" fill="white" />
        </clipPath>
      </Defs>
    </Svg>
  );
}

export default ExitCircle;
