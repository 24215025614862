import React from "react";
import { StyleSheet, View } from "react-native";
import EventList from "../../components/calendar/EventList/EventList";

const CalendarContainer = ({ events, isGcal }) => {
  return (
    <View style={styles.container}>
      <EventList events={events} isGcal={isGcal} />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    //alignItems: 'center',
  },
});

export default CalendarContainer;
