import React from "react";
import { View } from "react-native";
import Event from "../Event/Event";
import Placeholder from "../../general/Placeholder/Placeholder";

function EventList({ events, isGcal }) {
  function calculateDuration(start, end) {
    if (start.date && end.date) {
      return "All Day";
    }
    const startDate = new Date(start.dateTime);
    const endDate = new Date(end.dateTime);
    const difference = endDate.getTime() - startDate.getTime();
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference / (1000 * 60)) % 60);
    return `${hours}h ${minutes}m`;
  }

  if (!isGcal) {
    return (
      <View style={{ flex: 1 }}>
        <Placeholder
          title="Your Google Calendar is not connected"
          subtitle="Go to settings and connect your Google Calendar to see events here."
        />
      </View>
    );
  }

  return (
    <View style={{ flex: 1 }}>
      {events.length > 0 ? (
        events.map((event) => {
          const { id, summary: title, location = "", start, end } = event;
          let startTime, duration;
          if (start.date && end.date) {
            startTime = "All Day";
            duration = "All Day";
          } else {
            startTime = new Date(start.dateTime).toLocaleTimeString([], {
              hour: "numeric",
              minute: "2-digit",
            });
            duration = calculateDuration(start, end);
          }

          return <Event key={id} title={title} description={location} duration={duration} time={startTime} />;
        })
      ) : (
        <Placeholder
          title="No Events on Calendar"
          subtitle="You can add events to your calendar by clicking the 'Add Event' button."
        />
      )}
    </View>
  );
}

export default EventList;
