function getLearningRoute(params) {
  const field = params?.field;
  const subfield = params?.subfield;
  const topic = params?.topic;
  const chapter = params?.chapter;
  const subchapter = params?.subchapter;

  const ancestors = [field, subfield, topic, chapter, subchapter];
  for (let i = ancestors.length - 1; i >= 0; i--) {
    if (!ancestors[i]) {
      ancestors.splice(i, 1);
    } else {
      break;
    }
  }
  ancestors.pop();

  const name = subchapter || chapter || topic || subfield || field;
  const label = subchapter
    ? "Subchapter"
    : chapter
      ? "Chapter"
      : topic
        ? "Topic"
        : subfield
          ? "Subfield"
          : field
            ? "Field"
            : null;

  return { ancestors, name, label };
}

export default getLearningRoute;
