import { Svg, Path } from "react-native-svg";

const JourneyPath = () => (
  <Svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <Path
      d="M21.875 18.375C21.099 18.3757 20.3453 18.6338 19.7317 19.1088C19.1181 19.5839 18.6795 20.249 18.4844 21H7.875C6.94674 21 6.0565 20.6313 5.40013 19.9749C4.74375 19.3185 4.375 18.4283 4.375 17.5C4.375 16.5717 4.74375 15.6815 5.40013 15.0251C6.0565 14.3687 6.94674 14 7.875 14H18.375C19.5353 14 20.6481 13.5391 21.4686 12.7186C22.2891 11.8981 22.75 10.7853 22.75 9.625C22.75 8.46468 22.2891 7.35188 21.4686 6.53141C20.6481 5.71094 19.5353 5.25 18.375 5.25H7.875C7.64294 5.25 7.42038 5.34219 7.25628 5.50628C7.09219 5.67038 7 5.89294 7 6.125C7 6.35706 7.09219 6.57962 7.25628 6.74372C7.42038 6.90781 7.64294 7 7.875 7H18.375C19.0712 7 19.7389 7.27656 20.2312 7.76884C20.7234 8.26113 21 8.92881 21 9.625C21 10.3212 20.7234 10.9889 20.2312 11.4812C19.7389 11.9734 19.0712 12.25 18.375 12.25H7.875C6.48261 12.25 5.14726 12.8031 4.16269 13.7877C3.17812 14.7723 2.625 16.1076 2.625 17.5C2.625 18.8924 3.17812 20.2277 4.16269 21.2123C5.14726 22.1969 6.48261 22.75 7.875 22.75H18.4844C18.648 23.3838 18.9861 23.9589 19.4602 24.4102C19.9343 24.8615 20.5255 25.1707 21.1665 25.3029C21.8076 25.4351 22.4729 25.3848 23.0868 25.1579C23.7007 24.9309 24.2387 24.5364 24.6397 24.019C25.0406 23.5016 25.2885 22.8822 25.3551 22.2311C25.4217 21.5799 25.3043 20.9231 25.0164 20.3353C24.7285 19.7475 24.2815 19.2522 23.7262 18.9057C23.1709 18.5592 22.5295 18.3753 21.875 18.375ZM21.875 23.625C21.5289 23.625 21.1905 23.5224 20.9028 23.3301C20.615 23.1378 20.3907 22.8645 20.2582 22.5447C20.1258 22.2249 20.0911 21.8731 20.1586 21.5336C20.2262 21.1941 20.3928 20.8823 20.6376 20.6376C20.8823 20.3928 21.1941 20.2261 21.5336 20.1586C21.8731 20.0911 22.2249 20.1258 22.5447 20.2582C22.8645 20.3907 23.1378 20.615 23.3301 20.9028C23.5224 21.1905 23.625 21.5289 23.625 21.875C23.625 22.3391 23.4406 22.7842 23.1124 23.1124C22.7842 23.4406 22.3391 23.625 21.875 23.625Z"
      fill="#98A2B3"
    />
  </Svg>
);

export default JourneyPath;
