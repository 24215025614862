import React from "react";
import { StyleSheet, Animated, View, Pressable, ImageBackground } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CoreTitle from "../../../components/svgs/common/CoreTitle";
import GeneralTitle from "../../../components/svgs/common/GeneralTitle";
import ElectiveTitle from "../../../components/svgs/common/ElectiveTitle";
import ProgressTitle from "../../../components/svgs/common/ProgressTitle";
import TimeLeftTitle from "../../../components/svgs/common/TimeLeftTitle";
import { shadowStyles } from "../../../styles/shadow";
import Skeleton from "react-loading-skeleton";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";

const StatRow = ({ Icon, label, value, loading }) => (
  <View style={styles.statRow}>
    <Icon />
    <CustomText size="s" weight="medium" useTranslationText={false} style={styles.statLabel} text={label} />
    {loading ? (
      <Skeleton width={80} height={16} />
    ) : (
      <CustomText size="s" weight="normal" useTranslationText={false} style={styles.statValue} text={value} />
    )}
  </View>
);

function SlideInMenu({
  visible,
  slideAnim,
  onClose,
  onSettingsPress,
  isAnalyticsEnabled,
  onAcademicPlanNav,
  onLogout,
  progressStats,
  loading,
}) {
  if (!visible) return null;

  return (
    <Animated.View style={[styles.mobile, { transform: [{ translateX: slideAnim }] }]}>
      <View style={styles.mobileContainer}>
        <View style={styles.topRow}>
          <Pressable role="button" aria-label="Close menu" style={styles.closeButton} onPress={onClose}>
            <Ionicons name="close" size={24} color="black" />
          </Pressable>
        </View>

        <View style={styles.contentContainer}>
          {isAnalyticsEnabled && (
            <>
              <ImageBackground
                source={require("../../../assets/degrees/degreeBG.png")}
                resizeMode="cover"
                role="img"
                imageStyle={styles.headerBackgroundImage}
                aria-label="The Image for the Degree"
                style={styles.headerBackground}>
                <View style={styles.headerContent}>
                  <CustomText
                    size="s"
                    textType="display"
                    weight="ultraBold"
                    role="heading"
                    aria-level="1"
                    useTranslationText={false}
                    style={styles.title}
                    text="Computer Science"
                  />
                </View>
              </ImageBackground>

              <View style={styles.statsContainer}>
                <StatRow
                  Icon={ProgressTitle}
                  label="Overall Progress:"
                  value={`${progressStats.overall}% Complete`}
                  loading={loading}
                />
                <StatRow
                  Icon={TimeLeftTitle}
                  label="Est. Time Left:"
                  value={`${progressStats.timeLeft} hours`}
                  loading={loading}
                />
                <StatRow
                  Icon={CoreTitle}
                  label="Core Courses:"
                  value={`${progressStats.coreCourses}/${progressStats.totalCoreCourses} Completed`}
                  loading={loading}
                />
                <StatRow
                  Icon={GeneralTitle}
                  label="General Courses:"
                  value={`${progressStats.generalCourses}/${progressStats.totalGeneralCourses} Completed`}
                  loading={loading}
                />
                <StatRow
                  Icon={ElectiveTitle}
                  label="Electives:"
                  value={`${progressStats.electives}/${progressStats.totalElectives} Completed`}
                  loading={loading}
                />
              </View>

              <CustomButton
                text="Edit Academic Plan"
                styleType="primaryLight"
                onPress={onAcademicPlanNav}
                aria-label="Edit Academic Plan"
                style={[styles.editButton, shadowStyles.smallShadowButton]}
              />
            </>
          )}

          <CustomButton
            text="View Settings"
            styleType="primaryLight"
            onPress={onSettingsPress}
            aria-label="View Settings"
            style={[styles.editButton, shadowStyles.smallShadowButton]}
          />
          <CustomButton
            text="Log Out"
            styleType="primaryLight"
            onPress={onLogout}
            aria-label="Log Out"
            style={[styles.editButton, shadowStyles.smallShadowButton]}
            textStyle={{ color: "red" }}
          />
        </View>
      </View>
    </Animated.View>
  );
}

const styles = StyleSheet.create({
  mobile: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    width: 360,
    zIndex: 100,
  },
  mobileContainer: {
    flex: 1,
    padding: 20,
    paddingTop: 8,
    backgroundColor: "white",
    borderLeftWidth: 1,
    borderLeftColor: "#e0e0e0",
  },
  topRow: {
    flexDirection: "row",
    justifyContent: "flex-end",
    padding: 16,
    marginBottom: 24,
  },
  closeButton: {
    padding: 5,
  },
  contentContainer: {
    flex: 1,
  },
  headerBackground: {
    width: 320,
    height: 221,
    padding: 20,
  },
  headerBackgroundImage: {
    borderRadius: 12,
  },
  headerContent: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  title: {
    color: "white",
    fontSize: 24,
    fontWeight: "bold",
    marginBottom: 15,
    marginTop: 10,
  },
  statsContainer: {
    marginTop: 20,
    padding: 8,
  },
  statRow: {
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 20,
  },
  statLabel: {
    flex: 1,
    justifyContent: "center",
    lineHeight: 20,
    marginLeft: 8,
    color: "#344054",
  },
  statValue: {
    color: "#344054",
    lineHeight: 20,
  },
  editButton: {
    marginTop: 12,
    marginHorizontal: 20,
    paddingVertical: 12,
    alignItems: "center",
    borderWidth: 1,
    borderColor: "rgba(5, 6, 15, 0.1)",
  },
});

export default SlideInMenu;
