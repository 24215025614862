import * as React from "react";
import Svg, { Path } from "react-native-svg";

function GeneralHeader(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path d="M12 8.68l-2.862 1.654v3.333L12 15.32l2.863-1.654v-3.333L12 8.68z" fill="#008383" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.827 2.878a1.974 1.974 0 10-3.655 0l-5.414 3.09a1.974 1.974 0 10-1.642 3.07v5.924a1.974 1.974 0 101.642 3.07l5.415 3.091a1.974 1.974 0 103.655 0l5.414-3.09a1.974 1.974 0 101.642-3.07V9.038a1.974 1.974 0 10-1.642-3.07l-5.415-3.091zM11.03 6.732c.6-.346 1.34-.346 1.94 0l3.094 1.788c.602.348.971.99.971 1.684v3.593c0 .694-.37 1.336-.971 1.684l-3.093 1.787c-.6.347-1.34.347-1.94 0l-3.094-1.787a1.945 1.945 0 01-.971-1.684v-3.593c0-.694.369-1.336.97-1.684l3.094-1.788z"
        fill="#008383"
      />
    </Svg>
  );
}

export default GeneralHeader;
