import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import SuggestionsCardList from "../../../components/dashboard/SuggestionsCardList/SuggestionsCardList";
import { View } from "react-native";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import { MotiView } from "moti";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import { useSuggestionsQuery, useGetLearningTreeQuery } from "../../../graphql/generated/graphql";
import { useNavigation } from "@react-navigation/native";

function SuggestionsListContainer() {
  const { isMedium } = useResponsiveScreen();
  const navigation = useNavigation();

  const { data } = useSuggestionsQuery();
  const { refetch: refetchLearningTree } = useGetLearningTreeQuery({
    variables: { level: null, name: null },
    skip: true,
  });

  const suggestionData = data?.getSuggestions || [];

  const handleCardPress = async (content) => {
    const cardDate = data.getSuggestions.find((suggestion) => suggestion.content === content);
    const cardType = cardDate.type;

    switch (cardType) {
      case "Assignment":
        navigation.navigate("Resource", {
          resource_type: "assignment",
          resource_id: cardDate.assignmentId,
          course_id: cardDate.classId,
          integration_id: cardDate.integrationId,
        });
        break;
      case "Todo":
        navigation.navigate("Productivity");
        break;
      case "Journey":
        navigation.navigate("Journey", { id: cardDate.journeyId });
        break;
      case "Topic": {
        const treeDate = await refetchLearningTree({
          level: "Topic",
          name: cardDate.trackName,
        });
        const tree = treeDate?.data?.getLearningTree;
        navigation.navigate("Lesson", {
          field: tree.FieldName,
          subfield: tree.SubfieldName,
          topic: tree.TopicName,
        });
        break;
      }
    }
  };

  return (
    <MotiView
      from={{
        opacity: 0,
        translateY: 20,
        scale: 1,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
        scale: 1,
      }}
      delay={10}
      transition={{ type: "timing", duration: 500 }}>
      <View>
        <CustomText text="Suggestions" textType="display" size="xs" weight={isMedium ? "bold" : "ultraBold"} />
        <CustomSpacing type="vertical" size="xl" />
        {suggestionData.length === 0 ? (
          <View style={{ height: 150 }}>
            <View
              style={{
                borderRadius: 20,
                height: "100%",
                backgroundColor: "#F9FAFB",
                alignItems: "center",
                justifyContent: "center",
              }}>
              <View style={{ alignItems: "center", marginBottom: 10 }}>
                <CustomText
                  text="No suggestions at the moment!"
                  textType="text"
                  size="xl"
                  weight="medium"
                  style={{ textAlign: "center" }}
                />
                <CustomText
                  style={{ color: "#A9A9A9" }}
                  text="It looks like it's a quiet day."
                  size="m"
                  weight="normal"
                  textType="text"
                />
              </View>
            </View>
          </View>
        ) : (
          <SuggestionsCardList data={suggestionData} onPress={handleCardPress} />
        )}
      </View>
    </MotiView>
  );
}

export default SuggestionsListContainer;
