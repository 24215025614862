import { useState, useEffect, useRef, useCallback } from "react";
import { View, ScrollView, StyleSheet } from "react-native";
import { useAppState } from "../../../contexts/AppStateContext";
import MessageContainer from "../MessageContainer/MessageContainer";
import CustomButton from "../../../components/common/general/CustomButton/CustomButton";
import { DownArrow } from "../../../components/svgs/common";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import { useChatBar } from "../../../contexts/ChatBarContext";
import ProcessingMessageBox from "../../../components/chat/LoadingMessage/ProcessingMessageBox";

function ChatContainer({ chatData }) {
  const { state } = useAppState();
  const [conversationData, setConversationData] = useState([]);
  const scrollViewRef = useRef(null);
  const [isUserScrolling, setIsUserScrolling] = useState(false);
  const [downButton, setDownButton] = useState(false);
  const isAtBottomRef = useRef(true);
  const { isMedium } = useResponsiveScreen();
  const { state: chatBarState } = useChatBar();

  const handleScroll = useCallback((event) => {
    const { layoutMeasurement, contentOffset, contentSize } = event.nativeEvent;
    const isAtBottom = layoutMeasurement.height + contentOffset.y >= contentSize.height - 50;

    if (!isAtBottom) {
      setIsUserScrolling(true);
      setDownButton(true);
    } else {
      setIsUserScrolling(false);
      setDownButton(false);
    }

    isAtBottomRef.current = isAtBottom;
  }, []);

  const scrollToBottom = (animated = false) => {
    if (scrollViewRef.current) {
      scrollViewRef.current.scrollToEnd({ animated });
    }
  };

  const scrollToBottomButton = () => {
    setDownButton(false);
    scrollToBottom(true);
  };

  useEffect(() => {
    if (scrollViewRef.current) {
      scrollViewRef.current.setAttribute("data-sensitive", "true");
    }
  }, []);

  useEffect(() => {
    if (isAtBottomRef.current && !isUserScrolling) {
      scrollToBottom();
    }
  }, [conversationData, isUserScrolling]);

  useEffect(() => {
    scrollToBottom(true);
  }, [state.content?.author]);

  useEffect(() => {
    const conversation = chatData?.conversation ? [...chatData.conversation].reverse() : [];
    setConversationData(conversation);
  }, [chatData]);

  useEffect(() => {
    if (!state.content?.message) return;
    if (state.content?.author?.toLowerCase() !== "ai") return;

    const conversation = chatData?.conversation ? [...chatData.conversation].reverse() : [];

    const newAIMessage = {
      content: state.content?.message,
      sender: state.content?.author?.toLowerCase(),
    };

    const isNew = !conversation.some((item) => item.content === newAIMessage.content);
    const combinedChatData = isNew ? [...conversation, newAIMessage] : conversation;

    setConversationData(combinedChatData);
  }, [state.content]);

  return (
    <>
      <View style={styles.container}>
        <ScrollView
          ref={scrollViewRef}
          style={styles.scrollView}
          onScroll={handleScroll}
          scrollEventThrottle={16}
          showsVerticalScrollIndicator={false}>
          <View style={styles.view}>
            {conversationData.map((item, index) => (
              <View key={index}>
                <MessageContainer message={item} index={index} />
              </View>
            ))}
          </View>
        </ScrollView>
        {downButton && (
          <View style={styles.buttonContainer}>
            <CustomButton
              styleType="primary"
              style={styles.button}
              leftIcon={<DownArrow />}
              onPress={scrollToBottomButton}
              rightIcon={undefined}
              text={undefined}
              textProps={undefined}
              textStyle={undefined}
              disabled={false}
            />
          </View>
        )}
      </View>
      {chatBarState.llmMessage.meta.isLoading && <ProcessingMessageBox message={state?.meta?.executionInfo?.message} />}
    </>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    paddingBottom: 16,
    zIndex: 800,
  },
  scrollView: {
    height: 20,
    paddingHorizontal: 10,
    zIndex: 0,
  },
  view: {
    width: "100%",
    alignSelf: "center",
    maxWidth: 770,
    paddingHorizontal: 10,
  },
  button: {
    justifyContent: "center",
    position: "absolute",
    alignItems: "center",
    bottom: 10,
    width: 30,
    height: 30,
    borderRadius: 25,
    zIndex: 5,
  },
  buttonContainer: {
    alignItems: "center",
  },
  loader: {
    position: "absolute",
    left: 0,
    right: 0,
    alignItems: "center",
    justifyContent: "center",
  },
});

export default ChatContainer;
