import * as React from "react";
import Svg, { Path } from "react-native-svg";

function EducationIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M23.419 8.015l-10.875-6a1.125 1.125 0 00-1.088 0l-10.875 6a1.125 1.125 0 000 1.968l2.044 1.125v4.478c0 .46.17.906.477 1.249 1.27 1.418 4.117 3.79 8.898 3.79 1.27.007 2.534-.175 3.75-.54V22.5a1.125 1.125 0 102.25 0v-3.364a11.206 11.206 0 002.898-2.298c.308-.343.478-.787.477-1.248v-4.478l2.044-1.125a1.125 1.125 0 000-1.969v-.003zM12 4.285L20.546 9l-3.108 1.716a.177.177 0 01-.017-.012l-4.875-2.69a1.125 1.125 0 00-1.088 1.97l3.656 2.014L12 13.716 3.454 9 12 4.285zm0 14.09c-3.79 0-6.061-1.788-7.125-2.932V12.35l6.581 3.632a1.125 1.125 0 001.088 0l3.206-1.766v3.49c-1.198.453-2.47.68-3.75.669zm7.125-2.932c-.345.37-.721.71-1.125 1.014v-3.483l1.125-.62v3.09z"
        fill={fill}
      />
    </Svg>
  );
}

export default EducationIcon;
