import { View, StyleSheet } from "react-native";
import OnboardMenuContainer from "../containers/onboarding/OnboardMenuContainer/OnboardMenuContainer";
import BackgroundVideo from "../components/general/BackgroundVideo/BackgroundVideo.web";

function OnboardingLayout({ children }) {
  return (
    <View style={styles.container}>
      <BackgroundVideo />
      <OnboardMenuContainer />
      {children}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#fff",
  },
});

export default OnboardingLayout;
