import * as React from "react";
import Svg, { Path } from "react-native-svg";

function AssessmentCheck(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M21.375 4.5v15a1.875 1.875 0 01-1.875 1.875h-6.375a1.125 1.125 0 110-2.25h6V4.875H4.875v8.25a1.125 1.125 0 11-2.25 0V4.5A1.875 1.875 0 014.5 2.625h15A1.875 1.875 0 0121.375 4.5zm-9.33 9.704a1.124 1.124 0 00-1.593 0L6 18.656l-1.454-1.452a1.126 1.126 0 00-1.838 1.228c.056.137.14.261.244.366l2.25 2.25a1.125 1.125 0 001.594 0l5.25-5.25a1.125 1.125 0 000-1.594z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default AssessmentCheck;
