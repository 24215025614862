import React from "react";
import { StyleSheet, View } from "react-native";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import CustomText from "../../../common/general/CustomText/CustomText";
import { UpskillGrow } from "../../../svgs/careerGoals/index";

const UpskillAndGrow = () => {
  const { isLarge } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <UpskillGrow style={{ paddingRight: 4 }} aria-hidden={true} />
      <CustomText size={isLarge ? "s" : "xs"} style={styles.text} text="Upskill & Grow" />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
  },
  text: {},
});

export default UpskillAndGrow;
