import React from "react";
import { View, StyleSheet } from "react-native";
import CustomModal from "../../common/general/CustomModal/CustomModal";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomButton from "../../common/general/CustomButton/CustomButton";

const ExportAccountConfirmationModal = ({ visible, onClose }) => {
  return (
    <CustomModal visible={visible} onRequestClose={onClose}>
      <View style={styles.container}>
        <CustomText
          text="Your account data has been successfully exported. Check your downloads."
          size="m"
          textAlign="center"
        />
        <CustomButton text="Close" onPress={onClose} styleType="primary" style={{ marginTop: 30, width: "100%" }} />
      </View>
    </CustomModal>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 20,
    alignItems: "center",
  },
});

export default ExportAccountConfirmationModal;
