import React from "react";
import { StyleSheet, View } from "react-native";
import CustomButton from "../../common/general/CustomButton/CustomButton";
import CustomText from "../../common/general/CustomText/CustomText";
import { NotePencil, GreenCircle } from "../../svgs/common/index";
import UpskillAndGrow from "../CareerGoals/UpskillAndGrow/UpskillAndGrow";
import PivotNewCareer from "../CareerGoals/PivotNewCareer/PivotNewCareer";
import LookingForAJob from "../CareerGoals/LookingForAJob/LookingForAJob";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

const getGoal = (goal) => {
  switch (goal) {
    case "upskill":
      return <UpskillAndGrow />;
    case "pivot":
      return <PivotNewCareer />;
    case "looking":
      return <LookingForAJob />;
    default:
      return <CustomText text="" useTranslationText={false} />;
  }
};

const CareerInfo = ({ occupation, goal, careerPath, editPress }) => {
  const { isLarge } = useResponsiveScreen();

  return (
    <View style={styles.container}>
      <View style={{ flexDirection: isLarge ? "row" : "column" }}>
        <View style={styles.column}>
          <CustomText size={isLarge ? "s" : "xs"} weight="bold" style={styles.label} text="Current Occupation" />
          <CustomText size={isLarge ? "s" : "xs"} text={occupation} useTranslationText={false} />
        </View>
        <View style={styles.column}>
          <CustomText size={isLarge ? "s" : "xs"} weight="bold" style={styles.label} text="Career Goal" />
          <CustomText
            size={isLarge ? "s" : "xs"}
            weight="bold"
            style={styles.label}
            text={getGoal(goal)}
            useTranslationText={false}
          />
        </View>
        {careerPath ? (
          <View style={styles.column}>
            <GreenCircle style={{ paddingRight: 5 }} />
            <CustomText size={isLarge ? "s" : "xs"} weight="bold" style={styles.label} text="Active Career Path" />
            <CustomText size={isLarge ? "s" : "xs"} text={careerPath} style={styles.value} useTranslationText={false} />
          </View>
        ) : (
          <></>
        )}
      </View>
      <View style={styles.buttonColumn}>
        <CustomButton
          styleType="primaryTransparent"
          size="xl"
          leftIcon={<NotePencil />}
          style={styles.editButton}
          onPress={editPress}
          aria-label="Edit career info button"
        />
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "#f6f7ff",
    borderRadius: 25,
    padding: 18,
    alignItems: "flex-start",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: 15,
  },
  editButton: {
    backgroundColor: "white",
    maxWidth: 75,
    padding: 10,
  },
  label: {
    paddingRight: 8,
  },
  column: {
    flexDirection: "row",
    alignItems: "center",
    paddingTop: 10,
    paddingRight: 18,
  },
  buttonColumn: {
    alignItems: "flex-end",
  },
});

export default CareerInfo;
