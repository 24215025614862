import React, { useState } from "react";
import { View, Pressable, StyleSheet, Image } from "react-native";
import CustomText from "../../../common/general/CustomText/CustomText";
import CustomButton from "../../../common/general/CustomButton/CustomButton";
import { EyeIcon, EyeOffIcon } from "../../../svgs/common";
import useResponsiveScreen from "../../../../hooks/useResponsiveScreen";
import CustomSpacing from "../../../common/layout/CustomSpacing/CustomSpacing";
import CustomProgressBar from "../../../common/general/CustomProgressBar/CustomProgressBar";
import { shadowStyles } from "../../../../styles/shadow";

const HIDE = "hide";
const SHOW = "show";

const LearnMainCard = ({
  name,
  image,
  onCardPress,
  onShowHidePress,
  isHidden,
  completedTracks,
  tracksCount,
  mastery,
  screenReaderIsEnabled = false,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  const { isMedium } = useResponsiveScreen();

  const handleMouseEnter = () => {
    if (!isMedium) {
      return;
    }

    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    if (!isMedium) {
      return;
    }
    setIsHovered(false);
  };

  const isEyeButtonVisible = onShowHidePress && (isHovered || !isMedium || screenReaderIsEnabled);

  return (
    <Pressable
      role="button"
      aria-label={name}
      accessibilityHint="Press to navigate to the experience"
      style={[
        styles.mainContainer,
        shadowStyles.primary,
        isHidden && {
          opacity: 0.25,
        },
        isMedium ? styles.mediumDimensions : styles.mobileDimensions,
      ]}
      onPress={onCardPress}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <View style={isMedium ? { width: "100%" } : { minWidth: 120, width: "100%" }}>
        <Image
          style={[styles.image, isMedium ? { height: 200 } : { minHeight: 120 }]}
          source={image ? image : require("../../../../assets/onboardingModals/Experience.png")}
          resizeMode="cover"
          accessibilityLabel={name}
          alt={name}
          role={"image"}
        />
        {isEyeButtonVisible && (
          <View style={styles.showHideButton}>
            <CustomButton
              text=""
              styleType="light"
              size="xs"
              onPress={() => onShowHidePress(isHidden ? SHOW : HIDE)}
              leftIcon={
                isHidden ? (
                  <EyeOffIcon style={{ width: 24, height: 24 }} />
                ) : (
                  <EyeIcon style={{ width: 24, height: 24 }} />
                )
              }
              aria-label={isHidden ? "Show this card" : "Hide this card"}
            />
          </View>
        )}
        {!!mastery && (
          <View style={isMedium ? styles.progressBar : styles.progressBarMobile}>
            {isMedium && (completedTracks === 0 || completedTracks) && tracksCount && (
              <>
                <CustomText
                  useTranslationText={false}
                  text={`${completedTracks} of ${tracksCount} Track${tracksCount > 1 ? "s" : ""} Complete`}
                  size="xs"
                  weight="bold"
                  styleType="light"
                />
                <CustomSpacing type="vertical" size="xs" />
                <CustomSpacing type="vertical" size="xs" />
              </>
            )}
            <CustomProgressBar ariaLabel="Track mastery progress bar" progress={mastery} />
          </View>
        )}
      </View>
      <View style={[styles.bottomContainer, isMedium ? styles.mediumBottomPadding : styles.mobileBottomPadding]}>
        <CustomText
          useTranslationText={false}
          text={name}
          weight="bold"
          size={isMedium ? "m" : "s"}
          numberOfLines={2}
          ellipsizeMode="tail"
          style={{ textAlign: "left" }}
        />
      </View>
    </Pressable>
  );
};

const styles = StyleSheet.create({
  mainContainer: {
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    borderRadius: 10,
  },
  mobileDimensions: {
    width: "46%",
    margin: 6,
  },
  mediumDimensions: {
    width: 360,
    margin: 8,
  },
  bottomContainer: {
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    textAlign: "left",
    width: "100%",
  },
  mobileBottomPadding: {
    paddingVertical: 8,
    paddingHorizontal: 12,
  },
  mediumBottomPadding: {
    paddingVertical: 14,
    paddingHorizontal: 20,
  },
  image: {
    width: "100%",
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  showHideButton: {
    position: "absolute",
    top: 8,
    right: 8,
    elevation: 10,
  },
  progressBar: {
    position: "absolute",
    left: 4,
    bottom: 7,
    elevation: 10,
    backgroundColor: "#000B33B2",
    width: "97%",
    paddingHorizontal: 6,
    paddingVertical: 8,
    borderRadius: 10,
  },
  progressBarMobile: {
    position: "absolute",
    left: 0,
    bottom: 0,
    elevation: 10,
    backgroundColor: "#000B33B2",
    width: "100%",
    paddingHorizontal: 6,
    paddingVertical: 8,
  },
});

export default LearnMainCard;
