import Svg, { Path } from "react-native-svg";

function TodosIcon({ fill = "#000000", ...props }) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill={fill} xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M21.375 12a1.125 1.125 0 01-1.125 1.125H12a1.125 1.125 0 110-2.25h8.25A1.125 1.125 0 0121.375 12zM12 7.125h8.25a1.125 1.125 0 000-2.25H12a1.125 1.125 0 100 2.25zm8.25 9.75H12a1.125 1.125 0 100 2.25h8.25a1.125 1.125 0 000-2.25zM7.454 3.705L5.25 5.905l-.704-.705a1.127 1.127 0 00-1.594 1.594l1.5 1.5a1.125 1.125 0 001.594 0l3-3a1.127 1.127 0 00-1.594-1.594l.002.003zm0 6L5.25 11.905l-.704-.705a1.127 1.127 0 10-1.594 1.594l1.5 1.5a1.125 1.125 0 001.594 0l3-3a1.127 1.127 0 10-1.594-1.594l.002.003zm0 6L5.25 17.905l-.704-.705a1.126 1.126 0 00-1.838 1.228c.056.137.14.261.244.366l1.5 1.5a1.125 1.125 0 001.594 0l3-3a1.127 1.127 0 10-1.594-1.594l.002.003z"
        fill={fill}
      />
    </Svg>
  );
}

export default TodosIcon;
