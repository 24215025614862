import React from "react";
import { View, StyleSheet, Pressable } from "react-native";

import CustomText from "../../common/general/CustomText/CustomText";

import useResponsiveScreen from "../../../hooks/useResponsiveScreen";

import WhiteConvoIcon from "../../svgs/common/WhiteConvoIcon";
import WhiteMemoryIcon from "../../svgs/common/WhiteMemoryIcon";
import WhiteBulb from "../../svgs/common/WhiteBulb";

const ProgressDarkChatMenu = ({ onViewJournal }) => {
  const { isMedium } = useResponsiveScreen();

  return (
    <View role="menu" style={[styles.containerBase, isMedium ? styles.containerDesktop : styles.containerMobile]}>
      <View style={styles.titleContainer}>
        <CustomText styleType="light" text={"Coaching Chat"} size="m" textType="text" weight="ultraBold" />
      </View>

      <View style={styles.contentContainer}>
        <View style={styles.section}>
          <WhiteConvoIcon />
          <CustomText
            style={[styles.text, { marginVertical: 6 }]}
            text="Open-Ended Conversations"
            size="s"
            weight="bold"
            textType="text"
            useTranslationText={false}
          />
        </View>
        <CustomText
          text="Chat about anything, from general questions to personal interests."
          size="xs"
          style={styles.text}
          textType="text"
          useTranslationText={false}
        />

        <View style={styles.section}>
          <WhiteMemoryIcon />
          <CustomText
            style={[styles.text, { marginVertical: 6 }]}
            text="Persistent Memory"
            size="s"
            weight="bold"
            textType="text"
            useTranslationText={false}
          />
        </View>
        <CustomText
          text="Remembers past chats and brings them up in future conversations."
          size="xs"
          style={styles.text}
          textType="text"
          useTranslationText={false}
        />

        <View style={styles.section}>
          <WhiteBulb />
          <CustomText
            style={[styles.text, { marginVertical: 6 }]}
            text="Personalized Support"
            size="s"
            weight="bold"
            textType="text"
            useTranslationText={false}
          />
        </View>
        <CustomText
          text="Adapts to your conversations, offering insights and suggestions based on what you've shared in past chats."
          size="xs"
          style={styles.text}
          textType="text"
          useTranslationText={false}
        />
      </View>
      <Pressable style={styles.journalButton} onPress={onViewJournal} role="button" aria-label="View Journal">
        <CustomText text={"View Journal"} styleType="light" size="m" weight="normal" />
      </Pressable>
    </View>
  );
};

const styles = StyleSheet.create({
  containerBase: {
    flex: 1,
    alignSelf: "flex-start",
    width: 270,
    backgroundColor: "#06113C",
    paddingVertical: 32,
    paddingHorizontal: 8,
    flexDirection: "column",
    justifyContent: "space-between",
  },
  titleContainer: {
    padding: 20,
    paddingBottom: 5,
  },
  containerDesktop: {
    borderRadius: 20,
  },
  containerMobile: {
    top: 0,
    left: 0,
    borderRadius: 0,
  },
  journalButton: {
    backgroundColor: "#152351",
    paddingVertical: 10,
    paddingHorizontal: 16,
    alignItems: "center",
    justifyContent: "center",
    borderRadius: 10,
    marginHorizontal: 8,
    marginTop: 10,
  },
  contentContainer: {
    flex: 1,
    padding: 20,
  },
  section: {
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 16,
  },
  text: {
    color: "#FFFFFF",
  },
});

export default ProgressDarkChatMenu;
