import Sentry from "../utils/sentry";
import apiClient from "./ApiService";

const searchWeb = async (assignmentId, token) => {
  try {
    const response = await apiClient.get("/imgSearch", {
      params: { assignmentId }, // Pass both title and description as query parameters
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};

export { searchWeb };
