import { View, StyleSheet } from "react-native";
import Logo from "../../general/logos/Logo/Logo";
import CustomText from "../../common/general/CustomText/CustomText";
import CustomSpacing from "../../common/layout/CustomSpacing/CustomSpacing";
import CustomTextInput from "../../common/general/CustomTextInput/CustomTextInput";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function SendResetPasswordForm({ errorMessage, companionName, email, onEmailChange, onSendEmailPress, onBacktoLogin }) {
  return (
    <View style={styles.container}>
      <View accessible={true} role="header">
        <Logo name={companionName} height={55} width={190} />
        <CustomSpacing type="vertical" size="m" />
        <CustomText text="Reset Password" size="s" weight="bold" />
      </View>
      <CustomSpacing type="vertical" size="m" />
      <CustomText text="Email Address" size="s" />
      <CustomSpacing type="vertical" size="xs" />
      <CustomTextInput
        style={errorMessage && styles.textInputError}
        value={email}
        placeholder="Email..."
        onChangeText={(text) => onEmailChange(text)}
        autoComplete="email"
        aria-label="Email text input"
        accessibilityHint="Enter your email address"
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton
        text="Send Password Reset Email"
        styleType="primary"
        style={styles.submitButton}
        onPress={onSendEmailPress}
        accessibilityHint="Press to send password reset email"
      />
      <CustomSpacing type="vertical" size="m" />

      <CustomButton
        text="Go Back to Sign In"
        styleType="transparent"
        size="s"
        onPress={onBacktoLogin}
        accessibilityHint="Press to go back to sing in"
      />

      <CustomSpacing type="vertical" size="m" />
      <View aria-live={errorMessage ? "assertive" : "none"}>
        {errorMessage ? (
          <>
            <CustomSpacing type="vertical" size="s" />
            <CustomText role="alert" text={errorMessage} size="s" style={styles.errorMessage} />
            <CustomSpacing type="vertical" size="s" />
          </>
        ) : (
          <CustomSpacing type="vertical" size="xl" />
        )}
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    marginvertical: "auto",
    width: "100%",
    maxWidth: 600,
  },
  textInputError: {
    borderColor: "red",
  },
  submitButton: {
    justifyContent: "flex-start",
  },
  errorMessage: {
    color: "red",
    textAlign: "center",
  },
});

export default SendResetPasswordForm;
