import TaskDetails from "../../../components/tasks/TaskDetails/TaskDetails";

const TaskDetailsContainer = ({ task, onEdit, onDelete, onComplete, onClose }) => {
  return (
    <TaskDetails
      title={task ? task.name : ""}
      description={task ? task.description : ""}
      priority={task ? task.priority : "low"}
      startDate={task ? new Date(task.date) : new Date()}
      isRecurring={task ? task.isRecurring : false}
      recurringFrequency={task ? task.recurringFrequency : ""}
      recurringDayOfWeek={task ? task.recurringDayOfWeek : ""}
      recurringDayOfMonth={task ? task.recurringDayOfMonth : ""}
      onComplete={() => onComplete(task.id)}
      onEdit={() => onEdit(task.id)}
      onDelete={() => onDelete(task.id)}
      isComplete={task ? !task.completed : false}
      journeyId={task ? task.journeyId : ""}
      isPartOfJourney={task ? task.isPartOfJourney : false}
      onClose={onClose}
    />
  );
};

export default TaskDetailsContainer;
