import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ProgressTitle(props) {
  return (
    <Svg width={26} height={27} viewBox="0 0 26 27" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.583 2.22a3.25 3.25 0 00-3.25 3.25v17.333a1.083 1.083 0 002.167 0v-7.769c.339.12.704.185 1.083.185h11.83c1.817 0 2.826-2.101 1.692-3.52L18.72 8.72l2.384-2.98c1.134-1.418.125-3.52-1.692-3.52H7.583zM6.5 11.97c0 .598.485 1.083 1.083 1.083h11.83l-2.384-2.98a2.167 2.167 0 010-2.707l2.384-2.98H7.583c-.598 0-1.083.485-1.083 1.083v6.5z"
        fill="#344054"
      />
    </Svg>
  );
}

export default ProgressTitle;
