import * as React from "react";
import Svg, { Path } from "react-native-svg";

function NotificationBell(props) {
  return (
    <Svg width={20} height={20} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M9.954 1.25a1.565 1.565 0 00-1.565 1.565c0 .19-.005.38 0 .569-1.962.696-3.32 2.702-3.289 4.91.016 1.09-.019 2.213-.149 3.217-.075.582-.628.946-1.12 1.263a1.508 1.508 0 00-.706 1.289 1.563 1.563 0 001.563 1.562h10.625a1.563 1.563 0 001.562-1.563c0-.59-.308-1.051-.756-1.315-.494-.29-1.032-.636-1.13-1.2-.192-1.118-.288-2.395-.33-3.61-.072-2.038-1.345-3.83-3.143-4.515.01-.202.003-.405.003-.607A1.566 1.566 0 009.954 1.25zm-1.829 16a.375.375 0 01.375-.375h3a.375.375 0 01.375.375v.25a1.875 1.875 0 11-3.75 0v-.25z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default NotificationBell;
