import { useState, useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { StyleSheet } from "react-native";

import CustomText from "../components/common/general/CustomText/CustomText";

import { MotiView } from "moti";
import useResponsiveScreen from "../hooks/useResponsiveScreen";

import ProjectsView from "./ProjectsView";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import ComingSoonLayout from "../layouts/ComingSoonLayout.web";

function PortfolioView() {
  const { isMedium } = useResponsiveScreen();
  const [isCreatingJourney, setIsCreatingJourney] = useState(false);

  const posthog = usePostHog();

  useEffect(() => {
    posthog?.capture("Portfolio Page Visited");
  }, []);

  return (
    <ComingSoonLayout>
      {isMedium ? (
        <>
          <MotiView
            from={{
              opacity: 0,
              translateY: 20,
              scale: 1,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={10}
            transition={{ type: "timing", duration: 500 }}>
            <CustomText
              useTranslationText={false}
              role="header"
              text="Project Portfolio"
              size="l"
              textType="display"
              weight="ultraBold"
            />
            <CustomSpacing type="vertical" size="s" />
            <CustomText
              useTranslationText={false}
              text="Explore your existing projects or start a fresh one to demonstrate your skills and innovation."
              size="m"
              style={styles.coursesDescription}
            />
          </MotiView>
          <CustomSpacing type="vertical" size="s" />

          <CustomSpacing type="vertical" size="m" />
          <ProjectsView isCreatingJourney={isCreatingJourney} setIsCreatingJourney={setIsCreatingJourney} />
        </>
      ) : (
        <>
          <CustomText
            text="Project Portfolio"
            size="s"
            weight="ultraBold"
            textType="display"
            style={{ color: "#101828" }}
          />
          <CustomSpacing type="vertical" size="xs" />

          <ProjectsView />
        </>
      )}
    </ComingSoonLayout>
  );
}
const styles = StyleSheet.create({
  coursesDescription: {
    color: "#667085",
    marginTop: 8,
    fontSize: 16,
    lineHeight: 24,
    fontFamily: "Inter",
    fontWeight: 300,
    marginHorizontal: 4,
  },
});

export default PortfolioView;
