import * as React from "react";
import Svg, { Path } from "react-native-svg";

function DarkGraduationCap(props) {
  return (
    <Svg width={20} height={19} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M19.516 5.679l-9.063-5a.937.937 0 00-.906 0l-9.063 5a.938.938 0 000 1.64l1.703.938v3.731c0 .384.142.755.398 1.04 1.058 1.183 3.43 3.16 7.415 3.16a10.695 10.695 0 003.125-.45v2.012a.938.938 0 001.875 0v-2.803a9.338 9.338 0 002.415-1.915c.256-.286.398-.656.398-1.04V8.26l1.703-.937a.937.937 0 000-1.641v-.003zM10 2.57L17.122 6.5l-2.59 1.43a.154.154 0 01-.015-.01L10.455 5.68a.938.938 0 00-.907 1.64L12.595 9 10 10.428 2.878 6.5 10 2.571zm0 11.742c-3.158 0-5.05-1.49-5.938-2.444V9.292l5.485 3.027a.938.938 0 00.906 0l2.672-1.471v2.909a8.629 8.629 0 01-3.125.556zm5.938-2.444c-.288.309-.602.591-.938.845V9.812l.938-.517v2.575z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default DarkGraduationCap;
