import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ConvoIcon(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M16.152 6.46A7.875 7.875 0 001.125 9.75v6A1.875 1.875 0 003 17.625h4.884a7.891 7.891 0 007.116 4.5h6a1.875 1.875 0 001.875-1.875v-6a7.875 7.875 0 00-6.723-7.79zM3.375 9.75A5.625 5.625 0 119 15.375H3.375V9.75zm17.25 10.125H15a5.637 5.637 0 01-4.594-2.378 7.869 7.869 0 006.427-8.566 5.625 5.625 0 013.792 5.319v5.625z"
        fill="#98A2B3"
      />
    </Svg>
  );
}

export default ConvoIcon;
