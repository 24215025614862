import DefaultLayout from "../layouts/DefaultLayout";
import { useState } from "react";
import { View, StyleSheet } from "react-native";
import CareerPathCardWide from "../components/career/CareerPathCardWide/CareerPathCardWide";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CareerPathNav from "../components/career/CareerPathNav/CareerPathNav";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { useNavigation } from "@react-navigation/native";
import {
  useSetCareerPathActiveMutation,
  useCareerByIdQuery,
  GetCareerHeaderDocument,
  GetCareerTopicsDocument,
} from "../graphql/generated/graphql";
import Loader from "../components/common/status/Loader/Loader";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";

function CareerDetailView({ route }) {
  const navigation = useNavigation();
  const { id } = route.params;
  const { isLarge } = useResponsiveScreen();

  const [activeTab, setActiveTab] = useState("description");

  const { data, loading, error } = useCareerByIdQuery({
    variables: { id },
  });
  const careerData = data?.getCareerById;

  const [setCareerPathActive] = useSetCareerPathActiveMutation({
    refetchQueries: [
      {
        query: GetCareerHeaderDocument,
      },
      {
        query: GetCareerTopicsDocument,
      },
    ],
  });

  const handleSetActivePress = async () => {
    await setCareerPathActive({
      variables: {
        careerId: id,
      },
    });
    navigation.navigate("Career");
  };

  const handleTabPress = (tabKey) => {
    setActiveTab(tabKey);
  };

  return (
    <DefaultLayout>
      {loading && (
        <View style={styles.loadingContainer}>
          <Loader />
        </View>
      )}
      {error && (
        <View style={styles.loadingContainer}>
          <ErrorLoader message={error.message} />
        </View>
      )}

      {!loading && !error && (
        <>
          {isLarge ? (
            <>
              <View style={styles.header}>
                <CustomButton
                  onPress={() => navigation.navigate("Career")}
                  style={styles.backButton}
                  text="Back to Careers"
                  useTranslationText={false}
                />
                <CustomButton style={styles.activeButton} text="Set as Active" onPress={handleSetActivePress} />
              </View>
              <View style={{ marginTop: 20 }}>
                <CareerPathCardWide
                  flexDirection={isLarge ? "row" : "column"}
                  job={careerData?.name}
                  salary={careerData?.salary}
                  growth={careerData?.growth}
                  skills={careerData?.core_skills}
                  url={careerData?.url}
                />
              </View>

              <CareerPathNav
                flexDirection={isLarge ? "column" : "row"}
                onTabPress={handleTabPress}
                activeTab={activeTab}
                tabData={careerData}
              />
            </>
          ) : (
            <>
              <View style={styles.header}>
                <CustomButton
                  onPress={() => navigation.navigate("Career")}
                  style={styles.backButton}
                  text="Back to Careers"
                />
                <CustomButton style={styles.activeButton} text="Set as Active" onPress={handleSetActivePress} />
              </View>

              <View style={{ marginTop: 20 }}>
                <CareerPathCardWide
                  flexDirection={isLarge ? "row" : "column"}
                  job={careerData?.name}
                  salary={careerData?.salary}
                  growth={careerData?.growth}
                  skills={careerData?.core_skills}
                  url={careerData?.url}
                />
              </View>

              <CareerPathNav
                flexDirection={isLarge ? "column" : "row"}
                onTabPress={handleTabPress}
                activeTab={activeTab}
                tabData={careerData}
              />
            </>
          )}
        </>
      )}
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  loadingContainer: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  activeButton: {
    backgroundColor: "#5BE0681A",
  },
  backButton: {
    borderRadius: 0,
  },
});

export default CareerDetailView;
