import { MotiView } from "moti";
import ActiveCard from "../ActiveCard/ActiveCard";
import { View, StyleSheet, Pressable, Text, ScrollView } from "react-native";
import useResponsiveScreen from "../../../hooks/useResponsiveScreen";
import CustomText from "../../../components/common/general/CustomText/CustomText";
import CustomSpacing from "../../../components/common/layout/CustomSpacing/CustomSpacing";
import CustomButton from "../../common/general/CustomButton/CustomButton";

function ActiveCardList({ activeCardData, onPress, onViewallPressed }) {
  const { isXLarge } = useResponsiveScreen();
  const { id, stackTitle, customText, text, buttonText, data } = activeCardData;

  return (
    <MotiView
      key={id}
      from={{
        opacity: 0,
        translateY: 20,
        scale: 0.95,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
        scale: 1,
      }}
      delay={100}
      transition={{ type: "timing", duration: 800 }}
      style={isXLarge ? styles.motiContainer : styles.motiContainerMobile}>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={styles.headerContainer} role="header">
          <CustomText
            text={`Active ${stackTitle}`}
            textType="display"
            size="xs"
            weight={isXLarge ? "bold" : "ultraBold"}
          />
          {data && data.length != 0 && (
            <CustomButton
              text="View all"
              styleType="primaryTransparent"
              onPress={() => onViewallPressed(stackTitle)}
              textStyle={styles.viewAllButton}
              bold
              aria-label={`View all ${stackTitle}`}
              role="button"
              accessibilityHint={`Redirects to a page to view all ${stackTitle}`}
            />
          )}
        </View>
      </MotiView>
      <CustomSpacing type="vertical" size="xl" />
      {(!data || data.length === 0) && (
        <View
          style={{
            // height: "100%",
            minHeight: 350,
            justifyContent: "space-between",
          }}
          role="alert"
          aria-label={`No active ${stackTitle} available`}>
          <View
            style={{
              borderRadius: 20,
              padding: 60,
              // height: "50%",
              backgroundColor: "#F9FAFB",
              alignItems: "center",
              justifyContent: "center",
            }}>
            <View
              style={{
                alignItems: "center",
                textAlign: "center",
                marginBottom: 10,
              }}>
              <CustomText style={{ textAlign: "center" }} text={customText} size="xl" weight="medium" textType="text" />
              <CustomText
                text={text}
                style={{ color: "#A9A9A9", textAlign: "center" }}
                size="m"
                weight="normal"
                textType="text"
              />
            </View>
            <Pressable
              onPress={() => onViewallPressed(stackTitle)}
              style={styles.button}
              role="button"
              aria-label={buttonText}
              accessibilityHint={`Press to view all ${stackTitle}`}>
              <Text style={styles.buttonText}>{buttonText}</Text>
            </Pressable>
          </View>
        </View>
      )}
      {data && (
        <ScrollView
          horizontal={!isXLarge}
          showsHorizontalScrollIndicator={false}
          style={isXLarge ? styles.verticalScroll : styles.horizontalScroll}
          role="grid">
          {data.map((card, index) => {
            return (
              <View
                key={index}
                aria-label={`Active card for ${card.name || "Unnamed"}`}
                accessibilityHint={`Press to view details for ${card.name || "this item"}`}>
                <ActiveCard
                  key={`${card.id}-${index}`}
                  id={card.id}
                  data={card}
                  stackTitle={stackTitle}
                  onPress={onPress}
                  progressCurrent={500}
                  progressGoal={1000}
                  color="#85E139"
                />
              </View>
            );
          })}
        </ScrollView>
      )}
    </MotiView>
  );
}

const styles = StyleSheet.create({
  horizontalScroll: {
    flexDirection: "row",
  },
  verticalScroll: {
    flexDirection: "column",
  },
  headerContainer: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  motiContainer: {
    flexGrow: 1,
    width: "45%",
  },
  motiContainerMobile: {
    flexGrow: 1,
  },
  button: {
    backgroundColor: "#3E68FE",
    borderRadius: 8,
    paddingVertical: 10,
    paddingHorizontal: 20,
    justifyContent: "center",
    alignItems: "center",
  },
  buttonText: {
    color: "#FFFFFF",
    fontWeight: "bold",
  },
  viewAllButton: {
    fontSize: 16,
    color: "#667085",
  },
});

export default ActiveCardList;
