import React, { useEffect } from "react";
import { createContext, useContext, useState } from "react";
import { AccessibilityInfo, findNodeHandle } from "react-native";

export const AccessibilityContext = createContext({
  screenReaderIsEnabled: false,
  setFocus: () => {},
  setScreenReaderIsEnabled: () => {},
});

export const AccessibilityProvider = ({ children }) => {
  const [screenReaderIsEnabled, setScreenReaderIsEnabled] = useState(false);

  const updateScreenReaderStatus = async (isActive) => {
    setScreenReaderIsEnabled(isActive);
  };

  const setFocus = ({ ref }) => {
    const reactTag = findNodeHandle(ref.current);
    if (reactTag) {
      AccessibilityInfo.setAccessibilityFocus(reactTag);
    }
  };

  useEffect(() => {
    const screenReaderListener = AccessibilityInfo.addEventListener("screenReaderChanged", (isActive) => {
      updateScreenReaderStatus(isActive);
    });

    return () => {
      screenReaderListener.remove();
    };
  }, []);

  return (
    <AccessibilityContext.Provider
      value={{
        screenReaderIsEnabled,
        setFocus,
        setScreenReaderIsEnabled,
      }}>
      {children}
    </AccessibilityContext.Provider>
  );
};

export const useAccessibilityContext = () => useContext(AccessibilityContext);
