import * as React from "react";
import Svg, { Path } from "react-native-svg";

function ElectiveHeader(props) {
  return (
    <Svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Path
        d="M13.314 4.27a1.314 1.314 0 00-2.629-.01c-.008 2.417-.64 3.938-1.611 4.876-.983.95-2.534 1.517-4.828 1.55a1.314 1.314 0 00.019 2.628c2.548 0 4.062.673 4.966 1.65.924 1 1.434 2.556 1.454 4.782a1.314 1.314 0 002.627.054c.118-2.352.744-3.931 1.715-4.921.955-.974 2.438-1.574 4.702-1.565a1.314 1.314 0 00.034-2.629c-2.486-.053-4.036-.71-4.975-1.686-.942-.978-1.483-2.498-1.474-4.73z"
        fill="#7839EE"
      />
    </Svg>
  );
}

export default ElectiveHeader;
