import React from "react";
import { MotiView } from "moti";
import { View, Text, Image, Pressable, StyleSheet } from "react-native";
import LearnProgressCircle from "../../learning/general/LearnProgressCircle/LearnProgressCircle";
import { shadowStyles } from "../../../styles/shadow";

function CourseCard({ title, description, fillPercentage, logoUri, label, onPress }) {
  const isContinueCourse = fillPercentage > 0;

  const formattedLabel = label.charAt(0).toUpperCase() + label.slice(1);

  return (
    <MotiView
      aria-label="school card"
      from={{
        opacity: 0,
        translateY: 20,
      }}
      animate={{
        opacity: 1,
        translateY: 0,
      }}
      delay={1000}
      transition={{ type: "timing", duration: 500 }}>
      <View style={[styles.card, shadowStyles.primary]}>
        <View style={styles.infoContainer}>
          <View>
            <View style={styles.tagContainer}>
              <View style={styles.tag}>
                <Text style={styles.tagText}>{formattedLabel}</Text>
              </View>
            </View>
            <View style={styles.detailsContainer}>
              <Text style={styles.titleText} numberOfLines={2} ellipsizeMode="tail">
                {title}
              </Text>
              <View style={styles.descriptionContainer}>
                <Text style={styles.descriptionText} numberOfLines={2} ellipsizeMode="tail">
                  {description}
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.buttonContainer}>
            <Pressable
              onPress={onPress}
              style={isContinueCourse ? styles.continueButton : styles.startButton}
              role="button"
              aria-label={isContinueCourse ? "Continue course" : "Start course"}
              accessible={true}>
              <Text style={isContinueCourse ? styles.continueButtonText : styles.startButtonText}>
                {isContinueCourse ? "Continue" : "Start"}
              </Text>
            </Pressable>

            <View style={styles.progressContainer}>
              {fillPercentage > 0 && (
                <LearnProgressCircle ariaLabel="Learn progress circle" value={fillPercentage.toFixed(0)} />
              )}
            </View>

            {logoUri && (
              <Image
                style={styles.progressContainer}
                source={{ uri: logoUri }}
                accessibilityLabel={title}
                alt={description}
                role={"image"}
              />
            )}
          </View>
        </View>
      </View>
    </MotiView>
  );
}

const styles = StyleSheet.create({
  card: {
    marginTop: 10,
    marginBottom: 20,
    marginHorizontal: 10,
    flex: 1,
    maxWidth: 280,
    minWidth: 280,
    flexDirection: "column",
    padding: 20,
    borderRadius: 10,
    marginVertical: 10,
  },
  infoContainer: {
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 1,
    gap: 8,
  },
  tagContainer: {
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignContent: "center",
    marginBottom: 8,
  },
  tag: {
    justifyContent: "center",
    paddingLeft: 8,
    paddingRight: 8,
    paddingTop: 4,
    paddingBottom: 4,
    backgroundColor: "#E6EBFF",
    borderRadius: 100,
    alignContent: "center",
  },
  tagText: {
    color: "#022CC4",
    fontSize: 10,
    fontWeight: "700",
    wordWrap: "break-word",
  },
  detailsContainer: {
    alignSelf: "stretch",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "flex",
  },
  titleText: {
    alignSelf: "stretch",

    color: "#2D2F36",
    fontSize: 24,
    fontWeight: "700",
    wordWrap: "break-word",
  },
  descriptionContainer: {
    alignSelf: "stretch",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 10,
    display: "flex",
    marginTop: 8,
  },
  descriptionText: {
    alignSelf: "stretch",
    color: "#4B2359",
    fontSize: 14,
    fontWeight: "400",
    wordWrap: "break-word",
  },
  buttonContainer: {
    flexDirection: "row",
    alignSelf: "stretch",
    justifyContent: "space-between",
    alignItems: "flex-start",
    display: "inline-flex",
  },
  continueButton: {
    padding: 12,
    backgroundColor: "#3E68FE",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    display: "flex",
  },
  startButton: {
    padding: 12,
    color: "#3E68FE",
    borderRadius: 10,
    justifyContent: "center",
    alignItems: "center",
    gap: 4,
    display: "flex",
    borderColor: "#3E68FE",
    borderWidth: 2,
    borderStyle: "solid",
  },
  continueButtonText: {
    color: "white",
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 20,
    wordWrap: "break-word",
  },
  startButtonText: {
    color: "#3E68FE",
    fontSize: 14,
    fontWeight: "700",
    lineHeight: 20,
    wordWrap: "break-word",
  },
  progressContainer: {
    width: 44,
    height: 44,
  },
});

export default CourseCard;
