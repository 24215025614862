import React from "react";
import { View, Pressable, StyleSheet } from "react-native";
import { useAppConfig } from "../../../../AppConfigProvider";

const SelectableList = ({ items, onSelect, selectedItem, ariaLabel = "group of radio buttons" }) => {
  const { primaryColor } = useAppConfig();

  const handleSelect = (item) => {
    onSelect(item);
  };

  return (
    <View style={styles.container} role="radiogroup" aria-label={ariaLabel}>
      {items.map((item, index) => (
        <Pressable
          key={index}
          style={[
            styles.item,
            selectedItem === item.value && {
              ...styles.selectedItem,
              borderColor: primaryColor,
            },
          ]}
          onPress={() => handleSelect(item.value)}
          role="radio">
          {item.component}
        </Pressable>
      ))}
    </View>
  );
};

export default SelectableList;

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    alignItems: "center",
    paddingVertical: 10,
  },
  item: {
    borderWidth: 2,
    borderColor: "transparent",
    borderRadius: 10,
    marginHorizontal: 4,
    justifyContent: "center",
    alignItems: "center",
  },
  selectedItem: {
    borderWidth: 2,
  },
});
