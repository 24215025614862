import { useEffect } from "react";
import { usePostHog } from "posthog-js/react";
import { View, StyleSheet } from "react-native";
import CustomText from "../components/common/general/CustomText/CustomText";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { MotiView } from "moti";
import Divider from "../components/common/layout/Divider/Divider";
import CoursePlanListContainer from "../containers/school/CoursePlanListContainer.js/CoursePlanListContainer";
import ComingSoonLayout from "../layouts/ComingSoonLayout.web";

function AcademicPlanView() {
  const posthog = usePostHog();
  const { isMedium } = useResponsiveScreen();

  useEffect(() => {
    posthog?.capture("School Page Visited");
  }, []);

  return (
    <ComingSoonLayout>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={10}
        transition={{ type: "timing", duration: 500 }}>
        <View style={styles.subheaderContainer}>
          <CustomText
            useTranslationText={false}
            text={"Your Academic Plan"}
            size={isMedium ? "l" : "xs"}
            textType="display"
            weight="ultraBold"
            role="heading"
            aria-level="1"
          />
          <CustomText
            useTranslationText={false}
            text="Review your courses, customize your plan, and start your journey."
            size="m"
            weight="regular"
            style={styles.coursesDescription}
          />
          <Divider />
        </View>
      </MotiView>
      <MotiView
        from={{
          opacity: 0,
          translateY: 20,
          scale: 1,
        }}
        animate={{
          opacity: 1,
          translateY: 0,
          scale: 1,
        }}
        delay={500}
        transition={{ type: "timing", duration: 600 }}>
        <CustomSpacing type="vertical" size="s" />

        <CustomSpacing type="vertical" size="l" />
      </MotiView>
      <CoursePlanListContainer type="core" />

      <CustomSpacing type="vertical" size="l" />
      <CustomSpacing type="vertical" size="m" />

      <CoursePlanListContainer type="general" />

      <CustomSpacing type="vertical" size="l" />
      <CustomSpacing type="vertical" size="m" />

      <CoursePlanListContainer type="elective" />

      <CustomSpacing type="vertical" size="l" />
      <CustomSpacing type="vertical" size="l" />
    </ComingSoonLayout>
  );
}

const styles = StyleSheet.create({
  subheaderContainer: {
    justifyContent: "flex-start",
    flexDirection: "column",
    alignItems: "flex-start",
    marginLeft: 4,
  },
  coursesDescription: {
    color: "#667085",
    marginTop: 8,
    lineHeight: 20,
    marginHorizontal: 4,
    marginBottom: 20,
  },
});

export default AcademicPlanView;
