import * as React from "react";
import Svg, { Circle } from "react-native-svg";

function SvgComponent(props) {
  return (
    <Svg width={26} height={26} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <Circle cx={13} cy={13} r={11} fill="#5BE068" />
      <Circle cx={13} cy={13} r={13} fill="#5BE068" fillOpacity={0.2} />
    </Svg>
  );
}

export default SvgComponent;
