import { Platform, Alert } from "react-native";
import * as Sharing from "expo-sharing";
import RNHTMLtoPDF from "react-native-html-to-pdf";

export async function handleExportCustomCoursePress(data) {
  try {
    const courseName = data?.title || "course";

    const htmlContent = `
      <h1>${courseName}</h1>
      <h2>Course Details</h2>
      <p><strong>Last Modified:</strong> ${data?.last_modified || "Not modified"}</p>
      <p><strong>Objectives:</strong> ${data?.objectives?.join(", ") || "No objectives"}</p>
      <p><strong>Standard:</strong> ${data?.learningStandards || "No standard"}</p>
      <p><strong>Weeks:</strong> ${data?.lengthInWeeks || "N/A"}</p>

      <h3>Modules:</h3>
      ${data?.modules
        .map(
          (module) => `
        <p><strong>${module?.title || "Unnamed Module"}</strong></p>
        ${module?.resources[0].assignments.length > 0 ? "<h4>Assignments</h4>" : ""}
        <ul>
          ${module?.resources[0].assignments
            .map(
              (assignment) => `
            <li>
              ${assignment?.body || "<p>No content available</p>"}
            </li>
          `
            )
            .join("")}
        </ul>
        ${module?.resources[0].pages.length > 0 ? "<h4>Pages</h4>" : ""}
        <ul>
          ${module?.resources[0].pages
            .map(
              (page) => `
            <li>
              ${page?.body || "<p>No content available</p>"}
            </li>
          `
            )
            .join("")}
        </ul>
      `
        )
        .join("")}
    `;

    if (Platform.OS === "web") {
      const jsPDF = (await import("jspdf")).default;
      const doc = new jsPDF();

      doc.setFontSize(12);
      let yPos = 10;
      const pageHeight = 280;

      const checkPageOverflow = (doc, yPos) => {
        if (yPos >= pageHeight) {
          doc.addPage();
          return 10;
        }
        return yPos;
      };

      doc.text(`Course: ${courseName}`, 10, yPos);
      yPos += 10;

      const details = [
        `Last Modified: ${data?.last_modified || "Not modified"}`,
        `Objectives: ${data?.objectives?.join(", ") || "No objectives"}`,
        `Standard: ${data?.learningStandards || "No standard"}`,
        `Weeks: ${data?.lengthInWeeks || "N/A"}`,
      ];

      details.forEach((detail) => {
        doc.text(detail, 10, yPos);
        yPos += 10;
        yPos = checkPageOverflow(doc, yPos);
      });

      data?.modules.forEach((module) => {
        doc.setFontSize(12);
        doc.text(`Module: ${module?.title || "Unnamed Module"}`, 10, yPos);
        yPos += 10;
        yPos = checkPageOverflow(doc, yPos);

        doc.text(module?.resources[0].assignments.length > 0 ? "Assignments" : "", 13, yPos);
        yPos += 10;
        yPos = checkPageOverflow(doc, yPos);

        module?.resources[0].assignments.forEach((assignment) => {
          doc.setFontSize(10);

          const assignmentName = doc.splitTextToSize(`${assignment?.title || "Unnamed Assignment"}`, 180);
          doc.text(assignmentName, 15, yPos);
          yPos += assignmentName.length * 10;

          const assignmentHTML = doc.splitTextToSize(
            `${assignment?.body ? assignment.body.replace(/<[^>]*>?/gm, "") : "No content available"}`,
            180
          );
          doc.text(assignmentHTML, 15, yPos);
          yPos += assignmentHTML.length * 10;

          yPos = checkPageOverflow(doc, yPos);
        });

        doc.text(module?.resources[0].pages.length > 0 ? "Pages" : "", 13, yPos);
        yPos += 10;
        yPos = checkPageOverflow(doc, yPos);

        module?.resources[0].pages.forEach((page) => {
          doc.setFontSize(10);

          const pageName = doc.splitTextToSize(`${page?.title || "Unnamed Page"}`, 180);
          doc.text(pageName, 15, yPos);
          yPos += pageName.length * 10;

          const pageHTML = doc.splitTextToSize(
            `${page?.body ? page.body.replace(/<[^>]*>?/gm, "") : "No content available"}`,
            180
          );
          doc.text(pageHTML, 15, yPos);
          yPos += pageHTML.length * 10;

          yPos = checkPageOverflow(doc, yPos);
        });
      });

      doc.save(`${courseName.replace(/\s/g, "_")}_export.pdf`);
      Alert.alert("Export Successful", `File has been downloaded.`);
    } else {
      const pdfFile = await RNHTMLtoPDF.convert({
        html: htmlContent,
        fileName: courseName.replace(/\s/g, "_"),
        base64: false,
      });

      if (await Sharing.isAvailableAsync()) {
        await Sharing.shareAsync(pdfFile.filePath, {
          mimeType: "application/pdf",
          dialogTitle: "Export Course",
          UTI: "com.adobe.pdf",
        });
      } else {
        Alert.alert("Sharing not available", "Cannot share files on this platform.");
      }
    }
  } catch (error) {
    console.error("Error exporting data:", error);
    Alert.alert("Export Failed", "An error occurred while exporting the data.");
  }
}
