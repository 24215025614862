import React from "react";
import { View } from "react-native";
import Placeholder from "../../general/Placeholder/Placeholder";
import { MotiView } from "moti";
import AnnouncementCard from "../AnnouncementCard/AnnouncementCard";

function AnnouncementCardList({ items = [], onPress }) {
  return (
    <View role="grid">
      {items.length > 0 ? (
        items.map(({ id, body, created_date }, index) => (
          <MotiView
            key={index}
            from={{
              opacity: 0,
              translateY: 20,
              scale: 0.95,
            }}
            animate={{
              opacity: 1,
              translateY: 0,
              scale: 1,
            }}
            delay={300 + index * 300}
            transition={{ type: "timing", duration: 800 }}>
            <AnnouncementCard key={id} text={body} createdDate={created_date} onPress={() => onPress(id)} />
          </MotiView>
        ))
      ) : (
        <View style={{ flex: 1, justifyContent: "center" }}>
          <Placeholder subtitle="It look like there are no announcements yet." />
        </View>
      )}
    </View>
  );
}

export default AnnouncementCardList;
