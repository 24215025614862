import React, { createContext, useContext, useMemo, useState } from "react";

const ChatBarInputContext = createContext({
  inputValue: "",
  // eslint-disable-next-line no-unused-vars
  setInputValue: (input) => {},
});

export const ChatBarInputProvider = ({ children }) => {
  const [inputValue, setInputValue] = useState("");

  const value = useMemo(() => ({ inputValue, setInputValue }), [inputValue]);

  return <ChatBarInputContext.Provider value={value}>{children}</ChatBarInputContext.Provider>;
};

export const useChatBarInput = () => {
  return useContext(ChatBarInputContext);
};
