import { View, StyleSheet } from "react-native";
import ClassCard from "../ClassCard/ClassCard";
import { classCardColors, DEFAULT_CLASS_COLOR } from "../../../constants/classCardColors";

function ClassCardList({ courses = [], onPress }) {
  return (
    <View role="grid" aria-label="Classes cards" style={styles.container}>
      {courses.map((course, index) => {
        const color = classCardColors.find((color) => color.id === course.color)?.color ?? DEFAULT_CLASS_COLOR;

        return (
          <ClassCard
            key={index}
            title={course?.name}
            description={course?.description}
            onPress={() => onPress(course?.id)}
            color={color}
          />
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    flexDirection: "row",
  },
});

export default ClassCardList;
